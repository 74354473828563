import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
//import AppTheme from '../shared-theme/AppTheme';
//import AppAppBar from './Utah-components/AppAppBar';
import AppAppBar from './Saltlake-components/AppAppBar1';
import MainContent from './Saltlake-components/MainContent1';
import Latest from './Saltlake-components/Latest1';
//import Footer from './Utah-components/Footer';
import AppTheme from '../../shared-theme/AppTheme';

export default function BlogSaltlake(props) {
  return (
    <AppTheme {...props} >
      <CssBaseline enableColorScheme />
      {/* <AppAppBar /> */}
      <div className='bg-black'>
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column',  mt: { xs: 10, sm: 16, md: 18, lg:12 }, gap: 4, pt:4 }}
      >
      {/* <Container
  maxWidth="lg"
  component="main"
  sx={{
    display: 'flex',
    flexDirection: 'column',
    my: 16,
    gap: 4,
    bgcolor: 'black', // Set background to black
    color: 'white',   // Ensure text is visible
    minHeight: '100vh' // Make sure it covers the full screen
  }}
> */}

        <MainContent />
        <Latest />
      </Container>
      </div>
      {/* <Footer /> */}
    </AppTheme>
  );
}