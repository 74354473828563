import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";


const map = "/img/finance3.jpg";
const county = "/img/counties1.png";
const city = "/img/city2.png";

const jobs = "/img/jobs.png";
const population = "/img/population.png";
const unemployment = "/img/unemployment.webp";
const university = "/img/university.png";
const dfi = "/img/dfi.png";

const governor = "/img/governor-img.webp";

const zip = "/img/zip.png";


export default function Finance() {
  // const data = [
  //   { label: "Total Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahCounty},
  //   { label: "Total Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="city" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahCities},
  //   { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="population" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahPopulation },
  //   { label: "Total Jobs In Utah", value: "1,769,700 (2024)" , icon: <img src={jobs} alt="jobs" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahJobs},
  //   { label: "Total Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="university" className="h-20 w-18 filter brightness-0 invert" />  , backgroundImage: utahUniversity},
  //   { label: "Total Unemployment Rate in Utah", value: "3.50% (2024)" , icon: <img src={unemployment} alt="unemployment" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahUnemployment},
  //   { label: "Total Industries in Utah", value: "645 (2024)" , icon: <img src={industries} alt="industries" className="h-12 w-12 filter brightness-0 invert" /> , backgroundImage: utahIndustries},
  // ];

  // const data = [
  //   { label: "Total Counties in Utah", value: "29", icon: <img src={county} alt="Counties" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Cities in Utah", value: "329 (2024)", icon: <img src={city} alt="City" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]"},
  //   { label: "Total Population of Utah", value: "3.45 Million (2024)", icon: <img src={population} alt="Population" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]"},
  //   { label: "Total Jobs In Utah", value: "1,769,700 (2024)", icon: <img src={jobs} alt="Jobs" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Universities in Utah", value: "36 (2024)", icon: <img src={university} alt="University" className="h-24 w-18 filter " />,gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Unemployment Rate in Utah", value: "3.50% (2024)", icon: <img src={unemployment} alt="Unemployment" className="h-20 w-18 filter" />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  //   { label: "Total Industries in Utah", value: "645 (2024)", icon: <img src={industries} alt="Industries" className="h-20 w-18 filter " />, gradientClass: "bg-[radial-gradient(circle,_#30cfd0_0%,_#330867_100%)]" },
  // ];

  const data = [
    {
      label: "National Banks",
      value: "Total:13",
      icon: (
        <img
          src={dfi}
          alt="governor"
          className="h-16 w-16 filter "
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/national-bank",
    },
    {
      label: "State Banks",
      value: "Total:19",
      icon: <img src={dfi} alt="Counties"  className="h-16 w-16 filter "/>,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/state-bank",
    },
    {
      label: "Out-of-State State Banks",
      value: "Total:8",
      icon: <img src={dfi} alt="City"  className="h-16 w-16 filter " />,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/out-of-state",
    },
    {
      label: "State Industrial Banks",
      value: "Total:16",
      icon: <img src={dfi} alt="Zip code"  className="h-16 w-16 filter "/>,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/state-industrial",
    },
    {
      label: "Federal Credit Unions",
      value: "Total:32",
      icon: (
        <img src={dfi} alt="Population"  className="h-16 w-16 filter "/>
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/fedral-credit",
    },
    {
      label: "Federal Saving Associations",
      value: "Total:2",
      icon: <img src={dfi} alt="Jobs"  className="h-16 w-16 filter "/>,
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/fedral-savings",
    },
    {
      label: "State Credit Unions",
      value: "Total:22",
      icon: (
        <img src={dfi} alt="University"  className="h-16 w-16 filter "/>
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/state-credit",
    },
    {
      label: "Trust Company",
      value: "Total:1",
      icon: (
        <img
          src={dfi}
          alt="Unemployment"
          className="h-16 w-16 filter "
        />
      ),
      gradientClass: "bg-[linear-gradient(135deg,_#fdfcfb_0%,_#e2d1c3_100%)]",
      link: "/utah-at-a-glance/department-of-financial-institution/trust-company",
    },
   

  ];

  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);

 

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar Section */}

      <div
        className="relative flex-grow flex flex-col items-center justify-center mt-20 px-4 bg-cover bg-center bg-no-repeat "
        aria-label="Map Utah"
        style={{ backgroundImage: `url(${map})` }}
      >
        {/* Overlay */}
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-blue-700 opacity-60"></div>

        {/* Content with relative positioning to sit above the overlay */}
        <div className="relative text-center mt-5 p-1 rounded-md  inline-block px-6 py-2">
          <h1 className="text-3xl md:text-6xl font-bold text-[#ffc107]">
            <div className="flex items-center gap-4">
             
              <h1> Financial Institutions</h1>
              
            </div>
          </h1>
        </div>

        <div className="relative flex flex-wrap justify-center items-stretch gap-8 p-6 rounded-lg">
          {data.map((item, index) => (
            <div
              key={index}
              className={`relative p-4 w-full sm:w-1/2 md:w-1/4 flex flex-col justify-center items-center text-center
      overflow-hidden rounded-lg shadow-lg transform transition-transform duration-100 ease-in-out
      hover:scale-105 hover:shadow-2xl group  ${item.gradientClass}`}
            >
              <Link to={item.link} className="no-underline">
                {/* Background Image with Scaling Effect */}
                <div
                  className="absolute inset-0 bg-cover bg-center transform transition-transform duration-100 ease-in-out group-hover:scale-150 "
                  style={{ backgroundImage: `url(${item.backgroundImage})` }}
                ></div>

                {/* Overlay for Dark Effect */}
                {/* <div className="absolute inset-0 bg-blue-800 opacity-30"></div> */}

                {/* Content on top of the background */}
                <div className="relative z-10 flex flex-col items-center">
                  {/* <div className="mb-3 opacity-80">{item.icon}</div> */}
                  <strong className="block text-blue-800 text-xl">
                    {item.label}
                  </strong>
                  <span className="block text-blue-800 text-lg">
                    {item.value}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
    </div>
  );
}
