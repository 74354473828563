import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const EngTable7 = ({ data }) => {
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const pdfRef = useRef();

  // Calculate average for each age group
  const calculateAverage = (data, years) => {
    return data.map((ageGroup) => {
      const total = years.reduce(
        (sum, year) => sum + parseInt(ageGroup[`year_${year}`]),
        0
      );
      const average = total / years.length;
      return { agegrp_label: ageGroup.agegrp_label, average };
    });
  };

  // Prepare chart data
  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];
  const averages = calculateAverage(data, years);

  const datasets = data.map((ageGroup) => ({
    label: `Age Group ${ageGroup.agegrp_label}`,
    data: years.map((year) => parseInt(ageGroup[`year_${year}`])),
    borderColor: getRandomColor(),
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: 2,
    tension: 0.4,
    pointBackgroundColor: "white",
    pointBorderColor: getRandomColor(),
    pointBorderWidth: 2,
    pointRadius: 5,
    pointHoverRadius: 7,
    fill: true,
  }));

  // Add average dataset
  datasets.push({
    label: "Average Employment",
    data: averages.map((item) => item.average),
    borderColor: "black",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderWidth: 2,
    tension: 0.4,
    pointBackgroundColor: "black",
    pointBorderColor: "black",
    pointBorderWidth: 2,
    pointRadius: 5,
    pointHoverRadius: 7,
    fill: true,
  });

  const chartData = {
    labels: years,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: "Yearly Employment by Age Group with Averages",
        font: {
          size: 18,
        },
      },
    },
    elements: {
      line: {
        borderJoinStyle: "round",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Employment Numbers",
        },
      },
      x: {
        title: {
          display: true,
          text: "Year",
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295);

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("eng_table1.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <>
      <div className="dashboard-container">
        <div ref={pdfRef} className="content-to-export">
          <div style={{ padding: "20px" }}>
            <h2>Yearly Employment by Age Group with Averages</h2>

            {/* Render Table with Horizontal Scrolling */}
            <div className="overflow-x-auto">
              <table className="table table-striped">
                <thead>
                  <th>Age Group</th>
                  {years.map((year) => (
                    <th key={year}>{year}</th>
                  ))}
                  <th>Average</th>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.agegrp_label}</td>
                      {years.map((year) => (
                        <td key={year}>{row[`year_${year}`]}</td>
                      ))}
                      <td>{averages[index].average.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Line Chart Section */}
            <div
              style={{
                width: "90%",
                maxWidth: "800px",
                margin: "auto",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  minWidth: "600px",
                }}
              >
                <Line data={chartData} options={options} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={downloadPDF}
            className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
          >
            <span>Download as PDF </span>
            <span>
              <img className="w-[2rem]" src={pdf} alt="Download icon" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default EngTable7;
