import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";

import "../styles/AerospaceFaqSection.css";

import config from "../config/config.json";

import EngTable1 from "../EngineeringTables/EngTable1";
import EngTable2 from "../EngineeringTables/EngTable2";
import EngTable3 from "../EngineeringTables/EngTable3";
import EngTable4 from "../EngineeringTables/EngTable4";
import EngTable5 from "../EngineeringTables/EngTable5";
import EngTable6 from "../EngineeringTables/EngTable6";
import EngTable7 from "../EngineeringTables/EngTable7";
import EngTable8 from "../EngineeringTables/EngTable8";
import EngTable9 from "../EngineeringTables/EngTable9";
import EngTable10 from "../EngineeringTables/EngTable10";
import EngTable11 from "../EngineeringTables/EngTable11";
import EngTable12 from "../EngineeringTables/EngTable12";
import EngTable13 from "../EngineeringTables/EngTable13";
import EngTable14 from "../EngineeringTables/EngTable14";
import EngTable15 from "../EngineeringTables/EngTable15";
import EngTable16 from "../EngineeringTables/EngTable16";
import EngTable17 from "../EngineeringTables/EngTable17";
import EngTable18 from "../EngineeringTables/EngTable18";
import EngTable19 from "../EngineeringTables/EngTable19";
import EngTable21 from "../EngineeringTables/EngTable21";
import EngTable22 from "../EngineeringTables/EngTable22";
import EngTable23 from "../EngineeringTables/EngTable23";
import EngTable25 from "../EngineeringTables/EngTable25";

const EngineeringFaqSection = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Employment-Trend");

  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [expandedSubQuestion, setExpandedSubQuestion] = useState(null);

  const [data_sex_growth_data, set_data_sex_growth_data] = useState(null);
  const [data_yearly_sex_employment, set_data_data_yearly_sex_employment] =
    useState(null);
  const [
    data_female_employment_with_average,
    set_data_female_employment_with_average,
  ] = useState(null);
  const [data_male_employment_growth, set_data_male_employment_growth] =
    useState(null);
  const [data_all_sexes_employment, set_data_all_sexes_employment] =
    useState(null);
  const [data_employment_by_agegrp, set_data_employment_by_agegrp] =
    useState(null);
  const [
    data_employment_by_agegrp_with_average,
    set_data_employment_by_agegrp_with_average,
  ] = useState(null);
  const [
    data_employment_25_34_growth_rate,
    set_data_employment_25_34_growth_rate,
  ] = useState(null);
  const [
    data_employment_share_by_agegrp_2023,
    set_data_employment_share_by_agegrp_2023,
  ] = useState(null);
  const [data_employment_2023_by_agegrp, set_data_employment_2023_by_agegrp] =
    useState(null);
  const [
    data_employment_2023_female_percentage,
    set_data_employment_2023_female_percentage,
  ] = useState(null);
  const [
    data_get_employment_data_by_race,
    set_data_get_employment_data_by_race,
  ] = useState(null);
  const [
    data_calculate_white_alone_percentage,
    set_data_calculate_white_alone_percentage,
  ] = useState(null);
  const [
    data_calculate_average_employment,
    set_data_calculate_average_employment,
  ] = useState(null);
  const [data_calculate_employment_trend, set_data_calculate_employment_trend] =
    useState(null);
  const [data_employment_by_education, set_data_employment_by_education] =
    useState(null);
  const [data_employment_by_bachelors, set_data_employment_by_bachelors] =
    useState(null);
  const [
    data_employment_growth_bachelors,
    set_data_employment_growth_bachelors,
  ] = useState(null);
  const [
    data_education_employment_growth,
    set_data_education_employment_growth,
  ] = useState(null);
  const [
    data_education_growth_percentage,
    set_data_education_growth_percentage,
  ] = useState(null);
  const [data_education_growth, set_data_education_growth] = useState(null);
  const [data_fetch_education_employment, set_data_fetch_education_employment] =
    useState(null);
  const [
    data_fetch_education_employment_19,
    set_data_fetch_education_employment_19,
  ] = useState(null);

  useEffect(() => {
    axios
      .get(config.APP_URL + "/technology/technology_verticals")
      .then((response) => {
        if (response.data.success) {
          set_data_sex_growth_data(response.data.data.data_sex_growth_data);
          set_data_data_yearly_sex_employment(
            response.data.data.data_yearly_sex_employment
          );
          set_data_female_employment_with_average(
            response.data.data.data_female_employment_with_average
          );
          set_data_male_employment_growth(
            response.data.data.data_male_employment_growth
          );
          set_data_all_sexes_employment(
            response.data.data.data_all_sexes_employment
          );
          set_data_employment_by_agegrp(
            response.data.data.data_employment_by_agegrp
          );
          set_data_employment_by_agegrp_with_average(
            response.data.data.data_employment_by_agegrp_with_average
          );
          set_data_employment_25_34_growth_rate(
            response.data.data.data_employment_25_34_growth_rate
          );
          set_data_employment_share_by_agegrp_2023(
            response.data.data.data_employment_share_by_agegrp_2023
          );
          set_data_employment_2023_by_agegrp(
            response.data.data.data_employment_2023_by_agegrp
          );
          set_data_employment_2023_female_percentage(
            response.data.data.data_employment_2023_female_percentage
          );
          set_data_get_employment_data_by_race(
            response.data.data.data_get_employment_data_by_race
          );
          set_data_calculate_white_alone_percentage(
            response.data.data.data_calculate_white_alone_percentage
          );
          set_data_calculate_average_employment(
            response.data.data.data_calculate_average_employment
          );
          set_data_calculate_employment_trend(
            response.data.data.data_calculate_employment_trend
          );
          set_data_employment_by_education(
            response.data.data.data_employment_by_education
          );
          set_data_employment_by_bachelors(
            response.data.data.data_employment_by_bachelors
          );
          set_data_employment_growth_bachelors(
            response.data.data.data_employment_growth_bachelors
          );
          set_data_education_employment_growth(
            response.data.data.data_education_employment_growth
          );
          set_data_education_growth_percentage(
            response.data.data.data_education_growth_percentage
          );
          set_data_education_growth(response.data.data.data_education_growth);
          set_data_fetch_education_employment(
            response.data.data.data_fetch_education_employment
          );
          set_data_fetch_education_employment_19(
            response.data.data.data_fetch_education_employment_19
          );
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const question = searchParams.get("question");

    if (tab) setActiveTab(tab);
    if (question) setExpandedQuestion(decodeURIComponent(question));
  }, [location.search]);

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setExpandedQuestion(null); // Reset expanded question when switching tabs
  };

  return (
    <div className="w-[90vw] h-auto p-1 pt-4 mt-[3rem] border border-gray-300 rounded-lg shadow-lg  justify-center items-center mx-auto">
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        {/* employment trend */}
        <Tab
          eventKey="Employment-Trend"
          title="Employment Trend"
          id="Employment-Trend"
        >
          <Accordion>
            <Accordion.Item
              eventKey="What is the percentage growth in the total number of professionals from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage growth in the total number of professionals from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the percentage growth in the total number of
                  professionals from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_sex_growth_data ? (
                  <EngTable1 data={data_sex_growth_data} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="In which year was the gender gap (difference between males
                  and females) the highest?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "In which year was the gender gap (difference between males and females) the highest?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. In which year was the gender gap (difference between males
                  and females) the highest?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_yearly_sex_employment ? (
                  <EngTable2 data={data_yearly_sex_employment} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the average number of female professionals across
                  the years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the average number of female professionals across the years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What is the average number of female professionals across
                  the years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_female_employment_with_average ? (
                  <EngTable3 data={data_female_employment_with_average} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the yearly growth rate of male professionals from
                  2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the yearly growth rate of male professionals from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the yearly growth rate of male professionals from
                  2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_male_employment_growth ? (
                  <EngTable4 data={data_male_employment_growth} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the total count of professionals (all sexes)
                  changed between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the total count of professionals (all sexes) changed between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. How has the total count of professionals (all sexes)
                  changed between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_all_sexes_employment ? (
                  <EngTable5 data={data_all_sexes_employment} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {/* Age Analysis */}
        <Tab eventKey="Age Analysis" title="Age Analysis" id="Age-Analysis">
          {/* <Accordion defaultActiveKey={['0']} alwaysOpen className="w-full"> */}
          <Accordion>
            <Accordion.Item
              eventKey="Which age group has experienced the highest growth in
                  employment from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group has experienced the highest growth in employment from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which age group has experienced the highest growth in
                  employment from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_by_agegrp ? (
                  <EngTable6 data={data_employment_by_agegrp} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the average employment for each age group over the
                  2018-2023 period?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the average employment for each age group over the 2018-2023 period?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the average employment for each age group over the
                  2018-2023 period?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_by_agegrp_with_average ? (
                  <EngTable7 data={data_employment_by_agegrp_with_average} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the year-over-year growth rate for the 25-34 age
                  group? "
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the year-over-year growth rate for the 25-34 age group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What is the year-over-year growth rate for the 25-34 age
                  group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_25_34_growth_rate ? (
                  <EngTable8 data={data_employment_25_34_growth_rate} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the percentage share of males and females in each
                  age group during 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage share of males and females in each age group during 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the percentage share of males and females in each
                  age group during 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_share_by_agegrp_2023 ? (
                  <EngTable9 data={data_employment_share_by_agegrp_2023} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which age group had the highest number of male workers in
                  2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group had the highest number of male workers in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. Which age group had the highest number of male workers in
                  2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_2023_by_agegrp ? (
                  <EngTable10 data={data_employment_2023_by_agegrp} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which age group has the lowest percentage of female workers
                  in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group has the lowest percentage of female workers in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. Which age group has the lowest percentage of female workers
                  in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_2023_female_percentage ? (
                  <EngTable11 data={data_employment_2023_female_percentage} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {/* Education Analysis */}
        <Tab
          eventKey="Education Analysis"
          title="Education Analysis"
          id="Education-Analysis"
        >
          <Accordion>
            <Accordion.Item
              eventKey="Which year recorded the highest total employment for
                  all education categories?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which year recorded the highest total employment for all education categories?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which year recorded the highest total employment for all
                  education categories?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_by_education ? (
                  <EngTable12 data={data_employment_by_education} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What was the percentage increase in employment for
                  Bachelor's or advanced degrees year over year?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the percentage increase in employment for Bachelor's or advanced degrees year over year?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What was the percentage increase in employment for
                  Bachelor's or advanced degrees year over year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_by_bachelors ? (
                  <EngTable13 data={data_employment_by_bachelors} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What was the growth rate for female employment in
                  Bachelor's or advanced degrees from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the growth rate for female employment in Bachelor's or advanced degrees from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What was the growth rate for female employment in
                  Bachelor's or advanced degrees from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_employment_growth_bachelors ? (
                  <EngTable14 data={data_employment_growth_bachelors} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which category experienced a decline in male employment
                  from 2022 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which category experienced a decline in male employment from 2022 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. Which category experienced a decline in male employment
                  from 2022 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_education_employment_growth ? (
                  <EngTable15 data={data_education_employment_growth} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the percentage increase in the number of female
                  workers with less than a high school education from 2018 to
                  2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage increase in the number of female workers with less than a high school education from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the percentage increase in the number of female
                  workers with less than a high school education from 2018 to
                  2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_education_growth_percentage ? (
                  <EngTable16 data={data_education_growth_percentage} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which education category showed the least growth from 2018
                  to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which education category showed the least growth from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. Which education category showed the least growth from 2018
                  to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_education_growth ? (
                  <EngTable17 data={data_education_growth} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What is the trend in the number of male workers with a high
                  school education or equivalent from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the trend in the number of male workers with a high school education or equivalent from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. What is the trend in the number of male workers with a high
                  school education or equivalent from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_fetch_education_employment ? (
                  <EngTable18 data={data_fetch_education_employment} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="How many female workers with a Bachelor's degree or
                  higher were there in 2021?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How many female workers with a Bachelor's degree or higher were there in 2021?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  8. How many female workers with a Bachelor's degree or higher
                  were there in 2021?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_fetch_education_employment_19 ? (
                  <EngTable19 data={data_fetch_education_employment_19} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {/* Race Analysis */}
        <Tab eventKey="Race Analysis" title="Race Analysis" id="Race-Analysis">
          <Accordion>
            {/* <Accordion.Item
              eventKey="What was the percentage increase in population for the 'Asian Alone' group from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the percentage increase in population for the 'Asian Alone' group from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What was the percentage increase in population for the
                  "Asian Alone" group from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_employment_by_year_and_race_per ? (
                  <AeroTable23
                    data={data_ad_get_employment_by_year_and_race_per}
                  />
                ) : (
                  <p>Loading...</p>
                )}
                <p>Work In Progress...</p>
              </Accordion.Body>
            </Accordion.Item> */}

            <Accordion.Item
              eventKey="Which racial group has the lowest total employment from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which racial group has the lowest total employment from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which racial group has the lowest total employment from
                  2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_calculate_employment_trend ? (
                  <EngTable21 data={data_calculate_employment_trend} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the average employment count for 'American Indian or Alaska Native Alone' over the observed years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the average employment count for 'American Indian or Alaska Native Alone' over the observed years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the average employment count for "American Indian
                  or Alaska Native Alone" over the observed years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_calculate_average_employment ? (
                  <EngTable22 data={data_calculate_average_employment} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Did any racial minority groups experience population decline in recent years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Did any racial minority groups experience population decline in recent years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How does the employment trend for "Asian Alone" compare to
                  "Black or African American Alone" over the years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_get_employment_data_by_race ? (
                  <EngTable23 data={data_get_employment_data_by_race} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item
              eventKey="What is the compound annual growth rate (CAGR) for 'All Races' from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the compound annual growth rate (CAGR) for 'All Races' from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the compound annual growth rate (CAGR) for "All
                  Races" from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_total_and_cumulative_employment_by_year ? (
                  <AeroTable27
                    data={data_ad_get_total_and_cumulative_employment_by_year}
                  />
                ) : (
                  <p>Loading...</p>
                )}
                <p>Work In Progress...</p>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item
              eventKey="What percentage of the total employment does 'White
                  Alone' contribute each year?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What percentage of the total employment does 'White Alone' contribute each year?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What percentage of the total employment does "White Alone"
                  contribute each year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_calculate_white_alone_percentage ? (
                  <EngTable25 data={data_calculate_white_alone_percentage} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Ethinicity Analysis */}
        {/* <Tab
          eventKey="Ethinicity Analysis"
          title="Ethinicity Analysis"
          id="Ethinicity-Analysis"
        >
          <Accordion activeKey={expandedQuestion}>
            <Accordion.Item
              eventKey="Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which ethnic group had the largest decrease in employment
                  share percentage between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body><TableNo15/></Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Which ethnic group had the lowest growth in employment
                  numbers from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body><TableNo34/></Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How does the change in employment numbers for Hispanic or
                  Latino workers compare to the overall change for all
                  ethnicities between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body><TableNo35/></Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab> */}
      </Tabs>
    </div>
  );
};

export default EngineeringFaqSection;
