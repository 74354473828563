// // import React from 'react';
// // import Table from 'react-bootstrap/Table';
// // import { Line } from 'react-chartjs-2';
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   PointElement,
// //   LineElement,
// //   Title,
// //   Tooltip,
// //   Legend,
// // } from 'chart.js';

// // ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// // const AeroTable16 = ({ data }) => {
// //   // Extract data for the table
// //   const tableData = data;

// //   // Prepare data for the line chart
// //   const chartData = {
// //     labels: ['2018', '2019', '2020', '2021', '2022', '2023'],
// //     datasets: [
// //       {
// //         label: 'All Sexes',
// //         data: tableData.find(item => item.sex_label === 'All Sexes')
// //           ? [
// //               tableData.find(item => item.sex_label === 'All Sexes').year_2018,
// //               tableData.find(item => item.sex_label === 'All Sexes').year_2019,
// //               tableData.find(item => item.sex_label === 'All Sexes').year_2020,
// //               tableData.find(item => item.sex_label === 'All Sexes').year_2021,
// //               tableData.find(item => item.sex_label === 'All Sexes').year_2022,
// //               tableData.find(item => item.sex_label === 'All Sexes').year_2023,
// //             ]
// //           : [],
// //         borderColor: 'rgba(75, 192, 192, 0.8)',
// //         backgroundColor: 'rgba(75, 192, 192, 0.3)',
// //         tension: 0.4,
// //         fill: true,
// //       },
// //       {
// //         label: 'Female',
// //         data: tableData.find(item => item.sex_label === 'Female')
// //           ? [
// //               tableData.find(item => item.sex_label === 'Female').year_2018,
// //               tableData.find(item => item.sex_label === 'Female').year_2019,
// //               tableData.find(item => item.sex_label === 'Female').year_2020,
// //               tableData.find(item => item.sex_label === 'Female').year_2021,
// //               tableData.find(item => item.sex_label === 'Female').year_2022,
// //               tableData.find(item => item.sex_label === 'Female').year_2023,
// //             ]
// //           : [],
// //         borderColor: 'rgba(255, 99, 132, 0.8)',
// //         backgroundColor: 'rgba(255, 99, 132, 0.3)',
// //         tension: 0.4,
// //         fill: true,
// //       },
// //       {
// //         label: 'Male',
// //         data: tableData.find(item => item.sex_label === 'Male')
// //           ? [
// //               tableData.find(item => item.sex_label === 'Male').year_2018,
// //               tableData.find(item => item.sex_label === 'Male').year_2019,
// //               tableData.find(item => item.sex_label === 'Male').year_2020,
// //               tableData.find(item => item.sex_label === 'Male').year_2021,
// //               tableData.find(item => item.sex_label === 'Male').year_2022,
// //               tableData.find(item => item.sex_label === 'Male').year_2023,
// //             ]
// //           : [],
// //         borderColor: 'rgba(54, 162, 235, 0.8)',
// //         backgroundColor: 'rgba(54, 162, 235, 0.3)',
// //         tension: 0.4,
// //         fill: true,
// //       },
// //     ],
// //   };

// //   const chartOptions = {
// //     responsive: true,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //       title: {
// //         display: true,
// //         text: 'Education Employment Trend (2018-2023)',
// //       },
// //     },
// //     scales: {
// //       y: {
// //         beginAtZero: true,
// //         title: {
// //           display: true,
// //           text: 'Number of Employees',
// //         },
// //       },
// //       x: {
// //         title: {
// //           display: true,
// //           text: 'Year',
// //         },
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       <h3 className="mt-3">Education Employment Trend (2018-2023)</h3>

// //       {/* Table Section */}
// //       <Table striped bordered hover responsive className="mb-4">
// //         <thead>
// //           <tr>
// //             <th>Sex</th>
// //             <th>2018</th>
// //             <th>2019</th>
// //             <th>2020</th>
// //             <th>2021</th>
// //             <th>2022</th>
// //             <th>2023</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {tableData.map((item, index) => (
// //             <tr key={index}>
// //               <td>{item.sex_label}</td>
// //               <td>{item.year_2018}</td>
// //               <td>{item.year_2019}</td>
// //               <td>{item.year_2020}</td>
// //               <td>{item.year_2021}</td>
// //               <td>{item.year_2022}</td>
// //               <td>{item.year_2023}</td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </Table>

// //       {/* Chart Section */}
// //       {/* <div style={{ overflowX: 'auto', marginBottom: '20px', display: 'flex', justifyContent: 'center'}}>
// //       <div style={{ width: '800px', height: '500px' }}>
// //         <Line data={chartData} options={chartOptions} />
// //       </div>
// //     </div> */}

// //     <div
// //   style={{
// //     overflowX: "auto", // Enables horizontal scroll
// //     display: "flex",
// //     justifyContent: "center",
// //     width: "100%", // Takes full width
// //   }}
// // >
// //   <div
// //     style={{
// //       minWidth: "600px", // Minimum width to enable scroll
// //       width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
// //       height: "400px",
// //     }}
// //   >
// //     <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
// //   </div>
// // </div>
// //     </div>
// //   );
// // };

// // export default AeroTable16;

// import React, { useRef } from "react";
// import Table from "react-bootstrap/Table";
// import { Line } from "react-chartjs-2";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// // Import images for PDF header and footer

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const utahData = "/img/1.png";
// const kpi = "/img/KPI-DASH 2.png";
// const bodex = "/img/bodexLogo.png";
// const pdf = "/img/pdf1.png";

// const AeroTable16 = ({ data }) => {
//   const pdfRef = useRef(); // Reference for PDF capture

//   // Extract data for the table
//   const tableData = data;

//   // Prepare data for the line chart
//   const chartData = {
//     labels: ["2018", "2019", "2020", "2021", "2022", "2023"],
//     datasets: [
//       // Dataset configuration remains the same
//       {
//         label: "All Sexes",
//         data: tableData.find((item) => item.sex_label === "All Sexes")
//           ? [
//               tableData.find((item) => item.sex_label === "All Sexes")
//                 .year_2018,
//               tableData.find((item) => item.sex_label === "All Sexes")
//                 .year_2019,
//               tableData.find((item) => item.sex_label === "All Sexes")
//                 .year_2020,
//               tableData.find((item) => item.sex_label === "All Sexes")
//                 .year_2021,
//               tableData.find((item) => item.sex_label === "All Sexes")
//                 .year_2022,
//               tableData.find((item) => item.sex_label === "All Sexes")
//                 .year_2023,
//             ]
//           : [],
//         borderColor: "rgba(75, 192, 192, 0.8)",
//         backgroundColor: "rgba(75, 192, 192, 0.3)",
//         tension: 0.4,
//         fill: true,
//       },
//       // Other datasets for Female and Male remain unchanged
//     ],
//   };

//   // Download PDF function
//   const downloadPDF = () => {
//     const input = pdfRef.current;
//     input.style.width = "1100px"; // Expand width for better PDF capture

//     html2canvas(input, { scale: 3 }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("p", "mm", "a4");
//       const imgWidth = 210;
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       // Header
//       pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
//       pdf.setFontSize(26);
//       pdf.text("UTahData.org", 95, 22);
//       pdf.line(10, 35, 200, 35);

//       // Main Content
//       pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

//       // Footer
//       pdf.line(10, 260, 200, 260);
//       pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
//       pdf.setFontSize(10);
//       pdf.text("Built by", 55, 275);
//       pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
//       pdf.text("using", 112, 275);
//       pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
//       pdf.text("20250211.v1.113", 148, 275);
//       const currentDate = new Date().toLocaleDateString();
//       pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
//       const currentURL = window.location.href;
//       pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

//       pdf.save("education-employment-trend.pdf");
//       input.style.width = ""; // Reset width
//     });
//   };

//   return (
//     <div className="container">
//       <div ref={pdfRef} className="content-to-export">
//         <h3 className="mt-3">Education Employment Trend (2018-2023)</h3>

//         {/* Table Section */}
//         <Table striped bordered hover responsive className="mb-4">
//           <thead>
//             <tr>
//               <th>Sex</th>
//               <th>2018</th>
//               <th>2019</th>
//               <th>2020</th>
//               <th>2021</th>
//               <th>2022</th>
//               <th>2023</th>
//             </tr>
//           </thead>
//           <tbody>
//             {tableData.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.sex_label}</td>
//                 <td>{item.year_2018}</td>
//                 <td>{item.year_2019}</td>
//                 <td>{item.year_2020}</td>
//                 <td>{item.year_2021}</td>
//                 <td>{item.year_2022}</td>
//                 <td>{item.year_2023}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>

//         {/* Chart Section */}
//         <div
//           style={{
//             overflowX: "auto",
//             display: "flex",
//             justifyContent: "center",
//             width: "100%",
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px",
//               width: "1000px",
//               height: "400px",
//             }}
//           >
//             <Line
//               data={chartData}
//               options={{ responsive: true, maintainAspectRatio: false }}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Download PDF Button */}
//       <div className="flex justify-center mt-4">
//         <button
//           onClick={downloadPDF}
//           className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
//         >
//           <span>Download as PDF</span>
//           <span>
//             <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
//           </span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AeroTable16;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Import chart.js modules
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Image assets for PDF
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable16 = ({ data }) => {
  const pdfRef = useRef(); // Reference for PDF export

  // Extract years for chart labels
  const years = ["2018", "2019", "2020", "2021", "2022", "2023"];
  const chartLabels = years;

  // Prepare data for the bar chart
  const chartData = {
    labels: chartLabels,
    datasets: data.map((item, index) => ({
      label: item["sex_label"],
      data: years.map((year) => parseFloat(item[`year_${year}`]) || 0),
      backgroundColor: `hsl(${(index * 45) % 360}, 70%, 50%)`,
    })),
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Adjust width for better PDF rendering

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.line(10, 35, 200, 35);

      // Add Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Add Footer
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.text("20250211.v1.113", 148, 275);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("education-employment-trend.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        <h3 className="mt-3">Education Employment Trend (2018-2023)</h3>

        {/* Table Section */}
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Sex</th>
              {years.map((year) => (
                <th key={`year_${year}`}>{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item["sex_label"]}</td>
                {years.map((year) => (
                  <td key={`year_${year}`}>{item[`year_${year}`]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Chart Section */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Education Employment Trend by Sex",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="text-center mt-4">
        <div className="flex justify-center mt-4">
          <button
            onClick={downloadPDF}
            className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
          >
            <span>Download as PDF</span>
            <span>
              <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AeroTable16;
