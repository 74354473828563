import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Data Processing: Convert string numbers with commas to integers
const processData = (data) => {
  return data
    .filter((item) => item.Fields !== "Total Active Voters") // Exclude total row
    .map((item) => ({
      label: item.Fields,
      value: parseInt(item["No. of Voters"].replace(/,/g, ""), 10), // Remove commas and convert to number
    }));
};

const ActiveVotersChart = ({ data }) => {
  const chartRef = useRef(null);
  const processedData = processData(data);

  // ✅ State to handle dynamic chart height
  const [chartHeight, setChartHeight] = useState(500);

  // ✅ Adjust height based on screen size dynamically
  useEffect(() => {
    const updateChartHeight = () => {
      if (window.innerWidth < 768) {
        setChartHeight(350); // Mobile view
      } else {
        setChartHeight(500); // Desktop view
      }
    };

    updateChartHeight(); // Set initial height
    window.addEventListener("resize", updateChartHeight); // Listen to resize events

    return () => window.removeEventListener("resize", updateChartHeight); // Cleanup
  }, []);

  const userIcon = new Image();
  userIcon.src = "/img/user-icon.webp";

  const chartData = {
    labels: processedData.map((item) => item.label), // X-axis labels (party names)
    datasets: [
      {
        label: "No. of Voters",
        data: processedData.map((item) => item.value), // Y-axis values
        borderColor: "rgba(34, 197, 94, 1)", // ✅ Green border
        backgroundColor: "rgba(34, 197, 94, 0.4)", // ✅ Green fill with transparency
        fill: true, // Area fill
        borderWidth: 3, // Thicker border for 3D feel
        tension: 0.4, // Smooth curved lines
        pointRadius: 6, // Larger points for emphasis
        pointBackgroundColor: "rgba(34, 197, 94, 1)", // ✅ Green points
        pointStyle: userIcon, // ✅ Set custom user icon
        pointRadius: "10px",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // ✅ Allows dynamic resizing
    layout: {
      padding: {
        right: 30,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#ffffff", // ✅ White legend text
        },
      },
      title: {
        display: true,
        text: "Active Voters (17,60,053)",
        color: "#ffffff",
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Citizens",
          color: "#ffffff",
        },
        ticks: {
          color: "#ffffff",
          font: {
            size: 14,
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)", // ✅ Light white grid lines
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Active Voters",
          color: "#ffffff",
        },
        ticks: {
          color: "#ffffff",
          font: {
            size: 14,
          },
          callback: (value) => value.toLocaleString(), // ✅ Format numbers with commas
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)",
        },
      },
    },
    animation: {
      duration: 2000,
      easing: "easeInOutQuart", // ✅ Smooth animation
      // onHover: (event, elements) => {
      //   const chart = chartRef.current;
      //   if (chart?.canvas && elements.length) {
      //     chart.canvas.style.cursor = "pointer"; // ✅ Fix: Safe access using optional chaining
      //   } else if (chart?.canvas) {
      //     chart.canvas.style.cursor = "pointer";
      //   }
      // },
    },
  };

  // ✅ Custom Plugin for Hover Effect on Icons
  const customPlugin = {
    id: "customIconsWithHover",
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const dataset = chart.data.datasets[0];

      chart.getDatasetMeta(0).data.forEach((point, index) => {
        const baseSize = 24;
        let imgSize = baseSize;

        // Detect if hovering
        if (chart.tooltip && chart.tooltip.dataPoints) {
          const hoveredPoint = chart.tooltip.dataPoints.find(
            (d) => d.index === index
          );
          if (hoveredPoint) {
            imgSize = baseSize * 1.3; // ✅ Make icon 30% larger on hover
          }
        }

        const value = dataset.data[index].toLocaleString(); // Format number with commas

        // Draw Image Icon
        ctx.drawImage(
          userIcon,
          point.x - imgSize / 2,
          point.y - imgSize / 2,
          imgSize,
          imgSize
        );

        // Draw Text Above Icon (For Hover Effect)
        ctx.font = "bold 14px Arial";
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.cursor = "pointer";
        ctx.fillText(value, point.x, point.y - 20); // ✅ Display text above icon
      });
    },
  };

  return (
    <div
      className="chart-container"
      style={{
        width: "90%", // ✅ Adjust width dynamically
        maxWidth: "900px", // ✅ Prevents excessive stretching on large screens
        height: `${chartHeight}px`, // ✅ Dynamic height
        margin: "0 auto",
        background: "transparent",
        padding: "20px",
        background: "rgb(21, 38, 54)",
        borderRadius: "12px",
        border: "1px solid rgba(34, 197, 94, 1)",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)", // ✅ Soft shadow effect
      }}
    >
      <Line
        ref={chartRef}
        data={chartData}
        options={options}
        plugins={[customPlugin]}
      />
    </div>
  );
};

export default ActiveVotersChart;
