import React from 'react'

export default function School14() {

    const schoolData = 
    [
        {"schoolName": "Canyon View High", "numberOfStudents": 1123, "grades": "9-12"},
        {"schoolName": "Canyon View Middle", "numberOfStudents": 989, "grades": "6-8"},
        {"schoolName": "Cedar City High", "numberOfStudents": 1366, "grades": "9-12"},
        {"schoolName": "Cedar East School", "numberOfStudents": 551, "grades": "KG-5"},
        {"schoolName": "Cedar Middle", "numberOfStudents": 1115, "grades": "6-8"},
        {"schoolName": "Cedar North School", "numberOfStudents": 636, "grades": "KG-5"},
        {"schoolName": "Cedar South School", "numberOfStudents": 565, "grades": "KG-5"},
        {"schoolName": "Enoch School", "numberOfStudents": 593, "grades": "KG-5"},
        {"schoolName": "Escalante Valley School", "numberOfStudents": 122, "grades": "PK-6"},
        {"schoolName": "Fiddlers Canyon School", "numberOfStudents": 544, "grades": "KG-5"},
        {"schoolName": "Iron County Preschool", "numberOfStudents": 189, "grades": "PK-PK"},
        {"schoolName": "Iron Springs School", "numberOfStudents": 619, "grades": "KG-5"},
        {"schoolName": "Launch High", "numberOfStudents": 195, "grades": "9-12"},
        {"schoolName": "Parowan High", "numberOfStudents": 363, "grades": "7-12"},
        {"schoolName": "Parowan School", "numberOfStudents": 420, "grades": "KG-6"},
        {"schoolName": "Southwest Educational Academy", "numberOfStudents": 2585, "grades": "KG-12"},
        {"schoolName": "Three Peaks Pre School", "numberOfStudents": 121, "grades": "PK-PK"},
        {"schoolName": "Three Peaks School", "numberOfStudents": 643, "grades": "KG-5"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Iron County
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
