import React from 'react'

export default function StateBanks() {

    const schoolData = 
    [
        {
            "Bank Name": "Ally Bank",
            "Address": "200 W CIVIC CENTER DR STE 201, SANDY, UT 84070",
            "CEO/President": "Michael Rhodes, CEO",
            "Contact No:": "801-790-5000"
        },
        {
            "Bank Name": "Bank of Utah",
            "Address": "2605 WASHINGTON BLVD, OGDEN, UT 84401-0231",
            "CEO/President": "Branden Hansen, President",
            "Contact No:": "801-409-5000"
        },
        {
            "Bank Name": "Brighton Bank",
            "Address": "7101 HIGHLAND DR, SALT LAKE CITY, UT 84121-3703",
            "CEO/President": "Bruce Ashcroft, President",
            "Contact No:": "801-943-6500"
        },
        {
            "Bank Name": "Cache Valley Bank",
            "Address": "101 N MAIN ST, LOGAN, UT 84321-3917",
            "CEO/President": "John T. Jones, CEO",
            "Contact No:": "435-753-3020"
        },
        {
            "Bank Name": "Capital Community Bank",
            "Address": "3280 N UNIVERSITY AVE. PROVO, UT 84604",
            "CEO/President": "Michael Watson, CEO",
            "Contact No:": "801-356-6699"
        },
        {
            "Bank Name": "Central Bank",
            "Address": "75 N UNIVERSITY AVE, PROVO, UT 84601",
            "CEO/President": "Mark Packard, President",
            "Contact No:": "801-375-1000"
        },
        {
            "Bank Name": "Continental Bank",
            "Address": "15 W S TEMPLE STE 300, SALT LAKE CITY, UT 84101",
            "CEO/President": "Nathan J. Morgan, CEO",
            "Contact No:": "801-595-7000"
        },
        {
            "Bank Name": "FinWise Bank",
            "Address": "756 WINCHESTER ST 1ST FLOOR, MURRAY, UT 84107",
            "CEO/President": "Kent Landvatter, CEO",
            "Contact No:": "801-545-6000"
        },
        {
            "Bank Name": "First Utah Bank",
            "Address": "3826 S 2300 E, SALT LAKE CITY, UT 84109-3499",
            "CEO/President": "Mark Zupon, President",
            "Contact No:": "801-272-9454"
        },
        {
            "Bank Name": "Grand Valley Bank",
            "Address": "2 S MAIN ST, HEBER CITY, UT 84032",
            "CEO/President": "David Armbruster, President",
            "Contact No:": "435-654-7400"
        },
        {
            "Bank Name": "Green Dot Bank",
            "Address": "1675 N 200 W BLDG #1, PROVO, UT 84604-2540",
            "CEO/President": "George Gresham, CEO",
            "Contact No:": "801-344-7020"
        },
        {
            "Bank Name": "Holladay Bank & Trust",
            "Address": "2020 E 4800 S, PO BOX 17576, SALT LAKE CITY, UT 84117-0576",
            "CEO/President": "Katie Spratling, President",
            "Contact No:": "801-272-4275"
        },
        {
            "Bank Name": "Home Savings Bank",
            "Address": "3539 S MAIN ST, PO BOX 651480, SALT LAKE CITY, UT 84165",
            "CEO/President": "John G. Sorensen, Jr., President",
            "Contact No:": "801-487-0811"
        },
        {
            "Bank Name": "Liberty Bank",
            "Address": "79 S MAIN ST STE 400, SALT LAKE CITY, UT 84111",
            "CEO/President": "N. George Daines, CEO",
            "Contact No:": "801-355-7411"
        },
        {
            "Bank Name": "Prime Alliance Bank",
            "Address": "1868 S 500 W, WOODS CROSS, UT 84087",
            "CEO/President": "Michael Ward, President",
            "Contact No:": "801-296-2200"
        },
        {
            "Bank Name": "State Bank of Southern Utah",
            "Address": "377 N MAIN ST, PO BOX 340, CEDAR CITY, UT 84721-0340",
            "CEO/President": "Eric J. Schmutz, President",
            "Contact No:": "435-865-2300"
        },
        {
            "Bank Name": "Sunwest Bank",
            "Address": "10011 CENTENNIAL PKWY STE 450, SANDY, UT 84070",
            "CEO/President": "Carson Lappetito, President",
            "Contact No:": "801-938-5523"
        },
        {
            "Bank Name": "Transportation Alliance Bank, Inc.",
            "Address": "4185 HARRISON BLVD STE 200, OGDEN, UT 84403",
            "CEO/President": "Austin Strong, CEO",
            "Contact No:": "801-624-5000"
        },
        {
            "Bank Name": "Utah Independent Bank",
            "Address": "55 S STATE ST, PO BOX 9, SALINA, UT 84654-0009",
            "CEO/President": "Spencer White, President",
            "Contact No:": "435-529-7459"
        }
    ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        State Banks in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school['Bank Name']}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school['CEO/President']}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
