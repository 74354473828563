import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
const census = "/img/census.png";

const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const AboutUsImage = "/img/aboutus1.avif";
const sourcelogo1 = "/img/unemployment-logo.png";
const sourcelogo2 = "/img/utah-job-logo.png";

export default function Copyright() {
  const [modalShow, setModalShow] = React.useState(false);
  const iconData = [
    { icon: "fa-fighter-jet", title: "Aerospace and Defense" },
    { icon: "fa-seedling", title: "Agriculture, Food, and Natural Resources" },
    { icon: "fa-briefcase", title: "Business, Finance, and Marketing" },
    { icon: "fa-cogs", title: "Engineering and Technology" },
    { icon: "fa-hands-helping", title: "Human Services" },
    { icon: "fa-truck", title: "Transportation, Distribution, and Logistics" },
    { icon: "fa-building", title: "Architecture and Construction" },
    { icon: "fa-laptop-code", title: "Computer Science and IT" },
    { icon: "fa-heartbeat", title: "Health Science" },
    {
      icon: "fa-balance-scale",
      title: "Law, Public Safety, Corrections, and Security",
    },
    {
      icon: "fa-paint-brush",
      title: "Arts, Audio/Visual Technology, and Communication",
    },
    { icon: "fa-graduation-cap", title: "Education and Training" },
    { icon: "fa-concierge-bell", title: "Hospitality and Tourism" },
    { icon: "fa-industry", title: "Manufacturing" },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col">
      {/* Main content */}
      <div className=" bg-[#d2eaff] flex flex-col items-center justify-center">
        <span
          className="text-center px-4 text-lg sm:text-2xl md:text-3xl lg:text-4xl sm:mt-[4rem] md:mt-[8rem] lg:mt-[7rem] mt-[8rem]"
          style={{
            color: "black",
            paddingTop: "10vh",
            textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
            lineHeight: "1.5", // Adjusts line spacing
          }}
        >
          Copyright Disclaimer for Images on UtahData.org
        </span>
      </div>

      <div className="bg-[#d2eaff] h-auto sm:h-[90vh]">
        <div className="mt-[3rem] px-4 sm:px-0">
          <p className="about-us-content">
            UtahData.org respects the intellectual property rights of others.
            All images used on this website may be subject to their own
            copyrights, owned by third parties. We do not claim ownership of any
            copyrighted images unless explicitly stated.
          </p>
          <p className="about-us-content">
            Our use of images is intended for informational and educational
            purposes, and no copyright infringement is intended. If you believe
            that any image on this site violates your copyright, please contact
            us at bodex@bodex.io so we can promptly review and address your
            concerns.
          </p>
        </div>
        {/* <div className='mx-auto flex justify-center text-center mb-[0rem]'>
        <div>Sources of Data</div><br/>
          <div><img src={census}/></div>
          
          
        </div> */}
        <div className="mx-auto flex flex-col items-center text-center mb-0">
          {/* <div className="flex">
           
            <img src={sourcelogo1} className="mr-[2rem] w-[7rem]"></img>
              <img
                src={census}
                alt="Sources of Data"
                className="max-w-full h-auto mr-[2rem]"
              />
              <img src={sourcelogo2} className="w-[20rem]"></img>

            
          </div> */}
        </div>
      </div>

      {/* Footer */}
    </div>
  );
}
