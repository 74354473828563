import React from 'react'

export default function FedralCredit() {

    const schoolData = 
    [
        {
            "Name": "America First Federal Credit Union",
            "Address": "4774 S 1300 W BLDG # 3, PO BOX 9199, RIVERDALE, UT 84409-0199",
            "President": "Thayne Shaffer, President",
            "Contact No:": "801-627-0900"
        },
        {
            "Name": "American United Federal CU",
            "Address": "2687 W 7800 S, WEST JORDAN, UT 84088",
            "President": "Jake Bowman, President",
            "Contact No:": "801-583-5015"
        },
        {
            "Name": "Ascent Federal Credit Union",
            "Address": "4140 HARRISON BLVD, OGDEN, UT 84403",
            "President": "Brock Mortensen, President",
            "Contact No:": "801-399-9728"
        },
        {
            "Name": "C U P Federal Credit Union",
            "Address": "302 E 1860 S, PO BOX 50526, PROVO, UT 84605-0526",
            "President": "Lin Thompson, President",
            "Contact No:": "801-374-1170"
        },
        {
            "Name": "Cyprus Federal Credit Union",
            "Address": "3876 W CENTER VIEW WAY, PO BOX 9002, WEST JORDAN, UT 84084-5661",
            "President": "Todd E. Adamson, President",
            "Contact No:": "801-260-7600"
        },
        {
            "Name": "Deseret First Federal Credit Union",
            "Address": "3999 W PKWY BLVD, WEST VALLEY CITY, UT 84120",
            "President": "Shane London, President",
            "Contact No:": "801-456-7000"
        },
        {
            "Name": "Desert Rivers Federal Credit Union",
            "Address": "725 N MAIN, PO BOX 1047, MOAB, UT 84532-1047",
            "President": "Samuel Crane, President",
            "Contact No:": "435-259-6124"
        },
        {
            "Name": "Desertview Federal Credit Union",
            "Address": "196 N MAIN, PO BOX 580, HUNTINGTON, UT 84528",
            "President": "Michael H. Nelson, President",
            "Contact No:": "435-687-2472"
        },
        {
            "Name": "Devils Slide Federal Credit Union",
            "Address": "243 E 125 N, PO BOX 588, MORGAN, UT 84050",
            "President": "Jill J. Carrigan, President",
            "Contact No:": "801-829-6900"
        },
        {
            "Name": "Eastern Utah Community Federal CU",
            "Address": "675 E 100 N, PO BOX 719,PRICE, UT 84501-0719",
            "President": "Bruce Yost, President",
            "Contact No:": "435-637-2443"
        },
        {
            "Name": "Elevate Federal Credit Union",
            "Address": "1023 S MEDICAL DR, PO BOX 624, BRIGHAM CITY, UT 84302",
            "President": "Scott Webre, President",
            "Contact No:": "435-723-3437"
        },
        {
            "Name": "Flexpak Federal Credit Union",
            "Address": "1993 W 2425 S, WOODS CROSS, UT 84087",
            "President": "Ralph Jenkins, President",
            "Contact No:": "801-485-1052"
        },
        {
            "Name": "Gibbons & Reed Emp. Federal CU",
            "Address": "1000 WARM SPRINGS RD, PO BOX 30429, SALT LAKE CITY, UT 84130",
            "President": "L.William Christopherson, President",
            "Contact No:": "801-322-4010"
        },
        {
            "Name": "Goldenwest Federal Credit Union",
            "Address": "5025 S ADAMS, PO BOX 1111, OGDEN, UT 84402",
            "President": "Kerry Wahlen, President",
            "Contact No:": "801-337-8300"
        },
        {
            "Name": "Granite Federal Credit Union",
            "Address": "3675 S 900 E, SALT LAKE CITY, UT 84106-1964",
            "President": "Mark Young, President",
            "Contact No:": "801-288-3000"
        },
        {
            "Name": "Hercules First Federal Credit Union",
            "Address": "3141 W 4700 S, PO BOX 26977,SALT LAKE CITY, UT 84126-0977",
            "President": "Brett Blackburn, President",
            "Contact No:": "801-968-9011"
        },
        {
            "Name": "Horizon Utah Federal Credit Union",
            "Address": "225 S 200 W, PO BOX 687,FARMINGTON, UT 84025-0687",
            "President": "W. Page Bennett, President",
            "Contact No:": "801-451-5064"
        },
        {
            "Name": "Jordan Federal Credit Union",
            "Address": "1750 W 11400 S #200, SOUTH JORDAN, UT 84095",
            "President": "Lindsey Merritt, President",
            "Contact No:": "801-566-4195"
        },
        {
            "Name": "Logan Cache Rich Federal CU",
            "Address": "72 E 700 N, LOGAN, UT 84321",
            "President": "Jordan Burdge, President",
            "Contact No:": "435-563-6882"
        },
        {
            "Name": "Logan Medical Federal CU",
            "Address": "500 E 1400 N, LOGAN, UT 84341",
            "President": "Dale D. Howe, President",
            "Contact No:": "435-716-5392"
        },
        {
            "Name": "LU 354 I B E W Federal Credit Union",
            "Address": "3405 W 1987 S, SALT LAKE CITY, UT 84104-4942",
            "President": "Linda J. Perschon, President",
            "Contact No:": "801-975-9693"
        },
        {
            "Name": "Mountain America Federal CU",
            "Address": "9800 MONROE ST, SANDY, UT 84070",
            "President": "Sterling W. Nielsen, President",
            "Contact No:": "801-325-6228"
        },
        {
            "Name": "Nephi Western Employees Federal CU",
            "Address": "155 N MAIN ST, PO BOX 252, NEPHI, UT 84648",
            "President": "Mark Drake, President",
            "Contact No:": "435-623-1895"
        },
        {
            "Name": "North Sanpete Federal Credit Union",
            "Address": "11917 E 24711 N, FAIRVIEW, UT 84629",
            "President": "Jessica Clare, President",
            "Contact No:": "435-462-9075"
        },
        {
            "Name": "Orem City Employees Federal CU",
            "Address": "56 N STATE ST, OREM, UT 84057",
            "President": "Kendall Adamson, President",
            "Contact No:": "801-229-7168"
        },
        {
            "Name": "Ridgeline Federal Credit Union",
            "Address": "564 E 300 S, SALT LAKE CITY, UT 84102-4021",
            "President": "Doug C. Huntsman, President",
            "Contact No:": "801-328-1521"
        },
        {
            "Name": "University First Federal Cr. Union",
            "Address": "3450 S HIGHLAND DR STE 201, PO BOX 58025, SALT LAKE CITY, UT 84158",
            "President": "Jack Buttars, President",
            "Contact No:": "801-481-8800"
        },
        {
            "Name": "Utah Community Federal Credit Union",
            "Address": "360 W 4800 N, PROVO, UT 84604",
            "President": "Bret VanAusdal, President",
            "Contact No:": "801-223-8188"
        },
        {
            "Name": "Utah First Federal Credit Union",
            "Address": "200 E S TEMPLE, PO BOX 2197, SALT LAKE CITY, UT 84111",
            "President": "Darin B. Moody, President",
            "Contact No:": "801-320-2600"
        },
        {
            "Name": "Valley Wide Federal CU",
            "Address": "1330 E HIGHWAY 40, PO BOX 461, VERNAL, UT 84078",
            "President": "Monica Marchant, President",
            "Contact No:": "435-789-6918"
        },
        {
            "Name": "Varex Federal CU",
            "Address": "1678 S PIONEER RD, SALT LAKE CITY, UT 84104-4205",
            "President": "Tammy Phillips, President",
            "Contact No:": "801-973-5052"
        },
        {
            "Name": "Wasatch Peaks Federal CU",
            "Address": "4723 HARRISON BLVD, OGDEN, UT 84403-4304",
            "President": "Jeff Shaw, President",
            "Contact No:": "801-627-8700"
        }
    ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        Fedral Credit Unions in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.Name}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school.President}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
