// // import React from 'react';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { Table } from 'react-bootstrap';
// // import { Pie } from 'react-chartjs-2';
// // import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// // ChartJS.register(ArcElement, Tooltip, Legend);

// // const TableNo11 = () => {
// //   // Data for the table and chart
// //   const raceData = [
// //     { race: 'American Indian or Alaska Native Alone', share: 1.55 },
// //     { race: 'Asian Alone', share: 5.19 },
// //     { race: 'Black or African American Alone', share: 2.83 },
// //     { race: 'Native Hawaiian or Other Pacific Islander Alone', share: 1.14 },
// //     { race: 'Two or More Race Groups', share: 2.10 },
// //     { race: 'White Alone', share: 87.17 },
// //   ];

// //   // Chart data
// //   const chartData = {
// //     labels: raceData.map(item => item.race),
// //     datasets: [
// //       {
// //         label: 'Employment Share %',
// //         data: raceData.map(item => item.share),
// //         backgroundColor: [
// //           'rgba(255, 99, 132, 0.6)',
// //           'rgba(54, 162, 235, 0.6)',
// //           'rgba(255, 206, 86, 0.6)',
// //           'rgba(75, 192, 192, 0.6)',
// //           'rgba(153, 102, 255, 0.6)',
// //           'rgba(255, 159, 64, 0.6)',
// //         ],
// //         borderColor: [
// //           'rgba(255, 99, 132, 1)',
// //           'rgba(54, 162, 235, 1)',
// //           'rgba(255, 206, 86, 1)',
// //           'rgba(75, 192, 192, 1)',
// //           'rgba(153, 102, 255, 1)',
// //           'rgba(255, 159, 64, 1)',
// //         ],
// //         borderWidth: 1,
// //       },
// //     ],
// //   };

// //   // Pie chart options
// //   const options = {
// //     responsive: true,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //     },
// //   };

// //   return (
// //     <div className="container">
// //       <h2>Employment Share by Race</h2>

// //       {/* Responsive Table */}
// //       <Table striped bordered hover responsive>
// //         <thead>
// //           <tr>
// //             <th>Race</th>
// //             <th>Employment Share %</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {raceData.map((item, index) => (
// //             <tr key={index}>
// //               <td>{item.race}</td>
// //               <td>{item.share.toFixed(2)}%</td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </Table>

// //       {/* Pie Chart */}
// //       <div style={{ height: '400px', width: '100%' }}>
// //         <Pie data={chartData} options={options} />
// //       </div>
// //     </div>
// //   );
// // };

// // export default TableNo11;

// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Pie } from 'react-chartjs-2';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// ChartJS.register(ArcElement, Tooltip, Legend);

// const TableNo11 = () => {
//   // Data for the table and chart
//   const raceData = [
//     { race: 'American Indian or Alaska Native Alone', share: 1.55 },
//     { race: 'Asian Alone', share: 5.19 },
//     { race: 'Black or African American Alone', share: 2.83 },
//     { race: 'Native Hawaiian or Other Pacific Islander Alone', share: 1.14 },
//     { race: 'Two or More Race Groups', share: 2.10 },
//     { race: 'White Alone', share: 87.17 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: raceData.map(item => item.race),
//     datasets: [
//       {
//         label: 'Employment Share %',
//         data: raceData.map(item => item.share),
//         backgroundColor: [
//           'rgba(255, 99, 132, 0.6)',
//           'rgba(54, 162, 235, 0.6)',
//           'rgba(255, 206, 86, 0.6)',
//           'rgba(75, 192, 192, 0.6)',
//           'rgba(153, 102, 255, 0.6)',
//           'rgba(255, 159, 64, 0.6)',
//         ],
//         borderColor: [
//           'rgba(255, 99, 132, 1)',
//           'rgba(54, 162, 235, 1)',
//           'rgba(255, 206, 86, 1)',
//           'rgba(75, 192, 192, 1)',
//           'rgba(153, 102, 255, 1)',
//           'rgba(255, 159, 64, 1)',
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Pie chart options
//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//         labels: {
//           padding: 20,
//         },
//       },
//     },
//   };

//   return (
//     <div className="container my-5">
//       <h2 className="text-center mb-4">Employment Share by Race</h2>

//       {/* Responsive Table */}
//       <div className="table-responsive">
//         <Table striped bordered hover responsive="md">
//           <thead>
//             <tr>
//               <th>Race</th>
//               <th>Employment Share %</th>
//             </tr>
//           </thead>
//           <tbody>
//             {raceData.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.race}</td>
//                 <td>{item.share.toFixed(2)}%</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </div>

//       {/* Centered Pie Chart */}
//       <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "400px", // Minimum width to enable scroll
//               width: "400px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//           <Pie data={chartData} options={options} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TableNo11;

import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

ChartJS.register(ArcElement, Tooltip, Legend);

const TableNo11 = () => {
  const pdfRef = useRef(); // Ref for capturing PDF content

  // Data for the table and chart
  const raceData = [
    { race: "American Indian or Alaska Native Alone", share: 1.55 },
    { race: "Asian Alone", share: 5.19 },
    { race: "Black or African American Alone", share: 2.83 },
    { race: "Native Hawaiian or Other Pacific Islander Alone", share: 1.14 },
    { race: "Two or More Race Groups", share: 2.1 },
    { race: "White Alone", share: 87.17 },
  ];

  // Chart data
  const chartData = {
    labels: raceData.map((item) => item.race),
    datasets: [
      {
        label: "Employment Share %",
        data: raceData.map((item) => item.share),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          padding: 20,
        },
      },
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250211.v1.113", 148, 275);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("employment-share-by-race.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">Employment Share by Race</h2>

      {/* PDF Content */}
      <div ref={pdfRef}>
        {/* Responsive Table */}
        <div className="table-responsive">
          <Table striped bordered hover responsive="md">
            <thead>
              <tr>
                <th>Race</th>
                <th>Employment Share %</th>
              </tr>
            </thead>
            <tbody>
              {raceData.map((item, index) => (
                <tr key={index}>
                  <td>{item.race}</td>
                  <td>{item.share.toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Centered Pie Chart */}
        <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "400px", // Minimum width to enable scroll
              width: "400px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          >
            {/* <div
            style={{
              width: "90%",
              maxWidth: "800px",
              margin: "auto",
              overflowX: "auto",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%", // Takes 100% width of the parent div, but will scroll if needed
                minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
                height:"400px"
              }}
            > */}
            <Pie data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="d-flex justify-content-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg d-flex align-items-center"
        >
          <span>Download as PDF</span>
          <img
            className="ms-2"
            src={pdfIcon}
            alt="PDF Icon"
            style={{ width: "2rem" }}
          />
        </button>
      </div>
    </div>
  );
};

export default TableNo11;
