import React from "react";

const statePark = "/img/state-park.jpg";

function StateParks() {
  const park = [
    {
      "S.no": 1,
      Name: "Anasazi State Park Museum",
      "County Name": "Garfield",
      Address: "460 NO HWY 12 BOULDER, UT 84716",
      Website: "https://stateparks.utah.gov/parks/anasazi/",
      "Contact No": "435-335-7308",
    },
    {
      "S.no": 2,
      Name: "Antelope Island State Park",
      "County Name": "Davis",
      Address: "4528 W 1700 S SYRACUSE, UT 84075",
      Website: "https://stateparks.utah.gov/parks/antelope-island/",
      "Contact No": "801-773-2941",
    },
    {
      "S.no": 3,
      Name: "Bear Lake State Park (Utah)",
      "County Name": "Rich",
      Address: "940 N BEAR LAKE BLVD GARDEN CITY, UT 84028",
      Website: "https://stateparks.utah.gov/parks/bear-lake",
      "Contact No": "435-946-3343",
    },
    {
      "S.no": 4,
      Name: "Camp Floyd State Park Museum",
      "County Name": "Utah",
      Address: "18035 W 1540 N FAIRFIELD, UT 84013",
      Website: "https://stateparks.utah.gov/stateparks/parks/camp-floyd/",
      "Contact No": "801-768-8932",
    },
    {
      "S.no": 5,
      Name: "Coral Pink Sand Dunes State Park",
      "County Name": "Kane",
      Address: "12500 SAND DUNE ROAD KANAB, UT 84741",
      Website: "https://stateparks.utah.gov/parks/coral-pink",
      "Contact No": "435-648-2800",
    },
    {
      "S.no": 6,
      Name: "Dead Horse Point State Park",
      "County Name": "Grand and San Juan",
      Address: "STATE ROUTE 313 MOAB, UT 84532",
      Website: "https://stateparks.utah.gov/stateparks/parks/dead-horse/",
      "Contact No": "435-259-2614",
    },
    {
      "S.no": 7,
      Name: "Deer Creek State Park (Utah)",
      "County Name": "Wasatch",
      Address: "MILE MARKER 19 US 189 WALLSBURG, UT 84082",
      Website: "https://stateparks.utah.gov/parks/deer-creek/",
      "Contact No": "435-654-0171",
    },
    {
      "S.no": 8,
      Name: "East Canyon State Park",
      "County Name": "Morrgan",
      Address: "5535 S HIGHWAY 66 MORGAN, UT 84050",
      Website: "https://stateparks.utah.gov/parks/east-canyon",
      "Contact No": "801-829-6866",
    },
    {
      "S.no": 9,
      Name: "Echo State Park",
      "County Name": "Summit",
      Address: "2115 NORTH ECHO DAM ROAD, COALVILLE, UT 84017",
      Website: "https://stateparks.utah.gov/parks/echo",
      "Contact No": "435-336-9894",
    },
    {
      "S.no": 10,
      Name: "Edge of the Cedars State Park Museum",
      "County Name": "San Jaun",
      Address: "660 W 400 N BLANDING, UT 84511",
      Website: "https://stateparks.utah.gov/parks/edge-of-the-cedars",
      "Contact No": "435-678-2238",
    },
    {
      "S.no": 11,
      Name: "Escalante Petrified Forest State Park",
      "County Name": "Garfield",
      Address: "710 N RESERVOIR ROAD ESCALANTE, UT 84726",
      Website: "https://stateparks.utah.gov/parks/escalante",
      "Contact No": "435-826-4466",
    },
    {
      "S.no": 12,
      Name: "Flight Park State Recreation Area",
      "County Name": "Salt Lake City and Utah",
      Address: "121100 NORTH EAST FRONTAGE ROAD LEHI, UT 84020",
      Website: "https://stateparks.utah.gov/parks/flight-park",
      "Contact No": "801-533-5127",
    },
    {
      "S.no": 13,
      Name: "Fred Hayes State Park at Starvation",
      "County Name": "Duchesne",
      Address: "OLD HIGHWAY 40 DUCHESNE, UT 84021",
      Website: "https://stateparks.utah.gov/parks/starvation",
      "Contact No": "435-738-2326",
    },
    {
      "S.no": 14,
      Name: "Fremont Indian State Park and Museum",
      "County Name": "Sevier",
      Address: "3820 W CLEAR CREEK CANYON ROAD SEVIER, UT 84766",
      Website: "https://stateparks.utah.gov/stateparks/parks/fremont/",
      "Contact No": "435-527-4631",
    },
    {
      "S.no": 15,
      Name: "Frontier Homestead State Park Museum",
      "County Name": "Iron",
      Address: "635 N MAIN CEDAR CITY, UT 84721",
      Website: "https://stateparks.utah.gov/parks/frontier-homestead",
      "Contact No": "435-586-9290",
    },
    {
      "S.no": 16,
      Name: "Goblin Valley State Park",
      "County Name": "Emery",
      Address: "18630 GOBLIN VALLEY RD GREEN RIVER, UT 84525",
      Website: "https://stateparks.utah.gov/stateparks/parks/goblin-valley/",
      "Contact No": "435-275-4584",
    },
    {
      "S.no": 17,
      Name: "Goosenecks State Park",
      "County Name": "San Jaun",
      Address: "HWY 316 MEXICAN HAT, UT 84531",
      Website: "https://stateparks.utah.gov/parks/goosenecks",
      "Contact No": "435-678-3348",
    },
    {
      "S.no": 18,
      Name: "Great Salt Lake State Park",
      "County Name": "Salt Lake City",
      Address: "MARINA OFF I-80, EXIT 104 MAGNA,  UT 84044",
      Website: "https://stateparks.utah.gov/parks/great-salt-lake/",
      "Contact No": "801-250-1898",
    },
    {
      "S.no": 19,
      Name: "Green River State Park",
      "County Name": "Emery",
      Address: "130 S FAIRWAY DRIVE GREEN RIVER, UT 84525",
      Website: "https://stateparks.utah.gov/parks/green-river",
      "Contact No": "435-564-3633",
    },
    {
      "S.no": 20,
      Name: "Gunlock State Park",
      "County Name": "Washington",
      Address: "GUNLOCK ACCESS ROAD, OFF OLD HWY 91 IVINS, UT 84738",
      Website: "https://stateparks.utah.gov/parks/gunlock",
      "Contact No": "435-680-0715",
    },
    {
      "S.no": 21,
      Name: "Historic Union Pacific Rail Trail State Park",
      "County Name": "Summit",
      Address: "PO BOX 754 PARK CITY, UT 84060",
      Website:
        "https://stateparks.utah.gov/parks/historic-union-pacific-rail-trail/",
      "Contact No": "435-649-6839",
    },
    {
      "S.no": 22,
      Name: "Huntington State Park",
      "County Name": "Emery",
      Address: "1385 W MORELAND ROAD HUNTINGTON, UT 84528",
      Website: "https://stateparks.utah.gov/parks/huntington",
      "Contact No": "435-687-2491",
    },
    {
      "S.no": 23,
      Name: "Hyrum State Park",
      "County Name": "Cache",
      Address: "405 W 300 S HYRUM, UT 84319",
      Website: "https://stateparks.utah.gov/parks/hyrum",
      "Contact No": "435-245-6866",
    },

    {
      "S.no": 24,
      Name: "Jordan River Off-Highway Vehicle State Recreation Area",
      "County Name": "Salt Lake City",
      Address: "2800 N ROSE PARK LANE SALT LAKE CITY, UT 84116",
      Website: "https://stateparks.utah.gov/parks/jordan-river",
      "Contact No": "801-533-5425",
    },
    {
      "S.no": 25,
      Name: "Jordanelle State Park",
      "County Name": "Wasatch",
      Address: "MARINA OFF EXIT 8 US 40 HEBER CITY, UT 84032",
      Website: "https://stateparks.utah.gov/parks/jordanelle",
      "Contact No": "435-649-9540",
    },
    {
      "S.no": 26,
      Name: "Kodachrome Basin State Park",
      "County Name": "Kane",
      Address: "105 SO PARIA LANE CANNONVILLE, UT 84718",
      Website: "https://stateparks.utah.gov/stateparks/parks/kodachrome/",
      "Contact No": "435-679-8562",
    },
    {
      "S.no": 27,
      Name: "Lost Creek State Park",
      "County Name": "Morrgan",
      Address: "CROYDON, UT 84018",
      Website: "https://stateparks.utah.gov/parks/lost-creek/",
      "Contact No": "Planing Phase",
    },
    {
      "S.no": 28,
      Name: "Millsite State Park",
      "County Name": "Emery",
      Address: "FERRON CANYON ROAD FERRON, UT 84523",
      Website: "https://stateparks.utah.gov/parks/millsite",
      "Contact No": "SUMMER 435-384-2552\nWINTER 435-687-2491",
    },
    {
      "S.no": 29,
      Name: "Otter Creek Reservoir State Park",
      "County Name": "Piute",
      Address: "400 E SR 22 ANTIMONY, UT 84712",
      Website: "https://stateparks.utah.gov/parks/otter-creek",
      "Contact No": "435-624-3268",
    },
    {
      "S.no": 30,
      Name: "Palisade State Park",
      "County Name": "Sanpete",
      Address: "2200 E PALISADE ROAD STERLING, UT 84665",
      Website: "https://stateparks.utah.gov/parks/palisade",
      "Contact No": "435-835-7275",
    },
    {
      "S.no": 31,
      Name: "Piute State Park",
      "County Name": "Piute",
      Address: "PIUTE ACCESS ROAD OFF HWY 89 JUNCTION, UT 84740",
      Website: "https://stateparks.utah.gov/parks/piute",
      "Contact No": "435-624-3268",
    },
    {
      "S.no": 32,
      Name: "Quail Creek State Park",
      "County Name": "Washington",
      Address: "472 N 5300 W HURRICANE, UT 84737",
      Website: "https://stateparks.utah.gov/parks/quail-creek",
      "Contact No": "435-879-2378",
    },
    {
      "S.no": 33,
      Name: "Red Fleet State Park",
      "County Name": "Uintah",
      Address: "8750 N HIGHWAY 191 VERNAL, UT 84078",
      Website: "https://stateparks.utah.gov/parks/red-fleet",
      "Contact No": "435-789-4432",
    },
    {
      "S.no": 34,
      Name: "Rockport Reservoir State Park",
      "County Name": "Summit",
      Address: "9040 N HIGHWAY 302 PEOA, UT 84061",
      Website: "https://stateparks.utah.gov/parks/rockport",
      "Contact No": "435-336-2241",
    },
    {
      "S.no": 35,
      Name: "Sand Hollow State Park",
      "County Name": "Washington",
      Address: "3351 S SAND HOLLOW ROAD HURRICANE, UT 84737",
      Website: "https://stateparks.utah.gov/parks/sand-hollow",
      "Contact No": "435-680-0715",
    },
    {
      "S.no": 36,
      Name: "Scofield State Park",
      "County Name": "Carbon",
      Address: "STATE ROUTE 96 OFF US 6 SCOFIELD, UT 84526",
      Website: "https://stateparks.utah.gov/stateparks/parks/scofield/",
      "Contact No": "SUMMER 435-448-9449 WINTER 435-687-2491",
    },
    {
      "S.no": 37,
      Name: "Snow Canyon State Park",
      "County Name": "Washington",
      Address: "1002 SNOW CANYON DRIVE IVINS, UT 84738",
      Website: "https://stateparks.utah.gov/stateparks/parks/snow-canyon/",
      "Contact No": "435-628-2255",
    },
    {
      "S.no": 38,
      Name: "Steinaker State Park",
      "County Name": "Uintah",
      Address: "4335 N HIGHWAY 191 VERNAL, UT 84078",
      Website: "https://stateparks.utah.gov/parks/steinaker",
      "Contact No": "435-789-4432",
    },
    {
      "S.no": 39,
      Name: "Territorial Statehouse State Park",
      "County Name": "Millard",
      Address: "50 W CAPITOL AVE FILLMORE, UT 84631",
      Website: "https://stateparks.utah.gov/parks/territorial-statehouse",
      "Contact No": "435-743-5316",
    },
    {
      "S.no": 40,
      Name: "This Is the Place Heritage Park",
      "County Name": "Salt Lake",
      Address: "2601 SUNNYSIDE AVE SALT LAKE CITY, UT 84108",
      Website: "https://stateparks.utah.gov/parks/heritage-park/",
      "Contact No": "801-582-1847",
    },
    {
      "S.no": 41,
      Name: "Utah Field House of Natural History State Park Museum",
      "County Name": "Uintah",
      Address: "496 E MAIN STREET VERNAL, UT 84078",
      Website: "https://stateparks.utah.gov/parks/utah-field-house/",
      "Contact No": "435-789-3799",
    },
    {
      "S.no": 42,
      Name: "Utah Lake State Park",
      "County Name": "Utah",
      Address: "4400 W CENTER STREET PROVO, UT 84601",
      Website: "https://stateparks.utah.gov/parks/utah-lake/",
      "Contact No": "801-375-0731",
    },
    {
      "S.no": 43,
      Name: "UtahRaptor State Park",
      "County Name": "Grand",
      Address: "Moab, UT 84532",
      Website: "https://stateparks.utah.gov/parks/utahraptor/",
      "Contact No": "Planning Phase",
    },
    {
      "S.no": 44,
      Name: "Wasatch Mountain State Park",
      "County Name": "Wasatch",
      Address: "1281 WARM SPRINGS DRIVE MIDWAY, UT 84049",
      Website: "https://stateparks.utah.gov/parks/wasatch-mountain/",
      "Contact No": "435-654-1791",
    },
    {
      "S.no": 45,
      Name: "Willard Bay",
      "County Name": "Box Elder",
      Address: "900 W 650 N #A WILLARD, UT 84340",
      Website: "https://stateparks.utah.gov/parks/willard-bay/",
      "Contact No": "435-734-9494",
    },
    {
      "S.no": 46,
      Name: "Yuba State Park",
      "County Name": "Juab and Sanpete",
      Address: "1225 SO YUBA DAM ROAD LEVAN, UT 84639",
      Website: "https://stateparks.utah.gov/parks/yuba",
      "Contact No": "435-758-2611",
    },
  ];

  return (
    <div>
      {/* <div>
     <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      State Parks in Utah
      <div className='mt-2 font-bold text-2xl'>(46 State Parks)</div>
      </div> */}

      <div
        className="min-h-screen mt-[1rem]  flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgb(210, 234, 255)" }}
      >
        <div className="overflow-x-auto w-full mb-[3rem]">
          {/* <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      National Parks in Utah
      <div className='mt-2 font-bold text-2xl'>(5 National Parks)</div> */}
          <div
            className=" bg-[#d2eaff] flex flex-col items-center justify-center"
            aria-label="Utah aquatic scenery"
            style={{
              backgroundImage: `url(${statePark})`, // Replace with your image path
              backgroundSize: "cover", // Ensures the image covers the entire container
              backgroundPosition: "center", // Centers the image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
              width: "100%", // Ensures the container takes full width of the screen
              height: "45vh", // Makes the container span the full height of the viewport
            }}
          >
            <h2
              className="text-white text-center px-4 text-5xl sm:text-6xl md:text-6xl lg:text-7xl mt-7"
              style={{
                color: "white",
                textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
                lineHeight: "1.2", // Adjusts line spacing
              }}
            >
              State Parks
            </h2>
            <div className="mt-2 font-bold text-2xl" style={{ color: "#fff" }}>
              (46 State Parks)
            </div>

            <span
              className="text-center px-4 text-lg sm:text-2xl md:text-3xl lg:text-4xl mt-4"
              style={{
                color: "#fff",
                fontFamily: "SignPainter", // Use the SignPainter font
                textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
                lineHeight: "1.5", // Adjusts line spacing
              }}
            >
              Explore Utah By The Numbers
            </span>
          </div>
        </div>

        <div className="overflow-x-auto w-[85%] bg-white shadow-md rounded-lg p-4">
          <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
            <thead>
              <tr>
                {/* <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  S.no
                </th> */}
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Name
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  County Name
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Address
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Website
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  Contact No
                </th>
              </tr>
            </thead>
            <tbody>
              {park.map((park) => (
                <tr key={park.sn} className="hover:bg-gray-100">
                  {/* <td className="border border-gray-300 px-4 py-2 text-center ">
                    {park["S.no"]}
                  </td> */}

                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {park.Name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {park["County Name"]}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {park.Address}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <a
                      href={park.Website}
                      target="_blank"
                      rel=" noopener noreferrer"
                    >
                      {park.Website}
                    </a>
                  </td>

                  <td className="border border-gray-300 px-4 py-2">
                    {park["Contact No"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StateParks;
