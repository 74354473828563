import React from 'react'

export default function School1() {

    const schoolData = 
    [
        { "schoolName": "Alpine Online School", "numberOfStudents": 110, "grades": "KG-8" },
        { "schoolName": "Alpine School", "numberOfStudents": 643, "grades": "PK-6" },
        { "schoolName": "American Fork High", "numberOfStudents": 2412, "grades": "10-12" },
        { "schoolName": "American Fork Jr High", "numberOfStudents": 1894, "grades": "7-9" },
        { "schoolName": "Aspen School", "numberOfStudents": 421, "grades": "PK-6" },
        { "schoolName": "Barratt School", "numberOfStudents": 564, "grades": "PK-6" },
        { "schoolName": "Belmont School", "numberOfStudents": 1036, "grades": "PK-6" },
        { "schoolName": "Black Ridge School", "numberOfStudents": 1191, "grades": "PK-6" },
        { "schoolName": "Bonneville School", "numberOfStudents": 566, "grades": "PK-6" },
        { "schoolName": "Brookhaven School", "numberOfStudents": 1037, "grades": "PK-6" },
        { "schoolName": "Canyon View Jr High", "numberOfStudents": 1112, "grades": "7-9" },
        { "schoolName": "Cascade School", "numberOfStudents": 767, "grades": "PK-6" },
        { "schoolName": "Cedar Ridge School", "numberOfStudents": 682, "grades": "PK-6" },
        { "schoolName": "Cedar Valley High", "numberOfStudents": 2924, "grades": "7-12" },
        { "schoolName": "Cedar Valley School", "numberOfStudents": 118, "grades": "PK-6" },
        { "schoolName": "Centennial Elementary", "numberOfStudents": 729, "grades": "PK-6" },
        { "schoolName": "Central School", "numberOfStudents": 496, "grades": "PK-6" },
        { "schoolName": "Cherry Hill School", "numberOfStudents": 733, "grades": "PK-6" },
        { "schoolName": "Dan W. Peterson", "numberOfStudents": 227, "grades": "PK-12" },
        { "schoolName": "Deerfield School", "numberOfStudents": 603, "grades": "PK-6" },
        { "schoolName": "Dry Creek School", "numberOfStudents": 1127, "grades": "PK-6" },
        { "schoolName": "Eaglecrest School", "numberOfStudents": 855, "grades": "PK-6" },
        { "schoolName": "Eagle Valley School", "numberOfStudents": 970, "grades": "PK-6" },
        { "schoolName": "East Shore Online", "numberOfStudents": 0, "grades": "9-12" },
        { "schoolName": "Foothill School", "numberOfStudents": 582, "grades": "PK-6" },
        { "schoolName": "Forbes School", "numberOfStudents": 424, "grades": "KG-6" },
        { "schoolName": "Fox Hollow School", "numberOfStudents": 776, "grades": "PK-6" },
        { "schoolName": "Freedom School", "numberOfStudents": 758, "grades": "PK-6" },
        { "schoolName": "Frontier Middle School", "numberOfStudents": 1634, "grades": "7-9" },
        { "schoolName": "Greenwood School", "numberOfStudents": 928, "grades": "PK-6" },
        { "schoolName": "Grovecrest School", "numberOfStudents": 630, "grades": "PK-6" },
        { "schoolName": "Harbor Point Elementary", "numberOfStudents": 717, "grades": "PK-6" },
        { "schoolName": "Harvest School", "numberOfStudents": 1046, "grades": "PK-6" },
        { "schoolName": "Hidden Hollow School", "numberOfStudents": 1124, "grades": "PK-6" },
        { "schoolName": "Highland School", "numberOfStudents": 645, "grades": "PK-6" },
        { "schoolName": "Horizon School", "numberOfStudents": 142, "grades": "PK-12" },
        { "schoolName": "Lake Mountain Middle", "numberOfStudents": 1412, "grades": "7-9" },
        { "schoolName": "Lakeridge Jr High", "numberOfStudents": 1388, "grades": "7-9" },
        { "schoolName": "Legacy School", "numberOfStudents": 844, "grades": "PK-6" },
        { "schoolName": "Lehi High", "numberOfStudents": 1982, "grades": "10-12" },
        { "schoolName": "Lehi Jr High", "numberOfStudents": 995, "grades": "7-9" },
        { "schoolName": "Lehi School", "numberOfStudents": 520, "grades": "PK-6" },
        { "schoolName": "Liberty Hills Elementary", "numberOfStudents": 761, "grades": "PK-6" },
        { "schoolName": "Lindon School", "numberOfStudents": 639, "grades": "PK-6" },
        { "schoolName": "Lone Peak High", "numberOfStudents": 2469, "grades": "10-12" },
        { "schoolName": "Manila School", "numberOfStudents": 548, "grades": "PK-6" },
        { "schoolName": "Meadow School", "numberOfStudents": 680, "grades": "PK-6" },
        { "schoolName": "Mountain Ridge Jr High", "numberOfStudents": 1073, "grades": "7-9" },
        { "schoolName": "Mountain Trails School", "numberOfStudents": 1289, "grades": "PK-6" },
        { "schoolName": "Mountain View High", "numberOfStudents": 1508, "grades": "10-12" },
        { "schoolName": "Mount Mahogany School", "numberOfStudents": 661, "grades": "PK-6" },
        { "schoolName": "North Point School", "numberOfStudents": 1022, "grades": "PK-6" },
        { "schoolName": "Northridge School", "numberOfStudents": 462, "grades": "PK-6" },
        { "schoolName": "Oak Canyon Jr High", "numberOfStudents": 1334, "grades": "7-10" },
        { "schoolName": "Orchard School", "numberOfStudents": 710, "grades": "PK-6" },
        { "schoolName": "Orem High", "numberOfStudents": 1287, "grades": "10-12" },
        { "schoolName": "Orem Jr High", "numberOfStudents": 961, "grades": "7-9" },
        { "schoolName": "Orem School", "numberOfStudents": 716, "grades": "PK-6" },
        { "schoolName": "Parkside Elementary", "numberOfStudents": 621, "grades": "PK-6" },
        { "schoolName": "Pleasant Grove High", "numberOfStudents": 2084, "grades": "10-12" },
        { "schoolName": "Pleasant Grove Jr High", "numberOfStudents": 1256, "grades": "7-9" },
        { "schoolName": "Polaris High School", "numberOfStudents": 194, "grades": "9-12" },
        { "schoolName": "Pony Express School", "numberOfStudents": 1128, "grades": "PK-6" },
        { "schoolName": "Ridgeline School", "numberOfStudents": 886, "grades": "PK-6" },
        { "schoolName": "River Rock School", "numberOfStudents": 1049, "grades": "PK-6" },
        { "schoolName": "Riverview School", "numberOfStudents": 977, "grades": "PK-6" },
        { "schoolName": "Rocky Mountain School", "numberOfStudents": 604, "grades": "PK-6" },
        { "schoolName": "Sage Hills School", "numberOfStudents": 943, "grades": "PK-6" },
        { "schoolName": "Saratoga Shores School", "numberOfStudents": 782, "grades": "PK-6" },
        { "schoolName": "Sego Lily School", "numberOfStudents": 706, "grades": "PK-6" },
        { "schoolName": "Serv By Appt", "numberOfStudents": 0, "grades": "PK-3" },
        { "schoolName": "Sharon School", "numberOfStudents": 336, "grades": "PK-6" },
        { "schoolName": "Shelley School", "numberOfStudents": 814, "grades": "PK-6" },
        { "schoolName": "Silver Lake Elementary", "numberOfStudents": 762, "grades": "PK-6" },
        { "schoolName": "Skyridge High School", "numberOfStudents": 2387, "grades": "9-12" },
        { "schoolName": "Snow Springs School", "numberOfStudents": 824, "grades": "PK-6" },
        { "schoolName": "Springside School", "numberOfStudents": 735, "grades": "PK-6" },
        { "schoolName": "Summit High", "numberOfStudents": 115, "grades": "7-12" },
        { "schoolName": "Thunder Ridge School", "numberOfStudents": 1051, "grades": "PK-6" },
        { "schoolName": "Timberline Middle", "numberOfStudents": 1315, "grades": "7-9" },
        { "schoolName": "Timpanogos High", "numberOfStudents": 1365, "grades": "10-12" },
        { "schoolName": "Trailside Elementary", "numberOfStudents": 909, "grades": "PK-6" },
        { "schoolName": "Traverse Mountain School", "numberOfStudents": 737, "grades": "PK-6" },
        { "schoolName": "Valley View School", "numberOfStudents": 424, "grades": "PK-6" },
        { "schoolName": "Viewpoint Middle", "numberOfStudents": 1790, "grades": "7-9" },
        { "schoolName": "Vineyard School", "numberOfStudents": 798, "grades": "PK-6" },
        { "schoolName": "Vista Heights Middle", "numberOfStudents": 1519, "grades": "7-10" },
        { "schoolName": "Westfield School", "numberOfStudents": 642, "grades": "PK-6" },
        { "schoolName": "Westlake High", "numberOfStudents": 2659, "grades": "10-12" },
        { "schoolName": "Westmore School", "numberOfStudents": 525, "grades": "PK-6" },
        { "schoolName": "Willowcreek Middle", "numberOfStudents": 1746, "grades": "7-9" },
        { "schoolName": "Windsor School", "numberOfStudents": 569, "grades": "PK-6" }
      ]
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        Schools in Alpine
        
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
