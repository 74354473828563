import React from 'react'

export default function StateIndustrial() {

    const schoolData = 
    [
        {
            "Bank Name": "BMW Bank of North America, Inc.",
            "Address": "2855 E COTTONWOOD PKWY STE 200, SALT LAKE CITY, UT 84121",
            "CEO/President": "Michael Ferguson, President",
            "Contact No:": "801-461-6500"
        },
        {
            "Bank Name": "Celtic Bank",
            "Address": "268 S STATE ST STE 300, SALT LAKE CITY, UT 84111",
            "CEO/President": "Todd Boren, President",
            "Contact No:": "801-363-6500"
        },
        {
            "Bank Name": "Comenity Capital Bank",
            "Address": "12921 S VISTA STATION BLVD STE 100, DRAPER, UT 84020",
            "CEO/President": "Bruce Bowman, President",
            "Contact No:": "855-796-9632"
        },
        {
            "Bank Name": "First Electronic Bank",
            "Address": "2150 S 1300 E STE 400, PO BOX 521271, SALT LAKE CITY, UT 84152-1271",
            "CEO/President": "Derek Higginbotham, President & CEO",
            "Contact No:": "801-572-4004"
        },
        {
            "Bank Name": "Medallion Bank",
            "Address": "1100 E 6600 S STE 510, SALT LAKE CITY, UT 84121",
            "CEO/President": "Don Poulton, President",
            "Contact No:": "801-284-7065"
        },
        {
            "Bank Name": "Merrick Bank",
            "Address": "10705 S JORDAN GATEWAY STE 200, SOUTH JORDAN, UT 84095",
            "CEO/President": "Brian Jones, President",
            "Contact No:": "801-545-6600"
        },
        {
            "Bank Name": "Milestone Bank (formerly LCA Bank)",
            "Address": "310 S MAIN ST STE 900, SALT LAKE CITY, UT 84101",
            "CEO/President": "Gary Harding, President & CEO",
            "Contact No:": "435-658-5446"
        },
        {
            "Bank Name": "Nelnet Bank",
            "Address": "13907 S MINUTEMAN DR STE 250, DRAPER, UT 84020",
            "CEO/President": "Andrea Moss, President",
            "Contact No:": "800-511-6452"
        },
        {
            "Bank Name": "Optum Bank, Inc.",
            "Address": "12921 S VISTA STATION BLVD STE 200, DRAPER, UT 84020",
            "CEO/President": "Gavin Payne, President",
            "Contact No:": "866-234-8913"
        },
        {
            "Bank Name": "Sallie Mae Bank",
            "Address": "175 S W TEMPLE STE 600, SALT LAKE CITY, UT 84101",
            "CEO/President": "Kerri Palmer, President",
            "Contact No:": "800-472-5543"
        },
        {
            "Bank Name": "Square Financial Services, Inc.",
            "Address": "3165 E MILLROCK DR STE 160, SALT LAKE CITY, UT 84121",
            "CEO/President": "Lew Goodwin, President",
            "Contact No:": "855-700-6000"
        },
        {
            "Bank Name": "The Pitney Bowes Bank, Inc.",
            "Address": "215 S STATE ST STE 320, SALT LAKE CITY, UT 84111",
            "CEO/President": "Raymond Dardano, President & CEO",
            "Contact No:": "801-832-4440"
        },
        {
            "Bank Name": "UBS Bank USA",
            "Address": "95 S STATE ST STE 2200, SALT LAKE CITY, UT 84111",
            "CEO/President": "Frank Destra, President",
            "Contact No:": "801-741-0310"
        },
        {
            "Bank Name": "USAA Financial Services Corp.",
            "Address": "9800 FREDERICKSBURG RD, SAN ANTONIO, TX 78288-0553",
            "CEO/President": "Yvonne Aponte, Assoicate General Counsel",
            "Contact No:": "800-531-8722"
        },
        {
            "Bank Name": "WebBank",
            "Address": "215 S STATE ST STE 1000, SALT LAKE CITY, UT 84111",
            "CEO/President": "Jason Lloyd, President",
            "Contact No:": "801-456-8350"
        },
        {
            "Bank Name": "WEX Bank",
            "Address": "111 SEGO LILY DR STE 250, SANDY, UT 84070",
            "CEO/President": "Jason Price, President",
            "Contact No:": "801-568-4374"
        }
    ]
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        State Industrial Banks in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school['Bank Name']}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school['CEO/President']}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
