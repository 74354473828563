import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UtahHousingChart = ({ data }) => {
  const chartsData = data;

  if (!chartsData) {
    return "loading...";
  }

  const barData = {
    labels: ["Dec 2023", "Dec 2024"],
    datasets: [
      {
        label: "Price",
        data: [
          chartsData["12-2023"].replace(/[$,]/g, ""),
          chartsData["12-2024"].replace(/[$,]/g, ""),
        ],
        backgroundColor: ["rgba(54, 162, 235, 0.8)", "rgba(255, 99, 132, 0.8)"], // Blue & Red bars
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
        barThickness: 50,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: chartsData.Color, // White color for legend text
        },
      },
      title: {
        display: true,
        text: "Median Sales Price Comparison: Dec 2023 vs Dec 2024 (Monthly)",
        color: chartsData.Color,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `$${tooltipItem.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
          color: chartsData.Color,
        },
        ticks: {
          color: chartsData.Color, // White color for x-axis labels
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)", // Light white grid lines
        },
      },
      y: {
        title: {
          display: true,
          text: "Price ($)",
          color: chartsData.Color,
        },
        ticks: {
          color: chartsData.Color, // White color for x-axis labels
        },
        grid: {
          color: "rgba(255, 255, 255, 0.2)", // Light white grid lines
        },
        beginAtZero: true,
      },
    },
    animation: {
      duration: 1500,
      easing: "easeInOutBounce",
    },
  };

  const chartStyle = {
    perspective: "1000px",
    transform: "rotateX(-10deg)",
    margin: "20px auto",
    maxWidth: "90%",
    padding: "15px",
  };

  return (
    <div style={chartStyle}>
      <Bar data={barData} options={options} />

      {chartsData["Increase/Decrease"][0] === "-" ? (
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            marginTop: "10px",
            color: "red",
          }}
        >
          {" "}
          <strong>Decrease:</strong> {chartsData["Increase/Decrease"]}
        </p>
      ) : (
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            marginTop: "10px",
            color: "green",
          }}
        >
          {" "}
          <strong>Increase:</strong> {chartsData["Increase/Decrease"]}
        </p>
      )}
    </div>
  );
};

export default UtahHousingChart;
