// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// // Register Chart.js components
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export default function TableNo23() {
//     // Data for the table and chart
//     const data = {
//         year: '2023',
//         maleEmployees: 104888,
//     };

//     // Chart data configuration
//     const chartData = {
//         labels: [data.year],
//         datasets: [
//             {
//                 label: 'Total No: of Male Employees in 2023',
//                 data: [data.maleEmployees],
//                 backgroundColor: 'rgba(54, 162, 235, 0.7)',
//                 borderColor: 'rgba(54, 162, 235, 1)',
//                 borderWidth: 1,
//             },
//         ],
//     };

//     // Chart options
//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 position: 'top',
//             },
//             title: {
//                 display: true,
//                 text: 'Number of Male Employees in 2023',
//             },
//         },
//         scales: {
//             y: {
//                 beginAtZero: true,
//                 ticks: {
//                     callback: value => value.toLocaleString(), // Format y-axis values with commas
//                 },
//             },
//         },
//     };

//     return (
//         <div className="w-full mx-auto my-6 p-4">
//             <h2 className="text-center text-2xl font-bold mb-4">Number of Male Employees in 2023</h2>

//             {/* Table */}
//             <div className="overflow-x-auto mb-8">
//                 <table className="min-w-full border border-gray-300 text-center">
//                     <thead className="bg-gray-200">
//                         <tr>
//                             <th className="px-4 py-2">Year</th>
//                             <th className="px-4 py-2">Total No: of Male Employees</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr className="border-b">
//                             <td className="px-4 py-2 font-semibold">{data.year}</td>
//                             <td className="px-4 py-2">{data.maleEmployees.toLocaleString()}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>

//             {/* Bar Chart */}
//             <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//                 <Bar data={chartData} options={options} />
//             </div>
//         </div>
//         </div>
//     );
// }

import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Images for PDF branding
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";
const pdfIcon = "/img/pdf1.png";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TableNo23() {
  const pdfRef = useRef();

  // Data for the table and chart
  const data = {
    year: "2023",
    maleEmployees: 104888,
  };

  // Chart data configuration
  const chartData = {
    labels: [data.year],
    datasets: [
      {
        label: "Total No: of Male Employees in 2023",
        data: [data.maleEmployees],
        backgroundColor: "rgba(54, 162, 235, 0.7)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Number of Male Employees in 2023" },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(), // Format y-axis values with commas
        },
      },
    },
  };

  // PDF generation logic
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header branding
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      // Add chart content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Footer branding and metadata
      pdf.setLineWidth(0.5);
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250211.v1.113", 148, 275);
      pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      // Save PDF
      pdf.save("number_of_male_employees.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="w-full mx-auto my-6 p-4">
      <div ref={pdfRef}>
        <h2 className="text-center text-2xl font-bold mb-4">
          Number of Male Employees in 2023
        </h2>

        {/* Table */}
        <div className="overflow-x-auto mb-8">
          <table className="min-w-full border border-gray-300 text-center">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2">Year</th>
                <th className="px-4 py-2">Total No: of Male Employees</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-4 py-2 font-semibold">{data.year}</td>
                <td className="px-4 py-2">
                  {data.maleEmployees.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Bar Chart */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* PDF Download Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <img className="w-[2rem]" src={pdfIcon} alt="PDF Icon" />
        </button>
      </div>
    </div>
  );
}
