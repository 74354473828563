import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
// import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonBase } from '@mui/material';

const carbon = "/img/carbon.jpg"
const carbongeography = "/img/carbon-geography.jpg"
const CarbonDemography = "/img/carbon-demography1.jpg"
const boxEconomy = "/img/Box-Elder-Economy1.jpg"
const carbonhighlight = "/img/Carbon-County.png"

const cardData = [
  {
    img: carbon,
    tag: 'About Carbon County',
    title: 'Overview of Carbon County, Utah',
    description:
      'Carbon County is a county in the U.S. state of Utah. As of the 2020 United States Census, the population was 20,412. Its county seat and largest city is Price.The Price, UT Micropolitan Statistical Area includes all of Carbon County.',
    authors: [
      { name: '', avatar: '' },
      { name: '', avatar: '' },
    ],
  },
  {
    img: carbongeography,
    tag: 'Geography',
    title: 'Geography and Elevation of Carbon County ',
    description:
      'The Green River flows south-southeastward along the county eastern border. The lower central part of Carbon County is a continuation of Castle Valley in Emery County, but in Carbon, the valley is ringed with mountains - the Wasatch Range to the west and northwest, and the Book Cliffs to the north and northeast.',
    authors: [{ name: '', avatar: '/static/images/avatar/6.jpg' }],
  },
  {
    img: CarbonDemography,
    tag: ' Demography',
    title: 'Population Growth and Demographics of Carbon County',
    description:
      'According to the 2020 United States census[15] and 2020 American Community Survey,[16] there were 20,412 people in Carbon County with a population density of 13.8 people per square mile (5.3/km2). Among non-Hispanic or Latino people, the racial makeup was 16,645 (81.5%) White, 50 (0.2%) African American, 199 (1.0%) Native American',
    authors: [{ name: '', avatar: '/static/images/avatar/7.jpg' }],
  },
  {
    img: 'https://picsum.photos/800/450?random=4',
    tag: 'History',
    title: "Urban Expansion and Development in Box Elder County",
    description:
      "The county was created by the Utah Territory legislature on January 5, 1856, with the territory partitioned from Weber County. Its boundaries were altered in 1862 by adjustments between counties and in 1866 when all its area in the now-existent state of Nevada (which had gained territorial status in 1861 and statehood in 1864) was formally partitioned. The county boundaries were finally altered in 1880 by adjustments between Salt Lake and Weber counties. Its boundary has remained unchanged since 1880.",
    authors: [{ name: '', avatar: '/static/images/avatar/3.jpg' }],
  },
  {
    img: 'https://picsum.photos/800/450?random=45',
    tag: 'Settler',
    title: ' Early Settlement and Pioneer Life in Box Elder County',
    description:
      "During the 1820s and 1830s, fur trappers, including Peter Skene Ogden and Joseph R. Walker, explored the eastern and northern parts of the county.Permanent white settlement began in 1851, when a group of Mormons took up land in North Willow Creek (Willard). Brigham City, which later would become an important center of the Mormon cooperative movement, was settled that same year.",
    authors: [
      { name: '', avatar: '/static/images/avatar/4.jpg' },
      { name: '', avatar: '/static/images/avatar/5.jpg' },
    ],
  },
  {
    img: boxEconomy,
    tag: ' Economy',
    title: 'Economic Development and Industry in Box Elder County',
    description:
      'Agriculture has always played an important role in the economy of Box Elder County. About 40 percent of the county’s land is used for farming or ranching. Common crops like hay, grain and alfalfa are still grown. Starting in 1901, many farmers planted sugar beets. Two sugar factories, one in Garland and the other in Brigham City, operated for many years, but they have closed now. Box Elder also has abundant fruit orchards and vegetable crops.',
    authors: [{ name: '', avatar: '/static/images/avatar/2.jpg' }],
  },
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: (theme.vars || theme).palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px',
  },
}));

const SyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

// function Author({ authors }) {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         gap: 2,
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         padding: '16px',
//       }}
//     >
//       <Box
//         sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
//       >
//         <AvatarGroup max={3}>
//           {authors.map((author, index) => (
//             <Avatar
//               key={index}
//               alt={author.name}
//               src={author.avatar}
//               sx={{ width: 24, height: 24 }}
//             />
//           ))}
//         </AvatarGroup>
//         <Typography variant="caption">
//           {authors.map((author) => author.name).join(', ')}
//         </Typography>
//       </Box>
//       <Typography variant="caption">February 6, 2025</Typography>
//     </Box>
//   );
// }

// Author.propTypes = {
//   authors: PropTypes.arrayOf(
//     PropTypes.shape({
//       avatar: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
// };

export function Search() {
  return (
    <FormControl sx={{ width: { xs: '100%', md: '25ch' } }} variant="outlined">
      {/* <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'search',
        }}
      /> */}
    </FormControl>
  );
}

export default function MainContent() {

  //const [open, setOpen] = React.useState(false);
  //const [openIndex, setOpenIndex] = React.useState(null);
  const [openDialogIndex, setOpenDialogIndex] = React.useState(null);
  const [scroll, setScroll] = React.useState('paper');

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };
  // const handleClickOpen = (index, scrollType) => () => {
  //   setOpenIndex(index);
  //   setScroll(scrollType);
  // };
  const handleClickOpen = (index, scrollType) => () => {
    setOpenDialogIndex(index);
    setScroll(scrollType);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClose = () => {
  //   setOpenIndex(null);
  // };
  const handleClose = () => {
    setOpenDialogIndex(null);
  };

  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDialogIndex !== null) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialogIndex]);
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info('You clicked the filter chip.');
    window.scroll({
      top: 200,
      left: 100,
      behavior: 'smooth'
    });
  };
  const handleClick1 = () => {
    console.info('You clicked the filter chip.');
    window.scroll({
      top: 800,
      left: 100,
      behavior: 'smooth'
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div>
          <Typography variant="h1" gutterBottom sx={{ color: "#fff" }}>
            Carbon County
          </Typography>
          <Typography sx={{ color: "#fff" }}>
            Your Gateway to Carbon County's Trends & Analytics
          </Typography>
        </div>

        <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,  // space between text and image
      }}
    >
      <Typography sx={{ fontSize: '1.3rem', flex: 1 }}>
      <span className='text-2xl font-bold italic'>Population Demography</span>
      <p>
      According to the 2020 United States census and 2020 American Community Survey, there were 20,412 people in Carbon County with a population density of 13.8 people per square mile (5.3/km2). Among non-Hispanic or Latino people, the racial makeup was 16,645 (81.5%) White, 50 (0.2%) African American, 199 (1.0%) Native American, 63 (0.3%) Asian, 29 (0.1%) Pacific Islander, 58 (0.3%) from other races, and 691 (3.4%) from two or more races. 2,677 (13.1%) people were Hispanic or Latino.

There were 10,228 (50.11%) males and 10,184 (49.89%) females, and the population distribution by age was 5,227 (25.6%) under the age of 18, 11,372 (55.7%) from 18 to 64, and 3,813 (18.7%) who were at least 65 years old. The median age was 38.6 years.



      </p>
      <span className='text-2xl font-bold italic'>At a Glance</span>
      <p>
      Carbon County was part of Emery County, founded in 1880. The demographics along the Price River changed with the construction of the Denver and Rio Grande Western Railroad in 1883 and the development of coal mines, largely in upper Emery, to fuel the railroad. The Utah Territory Legislature was petitioned to split off the north part, and thus it established Carbon County effective March 8, 1894. It was named for the element Carbon, to emphasize the industrial nature of the area.

Carbon County is the second-largest natural gas producer in Utah (after Uintah County), with 94 billion cubic feet produced in 2008.
        </p>
      </Typography>
      <Box
        component="img"
        src={carbonhighlight}
        alt="Historic Courthouse"
        sx={{
          width: { xs: '100px', md: '200px' },  // Responsive width
          height: 'auto',
          borderRadius: 2,
          boxShadow: 3,  // Adds subtle shadow
        }}
      />
    </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          <Search />
          {/* <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "start", md: "center" },
            gap: 4,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              gap: 3,
              overflow: "auto",
            }}
          >
            <Chip onClick={handleClick} size="medium" label="About Us" />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Geography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label=" Demography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label=" Economy"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="History"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="Settler"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "row",
              gap: 1,
              width: { xs: "100%", md: "fit-content" },
              overflow: "auto",
            }}
          >
            <Search />
            {/* <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton> */}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(0, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(0)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 0 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[0].img}
                  sx={{
                    aspectRatio: "16 / 9",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />

                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[0].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[0].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[0].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Author authors={cardData[0].authors} /> */}
                {/* <Button onClick={handleClickOpen('paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
                {/* <Button onClick={handleClickOpen(0, 'paper')} sx={{ alignSelf: 'center' }}>
  Learn More...
</Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>

          <Dialog
            open={openDialogIndex === 0}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
            Overview of Carbon County, Utah
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')} */}
              Carbon County is a county in the U.S. state of Utah. As of the 2020 United States Census, the population was 20,412. Its county seat and largest city is Price.
The Price, UT Micropolitan Statistical Area includes all of Carbon County.



              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(1, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(1)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 1 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[1].img}
                  aspect-ratio="16 / 9"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[1].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[1].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[1].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[1].authors} /> */}
                {/* <Button onClick={handleClickOpen(1,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 1}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
            Geography and Elevation of Carbon County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
             The Green River flows south-southeastward along the county's eastern border. The lower central part of Carbon County is a continuation of Castle Valley in Emery County, but in Carbon, the valley is ringed with mountains - the Wasatch Range to the west and northwest, and the Book Cliffs to the north and northeast. The county generally slopes to the south and east; its highest point is Monument Peak on the crest of the Wasatch Mountains near the midpoint of the county's western border, at 10,452 ft (3,186 m) ASL. The county has a total area of 1,485 square miles (3,850 km2), of which 1,478 square miles (3,830 km2) is land and 6.1 square miles (16 km2) (0.4%) is water.

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(2, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(2)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 2 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[2].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[2].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[2].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[2].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[2].authors} /> */}
                {/* <Button onClick={handleClickOpen(2,'paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 2}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
            Population Growth and Demographics of Carbon County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
According to the 2020 United States census[15] and 2020 American Community Survey,[16] there were 20,412 people in Carbon County with a population density of 13.8 people per square mile (5.3/km2). Among non-Hispanic or Latino people, the racial makeup was 16,645 (81.5%) White, 50 (0.2%) African American, 199 (1.0%) Native American, 63 (0.3%) Asian, 29 (0.1%) Pacific Islander, 58 (0.3%) from other races, and 691 (3.4%) from two or more races. 2,677 (13.1%) people were Hispanic or Latino.

There were 10,228 (50.11%) males and 10,184 (49.89%) females, and the population distribution by age was 5,227 (25.6%) under the age of 18, 11,372 (55.7%) from 18 to 64, and 3,813 (18.7%) who were at least 65 years old. The median age was 38.6 years.

There were 7,950 households in Carbon County with an average size of 2.57 of which 5,279 (66.4%) were families and 2,671 (33.6%) were non-families. Among all families, 3,994 (50.2%) were married couples, 433 (5.4%) were male householders with no spouse, and 852 (10.7%) were female householders with no spouse. Among all non-families, 2,231 (28.1%) were a single person living alone and 440 (5.5%) were two or more people living together. 2,500 (31.4%) of all households had children under the age of 18. 5,665 (71.3%) of households were owner-occupied while 2,285 (28.7%) were renter-occupied.

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "100%",
              }}
            >
              <ButtonBase
                onClick={handleClickOpen(4, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(3)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 3 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[3].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[3].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[3].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[3].authors} /> */}
                  {/* <Button onClick={handleClickOpen(4,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 4}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                Urban Expansion and Development in Box Elder County
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    The county was created by the Utah Territory legislature on January 5, 1856, with the territory partitioned from Weber County. Its boundaries were altered in 1862 by adjustments between counties and in 1866 when all its area in the now-existent state of Nevada (which had gained territorial status in 1861 and statehood in 1864) was formally partitioned. The county boundaries were finally altered in 1880 by adjustments between Salt Lake and Weber counties. Its boundary has remained unchanged since 1880.[4][5]

The California Trail followed Goose Creek from a point just north of the Idaho/Utah border southwest across northwestern Box Elder County to Little Goose Creek in northeastern Elko County, Nevada.[6] The link-up of the first transcontinental railroad occurred at Promontory Summit, Utah in 1869.

The Spiral Jetty, an earthwork sculpture by Robert Smithson, was built on the north shore of the Great Salt Lake in Box Elder County in 1970.

On November 19, 2005, sculptor Zaq Landsberg declared his plot to be independent from the United States, creating the Republic of Zaqistan.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
              <ButtonBase
                onClick={handleClickOpen(5, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(4)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 4 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[4].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[4].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[4].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[4].authors} /> */}
                  {/* <Button onClick={handleClickOpen(5,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 5}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                Early Settlement and Pioneer Life in Box Elder County
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                   During the 1820s and 1830s, fur trappers, including Peter Skene Ogden and Joseph R. Walker, explored the eastern and northern parts of the county.

Permanent white settlement began in 1851, when a group of Mormons took up land in North Willow Creek (Willard). Brigham City, which later would become an important center of the Mormon cooperative movement, was settled that same year.


Dave Peterson’s farm, southeast of Tremonton in 1945
The settlers moved into land occupied by the Shoshone Indians, and their presence began to destroy Shoshone food sources. In return, the Indians raided livestock, and sometimes the settlers and Indians fought. These problems continued until July 30, 1863, when the territorial governor James Duane Doty negotiated the Treaty of Box Elder in Brigham City.

In 1856 the territorial legislature created Box Elder County from part of Weber County. Later, in 1880, the legislature divided the water and islands of the Great Salt Lake among Salt Lake, Davis, Weber, Tooele, and Box Elder counties.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(3, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(5)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 5 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[5].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[5].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[5].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[5].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Button onClick={handleClickOpen(3,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 3}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
            Economic Development and Industry in Box Elder County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
               Agriculture has always played an important role in the economy of Box Elder County. About 40 percent of the county’s land is used for farming or ranching. Common crops like hay, grain and alfalfa are still grown. Starting in 1901, many farmers planted sugar beets. Two sugar factories, one in Garland and the other in Brigham City, operated for many years, but they have closed now. Box Elder also has abundant fruit orchards and vegetable crops.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </React.Fragment>
  );
}