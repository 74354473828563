import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getAgeLabel = (value) => {
  switch (value) {
    case "A00":
      return "All Ages (14-99)";
    case "A01":
      return "14-18";
    case "A02":
      return "19-21";
    case "A03":
      return "22-24";
    case "A04":
      return "25-34";
    case "A05":
      return "35-44";
    case "A06":
      return "45-54";
    case "A07":
      return "55-64";
    case "A08":
      return "65-99";
    default:
      return "Unknown";
  }
};

const PercentageShareChart = (props) => {
  const pdfRef = useRef();
  const { data } = props;
  // Ensure data is valid and not empty
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }
  // Prepare data for the horizontal bar chart
  const chartData = {
    labels: data.map((item) => getAgeLabel(item.agegrp_label)), // Labels for age groups
    datasets: [
      {
        label: "Percentage Share (%)",
        data: data.map((item) => parseFloat(item.percentage_share || 0)), // Percentage share data
        backgroundColor: [
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 99, 132, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(255, 205, 86, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(201, 203, 207, 0.7)",
          "rgba(255, 159, 64, 0.7)",
          "rgba(99, 132, 255, 0.7)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(201, 203, 207, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(99, 132, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    indexAxis: "y", // Makes the bar chart horizontal
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Percentage Share by Age Group",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Percentage Share (%)",
        },
        beginAtZero: true,
      },
      y: {
        title: {
          display: true,
          text: "Age Groups",
        },
      },
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // Logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      // Date and URL
      const currentDate = new Date().toLocaleDateString();
      pdf.setFontSize(10);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("percentage-share-chart.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <>
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Percentage Share Table</h2>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Age Group</th>
                  <th>Number of Employees</th>
                  <th>Percentage Share (%)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{getAgeLabel(row.agegrp_label)}</td>
                    <td>{row.num_employees}</td>
                    <td>{row.percentage_share}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} onClick={downloadPDF} />
          </span>
        </button>
      </div>
    </>
  );
};

export default PercentageShareChart;
