import React from "react";
import { Link } from "react-router-dom";

const alpine = "/school district/Alpine School District.png";
const beaver = "/school district/Beaver School District.jpeg";
const boxelder = "/school district/Box Elder School District.png";
const cache = "/school district/Cache County School District.png";
const canyons = "/school district/canyons.jpeg";
const carbon = "/school district/Carbon School District.png";
const daggett = "/school district/Daggett School District.png";
const davis = "/school district/Davis School District.png";
const duchesne = "/school district/Duchesne County School District.png";
const emery = "/school district/Emery County School District.png";
const garfield = "/school district/Garfield School District.png";
const grand = "/school district/Grand County School District.png";
const granite = "/school district/Granite School District.png";
const iron = "/school district/Iron County School District.png";
const jordan = "/school district/Jordan School District.png";
const juab = "/school district/Juab School District.png";
const kane = "/school district/Kane School District.jpeg";
const logan = "/school district/logan School District.jpeg";
const millard = "/school district/Millard School District.png";
const morgan = "/school district/Morgan School District.png";
const murray = "/school district/Murray School District.png";
const nebo = "/school district/Nebo School District.png";
const North = "/school district/North Sanpete School District.png";
const northsummit = "/school district/North Summit School District.png";
const ogden = "/school district/Ogden School District.png";
const park = "/school district/Park City School District.png";
const piute = "/school district/Piute School District.png";
const provo = "/school district/Provo City School District.png";
const rich = "/school district/Rich School District.jpeg";
const saltLake = "/school district/Salt Lake City School District.png";
const san = "/school district/San Juan School District.png";
const sevier = "/school district/Sevier School District.png";
const southSanpete = "/school district/South Sanpette School District.jpeg";
const southSummit = "/school district/South Summit School District.png";
const tintic = "/school district/Tintic School District.jpeg";
const tooele = "/school district/Tooele County School District.png";
const uintah = "/school district/Unitah School District.png";
const wasatch = "/school district/Wasatch County School District.png";
const washington = "/school district/Washington County School District.jpeg";
const wayne = "/school district/Wayne County School District.jpeg";
const weber = "/school district/Weber School District.png";

export default function SchoolDistrict() {
  const schoolData = [
    {
      sn: 1,
      district: "Alpine School District",
      schools: 92,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/alpine",
      logo: alpine,
    },
    {
      sn: 2,
      district: "Beaver School District",
      schools: 7,
      county: "Beaver",
      link: "/utah-at-a-glance/utah-schools/beaver",
      logo: beaver,
    },
    {
      sn: 3,
      district: "Box Elder School District",
      schools: 23,
      county: "Box Elder",
      link: "/utah-at-a-glance/utah-schools/boxelder",
      logo: boxelder,
    },
    {
      sn: 4,
      district: "Cache County School District",
      schools: 26,
      county: "Cache",
      link: "/utah-at-a-glance/utah-schools/cache",
      logo: cache,
    },
    {
      sn: 5,
      district: "Canyons School District",
      schools: 50,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/canyons",
      logo: canyons,
    },
    {
      sn: 6,
      district: "Carbon School District",
      schools: 9,
      county: "Carbon",
      link: "/utah-at-a-glance/utah-schools/carbon",
      logo: carbon,
    },
    {
      sn: 7,
      district: "Daggett School District",
      schools: 4,
      county: "Daggett",
      link: "/utah-at-a-glance/utah-schools/daggett",
      logo: daggett,
    },
    {
      sn: 8,
      district: "Davis School District",
      schools: 96,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/davis",
      logo: davis,
    },
    {
      sn: 9,
      district: "Duchesne County School District",
      schools: 15,
      county: "Duchesne",
      link: "/utah-at-a-glance/utah-schools/duchesne",
      logo: duchesne,
    },
    {
      sn: 10,
      district: "Emery School District",
      schools: 10,
      county: "Emery",
      link: "/utah-at-a-glance/utah-schools/emery",
      logo: emery,
    },
    {
      sn: 11,
      district: "Garfield School District",
      schools: 10,
      county: "Garfield",
      link: "/utah-at-a-glance/utah-schools/garfield",
      logo: garfield,
    },
    {
      sn: 12,
      district: "Grand County School District",
      schools: 4,
      county: "Grand",
      link: "/utah-at-a-glance/utah-schools/grand",
      logo: grand,
    },
    {
      sn: 13,
      district: "Granite School District",
      schools: 89,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/granite",
      logo: granite,
    },
    {
      sn: 14,
      district: "Iron County School District",
      schools: 18,
      county: "Iron",
      link: "/utah-at-a-glance/utah-schools/iron",
      logo: iron,
    },
    {
      sn: 15,
      district: "Jordan School District",
      schools: 70,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/jordan",
      logo: jordan,
    },
    {
      sn: 16,
      district: "Juab School District",
      schools: 6,
      county: "Juab",
      link: "/utah-at-a-glance/utah-schools/juab",
      logo: juab,
    },
    {
      sn: 17,
      district: "Kane School District",
      schools: 10,
      county: "Kane",
      link: "/utah-at-a-glance/utah-schools/kane",
      logo: kane,
    },
    {
      sn: 18,
      district: "Logan School District",
      schools: 11,
      county: "Cache",
      link: "/utah-at-a-glance/utah-schools/logan",
      logo: logan,
    },
    {
      sn: 19,
      district: "Millard School District",
      schools: 9,
      county: "Millard",
      link: "/utah-at-a-glance/utah-schools/millard",
      logo: millard,
    },
    {
      sn: 20,
      district: "Morgan School District",
      schools: 8,
      county: "Morgan",
      link: "/utah-at-a-glance/utah-schools/morgan",
      logo: morgan,
    },
    {
      sn: 21,
      district: "Murray School District",
      schools: 12,
      county: "Salt Lake",
      link: "/utah-at-a-glance/utah-schools/murray",
      logo: murray,
    },
    {
      sn: 22,
      district: "Nebo School District",
      schools: 49,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/nebo",
      logo: nebo,
    },
    {
      sn: 23,
      district: "North Sanpete School District",
      schools: 8,
      county: "Sanpete",
      link: "/utah-at-a-glance/utah-schools/sanpete",
      logo: North,
    },
    {
      sn: 24,
      district: "North Summit School District",
      schools: 4,
      county: "Summit",
      link: "/utah-at-a-glance/utah-schools/summit",
      logo: northsummit,
    },
    {
      sn: 25,
      district: "Ogden School District",
      schools: 24,
      county: "Weber",
      link: "/utah-at-a-glance/utah-schools/ogden",
      logo: ogden,
    },
    {
      sn: 26,
      district: "Park City School District",
      schools: 12,
      county: "Summit",
      link: "/utah-at-a-glance/utah-schools/park",
      logo: park,
    },
    {
      sn: 27,
      district: "Piute School District",
      schools: 5,
      county: "Piute",
      link: "/utah-at-a-glance/utah-schools/piute",
      logo: piute,
    },
    {
      sn: 28,
      district: "Provo City School District",
      schools: 23,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/provo",
      logo: provo,
    },
    {
      sn: 29,
      district: "Rich School District",
      schools: 5,
      county: "Rich",
      link: "/utah-at-a-glance/utah-schools/rich",
      logo: rich,
    },
    {
      sn: 30,
      district: "Salt Lake City School District",
      schools: 41,
      county: "Salt Lake",
      link: "/utah-at-a-glance/utah-schools/salt",
      logo: saltLake,
    },
    {
      sn: 31,
      district: "San Juan School District",
      schools: 12,
      county: "San Juan",
      link: "/utah-at-a-glance/utah-schools/san",
      logo: san,
    },
    {
      sn: 32,
      district: "Sevier School District",
      schools: 14,
      county: "Sevier",
      link: "/utah-at-a-glance/utah-schools/sevier",
      logo: sevier,
    },
    {
      sn: 33,
      district: "South Sanpete School District",
      schools: 8,
      county: "Sanpete",
      link: "/utah-at-a-glance/utah-schools/southSanpete",
      logo: southSanpete,
    },
    {
      sn: 34,
      district: "South Summit School District",
      schools: 6,
      county: "Summit",
      link: "/utah-at-a-glance/utah-schools/southSummit",
      logo: southSummit,
    },
    {
      sn: 35,
      district: "Tintic School District",
      schools: 5,
      county: "Juab",
      link: "/utah-at-a-glance/utah-schools/tintic",
      logo: tintic,
    },
    {
      sn: 36,
      district: "Tooele County School District",
      schools: 27,
      county: "Tooele",
      link: "/utah-at-a-glance/utah-schools/tooele",
      logo: tooele,
    },
    {
      sn: 37,
      district: "Uintah School District",
      schools: 13,
      county: "Uintah",
      link: "/utah-at-a-glance/utah-schools/uintah",
      logo: uintah,
    },
    {
      sn: 38,
      district: "Wasatch School District",
      schools: 9,
      county: "Wasatch",
      link: "/utah-at-a-glance/utah-schools/wasatch",
      logo: wasatch,
    },
    {
      sn: 39,
      district: "Washington County School District",
      schools: 55,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/washington",
      logo: washington,
    },
    {
      sn: 40,
      district: "Wayne County School District",
      schools: 4,
      county: "Wayne",
      link: "/utah-at-a-glance/utah-schools/wayne",
      logo: wayne,
    },
    {
      sn: 41,
      district: "Weber School District",
      schools: 53,
      county: "Utah",
      link: "/utah-at-a-glance/utah-schools/weber",
      logo: weber,
    },
  ];

  return (
    <div
      className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
      style={{ backgroundColor: "rgb(210, 234, 255)" }}
    >
      <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
        <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          <h1>School Districts in Utah</h1>
          <div className="mt-2 font-bold text-2xl">(41 Districts)</div>
        </div>

        <div className="overflow-x-auto">
          <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
            <thead>
              <tr>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  S.no
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  School District
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  Number of Schools
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  County Name
                </th>
              </tr>
            </thead>
            <tbody>
              {schoolData.map((school) => (
                <tr key={school.sn} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    {school.sn}
                  </td>
                  {/* <td className="border border-gray-300 px-4 py-2"> */}
                  <td className="border border-gray-300 px-4 py-2 flex text-center items-center">
                    <img
                      src={school.logo}
                      className="w-[6rem] h-auto mx-[3rem]"
                    ></img>
                    <Link
                      to={school.link}
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      {school.district}
                    </Link>
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {school.schools}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {school.county}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
