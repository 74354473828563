import React from "react";
import { Grid, Card, CardContent, Typography, CardMedia } from "@mui/material";
import {
  Business,
  Person,
  CalendarToday,
  MonetizationOn,
  Public,
} from "@mui/icons-material";

const mlm_header_logo = "/img/mlmheader.jpg";

// List of 21 unique images with titles and descriptions
const companies = [
  {
    "S.no:": "1",
    "MLM Company Name": "Herbalife",
    Founder: "Mark Hughes",
    "Year Founded": "1980",
    Headquarter: "Los Angeles, CA",
    Industry: "Nutrition, weight management, beauty products",
    "Revenue  2023": "$5.02 Billion",
    "Company Size": "10,000+ employees",
    Website: "www.herbalife.com",
    logo: "/img/mlm_logos/logo21.webp",
  },
  {
    "S.no:": "2",
    "MLM Company Name": "Avon",
    Founder: "David H. McConnell",
    "Year Founded": "1886",
    Headquarter: "New York, NY",
    Industry: "Beauty, personal care, and household products",
    "Revenue  2023": "$3.63 Billion",
    "Company Size": "10,000+ employees",
    Website: "www.avonworldwide.com",
    logo: "/img/mlm_logos/logo22.png",
  },
  {
    "S.no:": "3",
    "MLM Company Name": "Nu Skin",
    Founder: "Blake Roney, Sandie Tillotson",
    "Year Founded": "1984",
    Headquarter: "Provo, Utah",
    Industry: "Skincare, anti-aging products, nutritional supplements",
    "Revenue  2023": "$1.9 Billion",
    "Company Size": "1,000-5,000 employees",
    Website: "www.nuskin.com",
    logo: "/img/mlm_logos/logo10.svg",
  },
  {
    "S.no:": "4",
    "MLM Company Name": "Young Living",
    Founder: "D. Gary Young",
    "Year Founded": "1993",
    Headquarter: "Lehi, Utah",
    Industry: "Essential oils, wellness products",
    "Revenue  2023": "$213.7 Million",
    "Company Size": "1,001-5,000 employees",
    Website: "www.youngliving.com",
    logo: "/img/mlm_logos/logo17.svg",
  },
  {
    "S.no:": "5",
    "MLM Company Name": "do Terra ",
    Founder:
      "David Stirling, Gregory P. Cook, Corey B. Lindley, Emily Wright, Dr. David K. Hill, Mark A. Wolfert & Robert J. Young",
    "Year Founded": "2008",
    Headquarter: "Pleasant Groove, Utah",
    Industry: "Essential oils, personal care, wellness products",
    "Revenue  2023": "$1500 Million",
    "Company Size": "1,001-5,000 employees",
    Website: "www.doterra.com",
    logo: "/img/mlm_logos/logo3.svg",
  },
  {
    "S.no:": "6",
    "MLM Company Name": "USANA Health Sciences",
    Founder: "Myron Wentz",
    "Year Founded": "1992",
    Headquarter: "Salt Lake City, Utah",
    Industry: "Nutritional supplements, health and wellness products",
    "Revenue  2023": "$921 Million",
    "Company Size": "501-1000 employees",
    Website: "www.usana.com",
    logo: "/img/mlm_logos/logo16.svg",
  },
  {
    "S.no:": "7",
    "MLM Company Name": "Arbonne International",
    Founder: "Petter Morck",
    "Year Founded": "1975",
    Headquarter: "Utah",
    Industry: "Nutrition, skin care, cosmetics",
    "Revenue  2023": "$700 Million",
    "Company Size": "201-500 employees",
    Website: "www.arbonne.com",
    logo: "/img/mlm_logos/logo1.svg",
  },
  {
    "S.no:": "8",
    "MLM Company Name": "Morinda",
    Founder: "Kelly Olsen",
    "Year Founded": "1996",
    Headquarter: "Provo, Utah",
    Industry: "Dietary supplements, personal care products, and essential oils",
    "Revenue  2023": "$500 Million",
    "Company Size": "201-500 employees",
    Website: "www.morinda.com",
    logo: "/img/mlm_logos/logo8.png",
  },
  {
    "S.no:": "9",
    "MLM Company Name": "Nature's Sunshine",
    Founder: "Gene Hughes",
    "Year Founded": "1972",
    Headquarter: "Lehi, Utah",
    Industry: "Health and wellness products, dietary supplements",
    "Revenue  2023": "$445 Million",
    "Company Size": "1,001-5,000 employees",
    Website: "www.naturessunshine.com",
    logo: "/img/mlm_logos/logo9.svg",
  },
  {
    "S.no:": "10",
    "MLM Company Name": "Plexus Worldwide",
    Founder: "Tarl Robinson & Alec Clark",
    "Year Founded": "2008",
    Headquarter: "Utah",
    Industry: "Weight management and wellness products",
    "Revenue  2023": "$377 Million",
    "Company Size": "10,000+ employees",
    Website: "www.plexusworldwide.com",
    logo: "/img/mlm_logos/logo11.webp",
  },
  {
    "S.no:": "11",
    "MLM Company Name": "Younique",
    Founder: "Derek Maxfield, Melanie Huscroft",
    "Year Founded": "2012",
    Headquarter: "Lehi, Utah",
    Industry: "Cosmetics, makeup products",
    "Revenue  2023": "$368 Million",
    "Company Size": "501-1000 employees",
    Website: "www.youniqueproducts.com",
    logo: "/img/mlm_logos/uniqueLogo.png",
  },
  {
    "S.no:": "12",
    "MLM Company Name": "LIfeVantage",
    Founder: "Darren Jensen",
    "Year Founded": "2003",
    Headquarter: "Salt Lake City, Utah",
    Industry:
      "Weight loss products, wellness, skincare, energy-related products",
    "Revenue  2023": "$213 Million",
    "Company Size": "51-200 employees",
    Website: "www.lifevantage.com",
    logo: "/img/mlm_logos/logo5.png",
  },
  {
    "S.no:": "13",
    "MLM Company Name": "Monat Global",
    Founder: "Rayner Urdaneta",
    "Year Founded": "2014",
    Headquarter: "Utah",
    Industry: "Hair care, skin care, wellness products",
    "Revenue  2023": "$210 Million",
    "Company Size": "",
    Website: "www.monatglobal.com",
    logo: "/img/mlm_logos/logo7.svg",
  },
  {
    "S.no:": "14",
    "MLM Company Name": "ASEA",
    Founder: "Verdis Norton, James Pack, Tyler Norton",
    "Year Founded": "2007",
    Headquarter: "Pleasant Groove, Utah",
    Industry: "Wellness products, nutritional supplements",
    "Revenue  2023": "$160 Million",
    "Company Size": "",
    Website: "www.aseaglobal.com",
    logo: "/img/mlm_logos/logo2.svg",
  },
  {
    "S.no:": "15",
    "MLM Company Name": "Zija International",
    Founder: "Ken Brailsford",
    "Year Founded": "2003",
    Headquarter: "Lehi, Utah",
    Industry: "Nutrition, wellness products",
    "Revenue  2023": "$136 Million",
    "Company Size": "1,001-5,000 employees",
    Website: "www.zijainternational.com",
    logo: "/img/mlm_logos/logo19.png",
  },
  {
    "S.no:": "16",
    "MLM Company Name": "Synergy Worldwide",
    Founder: "Dan Higginson",
    "Year Founded": "1996",
    Headquarter: "Pleasant Grove, Utah",
    Industry: "Health and wellness products",
    "Revenue  2023": "$75 Million",
    "Company Size": "501-1000 employees",
    Website: "www.synergyworldwide.com",
    logo: "/img/mlm_logos/logo15.svg",
  },
  {
    "S.no:": "17",
    "MLM Company Name": "Modere",
    Founder: "Leslie Mower & Thomas Mower",
    "Year Founded": "1992",
    Headquarter: "Springville, Utah",
    Industry: "Health and wellness, personal care, home care",
    "Revenue  2023": "$70 Million",
    "Company Size": "201-500 employees",
    Website: "www.modere.com",
    logo: "/img/mlm_logos/logo6.png",
  },
  {
    "S.no:": "18",
    "MLM Company Name": "Rain International",
    Founder: "Byron Belka",
    "Year Founded": "2009",
    Headquarter: "Orem, Utah",
    Industry: "Health and wellness, nutritional supplements",
    "Revenue  2023": "$35 Million",
    "Company Size": "501-1000 employees",
    Website: "www.rainintl.com",
    logo: "/img/mlm_logos/logo13.png",
  },
  {
    "S.no:": "19",
    "MLM Company Name": "Zrii",
    Founder: "Bill Farley",
    "Year Founded": "2008",
    Headquarter: "Draper, Utah",
    Industry: "Wellness products, nutritional supplements",
    "Revenue  2023": "$21 Million",
    "Company Size": "",
    Website: "www.zrii.com",
    logo: "/img/mlm_logos/logo20.png",
  },
  {
    "S.no:": "20",
    "MLM Company Name": "SeneGence",
    Founder: "Joni Rogers-Kante",
    "Year Founded": "1998",
    Headquarter: "USA",
    Industry: "Skin care, cosmetics, wellness and fitness products",
    "Revenue  2023": "$20.8 Million",
    "Company Size": "51-200 employees",
    Website: "www.senegence.com",
    logo: "/img/mlm_logos/logo14.webp",
  },
  {
    "S.no:": "21",
    "MLM Company Name": "Q Sciences",
    Founder: "Daren Hogge",
    "Year Founded": "2010",
    Headquarter: "American Fork, Utah",
    Industry: "Wellness products, nutritional supplements",
    "Revenue  2023": "$7.5 Million",
    "Company Size": "501-1000 employees",
    Website: "www.qsciences.com",
    logo: "/img/mlm_logos/logo12.svg",
  },
];

export default function MLM() {
  const gotosite = (website) => {
    window.open(`https://${website}`, "_blank");
  };

  return (
    <>
      <div className="main-wrapper flex flex-col min-h-screen">
        {/* Main Content */}
        <div
          className="flex-grow bg-[#d2eaff] pt-[13vh] mt-[10vh] relative"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${mlm_header_logo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="text-overlay text-white px-4 py-8 flex flex-col justify-center items-center h-full">
            <h2 className="title text-[#ffc107]">
              <i className=" text-[#ffc107]"></i>{" "}
              <h1>
                <span className="text-[#ffc107]">
                  Multi Level Marketing Companies in Utah (21)
                </span>
              </h1>
            </h2>
          </div>
        </div>

        <div className="icon-container">
          <Grid
            container
            spacing={3}
            sx={{ padding: 3, justifyContent: "center" }}
          >
            {companies.map((company, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => gotosite(company.Website)}
                >
                  {/* Company Logo */}
                  {index == 10 ? (
                    <CardMedia
                      component="img"
                      image={company.logo}
                      alt={company["MLM Company Name"]}
                      sx={{
                        objectFit: "contain",
                        padding: 1,
                        height: "60px",
                        background: "#B0C4DE",
                      }}
                    />
                  ) : index == 11 ? (
                    <CardMedia
                      component="img"
                      image={company.logo}
                      alt={company["MLM Company Name"]}
                      sx={{
                        objectFit: "contain",
                        padding: 0,
                        height: "60px",
                        background: "#B0C4DE",
                      }}
                    />
                  ) : index == 14 ? (
                    <CardMedia
                      component="img"
                      image={company.logo}
                      alt={company["MLM Company Name"]}
                      sx={{
                        objectFit: "contain",
                        padding: 1,
                        height: "60px",
                        background: "#B0C4DE",
                      }}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      image={company.logo}
                      alt={company["MLM Company Name"]}
                      sx={{
                        objectFit: "contain",
                        padding: 2,
                        height: "60px",
                        background: "#B0C4DE",
                      }}
                    />
                  )}

                  <CardContent sx={{ textAlign: "left" }}>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      {company.name}
                    </Typography>

                    <Grid
                      container
                      spacing={1}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {/* Founder */}
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Person
                          sx={{ fontSize: 16, color: "gray", marginRight: 1 }}
                        />
                        <strong>Founder:</strong>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {company.Founder}
                        </Typography>
                      </Grid>

                      {/* Year Founded */}
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <CalendarToday
                          sx={{ fontSize: 16, color: "gray", marginRight: 1 }}
                        />
                        <strong>Year:</strong>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {company["Year Founded"]}
                        </Typography>
                      </Grid>

                      {/* Industry */}
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Business
                          sx={{ fontSize: 16, color: "gray", marginRight: 1 }}
                        />
                        <strong>Industry:</strong>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {company.Industry}
                        </Typography>
                      </Grid>

                      {/* Revenue */}
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <MonetizationOn
                          sx={{ fontSize: 16, color: "gray", marginRight: 1 }}
                        />
                        <strong>Revenue (2023):</strong>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="body2"
                          sx={{ color: "green", fontWeight: "bold" }}
                        >
                          {company["Revenue  2023"] || "N/A"}
                        </Typography>
                      </Grid>

                      {/* Headquarters */}
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Public
                          sx={{ fontSize: 16, color: "gray", marginRight: 1 }}
                        />
                        <strong>HQ:</strong>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {company.Headquarter}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>

        {/* Footer */}
      </div>
    </>
  );
}
