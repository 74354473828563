import React from 'react'

export default function School2() {

    const schoolData = 
    [
        {
          "schoolName": "Beaver High",
          "numberOfStudents": 481,
          "grades": "7-12"
        },
        {
          "schoolName": "Beaver Preschool",
          "numberOfStudents": 54,
          "grades": "PK-PK"
        },
        {
          "schoolName": "Belknap School",
          "numberOfStudents": 458,
          "grades": "KG-6"
        },
        {
          "schoolName": "Milford High",
          "numberOfStudents": 222,
          "grades": "7-12"
        },
        {
          "schoolName": "Milford School",
          "numberOfStudents": 215,
          "grades": "KG-6"
        },
        {
          "schoolName": "Minersville School",
          "numberOfStudents": 41,
          "grades": "6-8"
        },
        {
          "schoolName": "Minersville School",
          "numberOfStudents": 90,
          "grades": "KG-5"
        }
      ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Beaver
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
