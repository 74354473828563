import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Table } from "react-bootstrap";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

// import utahData from "../../../public/img/1.png";
// import kpi from "../../../public/img/KPI-DASH 2.png";
// import bodex from "../../../public/img/bodexLogo.png";
// import pdf from "../../../public/img/pdf1.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AeroTable1 = ({ data }) => {
  const pdfRef = useRef();
  if (!data.length) return <p>No Data Available</p>;

  // Extracting years dynamically from the first data entry
  const years = Object.keys(data[0])
    .filter((key) => key.includes("Female-"))
    .map((key) => key.split("-")[1]);

  // Generate table headers
  const tableHeaders = [
    "Age Group",
    ...years.flatMap((year) => [`Female-${year}`, `Male-${year}`]),
  ];

  // Define your years
  const ageGroups = [...new Set(data.map((item) => item["Age Group"]))]; // Unique age groups from API

  // Generate labels for the X-axis (Female (2018), Male (2018), etc.)
  const chartLabels = years.flatMap((year) =>
    ["Female", "Male"].map((gender) => `${gender} (${year})`)
  );

  const datasets = ageGroups.map((ageGroup) => ({
    label: ageGroup,
    data: years.flatMap((year) =>
      ["Female", "Male"].map((gender) => {
        const fieldName = `${gender}-${year}`; // Construct the key dynamically (e.g., Female-2020)
        const ageGroupData = data.find(
          (item) => item["Age Group"] === ageGroup
        );
        return ageGroupData ? ageGroupData[fieldName] || 0 : 0; // Default to 0 if not found
      })
    ),
    backgroundColor: getRandomColor(), // Generate random colors for each age group
  }));

  // Helper function to generate random colors for datasets
  function getRandomColor() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  }

  // Chart configuration
  const chartData = {
    labels: chartLabels,
    datasets: datasets,
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment By Age, Gender, and Year",
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Gender and Year",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: "Employment Count",
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait mode, A4 size

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the header logo
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // (x, y, width, height)

      // Add "UTahData.org" text beside the logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22); // Position the text next to the logo

      // Add clickable link
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString(); // Format: MM/DD/YYYY or DD/MM/YYYY based on locale
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295); // Fixed backticks

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      // Add the main content (adjust position to avoid overlap with header)
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);

      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295); // Fixed backticks

      pdf.save("dashboard_with_footer.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="dashboard-container">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Employment Data by Age and Gender (2018-2023)</h2>

          {/* Render Table with Horizontal Scrolling */}
          <div style={{ overflowX: "auto", marginBottom: "20px" }}>
            <Table
              border="1"
              cellPadding="10"
              style={{ width: "100%", minWidth: "600px" }}
            >
              <thead>
                <tr>
                  {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{row["Age Group"]}</td>
                    {years.flatMap((year) => [
                      <td key={`female-${year}-${rowIndex}`}>
                        {row[`Female-${year}`]}
                      </td>,
                      <td key={`male-${year}-${rowIndex}`}>
                        {row[`Male-${year}`]}
                      </td>,
                    ])}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Bar Chart Section */}
          {/* <div
  style={{
    overflowX: "auto", // Enables horizontal scroll
    display: "flex",
    justifyContent: "center",
    width: "100%", // Takes full width
  }}
>
  <div
    style={{
      minWidth: "1000px", // Minimum width to enable scroll
      width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
      height: "400px",
    }}
  > */}
          <div
            style={{
              width: "90%",
              maxWidth: "800px",
              margin: "auto",
              overflowX: "auto",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%", // Takes 100% width of the parent div, but will scroll if needed
                minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
                height: "400px",
              }}
            >
              {/* <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} /> */}

              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable1;
