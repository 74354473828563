import React, { useRef, useState, useEffect } from "react";

import Form from "./Form";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/Aerospace.css";
import ModalFunction from "./Modal";

const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";

export default function ContactUs1() {
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Form Section */}
      <div className="flex-grow mt-16 bg-[#d2eaff]">
        {" "}
        {/* Adds spacing to avoid content going under fixed navbar */}
        <div>
          <div className="container mx-auto mt-[4.4rem] mb-[4.4rem] px-4 lg:px-0">
            <div
              className="iframe-container"
              style={{
                height: "150vh",
              }}
            >
              <iframe
                width="1100px"
                height="100%"
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=sDIR6CpYcE-NiPiL_r5pC1gAiP-8cr9CmaWsyO5_459UQUs0WE85SDFMWjVVSFZSNEhJT0tYTjhDMi4u&embed=true"
                frameborder="0"
                marginwidth="0"
                marginheight="0"
                style={{
                  border: "none",
                  maxWidth: "100%",
                }}
                allowfullscreen
                webkitallowfullscreen
                mozallowfullscreen
                msallowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
