import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

ChartJS.register(...registerables);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const EngTable15 = ({ data }) => {
  const pdfRef = useRef();

  const chartData = {
    labels: data.map((item) => item.education_label),
    datasets: [
      {
        label: "Year 2022",
        data: data.map((item) => parseInt(item.year_2022)),
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: "Year 2023",
        data: data.map((item) => parseInt(item.year_2023)),
        backgroundColor: "rgba(153, 102, 255, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: "Employment Growth by Education Level (2022-2023)",
        font: {
          size: 18,
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Employment",
        },
      },
      x: {
        title: {
          display: true,
          text: "Education Level",
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295);

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("eng_table2.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <>
      <div className="dashboard-container">
        <div ref={pdfRef} className="content-to-export">
          <div style={{ padding: "20px" }}>
            <h2>Employment Growth by Education Level (2022-2023)</h2>

            {/* Render Table with Horizontal Scrolling */}
            <div className="overflow-x-auto">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Education Level</th>
                    <th>Year 2022</th>
                    <th>Year 2023</th>
                    <th>Difference</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.education_label}</td>
                      <td>{row.year_2022}</td>
                      <td>{row.year_2023}</td>
                      <td>{row.difference}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Chart Section */}
            <div
              style={{
                width: "90%",
                maxWidth: "800px",
                margin: "auto",
                overflowX: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  minWidth: "600px",
                }}
              >
                <Bar data={chartData} options={options} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={downloadPDF}
            className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
          >
            <span>Download as PDF </span>
            <span>
              <img className="w-[2rem]" src={pdf} alt="Download icon" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default EngTable15;
