import React, { useRef, useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/Faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faChartLine,
  faCogs,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { FaqSection } from "./FaqSection";
//import { FaqSection } from './FaqSection';
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";

const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";

export default function Faq() {
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const [modalShow, setModalShow] = React.useState(false);

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
     

      {/* Main content */}
      <div className="bg-[#d2eaff] py-[6rem] mt-[-5rem]">
        <div className="container bg-[#d2eaff]">
          <div className="content">
            <h2 className="content-title">
              <FontAwesomeIcon icon={faIndustry} /> Manufacturing in Utah
            </h2>
            <p className="content-highlight">
              <FontAwesomeIcon icon={faChartLine} /> A Hub of Innovation and
              Growth
            </p>
            <hr className="content-separator" />
            <p className="content-description">
              <FontAwesomeIcon icon={faCogs} /> Utah’s manufacturing sector is a
              cornerstone of its economy, fostering growth and creating numerous
              employment opportunities. Spanning diverse industries such as
              aerospace manufacturing, technology hardware production, and
              traditional sectors like textiles and food processing, Utah’s
              manufacturing landscape is both dynamic and diverse.
            </p>
            <p className="content-description">
              <FontAwesomeIcon icon={faLightbulb} /> Dive into our comprehensive
              reports and insights to uncover how manufacturing is propelling
              innovation and shaping the future of economic development in Utah.
            </p>
            <div className="content-call-to-action">
              <button
                className="bg-[#F0A500] "
                onClick={() => {
                  window.scrollTo({
                    top: 600,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                View Detailed Reports
              </button>
            </div>
          </div>
        </div>
        <FaqSection />
      </div>

      {/* Footer */}
      
    </div>
  );
}
