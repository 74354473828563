import React from 'react'

export default function School16() {

    const schoolData = 
    [
        {"schoolName": "Arizona State University Prep Digital Powered By Juab", "numberOfStudents": 0, "grades": "6-12"},
        {"schoolName": "Juab High", "numberOfStudents": 890, "grades": "9-12"},
        {"schoolName": "Juab Jr High", "numberOfStudents": 639, "grades": "6-8"},
        {"schoolName": "Mona School", "numberOfStudents": 294, "grades": "KG-6"},
        {"schoolName": "Nebo View School", "numberOfStudents": 306, "grades": "KG-6"},
        {"schoolName": "Red Cliff School", "numberOfStudents": 602, "grades": "PK-6"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Juab
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
