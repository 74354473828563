import React from 'react'

export default function NationalBank() {

    const schoolData = 
    [
        {
            "Bank Name": "American Express National Bank",
            "Address": "4315 S 2700 W, SALT LAKE CITY, UT 84184",
            "CEO/President": "Anré Williams, CEO",
            "Contact No:": "800-528-2122"
        },
        {
            "Bank Name": "Bank of America",
            "Address": "1222 E DRAPER PKWY, DRAPER, UT 84020",
            "CEO/President": "Mori Paulsen, Salt Lake Market President",
            "Contact No:": "801-252-4112"
        },
        {
            "Bank Name": "BMO Bank N.A.",
            "Address": "142 E 200 S, SALT LAKE CITY, UT 84111",
            "CEO/President": "Darrel Hackett, CEO",
            "Contact No:": "801-531-3400"
        },
        {
            "Bank Name": "JP Morgan Chase Bank, N.A.",
            "Address": "201 S MAIN ST, SALT LAKE CITY, UT 84111",
            "CEO/President": "Phil Beck, Managing Director",
            "Contact No:": "801-364-3101"
        },
        {
            "Bank Name": "Key Bank, N.A.",
            "Address": "50 S MAIN ST STE 2000, SALT LAKE CITY, UT 84130-0815",
            "CEO/President": "Drew Yergensen, Utah Market President",
            "Contact No:": "800-539-2968"
        },
        {
            "Bank Name": "LendingClub Bank, N.A.",
            "Address": "2701 N THANKSGIVING WAY STE 300, LEHI, UT 84043",
            "CEO/President": "Scott Sanborn, CEO",
            "Contact No:": "800-242-0272"
        },
        {
            "Bank Name": "Morgan Stanley Bank N.A.",
            "Address": "201 S MAIN ST 5TH FLOOR, SALT LAKE CITY, UT 84111",
            "CEO/President": "Kisty Morris, Executive Director",
            "Contact No:": "801-578-8000"
        },
        {
            "Bank Name": "SoFi Bank, N.A.",
            "Address": "2750 E COTTONWOOD PKWY STE 350, COTTONWOOD HEIGHTS, UT 84121",
            "CEO/President": "Anthony Noto, CEO",
            "Contact No:": "530-742-3800"
        },
        {
            "Bank Name": "Stride Bank, N.A.",
            "Address": "331 S RIO GRANDE ST STE 307, SALT LAKE CITY, UT 84101",
            "CEO/President": "Brud Baker, CEO",
            "Contact No:": "800-229-7497"
        },
        {
            "Bank Name": "U.S. Bank, N.A.",
            "Address": "15 W S TEMPLE 6TH FLOOR, SALT LAKE CITY, UT 84101",
            "CEO/President": "Kevin Krohn, Utah Market President",
            "Contact No:": "801-534-6071"
        },
        {
            "Bank Name": "Varo Bank, N.A.",
            "Address": "11781 S LONE PEAK PKWY STE 150, DRAPER, UT 84020",
            "CEO/President": "Colin Walsh, CEO",
            "Contact No:": "415-513-5240"
        },
        {
            "Bank Name": "Wells Fargo Bank, N.A.",
            "Address": "299 S MAIN ST 10TH, SALT LAKE CITY, UT 84111",
            "CEO/President": "Matthew D. Bloye, Regional President",
            "Contact No:": "800-956-4442"
        },
        {
            "Bank Name": "Zions Bancorporation, N.A.",
            "Address": "ONE S MAIN ST, SALT LAKE CITY, UT 84111-1923",
            "CEO/President": "Paul E. Burdiss, President & CEO",
            "Contact No:": "801-974-8800"
        }
    ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        National Banks in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school['Bank Name']}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school['CEO/President']}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
