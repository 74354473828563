import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";

import "../styles/Aerospace.css";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faChartLine,
  faCogs,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { AerospaceFaqSection } from "./AerospaceFaqSection";
import ModalFunction from "./Modal";

const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const aerospace = "/img/Aerospace.webp";

export default function Aerospace() {
  const navbarRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col min-h-screen">
      {/* Main Content */}
      <div
        className="flex-grow bg-[#d2eaff] pt-[13vh] mt-[10vh] relative"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aerospace})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text-overlay text-white px-4 py-8 flex flex-col justify-center items-center h-full">
          <h2 className="title text-[#ffc107]">
            <i className=" text-[#ffc107]"></i>{" "}
            <h1>
              <span className="text-[#ffc107]">Aerospace and Defense</span>
            </h1>
          </h2>
          {/* <div className="about-us-content text-white text-center">
            <h1><span className="beta-label">We're still working on this. Hang tight!</span></h1>
          </div> */}
        </div>
      </div>

      <div className="bg-[#d2eaff] py-[6rem] mt-[-12rem]">
        <div className="container bg-[#d2eaff]">
          <div className="content">
            <h2 className="content-title">
              {/* <FontAwesomeIcon icon={faIndustry} />  */}
              <span className="fas fa-fighter-jet"></span>
              <span>Aerospace in Utah</span>
            </h2>
            <p className="content-highlight">
              <FontAwesomeIcon icon={faChartLine} /> A Hub of Innovation and
              Growth
            </p>
            <hr className="content-separator" />
            <p className="content-description">
              <FontAwesomeIcon icon={faCogs} /> Utah’s Aerospace sector is a
              cornerstone of its economy, fostering growth and creating numerous
              employment opportunities. Spanning diverse industries such as
              aerospace manufacturing, technology hardware production, and
              traditional sectors , Utah’s Aerospace landscape is both dynamic
              and diverse.
            </p>
            <p className="content-description">
              <FontAwesomeIcon icon={faLightbulb} /> Dive into our comprehensive
              reports and insights to uncover how Aerospace is propelling
              innovation and shaping the future of economic development in Utah.
            </p>
            <div className="content-call-to-action">
              <button
                className="bg-[#F0A500] "
                onClick={() => {
                  window.scrollTo({
                    top: 1000,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                View Detailed Reports
              </button>
            </div>
          </div>
        </div>
        <AerospaceFaqSection />
      </div>

      {/* Footer */}
    </div>
  );
}
