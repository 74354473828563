// RaceEthnicityAnalysis.js
import React from 'react';

const Table4 = () => {
    return (
        <div className="faq-item">
            
            <div className="faq-answer">
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Race/Ethnicity</th>
                            <th>Q4-2018</th>
                            <th>Q4-2019</th>
                            <th>Q4-2020</th>
                            <th>Q4-2021</th>
                            <th>Q4-2022</th>
                            <th>Q4-2023</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>White Alone</td>
                            <td>90,100</td>
                            <td>91,500</td>
                            <td>92,200</td>
                            <td>94,000</td>
                            <td>93,400</td>
                            <td>94,200</td>
                        </tr>
                        <tr>
                            <td>Hispanic or Latino</td>
                            <td>24,800</td>
                            <td>25,500</td>
                            <td>26,100</td>
                            <td>27,300</td>
                            <td>28,100</td>
                            <td>28,900</td>
                        </tr>
                        <tr>
                            <td>Black or African American</td>
                            <td>8,500</td>
                            <td>9,000</td>
                            <td>9,300</td>
                            <td>9,800</td>
                            <td>10,200</td>
                            <td>10,400</td>
                        </tr>
                        {/* Additional rows can be added here */}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    );
};

export default Table4;
