import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";

import "../styles/Aerospace.css";
import { useState } from "react";

import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faChartLine,
  faCogs,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
// import { AerospaceFaqSection } from './AerospaceFaqSection';

const law = "/img/law-public-safety-corrections-security.jpg";
const aerospace = "/img/Aerospace.webp";
const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";
export default function LawPublicSafety() {
  const navbarRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col min-h-screen">
      {/* Main Content */}
      <div
        className="flex-grow bg-[#d2eaff] pt-[13vh] mt-[10vh] relative"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${law})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text-overlay text-white px-4 py-8 flex flex-col justify-center items-center h-full">
          <h2 className="title text-[#ffc107]">
            <i className=" text-[#ffc107]"></i>{" "}
            <span className="text-[#ffc107]">
              Law, Public Safety, Corrections and Security
            </span>
          </h2>
          <div className="about-us-content text-white text-center">
            <h1>
              <span className="beta-label">
                We're still working on this. Hang tight!
              </span>
            </h1>
          </div>
        </div>
      </div>

      {/* Footer */}
    </div>
  );
}
