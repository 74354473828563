import React from 'react'

export default function School5() {

    const schoolData = 
    [
        {
          "schoolName": "Albion Middle",
          "numberOfStudents": 958,
          "grades": "6-8"
        },
        {
          "schoolName": "Alta High",
          "numberOfStudents": 2294,
          "grades": "8-12"
        },
        {
          "schoolName": "Altara School",
          "numberOfStudents": 538,
          "grades": "KG-5"
        },
        {
          "schoolName": "Alta View School",
          "numberOfStudents": 581,
          "grades": "KG-5"
        },
        {
          "schoolName": "Bella Vista School",
          "numberOfStudents": 202,
          "grades": "KG-5"
        },
        {
          "schoolName": "Brighton High",
          "numberOfStudents": 2360,
          "grades": "8-12"
        },
        {
          "schoolName": "Brookwood School",
          "numberOfStudents": 389,
          "grades": "KG-5"
        },
        {
          "schoolName": "Butler Middle",
          "numberOfStudents": 895,
          "grades": "6-8"
        },
        {
          "schoolName": "Butler School",
          "numberOfStudents": 605,
          "grades": "KG-5"
        },
        {
          "schoolName": "Canyons Online",
          "numberOfStudents": 163,
          "grades": "KG-8"
        },
        {
          "schoolName": "Canyons Technical Education Center",
          "numberOfStudents": 0,
          "grades": "7-12"
        },
        {
          "schoolName": "Canyons Transition Academy",
          "numberOfStudents": 46,
          "grades": "12-12"
        },
        {
          "schoolName": "Canyons Virtual High School",
          "numberOfStudents": 0,
          "grades": "6-12"
        },
        {
          "schoolName": "Canyon View School",
          "numberOfStudents": 500,
          "grades": "KG-5"
        },
        {
          "schoolName": "Copperview School",
          "numberOfStudents": 399,
          "grades": "KG-5"
        },
        {
          "schoolName": "Corner Canyon High",
          "numberOfStudents": 2434,
          "grades": "8-12"
        },
        {
          "schoolName": "Crescent School",
          "numberOfStudents": 423,
          "grades": "KG-5"
        },
        {
          "schoolName": "Diamond Ridge High School",
          "numberOfStudents": 97,
          "grades": "6-12"
        },
        {
          "schoolName": "Draper Park Middle",
          "numberOfStudents": 1494,
          "grades": "6-8"
        },
        {
          "schoolName": "Draper School",
          "numberOfStudents": 677,
          "grades": "KG-5"
        },
        {
          "schoolName": "East Midvale School",
          "numberOfStudents": 516,
          "grades": "KG-5"
        },
        {
          "schoolName": "Eastmont Middle",
          "numberOfStudents": 594,
          "grades": "6-8"
        },
        {
          "schoolName": "East Sandy School",
          "numberOfStudents": 377,
          "grades": "KG-5"
        },
        {
          "schoolName": "Entrada",
          "numberOfStudents": 25,
          "grades": "KG-12"
        },
        {
          "schoolName": "Glacier Hills Elementary",
          "numberOfStudents": 586,
          "grades": "KG-5"
        },
        {
          "schoolName": "Goldminer'S Daughter",
          "numberOfStudents": 5,
          "grades": "KG-8"
        },
        {
          "schoolName": "Granite School",
          "numberOfStudents": 355,
          "grades": "KG-5"
        },
        {
          "schoolName": "Hillcrest High",
          "numberOfStudents": 2296,
          "grades": "8-12"
        },
        {
          "schoolName": "Indian Hills Middle",
          "numberOfStudents": 1114,
          "grades": "6-8"
        },
        {
          "schoolName": "Jordan High",
          "numberOfStudents": 1785,
          "grades": "8-12"
        },
        {
          "schoolName": "Jordan Valley School",
          "numberOfStudents": 98,
          "grades": "KG-12"
        },
        {
          "schoolName": "Lone Peak School",
          "numberOfStudents": 580,
          "grades": "KG-5"
        },
        {
          "schoolName": "Midvale Middle",
          "numberOfStudents": 907,
          "grades": "6-8"
        },
        {
          "schoolName": "Midvale School",
          "numberOfStudents": 722,
          "grades": "KG-5"
        },
        {
          "schoolName": "Midvalley School",
          "numberOfStudents": 549,
          "grades": "KG-5"
        },
        {
          "schoolName": "Mt. Jordan Middle",
          "numberOfStudents": 776,
          "grades": "6-8"
        },
        {
          "schoolName": "Oakdale School",
          "numberOfStudents": 397,
          "grades": "KG-5"
        },
        {
          "schoolName": "Oak Hollow School",
          "numberOfStudents": 570,
          "grades": "KG-5"
        },
        {
          "schoolName": "Park Lane School",
          "numberOfStudents": 343,
          "grades": "KG-5"
        },
        {
          "schoolName": "Peruvian Park School",
          "numberOfStudents": 505,
          "grades": "KG-5"
        },
        {
          "schoolName": "Quail Hollow School",
          "numberOfStudents": 452,
          "grades": "KG-5"
        },
        {
          "schoolName": "Ridgecrest School",
          "numberOfStudents": 547,
          "grades": "KG-5"
        },
        {
          "schoolName": "Sandy School",
          "numberOfStudents": 512,
          "grades": "PK-5"
        },
        {
          "schoolName": "Silver Mesa School",
          "numberOfStudents": 577,
          "grades": "KG-5"
        },
        {
          "schoolName": "Special Ed Preschool",
          "numberOfStudents": 340,
          "grades": "PK-PK"
        },
        {
          "schoolName": "Sprucewood School",
          "numberOfStudents": 319,
          "grades": "KG-5"
        },
        {
          "schoolName": "Sunrise School",
          "numberOfStudents": 602,
          "grades": "KG-5"
        },
        {
          "schoolName": "Union Middle",
          "numberOfStudents": 862,
          "grades": "6-8"
        },
        {
          "schoolName": "Willow Canyon School",
          "numberOfStudents": 356,
          "grades": "KG-5"
        },
        {
          "schoolName": "Willow Springs School",
          "numberOfStudents": 664,
          "grades": "KG-5"
        }
      ]
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Canyons
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
