import React from 'react'

export default function School30() {

    const schoolData = 
    [
        {"schoolName": "Backman School", "numberOfStudents": 364, "grades": "PK-6"},
        {"schoolName": "Beacon Heights School", "numberOfStudents": 397, "grades": "KG-6"},
        {"schoolName": "Bonneville School", "numberOfStudents": 449, "grades": "KG-6"},
        {"schoolName": "Clayton Middle", "numberOfStudents": 634, "grades": "7-8"},
        {"schoolName": "Dilworth School", "numberOfStudents": 492, "grades": "PK-6"},
        {"schoolName": "East High", "numberOfStudents": 1886, "grades": "9-12"},
        {"schoolName": "Edison School", "numberOfStudents": 368, "grades": "PK-6"},
        {"schoolName": "Emerson School", "numberOfStudents": 467, "grades": "KG-6"},
        {"schoolName": "Ensign School", "numberOfStudents": 313, "grades": "PK-6"},
        {"schoolName": "Escalante School", "numberOfStudents": 362, "grades": "PK-6"},
        {"schoolName": "Franklin School", "numberOfStudents": 306, "grades": "PK-6"},
        {"schoolName": "Glendale Middle", "numberOfStudents": 680, "grades": "6-8"},
        {"schoolName": "Hawthorne School", "numberOfStudents": 362, "grades": "KG-6"},
        {"schoolName": "Highland High", "numberOfStudents": 1980, "grades": "9-12"},
        {"schoolName": "Highland Park School", "numberOfStudents": 493, "grades": "KG-6"},
        {"schoolName": "Hillside Middle", "numberOfStudents": 555, "grades": "7-8"},
        {"schoolName": "Horizonte Instruction & Training Center", "numberOfStudents": 323, "grades": "7-12"},
        {"schoolName": "Indian Hills School", "numberOfStudents": 302, "grades": "KG-6"},
        {"schoolName": "Innovations High School", "numberOfStudents": 293, "grades": "9-12"},
        {"schoolName": "Liberty School", "numberOfStudents": 391, "grades": "PK-6"},
        {"schoolName": "Mary W. Jackson School", "numberOfStudents": 388, "grades": "PK-6"},
        {"schoolName": "Meadowlark School", "numberOfStudents": 354, "grades": "PK-6"},
        {"schoolName": "M. Lynn Bennion School", "numberOfStudents": 175, "grades": "PK-6"},
        {"schoolName": "Mountain View School", "numberOfStudents": 550, "grades": "PK-6"},
        {"schoolName": "Newman School", "numberOfStudents": 267, "grades": "PK-6"},
        {"schoolName": "Nibley Park School", "numberOfStudents": 344, "grades": "PK-8"},
        {"schoolName": "North Star School", "numberOfStudents": 386, "grades": "PK-6"},
        {"schoolName": "Northwest Middle", "numberOfStudents": 633, "grades": "7-8"},
        {"schoolName": "Parkview School", "numberOfStudents": 249, "grades": "PK-6"},
        {"schoolName": "Riley School", "numberOfStudents": 223, "grades": "PK-6"},
        {"schoolName": "Rose Park School", "numberOfStudents": 294, "grades": "PK-6"},
        {"schoolName": "Salt Lake Center For Science Education Bryant", "numberOfStudents": 407, "grades": "7-8"},
        {"schoolName": "Salt Lake Head Start", "numberOfStudents": 18, "grades": "PK-PK"},
        {"schoolName": "Salt Lake Technology Center", "numberOfStudents": 0, "grades": "9-12"},
        {"schoolName": "Salt Lake Virtual Elementary", "numberOfStudents": 58, "grades": "KG-8"},
        {"schoolName": "South Park Academy", "numberOfStudents": 0, "grades": "12-12"},
        {"schoolName": "Uintah School", "numberOfStudents": 414, "grades": "KG-6"},
        {"schoolName": "Wasatch School", "numberOfStudents": 337, "grades": "KG-6"},
        {"schoolName": "Washington School", "numberOfStudents": 267, "grades": "PK-6"},
        {"schoolName": "West High", "numberOfStudents": 2600, "grades": "7-12"},
        {"schoolName": "Whittier School", "numberOfStudents": 499, "grades": "PK-6"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Salt Lake City
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
