import React, { useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const CalculateSexPercentage = (props) => {
  const pdfRef = useRef();
  const { data } = props;
  // State to control the selected year
  const [selectedYear, setSelectedYear] = useState("2023");
  // Ensure data is valid and not empty
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  // Extract years dynamically
  const years = Object.keys(data[0]).filter(
    (key) => key !== "sex_label" && key !== "percentage"
  );

  // Prepare data for the selected year
  const chartData = {
    labels: data.map((item) => item.sex_label), // Labels for categories
    datasets: [
      {
        label: `Employment Totals for ${selectedYear}`,
        data: data.map((item) => parseFloat(item[selectedYear] || 0)), // Data for the selected year
        backgroundColor: [
          "rgba(54, 162, 235, 0.7)", // Color for "All Sex"
          "rgba(255, 99, 132, 0.7)", // Color for "Female"
          "rgba(75, 192, 192, 0.7)", // Color for "Male"
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Employment Totals by Sex for ${selectedYear}`,
      },
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // Logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      // Date and URL
      const currentDate = new Date().toLocaleDateString();
      pdf.setFontSize(10);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("calculate-sex-percentage.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <>
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Sex Yearly Percentage Table</h2>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sex Label</th>
                  <th>2018</th>
                  <th>2019</th>
                  <th>2020</th>
                  <th>2021</th>
                  <th>2022</th>
                  <th>2023</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.sex_label}</td>
                    <td>{row["2018"]}</td>
                    <td>{row["2019"]}</td>
                    <td>{row["2020"]}</td>
                    <td>{row["2021"]}</td>
                    <td>{row["2022"]}</td>
                    <td>{row["2023"]}</td>
                    <td>{row.percentage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <label>Select Year: </label>
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex", // Enables flexbox for centering
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            height: "auto", // Full height of the screen
            width: "100%", // Full width of the screen
          }}
        >
          <div
            style={{
              width: "350px", // Fixed width for the chart (adjust as needed)
              height: "350px", // Fixed height for the chart (adjust as needed)
            }}
          >
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} onClick={downloadPDF} />
          </span>
        </button>
      </div>
    </>
  );
};

export default CalculateSexPercentage;
