import React from 'react'

export default function School36() {

    const schoolData = 
    [
        {"schoolName": "Anna Smith School", "numberOfStudents": 206, "grades": "PK-6"},
        {"schoolName": "Blue Peak High", "numberOfStudents": 103, "grades": "KG-12"},
        {"schoolName": "Clarke N. Johnsen Jr High", "numberOfStudents": 892, "grades": "7-8"},
        {"schoolName": "Copper Canyon School", "numberOfStudents": 468, "grades": "PK-6"},
        {"schoolName": "Digital Education Center", "numberOfStudents": 8561, "grades": "KG-12"},
        {"schoolName": "Dugway", "numberOfStudents": 125, "grades": "KG-12"},
        {"schoolName": "Grantsville High", "numberOfStudents": 1011, "grades": "9-12"},
        {"schoolName": "Grantsville Jr High", "numberOfStudents": 515, "grades": "7-8"},
        {"schoolName": "Grantsville School", "numberOfStudents": 725, "grades": "PK-6"},
        {"schoolName": "Ibapah School", "numberOfStudents": 20, "grades": "KG-6"},
        {"schoolName": "Middle Canyon School", "numberOfStudents": 617, "grades": "KG-6"},
        {"schoolName": "Northlake School", "numberOfStudents": 619, "grades": "PK-6"},
        {"schoolName": "Old Mill School", "numberOfStudents": 650, "grades": "PK-6"},
        {"schoolName": "Overlake School", "numberOfStudents": 596, "grades": "KG-6"},
        {"schoolName": "Rose Springs School", "numberOfStudents": 527, "grades": "PK-6"},
        {"schoolName": "Settlement Canyon School", "numberOfStudents": 604, "grades": "KG-6"},
        {"schoolName": "Stansbury High", "numberOfStudents": 2010, "grades": "9-12"},
        {"schoolName": "Stansbury Park School", "numberOfStudents": 689, "grades": "PK-6"},
        {"schoolName": "Sterling School", "numberOfStudents": 829, "grades": "PK-6"},
        {"schoolName": "Tooele Community Learning Center", "numberOfStudents": 302, "grades": "9-12"},
        {"schoolName": "Tooele High", "numberOfStudents": 1768, "grades": "9-12"},
        {"schoolName": "Tooele Jr High", "numberOfStudents": 733, "grades": "7-8"},
        {"schoolName": "Twenty Wells Elementary", "numberOfStudents": 576, "grades": "PK-6"},
        {"schoolName": "Vernon School", "numberOfStudents": 34, "grades": "KG-6"},
        {"schoolName": "Wendover High", "numberOfStudents": 189, "grades": "7-12"},
        {"schoolName": "West School", "numberOfStudents": 256, "grades": "PK-6"},
        {"schoolName": "Willow School", "numberOfStudents": 430, "grades": "KG-6"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Tooele
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
