import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import MainContent from "./CacheCounty-components/MainContent4";
import Latest from "./CacheCounty-components/Latest4";
import AppTheme from "../../shared-theme/AppTheme";

export default function BlogCacheCounty(props) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <div className="bg-black">
        <Container
          maxWidth="lg"
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: { xs: 10, sm: 16, md: 18, lg: 12 },
            gap: 4,
            pt: 4,
          }}
        >
          <MainContent />
          <Latest />
        </Container>
      </div>
    </AppTheme>
  );
}
