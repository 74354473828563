import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../styles/Geothermal.css";
//import geothermalData from "./GeoThermalJson.json";
import stationData from "./Station-Charging.json";
const electric = "/img/electric.png";
const bd = "/img/bd.png";
const lpg = "/img/lpg.png";
const cng = "/img/cng.png"
const e85 = "/img/e85.png";



// Utility to detect mobile view
const isMobileView = () => window.innerWidth <= 778; // Adjust breakpoint as needed

// Custom marker icon with dynamic popup anchor
// const createIcon = () =>
//   new L.Icon({
//     iconUrl: electric,
//     iconSize: [35, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: isMobileView() ? [0, 10] : [1, -34], // Below marker for mobile, above for others
//   });


// // Custom marker icon
// const icon = new L.Icon({
//   iconUrl: electric,
//   iconSize: [35, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
// });
const fuelIcons = {
    CNG: "/img/cng2.png",
    ELEC: "/img/ele.png",
    BD: "/img/bd1.png",
    LPG: "/img/lpg1.png",
    E85: "/img/e85.png",
  };
  
//   const createIcon = (fuelType) =>
//     new L.Icon({
//       iconUrl: fuelIcons[fuelType?.toUpperCase()] || "/img/default.png", // Default icon if fuel type is unknown
      
//       iconSize: [35, 41],
//       iconAnchor: [12, 41],
//       popupAnchor: isMobileView() ? [0, 10] : [1, -34], 
//     });

const createIcon = (fuelType) => {
    const iconUrl = fuelIcons[fuelType?.toUpperCase()] || "/img/default.png";
    console.log(`Icon for ${fuelType}:`, iconUrl); // Debugging
    return new L.Icon({
      iconUrl,
      iconSize: [35, 41],
      iconAnchor: [12, 41],
      popupAnchor: isMobileView() ? [0, 10] : [1, -34],
    });
  };
  

// Helper component to pan the map
const MapFocus = ({ location }) => {
  const map = useMap();
  if (location && window.innerWidth <= 778) {
    map.flyTo(location, 5); // Fly to the selected location with zoom level 10
  }
  if (location && window.innerWidth > 778) {
    map.flyTo(location, 10); // Fly to the selected location with zoom level 10
  }
  return null;
};

export default function StationCharging1(){
  const [selectedLocation, setSelectedLocation] = useState(null);
  const markerRefs = useRef([]); // Create refs for all markers
  const [searchTerm, setSearchTerm] = useState("");

  const handleLocationClick = (location, index) => {
    setSelectedLocation(location); // Update selected location

    // Open the corresponding popup
    if (markerRefs.current[index]) {
      markerRefs.current[index].openPopup();
    }
  };

  const filteredData = stationData.filter(
    (spot) =>
      spot.ZIP.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot["Station Name"].toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot.City.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot["Fuel Type Code"].toLowerCase().includes(searchTerm.toLowerCase())
      
  );
   return (
    <div className="geothermal-map">
      {/* Left-side list */}
      <div className="list-container">
        <h2>Charging Stations  <br></br><span className="text-sm font-base">(*Not all stations are marked, only those with fully verified datas are displayed.)</span></h2>
        <div className="flex justify-center items-center my-4">
        <input
    type="text"
    placeholder="Search by Fuel Type , Zip , Station Name or City"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    className="search-input p-2 border border-gray-300 rounded-md"
  />
  </div>
        <h3>
        <div className="list-item-content">
        <span>Fuel Type</span>
        <span>Station Name</span>
        
        <span>City</span>
        <span>Zip Code</span>
        </div>
        </h3>
        <ul>
          {filteredData
            .filter((spot)=>spot["Intersection Directions"])
            .map((spot, index) => (
              <li
                key={index}
                className="list-item"
                onClick={() =>
                  handleLocationClick(
                    { Latitude: spot.Latitude, Longitude: spot.Longitude, details: spot },
                    index
                  )
                }
              >
                <div className="list-item-content">
                <span>{spot["Fuel Type Code"]}</span>
                <span>{spot["Station Name"]}</span>
                  <span>{spot.City || `Hotspot ${index + 1}`}</span>
                  <span>{spot.ZIP}</span>
                 
                </div>
              </li>
            ))}
        </ul>
      </div>

      {/* Map */}
      <div className="map-container ">
        <MapContainer
          center={[39.32098, -111.093731]}
          zoom={7}
          minZoom={6}
          maxBounds={[
            [30, -105], // Southwest corner
            [50, -120], // Northeast corner
          ]}
          maxBoundsViscosity={1.0}
          className="map"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {selectedLocation && (
            <MapFocus location={[selectedLocation.Latitude, selectedLocation.Longitude]} />
          )}
          {filteredData
            .filter((spot)=>spot["Intersection Directions"])
            .map((spot, index) => (
              <Marker
                key={index}
                position={[spot.Latitude, spot.Longitude]}
                // icon={icon}
                // ref={(el) => (markerRefs.current[index] = el)} // Save reference to marker
                //icon={createIcon()} // Use dynamic icon here
                icon={createIcon(spot["Fuel Type Code"])}
                ref={(el) => (markerRefs.current[index] = el)}
              >
                <Popup>
                  <b>
                    <u> Charging Stations</u>
                  </b>
                  <br />
                  Fuel Type: {spot["Fuel Type Code"]} <br />
                  Station Name: {spot["Station Name"]} <br />
                  Street Address: {spot["Street Address"]}
                  <br />
                  City: {spot.City}
                  <br />
                  State: {spot.State}
                  <br />
                  Zip Code: {spot.ZIP}
                  <br />
                  Station Phone: {spot["Station Phone"]}
                  <br />
                  Status Code: {spot["Status Code"]}
                  <br />
                  {/* Location: {spot.LOCATION} */}
                  <br />
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  );
}
