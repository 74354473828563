import React, { useRef } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

// Register required Chart.js components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getAgeLabel = (value) => {
  switch (value) {
    case "A00":
      return "All Ages (14-99)";
    case "A01":
      return "14-18";
    case "A02":
      return "19-21";
    case "A03":
      return "22-24";
    case "A04":
      return "25-34";
    case "A05":
      return "35-44";
    case "A06":
      return "45-54";
    case "A07":
      return "55-64";
    case "A08":
      return "65-99";
    default:
      return "Unknown";
  }
};

const AgeGroupGrowth = ({ data }) => {
  const pdfRef = useRef();
  // Extract age group labels
  const ageGroups = data.map((item) => getAgeLabel(item.age_group_label));

  // Prepare data for the radar chart
  const chartData = {
    labels: ageGroups, // Labels for each age group
    datasets: [
      {
        label: "2018",
        data: data.map((item) => parseFloat(item.total_2018 || 0)), // Totals for 2018
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
      },
      {
        label: "2019",
        data: data.map((item) => parseFloat(item.total_2019 || 0)), // Totals for 2019
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
      },
      {
        label: "2020",
        data: data.map((item) => parseFloat(item.total_2020 || 0)), // Totals for 2020
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      },
      {
        label: "2021",
        data: data.map((item) => parseFloat(item.total_2021 || 0)), // Totals for 2021
        backgroundColor: "rgba(255, 205, 86, 0.2)",
        borderColor: "rgba(255, 205, 86, 1)",
        borderWidth: 2,
      },
      {
        label: "2022",
        data: data.map((item) => parseFloat(item.total_2022 || 0)), // Totals for 2022
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 2,
      },
      {
        label: "2023",
        data: data.map((item) => parseFloat(item.total_2023 || 0)), // Totals for 2023
        backgroundColor: "rgba(201, 203, 207, 0.2)",
        borderColor: "rgba(201, 203, 207, 1)",
        borderWidth: 2,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Age Group Growth (2018-2023)",
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Population Count",
        },
      },
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // Logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      // Date and URL
      const currentDate = new Date().toLocaleDateString();
      pdf.setFontSize(10);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("age-group-growth.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <>
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Age Group Growth Table</h2>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Age Group</th>
                  <th>Total 2018</th>
                  <th>Total 2019</th>
                  <th>Total 2020</th>
                  <th>Total 2021</th>
                  <th>Total 2022</th>
                  <th>Total 2023</th>
                  <th>Growth Rate (%)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{getAgeLabel(row.age_group_label)}</td>
                    <td>{row.total_2018}</td>
                    <td>{row.total_2019}</td>
                    <td>{row.total_2020}</td>
                    <td>{row.total_2021}</td>
                    <td>{row.total_2022}</td>
                    <td>{row.total_2023}</td>
                    <td>{row.growth_rate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex", // Enables flexbox for centering
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            height: "auto", // Full height of the screen
            width: "100%", // Full width of the screen
          }}
        >
          <div
            // style={{
            //   width: "350px", // Fixed width for the chart (adjust as needed)
            //   height: "350px", // Fixed height for the chart (adjust as needed)
            // }}
            className="w-[40rem] sm:w-[40rem] sm:h-[40rem]"
          >
            <Radar data={chartData} options={options} />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} onClick={downloadPDF} />
          </span>
        </button>
      </div>
    </>
  );
};

export default AgeGroupGrowth;
