

import React from "react";

const cities = [
  { name: "Alpine", county: "Utah", type: "City", area: "7.96 sq mi (20.6 km2)", elevation: "4,951 feet (1,509 m)", year: "1850" },
  { name: "Alta", county: "Salt Lake", type: "Town", area: "4.56 sq mi (11.8 km2)", elevation: "8,560 feet (2,610 m)", year: "1866" },
  { name: "Altamont", county: "Duchesne", type: "Town", area: "0.21 sq mi (0.54 km2)", elevation: "6,388 feet (1,947 m)", year: "1953" },
  { name: "Alton", county: "Kane", type: "Town", area: "2.29 sq mi (5.9 km2)", elevation: "7,041 feet (2,146 m)", year: "1865" },
  { name: "Amalga", county: "Cache", type: "Town", area: "3.49 sq mi (9.0 km2)", elevation: "4,439 feet (1,353 m)", year: "1860" },
  { "name": "American Fork", "county": "Utah", "type": "City", "area": "11.20 sq mi (29.0 km²)", "elevation": "4,606 feet (1,404 m)", "year": "1850" },
  { "name": "Annabella", "county": "Sevier", "type": "Town", "area": "0.65 sq mi (1.7 km²)", "elevation": "5,292 feet (1,613 m)", "year": "1871" },
  { "name": "Antimony", "county": "Garfield", "type": "Town", "area": "9.82 sq mi (25.4 km²)", "elevation": "6,453 feet (1,967 m)", "year": "1873" },
  { "name": "Apple Valley", "county": "Washington", "type": "Town", "area": "39.74 sq mi (102.9 km²)", "elevation": "4,941 feet (1,506 m)", "year": "2004" },
  { "name": "Aurora", "county": "Sevier", "type": "City", "area": "0.92 sq mi (2.4 km²)", "elevation": "5,200 feet (1,600 m)", "year": "1875" },
  { "name": "Ballard", "county": "Uintah", "type": "Town", "area": "13.98 sq mi (36.2 km²)", "elevation": "5,049 feet (1,539 m)", "year": "1905" },
  { "name": "Bear River City", "county": "Box Elder", "type": "City", "area": "1.80 sq mi (4.7 km²)", "elevation": "4,258 feet (1,298 m)", "year": "1866" },
  { "name": "Beaver", "county": "Beaver", "type": "City", "area": "6.68 sq mi (17.3 km²)", "elevation": "5,902 feet (1,799 m)", "year": "1856" },
  { "name": "Bicknell", "county": "Wayne", "type": "Town", "area": "0.57 sq mi (1.5 km²)", "elevation": "7,123 feet (2,171 m)", "year": "1879" },
  { "name": "Big Water", "county": "Kane", "type": "Town", "area": "5.99 sq mi (15.5 km²)", "elevation": "4,108 feet (1,252 m)", "year": "1958" },
  { "name": "Blanding", "county": "San Juan", "type": "City", "area": "13.18 sq mi (34.1 km²)", "elevation": "6,106 feet (1,861 m)", "year": "1887" },
  { "name": "Bluff", "county": "San Juan", "type": "Town", "area": "36.47 sq mi (94.5 km²)", "elevation": "4,324 feet (1,318 m)", "year": "1880" },
  { "name": "Bluffdale", "county": "Salt Lake/Utah", "type": "City", "area": "11.14 sq mi (28.9 km²)", "elevation": "4,436 feet (1,352 m)", "year": "1886" },
  { "name": "Boulder", "county": "Garfield", "type": "Town", "area": "20.93 sq mi (54.2 km²)", "elevation": "6,703 feet (2,043 m)", "year": "1889" },
  { "name": "Bountiful", "county": "Davis", "type": "City", "area": "13.19 sq mi (34.2 km²)", "elevation": "4,797 feet (1,462 m)", "year": "1847" },
  { "name": "Brian Head", "county": "Iron", "type": "Town", "area": "3.65 sq mi (9.5 km²)", "elevation": "9,800 feet (3,000 m)", "year": "1975" },
  { "name": "Brigham City", "county": "Box Elder", "type": "City", "area": "24.58 sq mi (63.7 km²)", "elevation": "4,436 feet (1,352 m)", "year": "1850" },
  { "name": "Brighton", "county": "Salt Lake", "type": "Town", "area": "15.77 sq mi (40.8 km²)", "elevation": "8,707 feet (2,653 m)", "year": "1871" },
  { "name": "Bryce Canyon City", "county": "Garfield", "type": "Town", "area": "3.35 sq mi (8.7 km²)", "elevation": "7,664 feet (2,336 m)", "year": "1875" },
  { "name": "Cannonville", "county": "Garfield", "type": "Town", "area": "2.57 sq mi (6.7 km²)", "elevation": "5,886 feet (1,794 m)", "year": "1874" },
  { "name": "Castle Dale", "county": "Emery", "type": "City", "area": "2.23 sq mi (5.8 km²)", "elevation": "5,676 feet (1,730 m)", "year": "1879" },
  { "name": "Castle Valley", "county": "Grand", "type": "Town", "area": "9.28 sq mi (24.0 km²)", "elevation": "4,685 feet (1,428 m)", "year": "1974" },
  { "name": "Cedar City", "county": "Iron", "type": "City", "area": "35.86 sq mi (92.9 km²)", "elevation": "5,846 feet (1,782 m)", "year": "1851" },
  { "name": "Cedar Fort", "county": "Utah", "type": "Town", "area": "21.38 sq mi (55.4 km²)", "elevation": "5,085 feet (1,550 m)", "year": "1856" },
  { "name": "Cedar Hills", "county": "Utah", "type": "City", "area": "2.73 sq mi (7.1 km²)", "elevation": "4,957 feet (1,511 m)", "year": "1977" },
  { "name": "Centerfield", "county": "Sanpete", "type": "City", "area": "1.91 sq mi (4.9 km²)", "elevation": "5,098 feet (1,554 m)", "year": "1869" },
  { "name": "Centerville", "county": "Davis", "type": "City", "area": "5.96 sq mi (15.4 km²)", "elevation": "4,377 feet (1,334 m)", "year": "1848" },
  { "name": "Central Valley", "county": "Sevier", "type": "Town", "area": "2.11 sq mi (5.5 km²)", "elevation": "5,305 feet (1,617 m)", "year": "1873" },
  { "name": "Charleston", "county": "Wasatch", "type": "Town", "area": "2.86 sq mi (7.4 km²)", "elevation": "5,440 feet (1,660 m)", "year": "1852" },
  { "name": "Circleville", "county": "Piute", "type": "Town", "area": "11.04 sq mi (28.6 km²)", "elevation": "6,066 feet (1,849 m)", "year": "1864" },
  { "name": "Clarkston", "county": "Cache", "type": "Town", "area": "0.93 sq mi (2.4 km²)", "elevation": "4,879 feet (1,487 m)", "year": "1864" },
  { "name": "Clawson", "county": "Emery", "type": "Town", "area": "1.01 sq mi (2.6 km²)", "elevation": "5,942 feet (1,811 m)", "year": "1897" },
  { "name": "Clearfield", "county": "Davis", "type": "City", "area": "7.71 sq mi (20.0 km²)", "elevation": "4,465 feet (1,361 m)", "year": "1877" },
  { "name": "Cleveland", "county": "Emery", "type": "Town", "area": "0.87 sq mi (2.3 km²)", "elevation": "5,722 feet (1,744 m)", "year": "1885" },
  { "name": "Clinton", "county": "Davis", "type": "City", "area": "5.93 sq mi (15.4 km²)", "elevation": "4,393 feet (1,339 m)", "year": "1936" },
  { "name": "Coalville", "county": "Summit", "type": "City", "area": "6.23 sq mi (16.1 km²)", "elevation": "5,577 feet (1,700 m)", "year": "1858" },
  { "name": "Copperton", "county": "Salt Lake", "type": "Metro Township", "area": "0.31 sq mi (0.80 km²)", "elevation": "5,643 feet (1,719 m)", "year": "1926" },
  { "name": "Corinne", "county": "Box Elder", "type": "Town", "area": "3.78 sq mi (9.8 km²)", "elevation": "4,226 feet (1,288 m)", "year": "1869" },
  { "name": "Cornish", "county": "Cache", "type": "Town", "area": "5.39 sq mi (14.0 km²)", "elevation": "4,485 feet (1,367 m)", "year": "1937" },
  { "name": "Cottonwood Heights", "county": "Salt Lake", "type": "City", "area": "9.23 sq mi (23.9 km²)", "elevation": "4,823 feet (1,470 m)", "year": "1848" },
  { "name": "Daniel", "county": "Wasatch", "type": "Town", "area": "3.29 sq mi (8.5 km²)", "elevation": "5,715 feet (1,742 m)", "year": "1874" },
  { "name": "Delta", "county": "Millard", "type": "City", "area": "8.60 sq mi (22.3 km²)", "elevation": "4,639 feet (1,414 m)", "year": "1906" },
  { "name": "Deweyville", "county": "Box Elder", "type": "Town", "area": "6.38 sq mi (16.5 km²)", "elevation": "4,437 feet (1,352 m)", "year": "1864" },
  { "name": "Draper", "county": "Salt Lake/Utah", "type": "City", "area": "29.95 sq mi (77.6 km²)", "elevation": "4,505 feet (1,373 m)", "year": "1849" },
  { "name": "Duchesne", "county": "Duchesne", "type": "City", "area": "2.80 sq mi (7.3 km²)", "elevation": "5,518 feet (1,682 m)", "year": "1904" },
  { "name": "Dutch John", "county": "Daggett", "type": "Town", "area": "5.90 sq mi (15.3 km²)", "elevation": "6,430 feet (1,960 m)", "year": "1957" },
  { "name": "Eagle Mountain", "county": "Utah", "type": "City", "area": "50.43 sq mi (130.6 km²)", "elevation": "4,882 feet (1,488 m)", "year": "1996" },
  { "name": "East Carbon", "county": "Carbon", "type": "City", "area": "11.29 sq mi (29.2 km²)", "elevation": "4,987 feet (1,520 m)", "year": "1922" },
  { "name": "Elk Ridge", "county": "Utah", "type": "City", "area": "2.82 sq mi (7.3 km²)", "elevation": "5,354 feet (1,632 m)", "year": "1976" },
  { "name": "Elmo", "county": "Emery", "type": "Town", "area": "0.71 sq mi (1.8 km²)", "elevation": "5,692 feet (1,735 m)", "year": "1908" },
  { "name": "Elsinore", "county": "Sevier", "type": "Town", "area": "1.34 sq mi (3.5 km²)", "elevation": "5,351 feet (1,631 m)", "year": "1874" },
  { "name": "Elwood", "county": "Box Elder", "type": "Town", "area": "8.06 sq mi (20.9 km²)", "elevation": "4,298 feet (1,310 m)", "year": "1879" },
  { "name": "Emery", "county": "Emery", "type": "Town", "area": "1.23 sq mi (3.2 km²)", "elevation": "6,253 feet (1,906 m)", "year": "1881" },
  { "name": "Emigration Canyon", "county": "Salt Lake", "type": "Metro Township", "area": "18.22 sq mi (47.2 km²)", "elevation": "", "year": "1846" },
  { "name": "Enoch", "county": "Iron", "type": "City", "area": "7.84 sq mi (20.3 km²)", "elevation": "5,545 feet (1,690 m)", "year": "1851" },
  { "name": "Enterprise", "county": "Washington", "type": "City", "area": "8.74 sq mi (22.6 km²)", "elevation": "5,318 feet (1,621 m)", "year": "1902" },
  { "name": "Ephraim", "county": "Sanpete", "type": "City", "area": "4.45 sq mi (11.5 km²)", "elevation": "5,541 feet (1,689 m)", "year": "1854" },
  { "name": "Erda", "county": "Tooele", "type": "City", "area": "22.5 sq mi", "elevation": "4,344 feet (1,324 m)", "year": "1852" },
  { "name": "Escalante", "county": "Garfield", "type": "City", "area": "3.30 sq mi (8.5 km²)", "elevation": "5,820 feet (1,770 m)", "year": "1876" },
  { "name": "Eureka", "county": "Juab", "type": "City", "area": "1.48 sq mi (3.8 km²)", "elevation": "6,430 feet (1,960 m)", "year": "1869" },
  { "name": "Fairfield", "county": "Utah", "type": "Town", "area": "26.09 sq mi (67.6 km²)", "elevation": "4,877 feet (1,487 m)", "year": "1855" },
  { "name": "Fairview", "county": "Sanpete", "type": "City", "area": "1.26 sq mi (3.3 km²)", "elevation": "6,948 feet (2,118 m)", "year": "1859" },
  { "name": "Farmington", "county": "Davis", "type": "City", "area": "9.95 sq mi (25.8 km²)", "elevation": "4,304 feet (1,312 m)", "year": "1847" },
  { "name": "Farr West", "county": "Weber", "type": "City", "area": "5.92 sq mi (15.3 km²)", "elevation": "4,265 feet (1,300 m)", "year": "1858" },
  { "name": "Fayette", "county": "Sanpete", "type": "Town", "area": "0.40 sq mi (1.0 km²)", "elevation": "5,052 feet (1,540 m)", "year": "1861" },
  { "name": "Ferron", "county": "Emery", "type": "City", "area": "2.33 sq mi (6.0 km²)", "elevation": "5,971 feet (1,820 m)", "year": "1877" },
  { "name": "Fielding", "county": "Box Elder", "type": "Town", "area": "0.51 sq mi (1.3 km²)", "elevation": "4,373 feet (1,333 m)", "year": "1892" },
  { "name": "Fillmore", "county": "Millard", "type": "City", "area": "6.97 sq mi (18.1 km²)", "elevation": "5,135 feet (1,565 m)", "year": "1851" },
  { "name": "Fountain Green", "county": "Sanpete", "type": "City", "area": "1.25 sq mi (3.2 km²)", "elevation": "5,899 feet (1,798 m)", "year": "1850" },
  { "name": "Francis", "county": "Summit", "type": "Town", "area": "2.98 sq mi (7.7 km²)", "elevation": "6,562 feet (2,000 m)", "year": "1869" },
  { "name": "Fruit Heights", "county": "Davis", "type": "City", "area": "2.29 sq mi (5.9 km²)", "elevation": "4,698 feet (1,432 m)", "year": "1850" },
  { "name": "Garden City", "county": "Rich", "type": "Town", "area": "8.78 sq mi (22.7 km²)", "elevation": "5,968 feet (1,819 m)", "year": "1877" },
  { "name": "Garland", "county": "Box Elder", "type": "City", "area": "1.81 sq mi (4.7 km²)", "elevation": "4,340 feet (1,320 m)", "year": "1890" },
  { "name": "Genola", "county": "Utah", "type": "Town", "area": "13.76 sq mi (35.6 km²)", "elevation": "4,600 feet (1,400 m)", "year": "1935" },
  { "name": "Glendale", "county": "Kane", "type": "Town", "area": "7.00 sq mi (18.1 km²)", "elevation": "5,778 feet (1,761 m)", "year": "1862" },
  { "name": "Glenwood", "county": "Sevier", "type": "Town", "area": "0.52 sq mi (1.3 km²)", "elevation": "5,272 feet (1,607 m)", "year": "1863" },
  { "name": "Goshen", "county": "Utah", "type": "Town", "area": "0.83 sq mi (2.1 km²)", "elevation": "4,551 feet (1,387 m)", "year": "1857" },
  { "name": "Grantsville", "county": "Tooele", "type": "City", "area": "37.47 sq mi (97.0 km²)", "elevation": "4,304 feet (1,312 m)", "year": "1850" },
  { "name": "Green River", "county": "Emery", "type": "City", "area": "27.14 sq mi (70.3 km²)", "elevation": "4,078 feet (1,243 m)", "year": "1876" },
  { "name": "Gunnison", "county": "Sanpete", "type": "City", "area": "4.79 sq mi (12.4 km²)", "elevation": "5,138 feet (1,566 m)", "year": "1859" },
  { "name": "Hanksville", "county": "Wayne", "type": "Town", "area": "1.69 sq mi (4.4 km²)", "elevation": "4,291 feet (1,308 m)", "year": "1882" },
  { "name": "Harrisville", "county": "Weber", "type": "City", "area": "2.98 sq mi (7.7 km²)", "elevation": "4,291 feet (1,308 m)", "year": "1850" },
  { "name": "Hatch", "county": "Garfield", "type": "Town", "area": "1.82 sq mi (4.7 km²)", "elevation": "6,919 feet (2,109 m)", "year": "1872" },
  { "name": "Heber City", "county": "Wasatch", "type": "City", "area": "8.99 sq mi (23.3 km²)", "elevation": "5,604 feet (1,708 m)", "year": "1858" },
  { "name": "Helper", "county": "Carbon", "type": "City", "area": "1.81 sq mi (4.7 km²)", "elevation": "5,817 feet (1,773 m)", "year": "1883" },
  { "name": "Henefer", "county": "Summit", "type": "Town", "area": "2.48 sq mi (6.4 km²)", "elevation": "5,335 feet (1,626 m)", "year": "1859" },
  { "name": "Henrieville", "county": "Garfield", "type": "Town", "area": "1.35 sq mi (3.5 km²)", "elevation": "5,997 feet (1,828 m)", "year": "1878" },
  { "name": "Herriman", "county": "Salt Lake", "type": "City", "area": "21.63 sq mi (56.0 km²)", "elevation": "5,000 feet (1,500 m)", "year": "1849" },
  { "name": "Hideout", "county": "Wasatch", "type": "Town", "area": "3.48 sq mi (9.0 km²)", "elevation": "6,588 feet (2,008 m)", "year": "2005" },
  { "name": "Highland", "county": "Utah", "type": "City", "area": "8.70 sq mi (22.5 km²)", "elevation": "4,977 feet (1,517 m)", "year": "1875" },
  { "name": "Hildale", "county": "Washington", "type": "City", "area": "5.60 sq mi (14.5 km²)", "elevation": "5,409 feet (1,649 m)", "year": "1962" },
  { "name": "Hinckley", "county": "Millard", "type": "Town", "area": "4.99 sq mi (12.9 km²)", "elevation": "4,603 feet (1,403 m)", "year": "1891" },
  { "name": "Holden", "county": "Millard", "type": "Town", "area": "0.54 sq mi (1.4 km²)", "elevation": "5,102 feet (1,555 m)", "year": "1855" },
  { "name": "Holladay", "county": "Salt Lake", "type": "City", "area": "8.50 sq mi (22.0 km²)", "elevation": "4,464 feet (1,361 m)", "year": "1848" },
  { "name": "Honeyville", "county": "Box Elder", "type": "City", "area": "11.74 sq mi (30.4 km²)", "elevation": "4,298 feet (1,310 m)", "year": "1861" },
  { "name": "Hooper", "county": "Weber", "type": "City", "area": "26.10 sq mi (67.6 km²)", "elevation": "4,242 feet (1,293 m)", "year": "1854" },
  { "name": "Howell", "county": "Box Elder", "type": "Town", "area": "35.28 sq mi (91.4 km²)", "elevation": "4,560 feet (1,390 m)", "year": "1910" },
  { "name": "Huntington", "county": "Emery", "type": "City", "area": "2.26 sq mi (5.9 km²)", "elevation": "5,787 feet (1,764 m)", "year": "1877" },
  { "name": "Huntsville", "county": "Weber", "type": "Town", "area": "0.83 sq mi (2.1 km²)", "elevation": "4,928 feet (1,502 m)", "year": "1860" },
  { "name": "Hurricane", "county": "Washington", "type": "City", "area": "52.76 sq mi (136.6 km²)", "elevation": "3,248 feet (990 m)", "year": "1906" },
  { "name": "Hyde Park", "county": "Cache", "type": "City", "area": "4.41 sq mi (11.4 km²)", "elevation": "4,537 feet (1,383 m)", "year": "1860" },
  { "name": "Hyrum", "county": "Cache", "type": "City", "area": "6.19 sq mi (16.0 km²)", "elevation": "4,698 feet (1,432 m)", "year": "1860" },
  { "name": "Independence", "county": "Wasatch", "type": "Town", "area": "30.55 sq mi (79.1 km²)", "elevation": "7,073 feet (2,156 m)", "year": "2008" },
  { "name": "Interlaken", "county": "Wasatch", "type": "Town", "area": "0.22 sq mi (0.57 km²)", "elevation": "5,919 feet (1,804 m)", "year": "2015" },
  { "name": "Ivins", "county": "Washington", "type": "City", "area": "9.44 sq mi (24.4 km²)", "elevation": "3,081 feet (939 m)", "year": "1922" },
  { "name": "Joseph", "county": "Sevier", "type": "Town", "area": "0.87 sq mi (2.3 km²)", "elevation": "5,436 feet (1,657 m)", "year": "1871" },
  { "name": "Junction", "county": "Piute", "type": "Town", "area": "13.19 sq mi (34.2 km²)", "elevation": "6,007 feet (1,831 m)", "year": "1880" },
  { "name": "Kamas", "county": "Summit", "type": "City", "area": "3.69 sq mi (9.6 km²)", "elevation": "6,486 feet (1,977 m)", "year": "1857" },
  { "name": "Kanab", "county": "Kane", "type": "City", "area": "14.44 sq mi (37.4 km²)", "elevation": "4,970 feet (1,510 m)", "year": "1864" },
  { "name": "Kanarraville", "county": "Iron", "type": "Town", "area": "0.45 sq mi (1.2 km²)", "elevation": "5,541 feet (1,689 m)", "year": "1861" },
  { "name": "Kanosh", "county": "Millard", "type": "Town", "area": "0.77 sq mi (2.0 km²)", "elevation": "5,020 feet (1,530 m)", "year": "1854" },
  { "name": "Kaysville", "county": "Davis", "type": "City", "area": "10.50 sq mi (27.2 km²)", "elevation": "4,357 feet (1,328 m)", "year": "1849" },
  { "name": "Kearns", "county": "Salt Lake", "type": "Metro Township", "area": "4.63 sq mi (12.0 km²)", "elevation": "4,528 feet (1,380 m)", "year": "1942" },
  { "name": "Kingston", "county": "Piute", "type": "Town", "area": "5.40 sq mi (14.0 km²)", "elevation": "6,017 feet (1,834 m)", "year": "1876" },
  { "name": "Koosharem", "county": "Sevier", "type": "Town", "area": "0.83 sq mi (2.1 km²)", "elevation": "6,919 feet (2,109 m)", "year": "1877" },
  { "name": "La Verkin", "county": "Washington", "type": "City", "area": "12.78 sq mi (33.1 km²)", "elevation": "3,192 feet (973 m)", "year": "1897" },
  { "name": "Laketown", "county": "Rich", "type": "Town", "area": "2.52 sq mi (6.5 km²)", "elevation": "5,974 feet (1,821 m)", "year": "1864" },
  { "name": "Lake Point", "county": "Tooele", "type": "City", "area": "4.6 sq mi (12 km²)", "elevation": "4,249 feet (1,295 m)", "year": "1854" },
  { "name": "Layton", "county": "Davis", "type": "City", "area": "22.50 sq mi (58.3 km²)", "elevation": "4,350 feet (1,330 m)", "year": "1920" },
  { "name": "Leamington", "county": "Millard", "type": "Town", "area": "1.53 sq mi (4.0 km²)", "elevation": "4,731 feet (1,442 m)", "year": "1871" },
  { "name": "Leeds", "county": "Washington", "type": "Town", "area": "6.30 sq mi (16.3 km²)", "elevation": "3,481 feet (1,061 m)", "year": "1867" },
  { "name": "Lehi", "county": "Utah", "type": "City", "area": "28.11 sq mi (72.8 km²)", "elevation": "4,564 feet (1,391 m)", "year": "1850" },
  { "name": "Levan", "county": "Juab", "type": "Town", "area": "0.81 sq mi (2.1 km²)", "elevation": "5,315 feet (1,620 m)", "year": "1868" },
  { "name": "Lewiston", "county": "Cache", "type": "City", "area": "25.84 sq mi (66.9 km²)", "elevation": "4,508 feet (1,374 m)", "year": "1870" },
  { "name": "Lindon", "county": "Utah", "type": "City", "area": "8.35 sq mi (21.6 km²)", "elevation": "4,642 feet (1,415 m)", "year": "1850" },
  { "name": "Loa", "county": "Wayne", "type": "Town", "area": "0.95 sq mi (2.5 km²)", "elevation": "7,064 feet (2,153 m)", "year": "1878" },
  { "name": "Logan", "county": "Cache", "type": "City", "area": "17.85 sq mi (46.2 km²)", "elevation": "4,534 feet (1,382 m)", "year": "1859" },
  { "name": "Lyman", "county": "Wayne", "type": "Town", "area": "1.81 sq mi (4.7 km²)", "elevation": "7,182 feet (2,189 m)", "year": "1893" },
  { "name": "Lynndyl", "county": "Millard", "type": "Town", "area": "3.12 sq mi (8.1 km²)", "elevation": "4,787 feet (1,459 m)", "year": "1907" },
  { "name": "Magna", "county": "Salt Lake", "type": "Metro Township", "area": "15.11 sq mi (39.1 km²)", "elevation": "4,278 feet (1,304 m)", "year": "1868" },
  { "name": "Manila", "county": "Daggett", "type": "Town", "area": "1.04 sq mi (2.7 km²)", "elevation": "6,348 feet (1,935 m)", "year": "1868" },
  { "name": "Manti", "county": "Sanpete", "type": "City", "area": "3.16 sq mi (8.2 km²)", "elevation": "5,610 feet (1,710 m)", "year": "1849" },
  { "name": "Mantua", "county": "Box Elder", "type": "Town", "area": "4.91 sq mi (12.7 km²)", "elevation": "5,200 feet (1,600 m)", "year": "1863" },
  { "name": "Mapleton", "county": "Utah", "type": "City", "area": "13.35 sq mi (34.6 km²)", "elevation": "4,731 feet (1,442 m)", "year": "1856" },
  { "name": "Marriott-Slaterville", "county": "Weber", "type": "City", "area": "7.21 sq mi (18.7 km²)", "elevation": "4,252 feet (1,296 m)", "year": "1849" },
  { "name": "Marysvale", "county": "Piute", "type": "Town", "area": "17.98 sq mi (46.6 km²)", "elevation": "5,863 feet (1,787 m)", "year": "1863" },
  { "name": "Mayfield", "county": "Sanpete", "type": "Town", "area": "0.90 sq mi (2.3 km²)", "elevation": "5,538 feet (1,688 m)", "year": "1871" },
  { "name": "Meadow", "county": "Millard", "type": "Town", "area": "0.51 sq mi (1.3 km²)", "elevation": "4,839 feet (1,475 m)", "year": "1857" },
  { "name": "Mendon", "county": "Cache", "type": "City", "area": "1.43 sq mi (3.7 km²)", "elevation": "4,495 feet (1,370 m)", "year": "1859" },
  { "name": "Midvale", "county": "Salt Lake", "type": "City", "area": "5.91 sq mi (15.3 km²)", "elevation": "4,383 feet (1,336 m)", "year": "1909" },
  { "name": "Midway", "county": "Wasatch", "type": "City", "area": "5.55 sq mi (14.4 km²)", "elevation": "5,584 feet (1,702 m)", "year": "1859" },
  { "name": "Milford", "county": "Beaver", "type": "City", "area": "2.14 sq mi (5.5 km²)", "elevation": "4,967 feet (1,514 m)", "year": "1873" },
  { "name": "Millcreek", "county": "Salt Lake", "type": "City", "area": "12.77 sq mi (33.1 km²)", "elevation": "4,285 feet (1,306 m)", "year": "1848" },
  { "name": "Millville", "county": "Cache", "type": "City", "area": "2.51 sq mi (6.5 km²)", "elevation": "4,616 feet (1,407 m)", "year": "1860" },
  { "name": "Minersville", "county": "Beaver", "type": "Town", "area": "2.18 sq mi (5.6 km²)", "elevation": "5,282 feet (1,610 m)", "year": "1859" },
  { "name": "Moab", "county": "Grand", "type": "City", "area": "4.80 sq mi (12.4 km²)", "elevation": "4,026 feet (1,227 m)", "year": "1855" },
  { "name": "Mona", "county": "Juab", "type": "City", "area": "2.65 sq mi (6.9 km²)", "elevation": "4,970 feet (1,510 m)", "year": "1852" },
  { "name": "Monroe", "county": "Sevier", "type": "City", "area": "3.57 sq mi (9.2 km²)", "elevation": "5,394 feet (1,644 m)", "year": "1863" },
  { "name": "Monticello", "county": "San Juan", "type": "City", "area": "3.66 sq mi (9.5 km²)", "elevation": "7,070 feet (2,150 m)", "year": "1879" },
  { "name": "Morgan", "county": "Morgan", "type": "City", "area": "2.97 sq mi (7.7 km²)", "elevation": "5,069 feet (1,545 m)", "year": "1860" },
  { "name": "Moroni", "county": "Sanpete", "type": "City", "area": "1.07 sq mi (2.8 km²)", "elevation": "5,531 feet (1,686 m)", "year": "1859" },
  { "name": "Mount Pleasant", "county": "Sanpete", "type": "City", "area": "2.93 sq mi (7.6 km²)", "elevation": "5,925 feet (1,806 m)", "year": "1852" },
  { "name": "Murray", "county": "Salt Lake", "type": "City", "area": "12.32 sq mi (31.9 km²)", "elevation": "4,301 feet (1,311 m)", "year": "1848" },
  { "name": "Myton", "county": "Duchesne", "type": "City", "area": "1.01 sq mi (2.6 km²)", "elevation": "5,085 feet (1,550 m)", "year": "1905" },
  { "name": "Naples", "county": "Uintah", "type": "City", "area": "6.60 sq mi (17.1 km²)", "elevation": "5,230 feet (1,590 m)", "year": "1878" },
  { "name": "Nephi", "county": "Juab", "type": "City", "area": "4.75 sq mi (12.3 km²)", "elevation": "5,128 feet (1,563 m)", "year": "1851" },
  { "name": "New Harmony", "county": "Washington", "type": "Town", "area": "0.61 sq mi (1.6 km²)", "elevation": "5,305 feet (1,617 m)", "year": "1862" },
  { "name": "Newton", "county": "Cache", "type": "Town", "area": "0.86 sq mi (2.2 km²)", "elevation": "4,534 feet (1,382 m)", "year": "1869" },
  { "name": "Nibley", "county": "Cache", "type": "City", "area": "4.39 sq mi (11.4 km²)", "elevation": "4,554 feet (1,388 m)", "year": "1855" },
  { "name": "North Logan", "county": "Cache", "type": "City", "area": "7.13 sq mi (18.5 km²)", "elevation": "4,692 feet (1,430 m)", "year": "1884" },
  { "name": "North Ogden", "county": "Weber", "type": "City", "area": "7.51 sq mi (19.5 km²)", "elevation": "4,501 feet (1,372 m)", "year": "1850" },
  { "name": "North Salt Lake", "county": "Davis", "type": "City", "area": "8.47 sq mi (21.9 km²)", "elevation": "4,334 feet (1,321 m)", "year": "1946" },
  { "name": "Oak City", "county": "Millard", "type": "Town", "area": "0.94 sq mi (2.4 km²)", "elevation": "5,112 feet (1,558 m)", "year": "1868" },
  { "name": "Oakley", "county": "Summit", "type": "City", "area": "7.08 sq mi (18.3 km²)", "elevation": "6,434 feet (1,961 m)", "year": "1868" },
  { "name": "Ogden", "county": "Weber", "type": "City", "area": "27.55 sq mi (71.4 km²)", "elevation": "4,300 feet (1,300 m)", "year": "1847" },
  { "name": "Orangeville", "county": "Emery", "type": "City", "area": "1.43 sq mi (3.7 km²)", "elevation": "5,778 feet (1,761 m)", "year": "1878" },
  { "name": "Orderville", "county": "Kane", "type": "Town", "area": "8.72 sq mi (22.6 km²)", "elevation": "5,449 feet (1,661 m)", "year": "1875" },
  { "name": "Orem", "county": "Utah", "type": "City", "area": "18.61 sq mi (48.2 km²)", "elevation": "4,774 feet (1,455 m)", "year": "1850" },
  { "name": "Panguitch", "county": "Garfield", "type": "City", "area": "3.08 sq mi (8.0 km²)", "elevation": "6,624 feet (2,019 m)", "year": "1866" },
  { "name": "Paradise", "county": "Cache", "type": "Town", "area": "1.45 sq mi (3.8 km²)", "elevation": "4,902 feet (1,494 m)", "year": "1860" },
  { "name": "Paragonah", "county": "Iron", "type": "Town", "area": "0.67 sq mi (1.7 km²)", "elevation": "5,879 feet (1,792 m)", "year": "1851" },
  { "name": "Park City", "county": "Summit/Wasatch", "type": "City", "area": "20.42 sq mi (52.9 km²)", "elevation": "7,000 feet (2,100 m)", "year": "1869" },
  { "name": "Parowan", "county": "Iron", "type": "City", "area": "6.89 sq mi (17.8 km²)", "elevation": "6,017 feet (1,834 m)", "year": "1851" },
  { "name": "Payson", "county": "Utah", "type": "City", "area": "13.04 sq mi (33.8 km²)", "elevation": "4,700 feet (1,400 m)", "year": "1850" },
  { "name": "Perry", "county": "Box Elder", "type": "City", "area": "8.00 sq mi (20.7 km²)", "elevation": "4,367 feet (1,331 m)", "year": "1853" },
  { "name": "Plain City", "county": "Weber", "type": "City", "area": "11.99 sq mi (31.1 km²)", "elevation": "4,242 feet (1,293 m)", "year": "1859" },
  { "name": "Pleasant Grove", "county": "Utah", "type": "City", "area": "9.18 sq mi (23.8 km²)", "elevation": "4,623 feet (1,409 m)", "year": "1849" },
  { "name": "Pleasant View", "county": "Weber", "type": "City", "area": "6.98 sq mi (18.1 km²)", "elevation": "5,632 feet (1,717 m)", "year": "1851" },
  { "name": "Plymouth", "county": "Box Elder", "type": "Town", "area": "0.83 sq mi (2.1 km²)", "elevation": "4,488 feet (1,368 m)", "year": "1869" },
  { "name": "Portage", "county": "Box Elder", "type": "Town", "area": "2.55 sq mi (6.6 km²)", "elevation": "4,367 feet (1,331 m)", "year": "1867" },
  { "name": "Price", "county": "Carbon", "type": "City", "area": "5.04 sq mi (13.1 km²)", "elevation": "5,627 feet (1,715 m)", "year": "1879" },
  { "name": "Providence", "county": "Cache", "type": "City", "area": "3.90 sq mi (10.1 km²)", "elevation": "4,596 feet (1,401 m)", "year": "1859" },
  { "name": "Provo", "county": "Utah", "type": "City", "area": "41.69 sq mi (108.0 km²)", "elevation": "4,551 feet (1,387 m)", "year": "1850" },
  { "name": "Randolph", "county": "Rich", "type": "Town", "area": "1.29 sq mi (3.3 km²)", "elevation": "6,283 feet (1,915 m)", "year": "1870" },
  { "name": "Redmond", "county": "Sevier", "type": "Town", "area": "0.94 sq mi (2.4 km²)", "elevation": "5,105 feet (1,556 m)", "year": "1875" },
  { "name": "Richfield", "county": "Sevier", "type": "City", "area": "5.93 sq mi (15.4 km²)", "elevation": "5,354 feet (1,632 m)", "year": "1863" },
  { "name": "Richmond", "county": "Cache", "type": "City", "area": "3.46 sq mi (9.0 km²)", "elevation": "4,610 feet (1,410 m)", "year": "1859" },
  { "name": "Riverdale", "county": "Weber", "type": "City", "area": "4.63 sq mi (12.0 km²)", "elevation": "4,370 feet (1,330 m)", "year": "1850" },
  { "name": "River Heights", "county": "Cache", "type": "City", "area": "0.66 sq mi (1.7 km²)", "elevation": "4,580 feet (1,400 m)", "year": "1882" },
  { "name": "Riverton", "county": "Salt Lake", "type": "City", "area": "12.58 sq mi (32.6 km²)", "elevation": "4,439 feet (1,353 m)", "year": "1870" },
  { "name": "Rockville", "county": "Washington", "type": "Town", "area": "8.47 sq mi (21.9 km²)", "elevation": "3,740 feet (1,140 m)", "year": "1860" },
  { "name": "Rocky Ridge", "county": "Juab", "type": "Town", "area": "1.71 sq mi (4.4 km²)", "elevation": "4,990 feet (1,520 m)", "year": "1875" },
  { "name": "Roosevelt", "county": "Duchesne", "type": "City", "area": "6.76 sq mi (17.5 km²)", "elevation": "5,095 feet (1,553 m)", "year": "1905" },
  { "name": "Roy", "county": "Weber", "type": "City", "area": "8.14 sq mi (21.1 km²)", "elevation": "4,541 feet (1,384 m)", "year": "1876" },
  { "name": "Rush Valley", "county": "Tooele", "type": "Town", "area": "18.08 sq mi (46.8 km²)", "elevation": "5,043 feet (1,537 m)", "year": "1856" },
  { "name": "St. George", "county": "Washington", "type": "City", "area": "78.46 sq mi (203.2 km²)", "elevation": "2,860 feet (870 m)", "year": "1861" },
  { "name": "Salem", "county": "Utah", "type": "City", "area": "10.50 sq mi (27.2 km²)", "elevation": "4,610 feet (1,410 m)", "year": "1851" },
  { "name": "Salina", "county": "Sevier", "type": "City", "area": "5.83 sq mi (15.1 km²)", "elevation": "5,161 feet (1,573 m)", "year": "1863" },
  { "name": "Salt Lake City", "county": "Salt Lake", "type": "City", "area": "110.34 sq mi (285.8 km²)", "elevation": "4,226 feet (1,288 m)", "year": "1847" },
  { "name": "Sandy", "county": "Salt Lake", "type": "City", "area": "24.15 sq mi (62.5 km²)", "elevation": "4,450 feet (1,360 m)", "year": "1871" },
  { "name": "Santa Clara", "county": "Washington", "type": "City", "area": "6.12 sq mi (15.9 km²)", "elevation": "2,762 feet (842 m)", "year": "1854" },
  { "name": "Santaquin", "county": "Utah/Juab", "type": "City", "area": "10.44 sq mi (27.0 km²)", "elevation": "4,984 feet (1,519 m)", "year": "1851" },
  { "name": "Saratoga Springs", "county": "Utah", "type": "City", "area": "23.23 sq mi (60.2 km²)", "elevation": "4,505 feet (1,373 m)", "year": "1997" },
  { "name": "Scipio", "county": "Millard", "type": "Town", "area": "1.04 sq mi (2.7 km²)", "elevation": "5,315 feet (1,620 m)", "year": "1859" },
  { "name": "Scofield", "county": "Carbon", "type": "Town", "area": "0.92 sq mi (2.4 km²)", "elevation": "7,739 feet (2,359 m)", "year": "1879" },
  { "name": "Sigurd", "county": "Sevier", "type": "Town", "area": "0.98 sq mi (2.5 km²)", "elevation": "5,226 feet (1,593 m)", "year": "1874" },
  { "name": "Smithfield", "county": "Cache", "type": "City", "area": "5.35 sq mi (13.9 km²)", "elevation": "4,603 feet (1,403 m)", "year": "1859" },
  { "name": "Snowville", "county": "Box Elder", "type": "Town", "area": "1.55 sq mi (4.0 km²)", "elevation": "4,547 feet (1,386 m)", "year": "1871" },
  { "name": "South Jordan", "county": "Salt Lake", "type": "City", "area": "22.19 sq mi (57.5 km²)", "elevation": "4,439 feet (1,353 m)", "year": "1859" },
  { "name": "South Ogden", "county": "Weber", "type": "City", "area": "3.90 sq mi (10.1 km²)", "elevation": "4,449 feet (1,356 m)", "year": "1848" },
  { "name": "South Salt Lake", "county": "Salt Lake", "type": "City", "area": "6.94 sq mi (18.0 km²)", "elevation": "4,225 feet (1,288 m)", "year": "1847" },
  { "name": "South Weber", "county": "Davis", "type": "City", "area": "4.64 sq mi (12.0 km²)", "elevation": "4,551 feet (1,387 m)", "year": "1851" },
  { "name": "Spanish Fork", "county": "Utah", "type": "City", "area": "16.21 sq mi (42.0 km²)", "elevation": "4,577 feet (1,395 m)", "year": "1851" },
  { "name": "Spring City", "county": "Sanpete", "type": "City", "area": "1.41 sq mi (3.7 km²)", "elevation": "5,823 feet (1,775 m)", "year": "1852" },
  { "name": "Springdale", "county": "Washington", "type": "Town", "area": "4.62 sq mi (12.0 km²)", "elevation": "3,898 feet (1,188 m)", "year": "1862" },
  { "name": "Springville", "county": "Utah", "type": "City", "area": "14.38 sq mi (37.2 km²)", "elevation": "4,577 feet (1,395 m)", "year": "1850" },
  { "name": "Sterling", "county": "Sanpete", "type": "Town", "area": "0.32 sq mi (0.83 km²)", "elevation": "5,574 feet (1,699 m)", "year": "1873" },
  { "name": "Stockton", "county": "Tooele", "type": "Town", "area": "1.68 sq mi (4.4 km²)", "elevation": "5,118 feet (1,560 m)", "year": "1863" },
  { "name": "Sunset", "county": "Davis", "type": "City", "area": "1.46 sq mi (3.8 km²)", "elevation": "4,511 feet (1,375 m)", "year": "1935" },
  { "name": "Syracuse", "county": "Davis", "type": "City", "area": "10.18 sq mi (26.4 km²)", "elevation": "4,285 feet (1,306 m)", "year": "1878" },
  { "name": "Tabiona", "county": "Duchesne", "type": "Town", "area": "0.10 sq mi (0.26 km²)", "elevation": "6,516 feet (1,986 m)", "year": "1860" },
  { "name": "Taylorsville", "county": "Salt Lake", "type": "City", "area": "10.85 sq mi (28.1 km²)", "elevation": "4,295 feet (1,309 m)", "year": "1848" },
  { "name": "Tooele", "county": "Tooele", "type": "City", "area": "24.14 sq mi (62.5 km²)", "elevation": "5,043 feet (1,537 m)", "year": "1851" },
  { "name": "Toquerville", "county": "Washington", "type": "City", "area": "22.20 sq mi (57.5 km²)", "elevation": "3,389 feet (1,033 m)", "year": "1858" },
  { "name": "Torrey", "county": "Wayne", "type": "Town", "area": "1.66 sq mi (4.3 km²)", "elevation": "6,837 feet (2,084 m)", "year": "1880s" },
  { "name": "Tremonton", "county": "Box Elder", "type": "City", "area": "8.02 sq mi (20.8 km²)", "elevation": "4,325 feet (1,318 m)", "year": "1888" },
  { "name": "Trenton", "county": "Cache", "type": "Town", "area": "8.21 sq mi (21.3 km²)", "elevation": "4,462 feet (1,360 m)", "year": "1870" },
  { "name": "Tropic", "county": "Garfield", "type": "Town", "area": "7.91 sq mi (20.5 km²)", "elevation": "6,309 feet (1,923 m)", "year": "1891" },
  { "name": "Uintah", "county": "Weber", "type": "City", "area": "1.24 sq mi (3.2 km²)", "elevation": "4,537 feet (1,383 m)", "year": "1850" },
  { "name": "Vernal", "county": "Uintah", "type": "City", "area": "4.62 sq mi (12.0 km²)", "elevation": "5,328 feet (1,624 m)", "year": "1876" },
  { "name": "Vernon", "county": "Tooele", "type": "Town", "area": "8.06 sq mi (20.9 km²)", "elevation": "5,515 feet (1,681 m)", "year": "1862" },
  { "name": "Vineyard", "county": "Utah", "type": "City", "area": "4.80 sq mi (12.4 km²)", "elevation": "4,557 feet (1,389 m)", "year": "1989" },
  { "name": "Virgin", "county": "Washington", "type": "Town", "area": "12.72 sq mi (32.9 km²)", "elevation": "3,606 feet (1,099 m)", "year": "1857" },
  { "name": "Wales", "county": "Sanpete", "type": "Town", "area": "0.40 sq mi (1.0 km²)", "elevation": "5,627 feet (1,715 m)", "year": "1857" },
  { "name": "Wallsburg", "county": "Wasatch", "type": "Town", "area": "0.63 sq mi (1.6 km²)", "elevation": "5,676 feet (1,730 m)", "year": "1861" },
  { "name": "Washington", "county": "Washington", "type": "City", "area": "34.79 sq mi (90.1 km²)", "elevation": "2,792 feet (851 m)", "year": "1857" },
  { "name": "Washington Terrace", "county": "Weber", "type": "City", "area": "2.04 sq mi (5.3 km²)", "elevation": "4,610 feet (1,410 m)", "year": "1878" },
  { "name": "Wellington", "county": "Carbon", "type": "City", "area": "5.11 sq mi (13.2 km²)", "elevation": "5,413 feet (1,650 m)", "year": "1878" },
  { "name": "Wellsville", "county": "Cache", "type": "City", "area": "7.27 sq mi (18.8 km²)", "elevation": "4,547 feet (1,386 m)", "year": "1856" },
  { "name": "Wendover", "county": "Tooele", "type": "City", "area": "8.93 sq mi (23.1 km²)", "elevation": "4,291 feet (1,308 m)", "year": "1906" },
  { "name": "West Bountiful", "county": "Davis", "type": "City", "area": "3.31 sq mi (8.6 km²)", "elevation": "4,268 feet (1,301 m)", "year": "1848" },
  { "name": "West Haven", "county": "Weber", "type": "City", "area": "10.64 sq mi (27.6 km²)", "elevation": "4,272 feet (1,302 m)", "year": "1854" },
  { "name": "West Jordan", "county": "Salt Lake", "type": "City", "area": "32.33 sq mi (83.7 km²)", "elevation": "4,373 feet (1,333 m)", "year": "1848" },
  { "name": "West Point", "county": "Davis", "type": "City", "area": "7.11 sq mi (18.4 km²)", "elevation": "4,314 feet (1,315 m)", "year": "1867" },
  { "name": "West Valley City", "county": "Salt Lake", "type": "City", "area": "35.83 sq mi (92.8 km²)", "elevation": "4,304 feet (1,312 m)", "year": "1849" },
  { "name": "White City", "county": "Salt Lake", "type": "Metro Township", "area": "0.87 sq mi (2.3 km²)", "elevation": "4,583 feet (1,397 m)", "year": "1955" },
  { "name": "Willard", "county": "Box Elder", "type": "City", "area": "5.56 sq mi (14.4 km²)", "elevation": "4,350 feet (1,330 m)", "year": "1851" },
  { "name": "Woodland Hills", "county": "Utah", "type": "City", "area": "2.53 sq mi (6.6 km²)", "elevation": "5,331 feet (1,625 m)", "year": "1867" },
  { "name": "Woodruff", "county": "Rich", "type": "Town", "area": "0.72 sq mi (1.9 km²)", "elevation": "6,339 feet (1,932 m)", "year": "1865" },
  { "name": "Woods Cross", "county": "Davis", "type": "City", "area": "3.83 sq mi (9.9 km²)", "elevation": "4,377 feet (1,334 m)", "year": "1865" }
];

export default function UtahCities() {
  // Function to generate Google Maps URL for a city
  const generateMapLink = (cityName) => {
    return `https://www.google.com/maps?q=${encodeURIComponent(cityName)}, Utah`;
  };

  return (
    <div
      className="min-h-screen mt-[5rem] flex flex-col items-center justify-center"
      style={{ backgroundColor: "rgb(210, 234, 255)" }}
    >
      {/* Title */}
      <div className="text-center mt-3 text-5xl font-extrabold mb-2 text-gray-800 pt-6">
        <h1>Utah Cities</h1>
      </div>
      <div className="mb-6 font-bold text-2xl">(329 cities)</div>

      {/* Table */}
      <div className="overflow-x-auto w-[80%] bg-white shadow-md rounded-lg p-4">
        <table className="table-auto w-full border-collapse border border-gray-400">
          {/* Table Header */}
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-400 px-4 py-2">Name</th>
              <th className="border border-gray-400 px-4 py-2">County</th>
              <th className="border border-gray-400 px-4 py-2">Type</th>
              <th className="border border-gray-400 px-4 py-2">Area</th>
              <th className="border border-gray-400 px-4 py-2">Elevation</th>
              <th className="border border-gray-400 px-4 py-2">Year Settled</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {cities.map((city, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="border border-gray-400 px-4 py-2">
                  <a
                    href={generateMapLink(city.name)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {city.name}
                  </a>
                </td>
                <td className="border border-gray-400 px-4 py-2">{city.county}</td>
                <td className="border border-gray-400 px-4 py-2">{city.type}</td>
                <td className="border border-gray-400 px-4 py-2">{city.area}</td>
                <td className="border border-gray-400 px-4 py-2">{city.elevation}</td>
                <td className="border border-gray-400 px-4 py-2">{city.year}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
