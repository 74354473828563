import React from "react";
import VotersChart from "./TotalVotersChart";

const votersData = [
  {
    Fields: "Constitution",
    "No. of Voters": "8,047",
  },
  {
    Fields: "Democratic",
    "No. of Voters": "2,80,845",
  },
  {
    Fields: "Green",
    "No. of Voters": "660",
  },
  {
    Fields: "Independent American",
    "No. of Voters": "89,223",
  },
  {
    Fields: "Libertarian",
    "No. of Voters": "24,077",
  },
  {
    Fields: "No Labels",
    "No. of Voters": "11,104",
  },
  {
    Fields: "Republican",
    "No. of Voters": "10,20,040",
  },
  {
    Fields: "United Utah",
    "No. of Voters": "2,983",
  },
  {
    Fields: "Utah Forward",
    "No. of Voters": "170",
  },
  {
    Fields: "Unaffiliated",
    "No. of Voters": "5,79,982",
  },
  {
    Fields: "Total Active & Inactive Voters",
    "No. of Voters": "20,17,131",
  },
];

const TotalVoters = () => {
  return (
    <div
      style={{
        background: "#1e293b",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "14vh",
      }}
    >
      <h1 style={{ color: "#ffc107", marginBottom: "20px" }}>
        Total Voters Chart
      </h1>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            minWidth: "600px",
          }}
        >
          <VotersChart data={votersData} />
        </div>
      </div>
    </div>
  );
};

export default TotalVoters;
