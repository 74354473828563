import React from 'react'
const statePark = "/img/land1.jpg";

function PlacesLand() {
    const park = [
      {
          "NAME": "Beartrap Canyon Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Virgin, UT 84779, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/beartrap-canyon-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Beaver Dam Mountains",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "northwestern Arizona and southwestern Utah, USA",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/beaver-dam-mountains-wilderness",
          "Established on": "28 August 1984"
      },
      {
          "NAME": "Black Ridge Canyons Wilderness",
          "MANAGEMENT AREA": "Moab Field Office",
          "Address": "Unnamed Road, Virgin, UT 84779, United States",
          "Phone No:": "970-244-3000",
          "Website": "https://www.blm.gov/visit/black-ridge-canyons-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Black Ridge Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Pine Valley, UT 84781, United States",
          "Phone No:": "435-259-2100",
          "Website": "https://www.blm.gov/visit/blackridge-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Canaan Mountain Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Apple Valley, UT 84737, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/canaan-mountain-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Cedar Mountain Wilderness",
          "MANAGEMENT AREA": "Salt Lake Field Office",
          "Address": "Tooele County, Utah, USA",
          "Phone No:": "801-977-4300",
          "Website": "https://www.blm.gov/visit/cedar-mountain-wilderness-area",
          "Established on": "6 January 2006"
      },
      {
          "NAME": "Cottonwood Canyon Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Washington County, Utah, USA",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/cottonwood-canyon-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Cougar Canyon Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "8597CXWX+3H, Utah, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/cougar-canyon-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Deep Creek Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Utah, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/deep-creek-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Deep Creek North Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Unnamed Road, Springdale, UT 84767, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/deep-creek-north-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Doc's Pass Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Gunlock, UT 84733, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/docs-pass-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Goose Creek Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Unnamed Road, Springdale, UT 84767, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/goose-creek-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "LaVerkin Creek Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "La Verkin Creek Trail, New Harmony, UT 84757, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/laverkin-creek-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Red Butte Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Washington County, Utah, USA",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/red-butte-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Red Mountain Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "18,700 acres N.W. of Snow Canyon State Park, Dammeron Valley, UT 84783, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/red-mountain-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Slaughter Creek Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Washington County, Utah, USA",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/slaughter-creek-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Taylor Creek Wilderness",
          "MANAGEMENT AREA": "St. George Field Office",
          "Address": "Lower Basin Rd, New Harmony, UT 84757, United States",
          "Phone No:": "435-688-3200",
          "Website": "https://www.blm.gov/visit/taylor-creek-wilderness",
          "Established on": "30 March 2009"
      },
      {
          "NAME": "Paria Canyon-Vermilion Cliffs",
          "MANAGEMENT AREA": "Arizona Strip Field Office",
          "Address": "Arizona Strip Field Office, 345 E. Riverside Drive, St. George, UT 84790",
          "Phone No:": "435-644-1200",
          "Website": "https://www.blm.gov/visit/paria-canyon-vermilion-cliffs-wilderness",
          "Established on": "28 August 1984"
      }
  ]
    return (
        <div>
          {/* <div>
         <div
        className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
        style={{ backgroundColor: 'rgb(210, 234, 255)' }}
      >
        <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
          <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          State Parks in Utah
          <div className='mt-2 font-bold text-2xl'>(46 State Parks)</div>
          </div> */}
    
          <div
            className="min-h-screen mt-[1rem]  flex flex-col items-center justify-center"
            style={{ backgroundColor: "rgb(210, 234, 255)" }}
          >
            <div className="overflow-x-auto w-full mb-[3rem]">
              {/* <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          National Parks in Utah
          <div className='mt-2 font-bold text-2xl'>(5 National Parks)</div> */}
              <div
                className=" bg-[#d2eaff] flex flex-col items-center justify-center"
                aria-label="Utah aquatic scenery"
                style={{
                  backgroundImage: `url(${statePark})`, // Replace with your image path
                  backgroundSize: "cover", // Ensures the image covers the entire container
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                  width: "100%", // Ensures the container takes full width of the screen
                  height: "59vh", // Makes the container span the full height of the viewport
                }}
              >
                <h2
                  className="text-white text-center px-4 text-5xl sm:text-6xl md:text-6xl lg:text-7xl mt-7"
                  style={{
                    color: "white",
                    textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
                    lineHeight: "1.2", // Adjusts line spacing
                  }}
                >
                  Bureau of Land Management Wilderness Areas
                </h2>
                <div className="mt-2 font-bold text-2xl" style={{ color: "#fff" }}>
                  
                </div>
    
                <span
                  className="text-center px-4 text-2xl sm:text-2xl md:text-3xl lg:text-4xl mt-4"
                  style={{
                    color: "#fff",
                    fontFamily: "SignPainter", // Use the SignPainter font
                    textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
                    lineHeight: "1.5", // Adjusts line spacing
                  }}
                >
                  Explore Utah By The Numbers
                </span>
              </div>
            </div>
    
            <div className="overflow-x-auto w-[95%] bg-white shadow-md rounded-lg p-4">
              <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
                <thead>
                  <tr>
                    {/* <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      S.no
                    </th> */}
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Name
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                        Management Area
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Address
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Contact No
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                      Website
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                      Established on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {park.map((park) => (
                    <tr key={park.sn} className="hover:bg-gray-100">
                      {/* <td className="border border-gray-300 px-4 py-2 text-center ">
                        {park["S.no"]}
                      </td> */}
    
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {park.NAME}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park['MANAGEMENT AREA']}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {park.Address}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["Phone No:"]}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center ">
                        <a
                          href={park.Website}
                          target="_blank"
                          rel=" noopener noreferrer"
                        >
                          {park.Website}
                        </a>
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["Established on"]}
                      </td>
    
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
}

export default PlacesLand
