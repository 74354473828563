// import React, { useEffect, useState } from "react";
// // Create a CSS file for styles
// import "../styles/Torch404.css";

// const Torch404 = () => {
//   const [torchPosition, setTorchPosition] = useState({ top: 0, left: 0 });

//   useEffect(() => {
//     const handleMouseMove = (event) => {
//       setTorchPosition({
//         top: event.pageY,
//         left: event.pageX,
//       });
//     };

//     document.addEventListener("mousemove", handleMouseMove);
//     return () => document.removeEventListener("mousemove", handleMouseMove);
//   }, []);

//   return (
//     <div className="torch-404-page sm:!h-[85vh]">
//       <div className="text1">
//         <h1>404</h1>
//         <h2>Uh, Ohh</h2>
//         <h3>
//           Sorry, we can't find what you are looking for 'cuz it's so dark in here
//         </h3>
//       </div>
//       <div
//         className="torch"
//         style={{
//           top: torchPosition.top + "px",
//           left: torchPosition.left + "px",
//         }}
//       ></div>
//     </div>
//   );
// };

// export default Torch404;




import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Link } from "react-router-dom";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
const census = "/img/census.png";


const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const AboutUsImage = "/img/aboutus1.png"
const sourcelogo1 = "/img/unemployment-logo.png"
const sourcelogo2 = "/img/utah-job-logo.png"

export default function Torch404() {
  const [modalShow, setModalShow] = React.useState(false);
  const iconData = [
    { icon: "fa-fighter-jet", title: "Aerospace and Defense" },
    { icon: "fa-seedling", title: "Agriculture, Food, and Natural Resources" },
    { icon: "fa-briefcase", title: "Business, Finance, and Marketing" },
    { icon: "fa-cogs", title: "Engineering and Technology" },
    { icon: "fa-hands-helping", title: "Human Services" },
    { icon: "fa-truck", title: "Transportation, Distribution, and Logistics" },
    { icon: "fa-building", title: "Architecture and Construction" },
    { icon: "fa-laptop-code", title: "Computer Science and IT" },
    { icon: "fa-heartbeat", title: "Health Science" },
    {
      icon: "fa-balance-scale",
      title: "Law, Public Safety, Corrections, and Security",
    },
    {
      icon: "fa-paint-brush",
      title: "Arts, Audio/Visual Technology, and Communication",
    },
    { icon: "fa-graduation-cap", title: "Education and Training" },
    { icon: "fa-concierge-bell", title: "Hospitality and Tourism" },
    { icon: "fa-industry", title: "Manufacturing" },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col">
    
   
      {/* Main content */}
      <div
  className=" bg-[#d2eaff] flex flex-col items-center justify-center"
  style={{
    backgroundImage: `url(${AboutUsImage})`, // Replace with your image path
    backgroundSize: "cover", // Ensures the image covers the entire container
    backgroundPosition: "center", // Centers the image
    backgroundRepeat: "no-repeat", // Prevents the image from repeating
    width: "100%", // Ensures the container takes full width of the screen
    height: "45vh", // Makes the container span the full height of the viewport
  }}
>
  <h2
    className="text-white text-center px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl mt-7"
    style={{
      color: "white",
      textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
      lineHeight: "1.2", // Adjusts line spacing
    }}
  >
    UTahData.org
  </h2>

  <span
    className="text-center px-4 text-lg sm:text-2xl md:text-3xl lg:text-4xl mt-4"
    style={{
      color: "black",
      fontFamily: "SignPainter", // Use the SignPainter font
      textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
      lineHeight: "1.5", // Adjusts line spacing
    }}
  >
    Explore Utah By The Numbers
  </span>
</div>

          <div className="bg-[#d2eaff] !h-auto sm:!h-[65vh] lg:!h-[55vh]">
          
          <div className="mt-[3rem] px-4 sm:px-0">
          <h1 className="flex text-center justify-center"> 404 Oops !!!!</h1>
        <h3 className="text-[#666] text-center justify-center flex mt-[3vh]">
        It seems like this page is missing...
        </h3>
        <h3 className="text-[#666] justify-center flex mt-[5vh]">
          No Data Found!!!
        </h3>
        <h3 className="text-[#666] justify-center text-center flex">
        Even our best analysts couldn’t find it!
        </h3>


        <Box  sx={{
    '& button': { m: 1 },
    '& button:hover': {
      color: 'white', // Change the text color to white on hover
      backgroundColor: '#1976d2', // Optional: Change background color on hover
    },
  }}>
        <div className="flex justify-center">
        <Link to="/">
        <Button variant="outlined" size="large">
          Go to Home Page
        </Button>
        </Link>
        </div>

        </Box>
       
        </div>
     
        
        </div>

      {/* Footer */}
     
    </div>
  );
}
