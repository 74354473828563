import React from "react";

const statePark = "/img/refuge.png";
function PlacesWildlifeRefuges() {
    const park =[
      {
          "NAME": "Bear River National Wildlife Refuge",
          "LAND ADMINISTRATOR": "U.S. Fish and Wildlife Service",
          "Address": "2155 W Forest St, Brigham City, UT 84302, United States",
          "Phone No:": "435-723-5887",
          "Website": "https://www.fws.gov/refuge/bear-river-migratory-bird",
          "Established on": "1928"
      },
      {
          "NAME": "Fish Springs National Wildlife Refuge",
          "LAND ADMINISTRATOR": "U.S. Fish and Wildlife Service",
          "Address": "8454 Auto Tour Rte 010, Dugway, UT 84022, United States",
          "Phone No:": "435-693-3122",
          "Website": "https://www.fws.gov/refuge/fish-springs",
          "Established on": "1959"
      },
      {
          "NAME": "Jones Hole National Fish Hatchery",
          "LAND ADMINISTRATOR": "U.S. Fish and Wildlife Service",
          "Address": "24495 East Jones Hole Hatchery Road, Vernal, UT 84078",
          "Phone No:": "435-789-4481",
          "Website": "https://www.fws.gov/fish-hatchery/jones-hole",
          "Established on": "1968"
      },
      {
          "NAME": "Ouray National Fish Hatchery",
          "LAND ADMINISTRATOR": "U.S. Fish and Wildlife Service",
          "Address": "21000 East Wildlife Refuge Road, Randlett, UT 84063",
          "Phone No:": "435-828-7134",
          "Website": "https://www.fws.gov/fish-hatchery/ouray",
          "Established on": "1996"
      },
      {
          "NAME": "Ouray National Wildlife Refuge",
          "LAND ADMINISTRATOR": "U.S. Fish and Wildlife Service",
          "Address": "HC 69 Box 232, Randlett, UT 84063",
          "Phone No:": "435-545-2522",
          "Website": "https://www.fws.gov/refuge/ouray",
          "Established on": "1960"
      }
  ]
    return (
        <div>
          {/* <div>
         <div
        className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
        style={{ backgroundColor: 'rgb(210, 234, 255)' }}
      >
        <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
          <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          State Parks in Utah
          <div className='mt-2 font-bold text-2xl'>(46 State Parks)</div>
          </div> */}
    
          <div
            className="min-h-screen mt-[1rem]  flex flex-col items-center justify-center"
            style={{ backgroundColor: "rgb(210, 234, 255)" }}
          >
            <div className="overflow-x-auto w-full mb-[3rem]">
              {/* <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          National Parks in Utah
          <div className='mt-2 font-bold text-2xl'>(5 National Parks)</div> */}
              <div
                className=" bg-[#d2eaff] flex flex-col items-center justify-center"
                aria-label="Utah aquatic scenery"
                style={{
                  backgroundImage: `url(${statePark})`, // Replace with your image path
                  backgroundSize: "cover", // Ensures the image covers the entire container
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                  width: "100%", // Ensures the container takes full width of the screen
                  height: "45vh", // Makes the container span the full height of the viewport
                }}
              >
                <h2
                  className="text-white text-center px-4 text-5xl sm:text-6xl md:text-6xl lg:text-7xl mt-7"
                  style={{
                    color: "white",
                    textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
                    lineHeight: "1.2", // Adjusts line spacing
                  }}
                >
                  National Wildlife Refuges
                </h2>
                <div className="mt-2 font-bold text-2xl" style={{ color: "#fff" }}>
                  
                </div>
    
                <span
                  className="text-center px-4 text-2xl sm:text-2xl md:text-3xl lg:text-4xl mt-4"
                  style={{
                    color: "#fff",
                    fontFamily: "SignPainter", // Use the SignPainter font
                    textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
                    lineHeight: "1.5", // Adjusts line spacing
                  }}
                >
                  Explore Utah By The Numbers
                </span>
              </div>
            </div>
    
            <div className="overflow-x-auto w-[95%] bg-white shadow-md rounded-lg p-4">
              <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
                <thead>
                  <tr>
                    {/* <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      S.no
                    </th> */}
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Name
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                        Land Administrator
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Address
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Contact No
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                      Website
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                      Established on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {park.map((park) => (
                    <tr key={park.sn} className="hover:bg-gray-100">
                      {/* <td className="border border-gray-300 px-4 py-2 text-center ">
                        {park["S.no"]}
                      </td> */}
    
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {park.NAME}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["LAND ADMINISTRATOR"]}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {park.Address}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["Phone No:"]}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center ">
                        <a
                          href={park.Website}
                          target="_blank"
                          rel=" noopener noreferrer"
                        >
                          {park.Website}
                        </a>
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["Established on"]}
                      </td>
    
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
}

export default PlacesWildlifeRefuges
