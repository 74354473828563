import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
import "../styles/Verticals.css";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";
import "../styles/Verticals.css";
const forests = "/img/forests.jpg";
const recreation = "/img/recreation.jpg";
const forestService = "/img/forestService.jpg";
const historic = "/img/historic.jpg";
const land = "/img/land.jpg";
const transport = "/img/transportation-distribution-logistics.jpg";
const architecture = "/img/Architectural-Engineering-construction.jpg";
const wildlife = "/img/wildlife.jpg";
const health = "/img/Heath-Science.jpg";
const law = "/img/law-public-safety-security.jpg";
const arts = "/img/arts-visual-communication.jpeg";
const education = "/img/education-training.webp";
const hospitality = "/img/hospitality-tourism.jpg";
const dataScience = "/img/Data-Science-and-Analytics.jpg";
const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const national = "/img/national.jpg";
const state = "/img/state.jpg";

const monument = "/img/monument.jpg";

function PlacesToVisit() {
    const [modalShow, setModalShow] = React.useState(false);

    const iconData = [
      {
        icon: "fa-solid fa-tree",
        title: "National Forests",
        alt: "Utah National Forest",
        backgroundImage: `url(${forests})`,
        link: "/utah-at-a-glance/places/national-forests",
      },
      {
        icon: "fa-solid fa-feather",
        title: "National Wildlife Refuges",
        alt: "Utah National Wildlife Refuges",
        backgroundImage: `url(${wildlife})`,
        link: "/utah-at-a-glance/places/national-wildlife-refuges",
      },
     
      {
        icon: "fa-solid fa-bicycle",
        title: "National Recreation Areas",
        alt: "Utah National Recreation Areas",
        backgroundImage: `url(${recreation})`,
        link: "/utah-at-a-glance/places/national-recreation-area",
      },
      {
        icon: "fs fa-history",
        title: "National Historic Site",
        alt: "National Historic Site Utah",
        backgroundImage: `url(${historic})`,
        link: "/utah-at-a-glance/places/national-historic-site",
      },
      {
        icon: "fa-solid fa-monument",
        title: "National Monuments",
        alt: "Utah National Monuments",
        backgroundImage: `url(${monument})`,
        link: "/utah-at-a-glance/places/national-monument",
      },
     
      {
        icon: "fa-solid fa-signs-post",
        title: "United States Forest Service Wilderness Areas",
        alt: "United States Forest Service Wilderness Areas",
        backgroundImage: `url(${forestService})`,
        link: "/utah-at-a-glance/places/national-forest-services",
      },
  
      {
        icon: "fa-solid fa-wheat-awn",
        title: "Bureau of Land Management Wilderness Areas",
        alt: "Bureau of Land Management Wilderness Areas",
        backgroundImage: `url(${land})`,
        link: "/utah-at-a-glance/places/land-management-wilderness",
      },
      {
        icon: "fa-solid fa-binoculars",
        title: "National Parks",
        alt: "National Park",
        backgroundImage: `url(${national})`,
        link: "/utah-at-a-glance/utah-nationalparks",
      },
      {
        icon: "fa-solid fa-fire",
        title: "State Parks",
        alt: "State Park",
        backgroundImage: `url(${state})`,
        link: "/utah-at-a-glance/utah-stateparks",
      },
     
    ];
  
    const navbarRef = useRef(null);
  
    // Close the navbar on outside click
    useEffect(() => {
      function handleClickOutside(event) {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
          const navbarCollapse =
            navbarRef.current.querySelector(".navbar-collapse");
          if (navbarCollapse) {
            navbarCollapse.classList.remove("show"); // Close the navbar
          }
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  return (
    <div>
       {/* Main content */}
       <div className="main-container bg-[#d2eaff]">
        <h2 className="title">
          {/* <i className="fas fa-info-circle"></i>  */}
          Places to Visit
        </h2>

        <div className="icon-container">
          {iconData.map((icon, index) => (
            <Link to={icon.link} key={index} style={{ textDecoration: "none" }}>
              <div
                className="icon-box"
                key={index}
                style={{
                  backgroundImage: icon.backgroundImage,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minWidth: "80px",
                }}
              >
                <i className={`fas ${icon.icon} `}></i>
                <h3 className="text-white">{icon.title}</h3>
                <img
                  src={icon.backgroundImage}
                  alt={icon.alt}
                  className="hidden-img"
                />
              </div>
            </Link>
          ))}
        </div>
      </div>

    </div>
  )
}

export default PlacesToVisit
