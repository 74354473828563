import React from "react";
import "../styles/VotersCount.css";
import { useNavigate } from "react-router-dom";

const utahvotercount = "/img/vote.avif";

const voters = [
  {
    id: 1,
    title: "Total Voters",
    value: "20,17,131",
    color: "blue",
    icon: (
      <i
        class="fa fa-users"
        aria-hidden="true"
        style={{
          padding: "20px",
          borderRadius: "50%",
          background: "rgba(0, 123, 255, 0.4)",
          color: "rgba(0, 123, 255, 1)",
        }}
      ></i>
    ),
    textColor: "blue-text",
  },
  {
    id: 2,
    title: "Active Voters",
    value: "17,60,053",
    color: "green",
    icon: (
      <i
        class="fa fa-user-plus"
        aria-hidden="true"
        style={{
          padding: "20px",
          borderRadius: "50%",
          background: "rgba(34, 197, 94, 0.4)",
          color: "rgba(34, 197, 94, 1)",
        }}
      ></i>
    ),
    textColor: "green-text",
  },
  {
    id: 3,
    title: "Inactive Voters",
    value: "2,57,078",
    color: "red",
    icon: (
      <i
        class="fa fa-user-times"
        aria-hidden="true"
        style={{
          padding: "20px",
          borderRadius: "50%",
          background: "rgba(220, 53, 69, 0.4)",
          color: "rgba(220, 53, 69, 1)",
        }}
      ></i>
    ),
    textColor: "red-text",
  },
];

const StatCard = () => {
  const navigate = useNavigate();

  const viewDetail = ({ voter }) => {
    console.log(voter);
    if (voter.title === "Total Voters") {
      navigate(`/utah-at-a-glance/utah-voters-count/total-voters`);
    } else if (voter.title === "Active Voters") {
      navigate(`/utah-at-a-glance/utah-voters-count/active-voters`);
    } else {
      navigate(`/utah-at-a-glance/utah-voters-count/inactive-voters`);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div
          className="relative flex-grow flex flex-col items-center justify-center mt-20 px-4 bg-cover bg-center bg-no-repeat "
          aria-label="Voter Count Utah"
          style={{
            backgroundImage: `url(${utahvotercount})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            height: "30vh",
          }}
        >
          {/* <div className="absolute inset-0 bg-blue-700 opacity-60"></div> */}

          <div className="relative text-center mt-4 p-1 rounded-md  inline-block px-6 py-2">
            <h1
              className="text-3xl md:text-6xl font-bold text-[#ffc107]"
              style={{
                textShadow: "rgba(0, 0, 0, 0.7) 2px 2px 6px",
                lineHeight: "1.2",
              }}
            >
              <div className="flex items-center gap-4">
                <h1>Voters Count of Utah</h1>
              </div>
            </h1>
          </div>
        </div>
        <div className="voter-container">
          {voters.map((voter) => (
            <div
              key={voter.id}
              className="voter-card"
              onClick={() => viewDetail({ voter })}
            >
              <div className={`card-header ${voter.color} voter-title`}>
                {voter.title}
              </div>
              <div className="card-body">
                <div className="icon">{voter.icon}</div>
                <p
                  className={`value ${voter.textColor}`}
                  style={{ marginBottom: "0px" }}
                >
                  {voter.value}
                </p>
                <p
                  className={`${voter.textColor}`}
                   style={{ margin: "0" }}
                >
                  (As on 10 Mar 2025)
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StatCard;
