import React from 'react'

export default function School15() {

    const schoolData = 
    [
        {"schoolName": "Antelope Canyon Elementary", "numberOfStudents": 612, "grades": "KG-6"},
        {"schoolName": "Aspen Elementary", "numberOfStudents": 642, "grades": "KG-6"},
        {"schoolName": "Bastian School", "numberOfStudents": 714, "grades": "KG-6"},
        {"schoolName": "Bingham High", "numberOfStudents": 2482, "grades": "10-12"},
        {"schoolName": "Blackridge School", "numberOfStudents": 941, "grades": "KG-6"},
        {"schoolName": "Bluffdale School", "numberOfStudents": 754, "grades": "KG-6"},
        {"schoolName": "Butterfield Canyon School", "numberOfStudents": 774, "grades": "KG-6"},
        {"schoolName": "Columbia School", "numberOfStudents": 619, "grades": "KG-6"},
        {"schoolName": "Copper Canyon School", "numberOfStudents": 569, "grades": "KG-6"},
        {"schoolName": "Copper Hills High", "numberOfStudents": 2841, "grades": "10-12"},
        {"schoolName": "Copper Mountain Middle", "numberOfStudents": 1085, "grades": "7-9"},
        {"schoolName": "Daybreak School", "numberOfStudents": 777, "grades": "KG-6"},
        {"schoolName": "Eastlake School", "numberOfStudents": 885, "grades": "KG-6"},
        {"schoolName": "Elk Meadows School", "numberOfStudents": 666, "grades": "KG-6"},
        {"schoolName": "Elk Ridge Middle", "numberOfStudents": 1118, "grades": "7-9"},
        {"schoolName": "Falcon Ridge School", "numberOfStudents": 654, "grades": "KG-6"},
        {"schoolName": "Foothills School", "numberOfStudents": 919, "grades": "KG-6"},
        {"schoolName": "Fort Herriman Middle", "numberOfStudents": 1261, "grades": "7-9"},
        {"schoolName": "Fox Hollow School", "numberOfStudents": 935, "grades": "KG-6"},
        {"schoolName": "Golden Fields School", "numberOfStudents": 882, "grades": "KG-6"},
        {"schoolName": "Hayden Peak School", "numberOfStudents": 723, "grades": "KG-6"},
        {"schoolName": "Heartland School", "numberOfStudents": 438, "grades": "KG-6"},
        {"schoolName": "Herriman High", "numberOfStudents": 2240, "grades": "10-12"},
        {"schoolName": "Herriman School", "numberOfStudents": 731, "grades": "KG-6"},
        {"schoolName": "Hidden Valley Middle", "numberOfStudents": 868, "grades": "7-9"},
        {"schoolName": "Joel P. Jensen Middle", "numberOfStudents": 801, "grades": "7-9"},
        {"schoolName": "Jordan Academy For Technology And Careers - North", "numberOfStudents": 0, "grades": "9-12"},
        {"schoolName": "Jordan Academy For Technology & Careers South", "numberOfStudents": 0, "grades": "10-12"},
        {"schoolName": "Jordan Hills School", "numberOfStudents": 665, "grades": "KG-6"},
        {"schoolName": "Jordan Ridge School", "numberOfStudents": 676, "grades": "KG-6"},
        {"schoolName": "Kauri Sue Hamilton", "numberOfStudents": 236, "grades": "KG-12"},
        {"schoolName": "Kelsey Peak Virtual Middle", "numberOfStudents": 120, "grades": "7-8"},
        {"schoolName": "Kings Peak High", "numberOfStudents": 256, "grades": "9-12"},
        {"schoolName": "Majestic School", "numberOfStudents": 257, "grades": "KG-6"},
        {"schoolName": "Midas Creek School", "numberOfStudents": 805, "grades": "KG-6"},
        {"schoolName": "Monte Vista School", "numberOfStudents": 833, "grades": "KG-6"},
        {"schoolName": "Mountain Creek Middle School", "numberOfStudents": 1275, "grades": "7-9"},
        {"schoolName": "Mountain Point Elementary", "numberOfStudents": 773, "grades": "KG-6"},
        {"schoolName": "Mountain Ridge High", "numberOfStudents": 2507, "grades": "10-12"},
        {"schoolName": "Mountain Shadows School", "numberOfStudents": 585, "grades": "KG-6"},
        {"schoolName": "Oakcrest School", "numberOfStudents": 662, "grades": "KG-6"},
        {"schoolName": "Oak Leaf Elementary", "numberOfStudents": 500, "grades": "KG-6"},
        {"schoolName": "Oquirrh Hills Middle", "numberOfStudents": 1332, "grades": "7-9"},
        {"schoolName": "Oquirrh School", "numberOfStudents": 458, "grades": "KG-6"},
        {"schoolName": "Preschool", "numberOfStudents": 1618, "grades": "PK-PK"},
        {"schoolName": "Ridge View Elementary", "numberOfStudents": 1092, "grades": "KG-6"},
        {"schoolName": "Rivers Edge School", "numberOfStudents": 10, "grades": "KG-8"},
        {"schoolName": "Riverside School", "numberOfStudents": 622, "grades": "KG-6"},
        {"schoolName": "Riverton High", "numberOfStudents": 2286, "grades": "10-12"},
        {"schoolName": "Riverton School", "numberOfStudents": 694, "grades": "KG-6"},
        {"schoolName": "Rocky Peak Virtual School", "numberOfStudents": 216, "grades": "KG-6"},
        {"schoolName": "Rosamond School", "numberOfStudents": 608, "grades": "KG-6"},
        {"schoolName": "Rose Creek School", "numberOfStudents": 757, "grades": "KG-6"},
        {"schoolName": "Silver Crest School", "numberOfStudents": 902, "grades": "KG-6"},
        {"schoolName": "South Hills Middle", "numberOfStudents": 1091, "grades": "7-9"},
        {"schoolName": "South Jordan Middle", "numberOfStudents": 1249, "grades": "7-9"},
        {"schoolName": "South Jordan School", "numberOfStudents": 882, "grades": "KG-6"},
        {"schoolName": "Southland School", "numberOfStudents": 681, "grades": "KG-6"},
        {"schoolName": "Southpointe Adult High", "numberOfStudents": 0, "grades": "9-12"},
        {"schoolName": "South Valley School", "numberOfStudents": 150, "grades": "9-12"},
        {"schoolName": "Sunset Ridge Middle", "numberOfStudents": 1454, "grades": "7-9"},
        {"schoolName": "Terra Linda School", "numberOfStudents": 474, "grades": "KG-6"},
        {"schoolName": "Valley High School", "numberOfStudents": 363, "grades": "7-12"},
        {"schoolName": "Welby School", "numberOfStudents": 790, "grades": "KG-6"},
        {"schoolName": "West Hills Middle", "numberOfStudents": 1247, "grades": "7-9"},
        {"schoolName": "West Jordan High", "numberOfStudents": 1826, "grades": "10-12"},
        {"schoolName": "West Jordan Middle", "numberOfStudents": 1104, "grades": "7-9"},
        {"schoolName": "West Jordan School", "numberOfStudents": 456, "grades": "KG-6"},
        {"schoolName": "Westland School", "numberOfStudents": 488, "grades": "KG-6"},
        {"schoolName": "Westvale School", "numberOfStudents": 490, "grades": "KG-6"}
      ]
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Jordan
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
