
// // // // // // // import React from 'react';
// // // // // // // import {
// // // // // // //   MDBBtn,
// // // // // // //   MDBContainer,
// // // // // // //   MDBCard,
// // // // // // //   MDBCardBody,
// // // // // // //   MDBInput,
// // // // // // //   MDBIcon,
// // // // // // //   MDBRow,
// // // // // // //   MDBCol,
// // // // // // //   MDBCheckbox
// // // // // // // } from 'mdb-react-ui-kit';
// // // // // // // //import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// // // // // // // import { AppProvider } from '@toolpad/core/AppProvider';
// // // // // // // import { SignInPage } from '@toolpad/core/SignInPage';
// // // // // // // import { useTheme } from '@mui/material/styles';
// // // // // // // import { Height } from '@mui/icons-material';

// // // // // // // const login = "/img/login1.jpg";



// // // // // // // const providers = [{ id: 'credentials', name: 'Email and Password' }];
// // // // // // // // preview-end

// // // // // // // const signIn = async (provider, formData) => {
// // // // // // //   const promise = new Promise((resolve) => {
// // // // // // //     setTimeout(() => {
// // // // // // //       alert(
// // // // // // //         `Signing in with "${provider.name}" and credentials: ${formData.get('email')}, ${formData.get('password')}`,
// // // // // // //       );
// // // // // // //       resolve();
// // // // // // //     }, 300);
// // // // // // //   });
// // // // // // //   return promise;
// // // // // // // };



// // // // // // // function Login1() {


// // // // // // //   const theme = useTheme();
// // // // // // //   return (
// // // // // // //     <MDBContainer fluid className="-mb-[10vh]">
// // // // // // //       <MDBRow className="g-0 !mt-[15vh] sm:!mt-[2vh] align-items-center justify-content-center">
// // // // // // //         {/* Left Column */}
// // // // // // //         <MDBCol 
// // // // // // //           md="6" 
// // // // // // //           lg="5" 
// // // // // // //           className="order-lg-1 order-2 p-3 lg:-mr-[15vw]"
// // // // // // //           style={{ zIndex: 2 }}
// // // // // // //         >
         
// // // // // // //             <MDBCardBody className=" shadow-5 text-center">


// // // // // // //             <AppProvider theme={theme}  className=" cascading-right mx-auto !h-[50vh]"
          
// // // // // // //             >
// // // // // // //       <SignInPage
// // // // // // //         signIn={signIn}
// // // // // // //         providers={providers}
// // // // // // //         slotProps={{ emailField: { autoFocus: false } }}
// // // // // // //       />
// // // // // // //     </AppProvider>
            
          
// // // // // // //             </MDBCardBody>
// // // // // // //           {/* </MDBCard> */}
// // // // // // //         </MDBCol>

// // // // // // //         {/* Right Column */}
// // // // // // //         <MDBCol md="6" lg="7" className="order-lg-2 order-1 text-center p-0">
// // // // // // //           <img
// // // // // // //             src={login}
// // // // // // //             className="w-90 rounded-4 shadow-4 img-fluid"
// // // // // // //             alt="Login Visual"
// // // // // // //             style={{
// // // // // // //               maxHeight: '70vh',
// // // // // // //               objectFit: 'cover',
// // // // // // //               position: 'relative',
// // // // // // //               zIndex: 1
// // // // // // //             }}
// // // // // // //           />
// // // // // // //         </MDBCol>
// // // // // // //       </MDBRow>
// // // // // // //     </MDBContainer>
// // // // // // //   );
// // // // // // // }

// // // // // // // export default Login1;














// // // // // // import React from 'react';
// // // // // // import { Box, Container, Grid, Card, CardContent, TextField, Typography, Button } from '@mui/material';
// // // // // // import { AppProvider } from '@toolpad/core/AppProvider';
// // // // // // import { SignInPage } from '@toolpad/core/SignInPage';
// // // // // // import { useTheme } from '@mui/material/styles';

// // // // // // const login = '/img/login1.jpg';

// // // // // // const providers = [{ id: 'credentials', name: 'Email and Password' }];

// // // // // // const signIn = async (provider, formData) => {
// // // // // //   const promise = new Promise((resolve) => {
// // // // // //     setTimeout(() => {
// // // // // //       alert(
// // // // // //         `Signing in with "${provider.name}" and credentials: ${formData.get('email')}, ${formData.get('password')}`
// // // // // //       );
// // // // // //       resolve();
// // // // // //     }, 300);
// // // // // //   });
// // // // // //   return promise;
// // // // // // };

// // // // // // function Login1() {
// // // // // //   const theme = useTheme();

// // // // // //   return (
// // // // // //     <Container maxWidth="xl" sx={{ my: 5 }}>
// // // // // //       <Grid container alignItems="center" justifyContent="center" spacing={4}>
// // // // // //         {/* Left Column */}
// // // // // //         <Grid item xs={12} md={6} lg={5} sx={{ zIndex: 2 }}>
// // // // // //           {/* <Card
// // // // // //             sx={{
// // // // // //               backgroundColor: 'rgba(255, 255, 255, 0.8)',
// // // // // //               backdropFilter: 'blur(10px)',
// // // // // //               boxShadow: 3,
// // // // // //               p: 3,
// // // // // //               maxWidth: '500px',
// // // // // //               margin: '0 auto',
// // // // // //             }}
// // // // // //           > */}
// // // // // //             <CardContent>
// // // // // //               <Typography variant="h4" align="center" sx={{ mb: 3 }}>
// // // // // //                 Sign up now
// // // // // //               </Typography>

// // // // // //               {/* Sign-in form */}
// // // // // //               <AppProvider theme={theme}>
// // // // // //                 <SignInPage
// // // // // //                   signIn={signIn}
// // // // // //                   providers={providers}
// // // // // //                   slotProps={{ emailField: { autoFocus: false } }}
// // // // // //                 />
// // // // // //               </AppProvider>
// // // // // //             </CardContent>
// // // // // //           {/* </Card> */}
// // // // // //         </Grid>

// // // // // //         {/* Right Column */}
// // // // // //         <Grid item xs={12} md={6} lg={7}>
// // // // // //           <Box
// // // // // //             component="img"
// // // // // //             src={login}
// // // // // //             alt="Login Visual"
// // // // // //             sx={{
// // // // // //               width: '90%',
// // // // // //               borderRadius: 4,
// // // // // //               boxShadow: 3,
// // // // // //               maxHeight: '70vh',
// // // // // //               objectFit: 'cover',
// // // // // //               position: 'relative',
// // // // // //               zIndex: 1,
// // // // // //             }}
// // // // // //           />
// // // // // //         </Grid>
// // // // // //       </Grid>
// // // // // //     </Container>
// // // // // //   );
// // // // // // }

// // // // // // export default Login1;















// // // // // import React from 'react';
// // // // // import { Box, Container, Grid, Card, CardContent, Typography } from '@mui/material';
// // // // // import { AppProvider } from '@toolpad/core/AppProvider';
// // // // // import { SignInPage } from '@toolpad/core/SignInPage';
// // // // // import { useTheme } from '@mui/material/styles';

// // // // // const login = '/img/login1.jpg';

// // // // // const providers = [{ id: 'credentials', name: 'Email and Password' }];

// // // // // const signIn = async (provider, formData) => {
// // // // //   const promise = new Promise((resolve) => {
// // // // //     setTimeout(() => {
// // // // //       alert(
// // // // //         `Signing in with "${provider.name}" and credentials: ${formData.get('email')}, ${formData.get('password')}`
// // // // //       );
// // // // //       resolve();
// // // // //     }, 300);
// // // // //   });
// // // // //   return promise;
// // // // // };

// // // // // function Login1() {
// // // // //   const theme = useTheme();

// // // // //   return (
// // // // //     <Container maxWidth="xl" sx={{ my: 5 }}>
// // // // //       <Grid container alignItems="center" justifyContent="center" spacing={4}>
// // // // //         {/* Left Column (Image) */}
// // // // //         <Grid item xs={12} md={6} lg={7}>
// // // // //           <Box
// // // // //             component="img"
// // // // //             src={login}
// // // // //             alt="Login Visual"
// // // // //             sx={{
// // // // //               width: '90%',
// // // // //               borderRadius: 4,
// // // // //               boxShadow: 3,
// // // // //               maxHeight: '70vh',
// // // // //               objectFit: 'cover',
// // // // //               position: 'relative',
// // // // //               zIndex: 1,
// // // // //             }}
// // // // //           />
// // // // //         </Grid>

// // // // //         {/* Right Column (Sign-in Box) */}
// // // // //         <Grid item xs={12} md={6} lg={5} sx={{ zIndex: 2 }}>
// // // // //           {/* <Card
// // // // //             sx={{
// // // // //               backgroundColor: 'rgba(255, 255, 255, 0.8)',
// // // // //               backdropFilter: 'blur(10px)',
// // // // //               boxShadow: 3,
// // // // //               p: 3,
// // // // //               maxWidth: '500px',
// // // // //               margin: '0 auto',
// // // // //             }}
// // // // //           > */}
// // // // //             <CardContent>
// // // // //               <Typography variant="h4" align="center" sx={{ mb: 3 }}>
// // // // //                 Sign up now
// // // // //               </Typography>

// // // // //               {/* Sign-in form */}
// // // // //               <AppProvider theme={theme}>
// // // // //                 <SignInPage
// // // // //                   signIn={signIn}
// // // // //                   providers={providers}
// // // // //                   slotProps={{ emailField: { autoFocus: false } }}
// // // // //                 />
// // // // //               </AppProvider>
// // // // //             </CardContent>
// // // // //           {/* </Card> */}
// // // // //         </Grid>
// // // // //       </Grid>
// // // // //     </Container>
// // // // //   );
// // // // // }

// // // // // export default Login1;

















// // // // import React from 'react';

// // // // import FormControlLabel from '@mui/material/FormControlLabel';
// // // // import Checkbox from '@mui/material/Checkbox';
// // // // import Link from '@mui/material/Link';
// // // // import Paper from '@mui/material/Paper';
// // // // import Box from '@mui/material/Box';
// // // // import Grid from '@mui/material/Grid';

// // // // import { makeStyles } from '@mui/styles';


// // // // import TextField from '@mui/material/TextField';
// // // // import Avatar from '@mui/material/Avatar';
// // // // import Button from '@mui/material/Button';
// // // // import CssBaseline from '@mui/material/CssBaseline';
// // // // import Typography from '@mui/material/Typography';
// // // // import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// // // // const login1 = "/img/login.jpeg"
// // // // const login2 = "/img/login2.jpeg"


// // // // function Copyright() {
// // // //   return (
// // // //     <Typography variant="body2" color="textSecondary" align="center">
// // // //       {'Copyright © '}
// // // //       <Link color="inherit" href="https://mui.com/">
// // // //         Your Website
// // // //       </Link>{' '}
// // // //       {new Date().getFullYear()}
// // // //       {'.'}
// // // //     </Typography>
// // // //   );
// // // // }

// // // // const useStyles = makeStyles((theme) => ({
// // // //   root: {
// // // //     height: '100vh',
// // // //   },
// // // //   image: {
// // // //     backgroundImage: `url(${login1})`,
// // // //     backgroundRepeat: 'no-repeat',
// // // //     backgroundColor:
// // // //       theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
// // // //     backgroundSize: 'cover',
// // // //     backgroundPosition: 'center',
// // // //   },

  

// // // //   paper: {
// // // //     margin: theme.spacing(8, 4),
// // // //     display: 'flex',
// // // //     flexDirection: 'column',
// // // //     alignItems: 'center',
// // // //   },
// // // //   avatar: {
// // // //     margin: theme.spacing(1),
// // // //     backgroundColor: theme.palette.secondary.main,
// // // //   },
// // // //   form: {
// // // //     width: '100%', // Fix IE 11 issue.
// // // //     marginTop: theme.spacing(1),
// // // //   },
// // // //   submit: {
// // // //     margin: theme.spacing(3, 0, 2),
// // // //   },
// // // // }));

// // // // export default function Login1() {
// // // //   const classes = useStyles();

// // // //   return (
// // // //     <Grid container component="main" className={classes.root}>
// // // //       <CssBaseline />
// // // //       <Grid item xs={false} sm={4} md={7} className={classes.image} >
// // // //       {/* <div className={classes.overlay}></div> */}
// // // //       </Grid>
// // // //       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
// // // //       <div className={classes.paper} style={{ marginTop: '16vh' }}>

// // // //           <Avatar className={classes.avatar}>
// // // //             <LockOutlinedIcon />
// // // //           </Avatar>
// // // //           <Typography component="h1" variant="h5">
// // // //             Sign in
// // // //           </Typography>
// // // //           <form className={classes.form} noValidate>
// // // //             <TextField
// // // //               variant="outlined"
// // // //               margin="normal"
// // // //               required
// // // //               fullWidth
// // // //               id="email"
// // // //               label="Email Address"
// // // //               name="email"
// // // //               autoComplete="email"
// // // //               autoFocus
// // // //             />
// // // //             <TextField
// // // //               variant="outlined"
// // // //               margin="normal"
// // // //               required
// // // //               fullWidth
// // // //               name="password"
// // // //               label="Password"
// // // //               type="password"
// // // //               id="password"
// // // //               autoComplete="current-password"
// // // //             />
// // // //             <FormControlLabel
// // // //               control={<Checkbox value="remember" color="primary" />}
// // // //               label="Remember me"
// // // //             />
// // // //             <Button
// // // //               type="submit"
// // // //               fullWidth
// // // //               variant="contained"
// // // //               color="primary"
// // // //               className={classes.submit}
// // // //             >
// // // //               Sign In
// // // //             </Button>
// // // //             <Grid container>
// // // //               <Grid item xs>
// // // //                 <Link href="#" variant="body2">
// // // //                   Forgot password?
// // // //                 </Link>
// // // //               </Grid>
// // // //               <Grid item>
// // // //                 <Link href="#" variant="body2">
// // // //                   {"Don't have an account? Sign Up"}
// // // //                 </Link>
// // // //               </Grid>
// // // //             </Grid>
// // // //             <Box mt={5}>
// // // //               <Copyright />
// // // //             </Box>
// // // //           </form>
// // // //         </div>
// // // //       </Grid>
// // // //     </Grid>
// // // //   );
// // // // }












// // // import React from 'react';

// // // import FormControlLabel from '@mui/material/FormControlLabel';
// // // import Checkbox from '@mui/material/Checkbox';
// // // import Link from '@mui/material/Link';
// // // import Paper from '@mui/material/Paper';
// // // import Box from '@mui/material/Box';
// // // import Grid from '@mui/material/Grid';

// // // import { makeStyles } from '@mui/styles';

// // // import TextField from '@mui/material/TextField';
// // // import Avatar from '@mui/material/Avatar';
// // // import Button from '@mui/material/Button';
// // // import CssBaseline from '@mui/material/CssBaseline';
// // // import Typography from '@mui/material/Typography';
// // // import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// // // const login1 = "/img/login.jpeg";
// // // const login2 = "/img/login2.jpeg";

// // // function Copyright() {
// // //   return (
// // //     <Typography variant="body2" color="textSecondary" align="center">
// // //       {'Copyright © '}
// // //       <Link color="inherit" href="https://mui.com/">
// // //         Your Website
// // //       </Link>{' '}
// // //       {new Date().getFullYear()}
// // //       {'.'}
// // //     </Typography>
// // //   );
// // // }

// // // const useStyles = makeStyles((theme) => ({
// // //   root: {
// // //     height: '100vh',
// // //     position: 'relative',
// // //     overflow: 'hidden',
// // //   },
// // //   image: {
// // //     backgroundImage: `url(${login1})`,
// // //     backgroundRepeat: 'no-repeat',
// // //     backgroundSize: 'cover',
// // //     backgroundPosition: 'center',
// // //     position: 'absolute',
// // //     top: 0,
// // //     left: 0,
// // //     right: 0,
// // //     bottom: 0,
// // //     zIndex: 1,
// // //   },
// // //   overlayImage: {
// // //     backgroundImage: `url(${login2})`,
// // //     backgroundRepeat: 'no-repeat',
// // //     backgroundSize: 'cover',
// // //     backgroundPosition: 'center',
// // //     position: 'absolute',
// // //     top: 0,
// // //     left: 0,
// // //     right: 0,
// // //     bottom: 0,
// // //     zIndex: 2,
// // //     opacity: 0.7, // Transparency for the second image
// // //     backgroundColor: 'rgba(0, 0, 0, 0.5)', // Darker overlay
// // //     mixBlendMode: 'multiply', // Ensures the darker tone blends with the image
// // //   },
// // //   paper: {
// // //     margin: theme.spacing(8, 4),
// // //     display: 'flex',
// // //     flexDirection: 'column',
// // //     alignItems: 'center',
// // //     zIndex: 3, // Ensures the form appears above the images
// // //     position: 'relative',
// // //   },
// // //   avatar: {
// // //     margin: theme.spacing(1),
// // //     backgroundColor: theme.palette.secondary.main,
// // //   },
// // //   form: {
// // //     width: '100%', // Fix IE 11 issue.
// // //     marginTop: theme.spacing(1),
// // //   },
// // //   submit: {
// // //     margin: theme.spacing(3, 0, 2),
// // //   },
// // // }));

// // // export default function Login1() {
// // //   const classes = useStyles();

// // //   return (
// // //     <Grid container component="main" className={classes.root}>
// // //       <CssBaseline />
// // //       <div className={classes.image}></div>
// // //       <div className={classes.overlayImage}></div>
// // //       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
// // //         <div className={classes.paper} style={{ marginTop: '16vh' }}>
// // //           <Avatar className={classes.avatar}>
// // //             <LockOutlinedIcon />
// // //           </Avatar>
// // //           <Typography component="h1" variant="h5">
// // //             Sign in
// // //           </Typography>
// // //           <form className={classes.form} noValidate>
// // //             <TextField
// // //               variant="outlined"
// // //               margin="normal"
// // //               required
// // //               fullWidth
// // //               id="email"
// // //               label="Email Address"
// // //               name="email"
// // //               autoComplete="email"
// // //               autoFocus
// // //             />
// // //             <TextField
// // //               variant="outlined"
// // //               margin="normal"
// // //               required
// // //               fullWidth
// // //               name="password"
// // //               label="Password"
// // //               type="password"
// // //               id="password"
// // //               autoComplete="current-password"
// // //             />
// // //             <FormControlLabel
// // //               control={<Checkbox value="remember" color="primary" />}
// // //               label="Remember me"
// // //             />
// // //             <Button
// // //               type="submit"
// // //               fullWidth
// // //               variant="contained"
// // //               color="primary"
// // //               className={classes.submit}
// // //             >
// // //               Sign In
// // //             </Button>
// // //             <Grid container>
// // //               <Grid item xs>
// // //                 <Link href="#" variant="body2">
// // //                   Forgot password?
// // //                 </Link>
// // //               </Grid>
// // //               <Grid item>
// // //                 <Link href="#" variant="body2">
// // //                   {"Don't have an account? Sign Up"}
// // //                 </Link>
// // //               </Grid>
// // //             </Grid>
// // //             <Box mt={5}>
// // //               <Copyright />
// // //             </Box>
// // //           </form>
// // //         </div>
// // //       </Grid>
// // //     </Grid>
// // //   );
// // // }









// // import React from 'react';

// // import FormControlLabel from '@mui/material/FormControlLabel';
// // import Checkbox from '@mui/material/Checkbox';
// // import Link from '@mui/material/Link';
// // import Paper from '@mui/material/Paper';
// // import Box from '@mui/material/Box';
// // import Grid from '@mui/material/Grid';

// // import { makeStyles } from '@mui/styles';

// // import TextField from '@mui/material/TextField';
// // import Avatar from '@mui/material/Avatar';
// // import Button from '@mui/material/Button';
// // import CssBaseline from '@mui/material/CssBaseline';
// // import Typography from '@mui/material/Typography';
// // import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// // const login1 = "/img/login.jpeg";
// // const login2 = "/img/login2.avif";

// // function Copyright() {
// //   return (
// //     <Typography variant="body2" color="textSecondary" align="center">
// //       {' © '}
// //       {/* <Link color="inherit" href="https://mui.com/">
// //         Your Website
// //       </Link>{' '} */}
// //       {new Date().getFullYear()}
// //       {' UTahData. All rights reserved.'}
// //     </Typography>
// //   );
// // }

// // const useStyles = makeStyles((theme) => ({
// //   root: {
// //     height: '100vh',
// //   },
// //   imageWrapper: {
// //     position: 'relative',
// //     height: '100%',
// //     overflow: 'hidden',
// //   },
// //   image: {
// //     backgroundImage: `url(${login1})`,
// //     backgroundRepeat: 'no-repeat',
// //     backgroundSize: 'cover',
// //     backgroundPosition: 'center',
// //     position: 'absolute',
// //     top: 0,
// //     left: 0,
// //     right: 0,
// //     bottom: 0,
// //     zIndex: 1,
// //   },
// //   overlayImage: {
// //     backgroundImage: `url(${login2})`,
// //     backgroundRepeat: 'no-repeat',
// //     backgroundSize: 'cover',
// //     backgroundPosition: 'center',
// //     position: 'absolute',
// //     top: 0,
// //     left: 0,
// //     right: 0,
// //     bottom: 0,
// //     zIndex: 2,
// //     opacity: 0.5, // Transparency for the second image
// //     backgroundColor: 'rgba(0, 0, 0, 0.5)', // Darker overlay
// //     mixBlendMode: 'multiply', // Ensures the darker tone blends with the image
// //   },
// //   paper: {
// //     margin: theme.spacing(8, 4),
// //     display: 'flex',
// //     flexDirection: 'column',
// //     alignItems: 'center',
// //   },
// //   avatar: {
// //     margin: theme.spacing(1),
// //     backgroundColor: theme.palette.secondary.main,
// //   },
// //   form: {
// //     width: '100%', // Fix IE 11 issue.
// //     marginTop: theme.spacing(1),
// //   },
// //   submit: {
// //     margin: theme.spacing(3, 0, 2),
// //   },
// // }));

// // export default function Login1() {
// //   const classes = useStyles();

// //   return (
// //     <Grid container component="main" className={classes.root}>
// //       <CssBaseline />
// //       {/* Left side with overlapping images */}
// //       <Grid item xs={false} sm={4} md={7} className={classes.imageWrapper}>
// //         <div className={classes.image}></div>
// //         <div className={classes.overlayImage}></div>
// //       </Grid>

// //       {/* Right side with form */}
// //       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
// //         <div className={classes.paper} style={{ marginTop: '16vh' }}>
// //           <Avatar className={classes.avatar}>
// //             <LockOutlinedIcon />
// //           </Avatar>
// //           <Typography component="h1" variant="h5">
// //             Sign in
// //           </Typography>
// //           <form className={classes.form} noValidate>
// //             <TextField
// //               variant="outlined"
// //               margin="normal"
// //               required
// //               fullWidth
// //               id="email"
// //               label="Email Address"
// //               name="email"
// //               autoComplete="email"
// //               autoFocus
// //             />
// //             <TextField
// //               variant="outlined"
// //               margin="normal"
// //               required
// //               fullWidth
// //               name="password"
// //               label="Password"
// //               type="password"
// //               id="password"
// //               autoComplete="current-password"
// //             />
// //             <FormControlLabel
// //               control={<Checkbox value="remember" color="primary" />}
// //               label="Remember me"
// //             />
// //             <Button
// //               type="submit"
// //               fullWidth
// //               variant="contained"
// //               color="primary"
// //               className={classes.submit}
// //             >
// //               Sign In
// //             </Button>
// //             <Grid container>
// //               <Grid item xs>
// //                 <Link href="#" variant="body2">
// //                   Forgot password?
// //                 </Link>
// //               </Grid>
// //               <Grid item>
// //                 <Link href="#" variant="body2">
// //                   {"Don't have an account? Sign Up"}
// //                 </Link>
// //               </Grid>
// //             </Grid>
// //             <Box mt={5}>
// //               <Copyright />
// //             </Box>
// //           </form>
// //         </div>
// //       </Grid>
// //     </Grid>
// //   );
// // }














// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom'; // Import for navigation
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { makeStyles } from '@mui/styles';
// import TextField from '@mui/material/TextField';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import Typography from '@mui/material/Typography';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// const login1 = "/img/login.jpeg";
// const login2 = "/img/login2.avif";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {' © '}
//       {new Date().getFullYear()}
//       {' UTahData. All rights reserved.'}
//     </Typography>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '83vh', // Default height for large screens
//     [theme.breakpoints.down('lg')]: { // For screens smaller than 'lg'
//       height: '90vh', // Adjust height for medium screens
//     },
//     [theme.breakpoints.down('md')]: { // For screens smaller than 'md'
//       height: '90vh', // Adjust height for smaller screens
//     },
//     [theme.breakpoints.down('sm')]: { // For small screens (mobile devices)
//       height: '90vh', // Adjust height for mobile screens
//     },
//   },
//   imageWrapper: {
//     position: 'relative',
//     height: '100%',
//     overflow: 'hidden',
//   },
//   image: {
//     backgroundImage: `url(${login1})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     zIndex: 1,
//   },
//   overlayImage: {
//     backgroundImage: `url(${login2})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     zIndex: 2,
//     opacity: 0.5,
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     mixBlendMode: 'multiply',
//   },
//   paper: {
//     margin: theme.spacing(8, 4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%',
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));

// export default function Login1() {
//   const classes = useStyles();
//   const navigate = useNavigate(); // For navigation

//   // Hardcoded user data
//   const users = [
//     {name: "Akash" , email: "akash@bodex.io", password: "12345" },
//     {name: "Rajesh" ,  email: "rajesh@bodex.io", password: "12345" },
//     {name: "Sumit" ,  email: "sumit@bodex.io", password: "12345" },
//   ];

//   // State for form inputs
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');

//   // Form submission handler
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Check if the credentials match
//     const user = users.find((u) => u.email === email && u.password === password);

//     if (user) {
//       // Redirect to the dashboard or success page
//       localStorage.setItem("user", JSON.stringify(user));
//       navigate("/login/dashboard", { state: { name: user.name } });// Replace '/dashboard' with your actual route
//     } else {
//       // Show error message
//       setError('Invalid email or password');
//     }
//   };

//   return (
//     <Grid container component="main" className={classes.root} >
//       <CssBaseline />
//       {/* Left side with overlapping images */}
//       <Grid item xs={false} sm={4} md={7} className={classes.imageWrapper}>
//         <div className={classes.image}></div>
//         <div className={classes.overlayImage}></div>
//       </Grid>

//       {/* Right side with form */}
//       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//         <div className={classes.paper} style={{ marginTop: '16vh' }}>
//           <Avatar className={classes.avatar}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign in
//           </Typography>
//           <form className={classes.form} noValidate onSubmit={handleSubmit}>
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//               value={email}
//               onChange={(e) => setEmail(e.target.value)} // Update email state
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)} // Update password state
//             />
//             {error && (
//               <Typography color="error" variant="body2">
//                 {error}
//               </Typography>
//             )}
//             <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               color="primary"
//               className={classes.submit}
//             >
//               Sign In
//             </Button>
//             <Grid container>
//               <Grid item xs>
//                 <Link href="#" variant="body2">
//                   Forgot password?
//                 </Link>
//               </Grid>
//               {/* <Grid item>
//                 <Link href="#" variant="body2">
//                   {"Don't have an account? Sign Up"}
//                 </Link>
//               </Grid> */}
//             </Grid>
//             <Box mt={5}>
//               <Copyright />
//             </Box>
//           </form>
//         </div>
//       </Grid>
//     </Grid>
//   );
// }













import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import for navigation
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import {toast} from 'react-toastify'

const loginBackground = "/img/login.jpeg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {" © "}
      {new Date().getFullYear()}
      {" UTahData. All rights reserved."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
    
          height: '83vh', // Default height for large screens
          [theme.breakpoints.down('lg')]: { // For screens smaller than 'lg'
            height: '104vh', // Adjust height for medium screens
          },
          [theme.breakpoints.down('md')]: { // For screens smaller than 'md'
            height: '90vh', // Adjust height for smaller screens
          },
          [theme.breakpoints.down('sm')]: { // For small screens (mobile devices)
            height: '90vh', // Adjust height for mobile screens
          },
     
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay for better text visibility
    zIndex: 1,
  },
  paper: {
    position: "relative",
    zIndex: 2,
    padding: theme.spacing(4),
    borderRadius: "8px",
    backgroundColor: "white",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "400px",
    width: "90%", // Responsive width
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login1() {
  const classes = useStyles();
  const navigate = useNavigate(); // For navigation

  // Hardcoded user data
  const users = [
    { name: "Akash", email: "akash@bodex.io", password: "12345" },
    { name: "Rajesh", email: "rajesh@bodex.io", password: "12345" },
    { name: "Sumit", email: "sumit@bodex.io", password: "12345" },
  ];

  // State for form inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [userData, setUserDataState] = useState(() => {
    const storedData = localStorage.getItem("userData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(!!userData);
  const setUserData = (data) => {
    setUserDataState(data);
    setIsLoggedin(!!data);
  };
  const getUserData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`https://47g.utahdata.org/47gauthen/user/data`, {
        withCredentials: true
      });
      console.log(data)
      if (data.success) {
        setUserData(data.userData);
    
          localStorage.setItem("userData", JSON.stringify(data.userData));

        // fetchAllRecordsInBackground();
      } else {
        // toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setIsLoading(false);
    }
  };
  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    //   // Attempt login via external API
    //   const response = await axios.post(
    //     "http://167.71.21.196:3000/api/auth/login",
    //     { email, password },
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json", // Ensures JSON format
    //         "Accept": "application/json",       // Accepts JSON response
    //       },
    //       withCredentials: true, // Required if the API uses cookies
    //     }
    //   );
    // console.log(response)
    //   if (response.data.success) {
    //     console.log(response.data.success)
    //     // If external API login is successful, redirect to the external home page
    //     // localStorage.setItem("user", JSON.stringify({ email, token: response.data.token }));
    //     window.location.href = "http://167.71.21.196:5173/home"; 
    //     return;
    //   }
         // Attempt login via external API
         const response = await axios.post(
          "https://47g.utahdata.org/47gauthen/auth/login",
          { email, password },
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Ensures JSON format
              "Accept": "application/json",       // Accepts JSON response
            },
            withCredentials: true, // Required if the API uses cookies
          }
        );
      console.log(response)
        if (response.data.success) {
          console.log(response.data.success)
          getUserData()
          if (!isLoading) {
            window.open('https://47g.utahdata.org/home', '_self');

        }
          // .then(() => {    
          //   window.location.href = "https://47g.utahdata.org/home"; 
          // })
          // If external API login is successful, redirect to the external home page
          // localStorage.setItem("user", JSON.stringify({ email, token: response.data.token }));
          
          return;
        }
    } catch (err) {
      console.log("External login failed, checking local users...");
    }

    // Check if the credentials match local users
    const user = users.find((u) => u.email === email && u.password === password);

    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/login/dashboard", { state: { name: user.name } });
    } else {
      setError("Invalid email or password");
    }
    // Check if the credentials match
    // const user = users.find((u) => u.email === email && u.password === password);

    // if (user) {
    //   // Redirect to the dashboard or success page
    //   localStorage.setItem("user", JSON.stringify(user));
    //   navigate("/login/dashboard", { state: { name: user.name } }); // Replace '/dashboard' with your actual route
    // } else {
    //   // Show error message
    //   setError("Invalid email or password");
    // }
  };

  return (
    <div className={classes.root}>
      <div className={classes.overlay}></div>
      
      <Paper className={classes.paper} style={{
        marginTop:"9vh"
      }}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </Paper>
    </div>
  );
}
