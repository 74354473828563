import React from 'react'

export default function School3() {

    const schoolData = 
    [
        {
          "schoolName": "Bear River High",
          "numberOfStudents": 1172,
          "grades": "10-12"
        },
        {
          "schoolName": "Bear River Middle",
          "numberOfStudents": 843,
          "grades": "8-10"
        },
        {
          "schoolName": "Box Elder High",
          "numberOfStudents": 1583,
          "grades": "10-12"
        },
        {
          "schoolName": "Box Elder Middle",
          "numberOfStudents": 1153,
          "grades": "8-9"
        },
        {
          "schoolName": "Century School",
          "numberOfStudents": 491,
          "grades": "KG-5"
        },
        {
          "schoolName": "Corinne Early Learning Center",
          "numberOfStudents": 0,
          "grades": "PK-PK"
        },
        {
          "schoolName": "Discovery School",
          "numberOfStudents": 466,
          "grades": "KG-5"
        },
        {
          "schoolName": "Fielding School",
          "numberOfStudents": 579,
          "grades": "KG-5"
        },
        {
          "schoolName": "Garland School",
          "numberOfStudents": 660,
          "grades": "KG-5"
        },
        {
          "schoolName": "Golden Spike Elementary",
          "numberOfStudents": 838,
          "grades": "PK-5"
        },
        {
          "schoolName": "Grouse Creek Elementary",
          "numberOfStudents": 5,
          "grades": "KG-6"
        },
        {
          "schoolName": "Grouse Creek Secondary",
          "numberOfStudents": 1,
          "grades": "7-10"
        },
        {
          "schoolName": "Harris Intermediate",
          "numberOfStudents": 813,
          "grades": "6-7"
        },
        {
          "schoolName": "Lake View School",
          "numberOfStudents": 609,
          "grades": "KG-5"
        },
        {
          "schoolName": "Mckinley School",
          "numberOfStudents": 607,
          "grades": "PK-5"
        },
        {
          "schoolName": "North Park School",
          "numberOfStudents": 635,
          "grades": "PK-5"
        },
        {
          "schoolName": "Park Valley Elementary",
          "numberOfStudents": 22,
          "grades": "KG-6"
        },
        {
          "schoolName": "Park Valley Secondary",
          "numberOfStudents": 5,
          "grades": "7-10"
        },
        {
          "schoolName": "Snowville School",
          "numberOfStudents": 27,
          "grades": "KG-5"
        },
        {
          "schoolName": "Sunrise High School",
          "numberOfStudents": 159,
          "grades": "5-12"
        },
        {
          "schoolName": "Three Mile Creek School",
          "numberOfStudents": 604,
          "grades": "2-5"
        },
        {
          "schoolName": "Willard School",
          "numberOfStudents": 298,
          "grades": "PK-1"
        },
        {
          "schoolName": "Young Intermediate",
          "numberOfStudents": 1079,
          "grades": "6-7"
        }
      ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Box Elder
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
