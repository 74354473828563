


import React, { useState } from "react";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button, Offcanvas } from 'react-bootstrap';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const logo = "/img/utah-job-logo.png"

// Data for the chart
const data = [
  { county: "BEAVER", estimate: 2425, actual: 2495, percentChange: -2.8 },
  { county: "BOX ELDER", estimate: 24316, actual: 24124, percentChange: 0.8 },
  { county: "CACHE", estimate: 68860, actual: 68225, percentChange: 0.9 },
  { county: "CARBON", estimate: 9018, actual: 8906, percentChange: 1.3 },
  { county: "DAGGETT", estimate: 452, actual: 415, percentChange: 8.9 },
  { county: "DAVIS", estimate: 147810, actual: 146757, percentChange: 0.7 },
  { county: "DUCHESNE", estimate: 8981, actual: 8839, percentChange: 1.6 },
  { county: "EMERY", estimate: 3866, actual: 3685, percentChange: 4.9 },
  { county: "GARFIELD", estimate: 2595, actual: 2549, percentChange: 1.8 },
  { county: "GRAND", estimate: 7304, actual: 7230, percentChange: 1.0 },
  { county: "IRON", estimate: 26409, actual: 26112, percentChange: 1.1 },
  { county: "JUAB", estimate: 4482, actual: 4342, percentChange: 3.2 },
  { county: "KANE", estimate: 4164, actual: 4172, percentChange: -0.2 },
  { county: "MILLARD", estimate: 5122, actual: 4891, percentChange: 4.7 },
  { county: "MORGAN", estimate: 3288, actual: 3065, percentChange: 7.3 },
  { county: "PIUTE", estimate: 309, actual: 307, percentChange: 0.7 },
  { county: "RICH", estimate: 1092, actual: 1086, percentChange: 0.6 },
  { county: "SALT LAKE", estimate: 813742, actual: 806942, percentChange: 0.8 },
  { county: "SAN JUAN", estimate: 4746, actual: 4744, percentChange: 0.0 },
  { county: "SANPETE", estimate: 9926, actual: 10140, percentChange: -2.1 },
  { county: "SEVIER", estimate: 10033, actual: 9897, percentChange: 1.4 },
  { county: "SUMMIT", estimate: 28512, actual: 28316, percentChange: 0.7 },
  { county: "TOOELE", estimate: 20644, actual: 19486, percentChange: 5.9 },
  { county: "UINTAH", estimate: 15730, actual: 14791, percentChange: 6.3 },
  { county: "UTAH", estimate: 316664, actual: 310753, percentChange: 1.9 },
  { county: "WASATCH", estimate: 12078, actual: 12259, percentChange: -1.5 },
  { county: "WASHINGTON", estimate: 89344, actual: 86325, percentChange: 3.5 },
  { county: "WAYNE", estimate: 1300, actual: 1272, percentChange: 2.2 },
  { county: "WEBER", estimate: 124936, actual: 123823, percentChange: 0.9 },
];

const UtahJobs = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#d2eaff] p-4">
      {/* Heading */}
      <div className="w-full max-w-7xl mt-[7rem] text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6">
          County Employment and Growth Rate
        </h2>
        <div className="text-2xl">(1.77 million September 2024)</div>
      </div>

      {/* Chart with Horizontal Scroll */}
      <div className="w-full max-w-7xl p-4 mb-20 overflow-x-auto">
        <div className="min-w-[1000px] w-full">
          <ResponsiveContainer width="100%" height={800} >
          <ComposedChart data={data} margin={{ top: 20, right: 20, left: 30, bottom: 52 }}>
  <CartesianGrid strokeDasharray="3 3" />
  
  {/* X Axis */}
  <XAxis 
    dataKey="county" 
    interval={0} 
    angle={-45} 
    dy={15} 
    dx={-5} 
    textAnchor="end" 
    style={{ fontSize: '12px' }} 
  />
  
  {/* Left Y Axis for estimate and actual values */}
  <YAxis 
    yAxisId="left"
    type="number"
    domain={['auto', 'auto']} // Auto-scale based on the data
  />
  
  {/* Right Y Axis for percentChange */}
  {/* <YAxis 
    yAxisId="right"
    orientation="right"
    type="number"
    domain={[-8, 8]} // Set fixed range for percentChange between -8 and 8
    tickFormatter={(tick) => `${tick}`} // Format as normal for percentage values
  /> */}
  
  <Tooltip />
  <Legend
    layout="vertical"
    align="right"
    verticalAlign="top"
    iconSize={20}
    iconType="circle"
  />
  
  {/* Bar for Estimate and Actual */}
  <Bar dataKey="estimate" fill="#8884d8" name="October 2024 Estimate" yAxisId="left" />
  <Bar dataKey="actual" fill="#82ca9d" name="October 2023 Actual" yAxisId="left" />
  
  {/* Line for Percent Change */}
  {/* <Line 
    type="monotone" 
    dataKey="percentChange" 
    stroke="#ff7300" 
    strokeWidth={2} 
    name="Percent Change"
    yAxisId="right" 
  /> */}
</ComposedChart>

          </ResponsiveContainer>
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col md:flex-row justify-between items-center w-full max-w-7xl !mb-[1rem] gap-4 px-4">
        <div className="text-xs sm:text-sm text-center md:text-left flex ">
          <span className="mr-[1.5rem] mt-[1.5rem]">Source:</span>
          {/* <a
            href="https://jobs.utah.gov/wi/update/index.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            www.jobs.utah.gov
          </a> */}
          <img src={logo}></img>
        </div>
        <div className="flex justify-center md:justify-end w-full md:w-auto">
          <Button variant="primary" onClick={handleShow}>
            This Chart Described <ArrowForwardIosIcon style={{ fontSize: 20 }} />
          </Button>
        </div>
      </div>

      {/* Offcanvas */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>This chart describes</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h4 className="font-bold mb-3">Employment and Job Growth Analysis</h4>
          <p>
            This chart provides a comprehensive overview of employment trends and
            job growth across various counties in Utah. It visualizes the
            following key metrics:
          </p>
          <ul className="list-disc ml-5">
            <li>
              <strong>Employment Numbers:</strong>
              <ul className="list-disc ml-5">
                <li>
                  <strong>October 2024</strong> and <strong>October 2023</strong>{" "}
                  data are depicted as bars, showcasing the year-over-year
                  employment levels in each county.
                </li>
              </ul>
            </li>
            <li>
              <strong>Percent Change:</strong>
              <p>
                Represented as a line graph, the percent change illustrates the
                growth or decline in employment rates across different time
                frames.
              </p>
            </li>
          </ul>
          <p>
            This chart helps analyze regional employment dynamics and growth trends
            in Utah’s counties.
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default UtahJobs;
