import React from 'react'

export default function School9() {

    const schoolData = 
    [
        {
          "schoolName": "Altamont High",
          "numberOfStudents": 254,
          "grades": "7-12"
        },
        {
          "schoolName": "Altamont School",
          "numberOfStudents": 359,
          "grades": "PK-6"
        },
        {
          "schoolName": "Basin Online School",
          "numberOfStudents": 54,
          "grades": "KG-12"
        },
        {
          "schoolName": "Centennial School",
          "numberOfStudents": 524,
          "grades": "KG-5"
        },
        {
          "schoolName": "Con Amore School",
          "numberOfStudents": 68,
          "grades": "PK-12"
        },
        {
          "schoolName": "Duchesne High",
          "numberOfStudents": 396,
          "grades": "7-12"
        },
        {
          "schoolName": "Duchesne School",
          "numberOfStudents": 442,
          "grades": "PK-6"
        },
        {
          "schoolName": "East School",
          "numberOfStudents": 439,
          "grades": "PK-5"
        },
        {
          "schoolName": "Kings Peak School",
          "numberOfStudents": 462,
          "grades": "KG-5"
        },
        {
          "schoolName": "Myton School",
          "numberOfStudents": 122,
          "grades": "KG-5"
        },
        {
          "schoolName": "Neola School",
          "numberOfStudents": 148,
          "grades": "KG-5"
        },
        {
          "schoolName": "Roosevelt Jr High",
          "numberOfStudents": 829,
          "grades": "6-8"
        },
        {
          "schoolName": "Tabiona High",
          "numberOfStudents": 72,
          "grades": "7-12"
        },
        {
          "schoolName": "Tabiona School",
          "numberOfStudents": 113,
          "grades": "KG-6"
        },
        {
          "schoolName": "Union High",
          "numberOfStudents": 1153,
          "grades": "9-12"
        }
      ]
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Duchesne
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
