import React from "react";
import VotersChart from "./InActiveVotersChart";

const votersData = [
  {
    Fields: "Constitution",
    "No. of Voters": "1,531",
  },
  {
    Fields: "Democratic",
    "No. of Voters": "39,510",
  },
  {
    Fields: "Green",
    "No. of Voters": "15",
  },
  {
    Fields: "Independent American",
    "No. of Voters": "15,676",
  },
  {
    Fields: "Libertarian",
    "No. of Voters": "5,279",
  },
  {
    Fields: "No Labels",
    "No. of Voters": "309",
  },
  {
    Fields: "Republican",
    "No. of Voters": "87,091",
  },
  {
    Fields: "United Utah",
    "No. of Voters": "787",
  },
  {
    Fields: "Utah Forward",
    "No. of Voters": "2",
  },
  {
    Fields: "Unaffiliated",
    "No. of Voters": "1,06,878",
  },
  {
    Fields: "Total Inactive Voters",
    "No. of Voters": "2,57,078",
  },
];

const InActiveVoters = () => {
  return (
    <div
      style={{
        background: "#1e293b",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "14vh",
      }}
    >
      <h1 style={{ color: "#ffc107", marginBottom: "20px" }}>
        Inactive Voters Chart
      </h1>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            minWidth: "600px",
          }}
        >
          <VotersChart data={votersData} />
        </div>
      </div>
    </div>
  );
};

export default InActiveVoters;
