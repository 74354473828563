// // import React from "react";
// // import "../styles/UtahMap.css";

// // const map = "/img/utah-map.png"

// // const UtahMap = () => {
// //   const totalPopulation = "3,417,734"; // Replace with dynamic data if needed

// //   return (
// //     <div className="utah-map-container">
// //       <img
// //         src={map} // Replace with the actual image path
// //         alt="Utah Map"
// //         className="utah-map-image"
// //       />
// //       <div className="population-overlay">
// //         <h2>Total Utah Population:</h2>
// //         <p>{totalPopulation}</p>
// //         <h2>Total area in Utah:</h2>
// //         <p>84,899 sq mi </p>
// //       </div>
// //     </div>
// //   );
// // };

// // export default UtahMap;
















// import React from "react";
// import "../styles/UtahMap.css";

// const map = "/img/utah-map.png";

// const UtahMap = () => {
//   const totalPopulation = "3,417,734"; // Replace with dynamic data if needed

//   return (
//     <div className="utah-map-container">
//       <img
//         src={map} // Replace with the actual image path
//         alt="Utah Map"
//         className="utah-map-image"
//       />
//       <div className="population-overlay">
//         <h2>Total Utah Population:</h2>
//         <p>{totalPopulation}</p>
//         <h2>Total Area in Utah:</h2>
//         <p>84,899 sq mi</p>
//       </div>
//     </div>
//   );
// };

// export default UtahMap;














import React from "react";
import "../styles/UtahMap.css";

const map = "/img/utah-map.png";

const UtahMap = () => {
  const totalPopulation = "3,417,734";
  const totalArea = "84,899 sq mi";

  return (
    <div className="utah-map-container">
      <img
        src={map}
        alt="Utah Map"
        className="utah-map-image"
      />
      <div className="population-overlay">
        <h2> Utah Population:</h2>
        <p>{totalPopulation}</p>
        <h2> Area in Utah:</h2>
        <p>{totalArea}</p>
      </div>
    </div>
  );
};

export default UtahMap;
