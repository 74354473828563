import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";

import "../styles/AerospaceFaqSection.css";

import config from "../config/config.json";

import CsAndItTable1 from "../CsAndItTables/CsAndItTable1";
import CsAndItTable2 from "../CsAndItTables/CsAndItTable2";
import CsAndItTable3 from "../CsAndItTables/CsAndItTable3";
import CsAndItTable4 from "../CsAndItTables/CsAndItTable4";
import CsAndItTable5 from "../CsAndItTables/CsAndItTable5";
import CsAndItTable6 from "../CsAndItTables/CsAndItTable6";
import CsAndItTable7 from "../CsAndItTables/CsAndItTable7";
import CsAndItTable8 from "../CsAndItTables/CsAndItTable8";
import CsAndItTable9 from "../CsAndItTables/CsAndItTable9";
import CsAndItTable10 from "../CsAndItTables/CsAndItTable10";
import CsAndItTable11 from "../CsAndItTables/CsAndItTable11";
import CsAndItTable12 from "../CsAndItTables/CsAndItTable12";
import CsAndItTable13 from "../CsAndItTables/CsAndItTable13";
import CsAndItTable14 from "../CsAndItTables/CsAndItTable14";
import CsAndItTable15 from "../CsAndItTables/CsAndItTable15";
import CsAndItTable16 from "../CsAndItTables/CsAndItTable16";
import CsAndItTable17 from "../CsAndItTables/CsAndItTable17";
import CsAndItTable18 from "../CsAndItTables/CsAndItTable18";
import CsAndItTable19 from "../CsAndItTables/CsAndItTable19";
import CsAndItTable20 from "../CsAndItTables/CsAndItTable20";
import CsAndItTable21 from "../CsAndItTables/CsAndItTable21";
import CsAndItTable22 from "../CsAndItTables/CsAndItTable22";
import CsAndItTable23 from "../CsAndItTables/CsAndItTable23";
import CsAndItTable24 from "../CsAndItTables/CsAndItTable24";
import CsAndItTable25 from "../CsAndItTables/CsAndItTable25";

const CsAndItFaqSecton = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Employment-Trend");

  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [expandedSubQuestion, setExpandedSubQuestion] = useState(null);

  const [data_it_get_employment_growth_1, set_data_it_get_employment_growth_1] =
    useState(null);
  const [
    data_it_get_employment_difference_2,
    set_data_it_get_employment_difference_2,
  ] = useState(null);
  const [
    data_it_get_employment_male_female_ratio_3,
    set_data_it_get_employment_male_female_ratio_3,
  ] = useState(null);
  const [
    data_it_get_employment_avg_female_male_4,
    set_data_it_get_employment_avg_female_male_4,
  ] = useState(null);
  const [
    data_it_get_employment_female_male_share_5,
    set_data_it_get_employment_female_male_share_5,
  ] = useState(null);
  const [
    data_it_get_employment_share_by_age_group_6,
    set_data_it_get_employment_share_by_age_group_6,
  ] = useState(null);
  const [
    data_it_get_employment_growth_by_age_7,
    set_data_it_get_employment_growth_by_age_7,
  ] = useState(null);
  const [
    data_it_get_employment_change_by_age_8,
    set_data_it_get_employment_change_by_age_8,
  ] = useState(null);
  const [
    data_it_get_employment_growth_45_54_9,
    set_data_it_get_employment_growth_45_54_9,
  ] = useState(null);
  const [
    data_it_get_employment_growth_22_24_10,
    set_data_it_get_employment_growth_22_24_10,
  ] = useState(null);
  const [
    data_it_get_edu_attainment_na_11,
    set_data_it_get_edu_attainment_na_11,
  ] = useState(null);
  const [
    data_it_get_employment_by_education_12,
    set_data_it_get_employment_by_education_12,
  ] = useState(null);
  const [
    data_it_get_employment_by_sex_ratio_13,
    set_data_it_get_employment_by_sex_ratio_13,
  ] = useState(null);
  const [
    data_it_get_employment_by_education_14,
    set_data_it_get_employment_by_education_14,
  ] = useState(null);
  const [
    data_it_get_employment_by_education_all_15,
    set_data_it_get_employment_by_education_all_15,
  ] = useState(null);
  const [
    data_it_get_employment_high_school_16,
    set_data_it_get_employment_high_school_16,
  ] = useState(null);
  const [
    data_it_get_employment_by_education_17,
    set_data_it_get_employment_by_education_17,
  ] = useState(null);
  const [
    data_it_get_employment_by_education_18,
    set_data_it_get_employment_by_education_18,
  ] = useState(null);
  const [
    data_it_get_employment_by_race_19,
    set_data_it_get_employment_by_race_19,
  ] = useState(null);
  const [
    data_it_get_employment_by_race_change_20,
    set_data_it_get_employment_by_race_change_20,
  ] = useState(null);
  const [
    data_it_get_employment_growth_by_race_21,
    set_data_it_get_employment_growth_by_race_21,
  ] = useState(null);
  const [
    data_it_get_employment_avg_by_race_22,
    set_data_it_get_employment_avg_by_race_22,
  ] = useState(null);
  const [
    data_it_get_employment_by_race_23,
    set_data_it_get_employment_by_race_23,
  ] = useState(null);
  const [data_it_yoy_change_by_race_24, set_data_it_yoy_change_by_race_24] =
    useState(null);
  const [
    data_it_pandemic_year_change_by_race_25,
    set_data_it_pandemic_year_change_by_race_25,
  ] = useState(null);

  useEffect(() => {
    axios
      .get(config.APP_URL + "/csAndIt/csAndIt_verticals")
      .then((response) => {
        if (response.data.success) {
          set_data_it_get_employment_growth_1(
            response.data.data.data_it_get_employment_growth_1
          );
          set_data_it_get_employment_difference_2(
            response.data.data.data_it_get_employment_difference_2
          );
          set_data_it_get_employment_male_female_ratio_3(
            response.data.data.data_it_get_employment_male_female_ratio_3
          );
          set_data_it_get_employment_avg_female_male_4(
            response.data.data.data_it_get_employment_avg_female_male_4
          );
          set_data_it_get_employment_female_male_share_5(
            response.data.data.data_it_get_employment_female_male_share_5
          );
          set_data_it_get_employment_share_by_age_group_6(
            response.data.data.data_it_get_employment_share_by_age_group_6
          );
          set_data_it_get_employment_growth_by_age_7(
            response.data.data.data_it_get_employment_growth_by_age_7
          );
          set_data_it_get_employment_change_by_age_8(
            response.data.data.data_it_get_employment_change_by_age_8
          );
          set_data_it_get_employment_growth_45_54_9(
            response.data.data.data_it_get_employment_growth_45_54_9
          );
          set_data_it_get_employment_growth_22_24_10(
            response.data.data.data_it_get_employment_growth_22_24_10
          );
          set_data_it_get_edu_attainment_na_11(
            response.data.data.data_it_get_edu_attainment_na_11
          );
          set_data_it_get_employment_by_education_12(
            response.data.data.data_it_get_employment_by_education_12
          );
          set_data_it_get_employment_by_sex_ratio_13(
            response.data.data.data_it_get_employment_by_sex_ratio_13
          );
          set_data_it_get_employment_by_education_14(
            response.data.data.data_it_get_employment_by_education_14
          );
          set_data_it_get_employment_by_education_all_15(
            response.data.data.data_it_get_employment_by_education_all_15
          );
          set_data_it_get_employment_high_school_16(
            response.data.data.data_it_get_employment_high_school_16
          );
          set_data_it_get_employment_by_education_17(
            response.data.data.data_it_get_employment_by_education_17
          );
          set_data_it_get_employment_by_education_18(
            response.data.data.data_it_get_employment_by_education_18
          );
          set_data_it_get_employment_by_race_19(
            response.data.data.data_it_get_employment_by_race_19
          );
          set_data_it_get_employment_by_race_change_20(
            response.data.data.data_it_get_employment_by_race_change_20
          );
          set_data_it_get_employment_growth_by_race_21(
            response.data.data.data_it_get_employment_growth_by_race_21
          );
          set_data_it_get_employment_avg_by_race_22(
            response.data.data.data_it_get_employment_avg_by_race_22
          );
          set_data_it_get_employment_by_race_23(
            response.data.data.data_it_get_employment_by_race_23
          );
          set_data_it_yoy_change_by_race_24(
            response.data.data.data_it_yoy_change_by_race_24
          );
          set_data_it_pandemic_year_change_by_race_25(
            response.data.data.data_it_pandemic_year_change_by_race_25
          );
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const question = searchParams.get("question");

    if (tab) setActiveTab(tab);
    if (question) setExpandedQuestion(decodeURIComponent(question));
  }, [location.search]);

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setExpandedQuestion(null); // Reset expanded question when switching tabs
  };

  return (
    <div className="w-[90vw] h-auto p-1 pt-4 mt-[3rem] border border-gray-300 rounded-lg shadow-lg  justify-center items-center mx-auto">
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        {/* employment trend */}
        <Tab
          eventKey="Employment-Trend"
          title="Employment Trend"
          id="Employment-Trend"
        >
          <Accordion>
            <Accordion.Item
              eventKey="1. What is the annual growth rate of employees in the Information field from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "1. What is the annual growth rate of employees in the Information field from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the annual growth rate of employees in the
                  Information field from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_growth_1 ? (
                  <CsAndItTable1 data={data_it_get_employment_growth_1} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="2. What is the difference in total employment between consecutive years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "2. What is the difference in total employment between consecutive years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the difference in total employment between
                  consecutive years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_difference_2 ? (
                  <CsAndItTable2 data={data_it_get_employment_difference_2} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="3. How does the male-to-female ratio change over time?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "3. How does the male-to-female ratio change over time?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How does the male-to-female ratio change over time?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_male_female_ratio_3 ? (
                  <CsAndItTable3
                    data={data_it_get_employment_male_female_ratio_3}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="4. What is the average number of employees per year for males and females from the year 2018?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "4. What is the average number of employees per year for males and females from the year 2018?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the average number of employees per year for males
                  and females from the year 2018?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_avg_female_male_4 ? (
                  <CsAndItTable4
                    data={data_it_get_employment_avg_female_male_4}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="5. In which year was the highest percentage increase in employment recorded?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "5. In which year was the highest percentage increase in employment recorded?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. In which year was the highest percentage increase in
                  employment recorded?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_female_male_share_5 ? (
                  <CsAndItTable5
                    data={data_it_get_employment_female_male_share_5}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {/* Age Analysis */}
        <Tab eventKey="Age Analysis" title="Age Analysis" id="Age-Analysis">
          {/* <Accordion defaultActiveKey={['0']} alwaysOpen className="w-full"> */}
          <Accordion>
            <Accordion.Item
              eventKey="1. What is the percentage share of males and females in each age group during 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "1. What is the percentage share of males and females in each age group during 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the percentage share of males and females in each
                  age group during 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_share_by_age_group_6 ? (
                  <CsAndItTable6
                    data={data_it_get_employment_share_by_age_group_6}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="2. Which age group showed the fastest growth in the number of workers from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "2. Which age group showed the fastest growth in the number of workers from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Which age group showed the fastest growth in the number of
                  workers from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_growth_by_age_7 ? (
                  <CsAndItTable7
                    data={data_it_get_employment_growth_by_age_7}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="3. How does male employment differ between the 14-18 and 22-24 age groups from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "3. How does male employment differ between the 14-18 and 22-24 age groups from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How does male employment differ between the 14-18 and 22-24
                  age groups from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_change_by_age_8 ? (
                  <CsAndItTable8
                    data={data_it_get_employment_change_by_age_8}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="4. What is the year-over-year growth rate for the 45-54 age group?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "4. What is the year-over-year growth rate for the 45-54 age group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the year-over-year growth rate for the 45-54 age
                  group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_growth_45_54_9 ? (
                  <CsAndItTable9 data={data_it_get_employment_growth_45_54_9} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="5. What was the percentage increase in Female employee count for the 22-24 age group from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "5. What was the percentage increase in Female employee count for the 22-24 age group from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What was the percentage increase in Female employee count
                  for the 22-24 age group from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_growth_22_24_10 ? (
                  <CsAndItTable10
                    data={data_it_get_employment_growth_22_24_10}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {/* Education Analysis */}
        <Tab
          eventKey="Education Analysis"
          title="Education Analysis"
          id="Education-Analysis"
        >
          <Accordion>
            <Accordion.Item
              eventKey='1. How has the Educational attainment not available" category changed over the years?'
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  '1. How has the "Educational attainment not available" category changed over the years?'
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. How has the "Educational attainment not available" category
                  changed over the years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_edu_attainment_na_11 ? (
                  <CsAndItTable11 data={data_it_get_edu_attainment_na_11} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="2. How did the percentage of the population with a Bachelor's degree or above change annually?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "2. How did the percentage of the population with a Bachelor's degree or above change annually?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. How did the percentage of the population with a Bachelor's
                  degree or above change annually?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_education_12 ? (
                  <CsAndItTable12
                    data={data_it_get_employment_by_education_12}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey='3. What was the ratio of employed males to employed females with a "Some college or Associate degree" in 2023?'
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  '3. What was the ratio of employed males to employed females with a "Some college or Associate degree" in 2023?'
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What was the ratio of employed males to employed females
                  with a "Some college or Associate degree" in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_sex_ratio_13 ? (
                  <CsAndItTable13
                    data={data_it_get_employment_by_sex_ratio_13}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey='4. Compare the population trends of Bachelors degree holders and "Educational attainment not available" workers.'
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  `4. Compare the population trends of Bachelors degree holders and "Educational attainment not available" workers.`
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. Compare the population trends of Bachelor's degree holders
                  and "Educational attainment not available" workers.
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_education_14 ? (
                  <CsAndItTable14
                    data={data_it_get_employment_by_education_14}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey='5. What was the total count of  "All Education Categories" by gender from 2018 to 2023?'
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  '5. What was the total count of  "All Education Categories" by gender from 2018 to 2023?'
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What was the total count of "All Education Categories" by
                  gender from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_education_all_15 ? (
                  <CsAndItTable15
                    data={data_it_get_employment_by_education_all_15}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="6. How does the number of female employees with a high school diploma or equivalent compare across different years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "6. How does the number of female employees with a high school diploma or equivalent compare across different years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. How does the number of female employees with a high school
                  diploma or equivalent compare across different years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_high_school_16 ? (
                  <CsAndItTable16
                    data={data_it_get_employment_high_school_16}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="7. What is the total number of male employees across all education categories for each year from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "7. What is the total number of male employees across all education categories for each year from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. What is the total number of male employees across all
                  education categories for each year from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_education_17 ? (
                  <CsAndItTable17
                    data={data_it_get_employment_by_education_17}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="8. Which education category has seen the highest growth in female employees over the years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "8. Which education category has seen the highest growth in female employees over the years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  8. Which education category has seen the highest growth in
                  female employees over the years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_education_18 ? (
                  <CsAndItTable18
                    data={data_it_get_employment_by_education_18}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        {/* Race Analysis */}
        <Tab eventKey="Race Analysis" title="Race Analysis" id="Race-Analysis">
          <Accordion>
            <Accordion.Item
              eventKey="1. What is the overall employment trend across all races from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "1. What is the overall employment trend across all races from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the overall employment trend across all races from
                  2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_race_19 ? (
                  <CsAndItTable19 data={data_it_get_employment_by_race_19} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2. What is the percentage increase or decrease in employment for each race from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "2. What is the percentage increase or decrease in employment for each race from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the percentage increase or decrease in employment
                  for each race from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_race_change_20 ? (
                  <CsAndItTable20
                    data={data_it_get_employment_by_race_change_20}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3. Which race had the highest employment growth over the years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "3. Which race had the highest employment growth over the years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. Which race had the highest employment growth over the
                  years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_growth_by_race_21 ? (
                  <CsAndItTable21
                    data={data_it_get_employment_growth_by_race_21}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="4. What is the average employment count per race over the years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "4. What is the average employment count per race over the years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the average employment count per race over the
                  years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_avg_by_race_22 ? (
                  <CsAndItTable22
                    data={data_it_get_employment_avg_by_race_22}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="5. How does the employment trend of Asian individuals compare to Black individuals over the years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "5. How does the employment trend of Asian individuals compare to Black individuals over the years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. How does the employment trend of Asian individuals compare
                  to Black individuals over the years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_get_employment_by_race_23 ? (
                  <CsAndItTable23 data={data_it_get_employment_by_race_23} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="6. What is the year-over-year employment change for each racial group?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "6. What is the year-over-year employment change for each racial group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. What is the year-over-year employment change for each
                  racial group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_yoy_change_by_race_24 ? (
                  <CsAndItTable24 data={data_it_yoy_change_by_race_24} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="7.: How did employment count vary across different racial groups
            during the pandemic year (2020)?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "7. How did employment count vary across different racial groups during the pandemic year (2020)?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. How did employment count vary across different racial
                  groups during the pandemic year (2020)?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_it_pandemic_year_change_by_race_25 ? (
                  <CsAndItTable25
                    data={data_it_pandemic_year_change_by_race_25}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
      </Tabs>
    </div>
  );
};

export default CsAndItFaqSecton;
