// // // // import React from 'react'
// // // // //import DataTable from 'react-data-table-component'
// // // // import DataTable from 'react-data-table-component'
// // // // export default function DataSet() {

// // // //     const columns = [
// // // //         { name: "ID", selector: (row) => row.id, sortable: true },
// // // //         { name: "Title", selector: (row) => row.title, sortable: true, wrap: true },
// // // //         { name: "Body", selector: (row) => row.body, sortable: false, wrap: true },
// // // //       ];
      
// // // //       const DataTableExample = () => {
// // // //         const [data, setData] = useState([]);
// // // //         const [loading, setLoading] = useState(true);
      
// // // //         // Fetch data from JSONPlaceholder API
// // // //         useEffect(() => {
// // // //           const fetchData = async () => {
// // // //             try {
// // // //               const response = await fetch("https://jsonplaceholder.typicode.com/posts");
// // // //               const result = await response.json();
// // // //               setData(result);
// // // //             } catch (error) {
// // // //               console.error("Error fetching data:", error);
// // // //             } finally {
// // // //               setLoading(false);
// // // //             }
// // // //           };
      
// // // //           fetchData();
// // // //         }, []);

        

// // // //   return (
   
      

// // // //       <div style={{ padding: "20px" }}>
// // // //       <h1>Posts Data Table</h1>
// // // //       <DataTable
// // // //         title="Posts List"
// // // //         columns={columns}
// // // //         data={data}
// // // //         pagination
// // // //         progressPending={loading}
// // // //         highlightOnHover
// // // //         responsive
// // // //         striped
// // // //       />
// // // //     </div>

 
// // // //   )
// // // // }











// // // import React, { useEffect, useState } from "react";
// // // import DataTable from "react-data-table-component";
// // // import { createTheme } from "react-data-table-component";

// // // // Define the table columns
// // // const columns = [
// // //   { name: "ID", selector: (row) => row.id, sortable: true },
// // //   { name: "Title", selector: (row) => row.title, sortable: true, wrap: true },
// // //   { name: "Body", selector: (row) => row.body, sortable: false, wrap: true },
// // //   {name: "data", selector: (row) => row.body, sortable: false, wrap: true }
// // // ];

// // // const customStyles = {
// // //     header: {
// // //       style: {
// // //         fontSize: '22px',
// // //         fontWeight: 'bold',
// // //         color: '#FFFFFF',
// // //         backgroundColor: '#4CAF50',
// // //       },
// // //     },
// // //     rows: {
// // //       style: {
// // //         fontSize: '16px',
// // //         color: '#333333',
// // //       },
// // //       highlightOnHoverStyle: {
// // //         backgroundColor: '#F3F3F3',
// // //         borderBottomColor: '#FFFFFF',
// // //         borderRadius: '25px',
// // //         outline: '1px solid #FFFFFF',
// // //       },
// // //     },
// // //     pagination: {
// // //       style: {
// // //         fontSize: '16px',
// // //         color: '#FFFFFF',
// // //         backgroundColor: '#4CAF50',
// // //       },
// // //     },
// // //   };



// // // const customTheme = createTheme('custom', {
// // //     text: {
// // //       primary: '#4CAF50',
// // //       secondary: '#2aa198',
// // //     },
// // //     background: {
// // //       default: '#f5f5f5',
// // //     },
// // //     context: {
// // //       background: '#e91e63',
// // //       text: '#FFFFFF',
// // //     },
// // //     divider: {
// // //       default: '#D3D3D3',
// // //     },
// // //   }, 'light');
  

// // // const DataTableExample = () => {
// // //   const [data, setData] = useState([]);
// // //   const [loading, setLoading] = useState(true);

// // //   // Fetch data from JSONPlaceholder API
// // //   useEffect(() => {
// // //     const fetchData = async () => {
// // //       try {
// // //         const response = await fetch("https://jsonplaceholder.typicode.com/posts");
// // //         const result = await response.json();
// // //         setData(result);
// // //       } catch (error) {
// // //         console.error("Error fetching data:", error);
// // //       } finally {
// // //         setLoading(false);
// // //       }
// // //     };

// // //     fetchData();
// // //   }, []);

// // //   const [filterText, setFilterText] = useState("");

// // // const filteredData = data.filter(
// // //   item =>
// // //     item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
// // // );


// // //   return (
// // //     <div style={{ padding: "20px" }}>
// // //      <div style={{ padding: "20px" }}>
// // //     <h1>Posts Data Table</h1>
// // //     <input
// // //       type="text"
// // //       placeholder="Search..."
// // //       className="my-[5vh]"
// // //       style={{ marginBottom: "10px", padding: "5px" }}
// // //       onChange={(e) => setFilterText(e.target.value)}
// // //     />
// // //       <DataTable
// // //         title="Posts List"
// // //         columns={columns}
// // //         data={filteredData}
// // //         pagination
// // //         progressPending={loading}
// // //         highlightOnHover
// // //         responsive
// // //         striped
// // //         theme="custom"
// // //         customStyles={customStyles}
// // //       />
// // //     </div>
// // //     </div>
// // //   );
// // // };

// // // export default DataTableExample;












// // import React, { useEffect, useState } from "react";
// // import DataTable from "react-data-table-component";
// // import { createTheme } from "react-data-table-component";

// // // Define the table columns
// // const columns = [
// //   { name: "ID", selector: (row) => row.id, sortable: true },
// //   { name: "Title", selector: (row) => row.title, sortable: true, wrap: true },
// //   { name: "Body", selector: (row) => row.body, sortable: false, wrap: true },
// //   { name: "Data", selector: (row) => row.body, sortable: false, wrap: true }
// // ];

// // const customStyles = {
// //   header: {
// //     style: {
// //       fontSize: '22px',
// //       fontWeight: 'bold',
// //       color: '#FFFFFF',
// //       backgroundColor: '#4CAF50',
// //     },
// //   },
// //   rows: {
// //     style: {
// //       fontSize: '16px',
// //       color: '#333333',
// //     },
// //     highlightOnHoverStyle: {
// //       backgroundColor: '#F3F3F3',
// //       borderBottomColor: '#FFFFFF',
// //       borderRadius: '25px',
// //       outline: '1px solid #FFFFFF',
// //     },
// //   },
// //   pagination: {
// //     style: {
// //       fontSize: '16px',
// //       color: '#FFFFFF',
// //       backgroundColor: '#4CAF50',
// //     },
// //   },
// // };

// // const customTheme = createTheme('custom', {
// //   text: {
// //     primary: '#4CAF50',
// //     secondary: '#2aa198',
// //   },
// //   background: {
// //     default: '#f5f5f5',
// //   },
// //   context: {
// //     background: '#e91e63',
// //     text: '#FFFFFF',
// //   },
// //   divider: {
// //     default: '#D3D3D3',
// //   },
// // }, 'light');

// // const DataTableExample = () => {
// //   const [data, setData] = useState([]);
// //   const [loading, setLoading] = useState(true);

// //   // Fetch data from JSONPlaceholder API
// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await fetch("https://jsonplaceholder.typicode.com/posts");
// //         const result = await response.json();
// //         setData(result);
// //       } catch (error) {
// //         console.error("Error fetching data:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, []);

// //   const [filterText, setFilterText] = useState("");

// //   const filteredData = data.filter(
// //     (item) =>
// //       item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
// //   );

// //   return (
// //     <div style={{ padding: "20px" }}>
// //       <h1>Posts Data Table</h1>
// //       <input
// //         type="text"
// //         placeholder="Search..."
// //         className="my-[5vh]"
// //         style={{ marginBottom: "10px", padding: "5px" }}
// //         onChange={(e) => setFilterText(e.target.value)}
// //       />

// //       <DataTable
// //         title="Posts List"
// //         columns={columns}
// //         data={filteredData}
// //         pagination
// //         progressPending={loading}
// //         highlightOnHover
// //         responsive
// //         striped
// //         theme="custom" // Use the custom theme here
// //         customStyles={customStyles}
// //       />
// //     </div>
// //   );
// // };

// // export default DataTableExample;














// import React, { useState } from "react";
// import DataTable from "react-data-table-component";
// import { createTheme } from "react-data-table-component";

// // Define the table columns
// const columns = [
//   { name: "Year", selector: (row) => row.year, sortable: true },
//   {
//     name: "Values",
//     selector: (row) => row.values.join(", "),
//     sortable: false,
//     wrap: true,
//   },
// ];

// // Custom Styles
// const customStyles = {
//   header: {
//     style: {
//       fontSize: "22px",
//       fontWeight: "bold",
//       color: "#FFFFFF",
//       backgroundColor: "#4CAF50",
//     },
//   },
//   rows: {
//     style: {
//       fontSize: "16px",
//       color: "#333333",
//     },
//     highlightOnHoverStyle: {
//       backgroundColor: "#F3F3F3",
//       borderBottomColor: "#FFFFFF",
//       borderRadius: "25px",
//       outline: "1px solid #FFFFFF",
//     },
//   },
//   pagination: {
//     style: {
//       fontSize: "16px",
//       color: "#FFFFFF",
//       backgroundColor: "#4CAF50",
//     },
//   },
// };

// // Custom Theme
// const customTheme = createTheme(
//   "custom",
//   {
//     text: {
//       primary: "#4CAF50",
//       secondary: "#2aa198",
//     },
//     background: {
//       default: "#f5f5f5",
//     },
//     context: {
//       background: "#e91e63",
//       text: "#FFFFFF",
//     },
//     divider: {
//       default: "#D3D3D3",
//     },
//   },
//   "light"
// );

// // JSON Data
// const staticData = [
//   { year: 2014, values: [3.8, 3.7, 3.7, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6] },
//   { year: 2015, values: [3.6, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5] },
//   { year: 2016, values: [3.5, 3.5, 3.5, 3.4, 3.4, 3.4, 3.3, 3.3, 3.3, 3.2, 3.2, 3.2] },
//   { year: 2017, values: [3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.1, 3.1, 3.1, 3.1] },
//   { year: 2018, values: [3.0, 3.0, 3.0, 2.9, 2.9, 2.9, 2.9, 2.9, 2.9, 2.9, 2.9, 2.8] },
//   { year: 2019, values: [2.8, 2.7, 2.7, 2.6, 2.6, 2.5, 2.5, 2.5, 2.4, 2.4, 2.4, 2.4] },
//   { year: 2020, values: [2.5, 2.5, 2.5, 10.0, 7.7, 6.4, 5.7, 4.9, 4.5, 4.0, 3.8, 3.6] },
//   { year: 2021, values: [3.4, 3.2, 3.1, 3.0, 2.9, 2.8, 2.7, 2.6, 2.5, 2.4, 2.3, 2.2] },
//   { year: 2022, values: [2.2, 2.2, 2.2, 2.3, 2.3, 2.3, 2.4, 2.4, 2.5, 2.5, 2.5, 2.5] },
//   { year: 2023, values: [2.5, 2.5, 2.5, 2.5, 2.5, 2.6, 2.7, 2.7, 2.8, 2.8, 2.8, 2.8] },
//   { year: 2024, values: [2.8, 2.8, 2.8, 2.8, 2.9, 3.0, 3.2, 3.3, 3.5, 3.5] },
// ];

// const DataTableExample = () => {
//   const [data] = useState(staticData);
//   const [filterText, setFilterText] = useState("");

//   const filteredData = data.filter(
//     (item) =>
//       item.year.toString().includes(filterText) ||
//       item.values.some((value) => value.toString().includes(filterText))
//   );

//   return (
//     <div style={{ padding: "20px" }}>
//       <h1>Unemployment Rate Data</h1>
//       <input
//         type="text"
//         placeholder="Search..."
//         className="my-[5vh]"
//         style={{ marginBottom: "10px", padding: "5px" }}
//         onChange={(e) => setFilterText(e.target.value)}
//       />

//       <DataTable
//         title="Unemployment Rate Table"
//         columns={columns}
//         data={filteredData}
//         pagination
//         highlightOnHover
//         responsive
//         striped
//         theme="custom" // Use the custom theme here
//         customStyles={customStyles}
//       />
//     </div>
//   );
// };

// export default DataTableExample;























import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { createTheme } from "react-data-table-component";

// Define the months for table headers
const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
];

// Define the table columns dynamically
const columns = [
  { name: "Year", selector: (row) => row.year, sortable: true },
  ...months.map((month, index) => ({
    name: month, 
    selector: (row) => row.values[index] || "-", // Use "-" for months without values
    sortable: true,
  })),
];

// Custom Styles
const customStyles = {
  header: {
    style: {
      fontSize: "22px",
      fontWeight: "bold",
      color: "#FFFFFF",
      backgroundColor: "#9999ff",
    },
  },
  rows: {
    style: {
      fontSize: "16px",
      color: "#333333",
    },
  },
  pagination: {
    style: {
      fontSize: "16px",
      color: "#FFFFFF",
      backgroundColor: "#9999ff",
    },
  },
};

// Custom Theme
const customTheme = createTheme(
  "custom",
  {
    text: {
      primary: "#666699",
      secondary: "#2aa198",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  "light"
);

// JSON Data
const staticData = [
  { year: 2014, values: [3.8, 3.7, 3.7, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6, 3.6] },
  { year: 2015, values: [3.6, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5] },
  { year: 2016, values: [3.5, 3.5, 3.5, 3.4, 3.4, 3.4, 3.3, 3.3, 3.3, 3.2, 3.2, 3.2] },
  { year: 2017, values: [3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.2, 3.1, 3.1, 3.1, 3.1] },
  { year: 2018, values: [3.0, 3.0, 3.0, 2.9, 2.9, 2.9, 2.9, 2.9, 2.9, 2.9, 2.9, 2.8] },
  { year: 2019, values: [2.8, 2.7, 2.7, 2.6, 2.6, 2.5, 2.5, 2.5, 2.4, 2.4, 2.4, 2.4] },
  { year: 2020, values: [2.5, 2.5, 2.5, 10.0, 7.7, 6.4, 5.7, 4.9, 4.5, 4.0, 3.8, 3.6] },
  { year: 2021, values: [3.4, 3.2, 3.1, 3.0, 2.9, 2.8, 2.7, 2.6, 2.5, 2.4, 2.3, 2.2] },
  { year: 2022, values: [2.2, 2.2, 2.2, 2.3, 2.3, 2.3, 2.4, 2.4, 2.5, 2.5, 2.5, 2.5] },
  { year: 2023, values: [2.5, 2.5, 2.5, 2.5, 2.5, 2.6, 2.7, 2.7, 2.8, 2.8, 2.8, 2.8] },
  { year: 2024, values: [2.8, 2.8, 2.8, 2.8, 2.9, 3.0, 3.2, 3.3, 3.5, 3.5] },
];

const DataSet = () => {
  const [data] = useState(staticData);
  const [filterText, setFilterText] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.year.toString().includes(filterText) ||
      item.values.some((value) => value.toString().includes(filterText))
  );

  return (
    <div style={{background:'rgb(210 234 255 ' , height:'110vh'}}>
    <div style={{ padding: "20px" }}>
      <h1>Unemployment Rate Data (2014 - 2024)</h1>
      <input
        type="text"
        placeholder="Search by Year or Value..."
        className="my-[5vh]"
        style={{ marginBottom: "10px", padding: "5px" }}
        onChange={(e) => setFilterText(e.target.value)}
      />

      <DataTable
        title="Unemployment Rate Table"
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        responsive
        striped
        theme="custom"
        customStyles={customStyles}
      />
    </div>
    </div>
  );
};

export default DataSet;

