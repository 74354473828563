import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getEduLabel = (value) => {
  switch (value) {
    case "E0":
      return "All Education Categories";
    case "E1":
      return "Less than high school";
    case "E2":
      return "High school or equivalent, no college";
    case "E3":
      return "Some college or Associate degree";
    case "E4":
      return "Bachelor's degree or advanced degree";
    case "E5":
      return "Educational attainment not available (workers aged 24 or younger)";
    default:
      return "Unknown";
  }
};

const EducationGrowthChart = (props) => {
  const pdfRef = useRef();
  const { data } = props;
  // Ensure data is valid and not empty
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }
  // Prepare data for the chart
  const chartData = {
    labels: data.map((item) => getEduLabel(item.education_label)), // X-axis labels (education levels)
    datasets: [
      {
        type: "bar", // Bar chart for 2018 totals
        label: "2018 Total",
        data: data.map((item) => parseInt(item.total_2018 || 0)),
        backgroundColor: "rgba(54, 162, 235, 0.8)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        barThickness: 30,
      },
      {
        type: "bar", // Bar chart for 2023 totals
        label: "2023 Total",
        data: data.map((item) => parseInt(item.total_2023 || 0)),
        backgroundColor: "rgba(255, 99, 132, 0.8)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        barThickness: 30,
      },
      {
        type: "line", // Line chart for growth percentage
        label: "Growth Percent (%)",
        data: data.map((item) => parseFloat(item.growth_percent || 0)),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderWidth: 2,
        tension: 0.4, // Smooth curves
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "#fff",
        pointRadius: 5, // Point size
        yAxisID: "y2", // Attach to secondary Y-axis
      },
    ],
  };

  // Chart options with animations and 3D-like effects
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Education Growth (2018-2023)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Education Levels",
        },
      },
      y: {
        title: {
          display: true,
          text: "Total Count",
        },
        beginAtZero: true,
      },
      y2: {
        position: "right",
        title: {
          display: true,
          text: "Growth Percent (%)",
        },
        grid: {
          drawOnChartArea: false, // Disable grid lines for secondary Y-axis
        },
      },
    },
    animation: {
      duration: 1500,
      easing: "easeInOutBounce", // Smooth animations
    },
  };

  // PDF Generation function
  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px"; // Temporarily increase width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and footer
      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30); // Logo
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35); // Line after header

      // Add main content
      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);
      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);
      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      // Date and URL
      const currentDate = new Date().toLocaleDateString();
      pdf.setFontSize(10);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("yearly-chart-data.pdf");
      input.style.width = originalWidth; // Reset width
    });
  };

  return (
    <>
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Education Growth Table</h2>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Education Label</th>
                  <th>Total 2018</th>
                  <th>Total 2023</th>
                  <th>Growth Percentage</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{getEduLabel(row.education_label)}</td>
                    <td>{row.total_2018}</td>
                    <td>{row.total_2023}</td>
                    <td>{row.growth_percent}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
            }}
          >
            <div
              style={{ perspective: "1000px", transform: "rotateX(-10deg)" }}
            >
              <Chart type="bar" data={chartData} options={options} />
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} onClick={downloadPDF} />
          </span>
        </button>
      </div>
    </>
  );
};

export default EducationGrowthChart;
