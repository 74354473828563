import React from "react";
import "../styles/UtahCounty.css";
import GroupsIcon from "@mui/icons-material/Groups";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import Groups2Icon from "@mui/icons-material/Groups2";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import ZoomOutMapRoundedIcon from "@mui/icons-material/ZoomOutMapRounded";

const map = "/img/utahcountymap.png";

export default function UtahCounty() {
  return (
    <div className="utah-county-bg">
      <div className="utah-county-container">
        <h1 className="utah-county-title">Utah County</h1>

        {/* Desktop Layout: Left Info Boxes, Right Map */}
        <div className="utah-content">
          {/* LEFT COLUMN (INFO BOXES) */}
          <div className="utah-info">
            <div className="utah-grid">
              <InfoBox
                icon={<GroupsIcon fontSize="large" />}
                title="Total Population"
                value="0.736 M"
                subtitle="Total Population as on 2024"
                className="population"
              />
              <InfoBox
                icon={<GroupsIcon fontSize="large" />}
                title="Male Population"
                value="50.3%"
                subtitle="Male Population as on 2024"
                className="male_population"
              />
              <InfoBox
                icon={<Groups2Icon fontSize="large" />}
                title="Female Population"
                value="49.7%"
                subtitle="Female Population as on 2024"
                className="female_population"
              />
              <InfoBox
                icon={<LocationCityIcon fontSize="large" />}
                title="Cities"
                value="32"
                subtitle="Founded in 1852"
                className="utahcounty"
              />
              <InfoBox
                icon={<MyLocationRoundedIcon fontSize="large" />}
                title="Utah Area"
                value="2,144 Sq mi"
                subtitle="Total area of Utah County"
                className="area"
              />
              <InfoBox
                icon={<ZoomOutMapRoundedIcon fontSize="large" />}
                title="County Seat"
                value="Provo"
                subtitle="Largest City"
                className="largestcity"
              />
              <InfoBox
                icon={<HomeIcon fontSize="large" />}
                title="No. of Houses"
                value="133,266"
                subtitle="As on December 2024"
                className="houses"
              />

              <InfoBox
                icon={<MonetizationOnIcon fontSize="large" />}
                title="Unemployment %"
                value="3.3%"
                subtitle="As on October 2024"
                className="poverty"
              />
              <InfoBox
                icon={<EscalatorWarningIcon fontSize="large" />}
                title="2024 Median Age"
                value="25.2%"
                subtitle="4.32% 1-Year Increase"
                className="year_growth"
              />
            </div>
          </div>

          {/* RIGHT COLUMN (MAP) */}
          <div className="utah-map">
            <img src={map} alt="Utah County Map" className="map-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

/* 🔹 INFO BOX COMPONENT */
const InfoBox = ({ icon, title, value, subtitle, className }) => {
  return (
    <div className={`info-box ${className}`}>
      <div className="info-icon">{icon}</div>
      <div className="info-text">
        <div className="info-title">{title}</div>
        <div className="info-value">{value}</div>
        <div className="info-subtitle">{subtitle}</div>
      </div>
    </div>
  );
};
