import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
// import * as React from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonBase } from "@mui/material";

const saltlake = "/img/saltlake.avif";
const saltlakegeography = "/img/saltlake-geography.jpg";
const saltlakeDemography = "/img/saltlake-valley.jpg";
const saltlakeEconomy = "/img/Salt-Lake-Economy.jpg";
const saltlakehighlight = "/img/Salt-Lake-County.png";

const cardData = [
  {
    img: saltlake,
    tag: "About Salt Lake",
    title: "Salt Lake County: Utah’s Most Populous County and Urban Hub",
    description:
      "Salt Lake County is located in the U.S. state of Utah. As of the 2020 United States census, the population was 1,185,238, making it the most populous county in Utah. Its county seat and largest city is Salt Lake City, the state capital. Salt Lake City, often shortened to Salt Lake or SLC, is also the central core of the larger of only two major urban areas located within the Great Basin (the other being Reno, Nevada). The county was created in 1850. Salt Lake County is the 37th most populated county in the United States and is one of four counties in the Rocky Mountains to make it into the top 100.",
    authors: [
      { name: "", avatar: "" },
      { name: "", avatar: "" },
    ],
  },
  {
    img: saltlakegeography,
    tag: "Geography",
    title: "Geography and Elevation of Salt Lake City",
    description:
      "Salt Lake City has an area of 110.4 square miles (286 km2) and an average elevation of 4,327 feet (1,319 m) above sea level. The lowest point within the boundaries of the city is 4,210 feet (1,280 m) near the Jordan River and the Great Salt Lake, and the highest is Grandview Peak, at 9,410 feet (2,868 m)",
    authors: [{ name: "", avatar: "/static/images/avatar/6.jpg" }],
  },
  {
    img: saltlakeDemography,
    tag: " Demography",
    title: "Population Growth and Demographics of Salt Lake County",
    description:
      "The population of Salt Lake County, Utah in 2022 was 1.2 million, 3.2% up from the 1.1 million who lived there in 2018. For comparison, the US population grew 2% and Utahs population grew 7.2% during that period.In 2022, the largest racial or ethnic group in Salt Lake County was the white (non-Hispanic) group, which had a population of 817,101.",
    authors: [{ name: "", avatar: "/static/images/avatar/7.jpg" }],
  },
  {
    img: "https://picsum.photos/800/450?random=4",
    tag: "History",
    title: "Urban Expansion and Development in Salt Lake County",
    description:
      "Salt Lake City's selection as the host of the 2002 Winter Olympics spurred a construction boom in the city that continued after the Olympics, slowing only in 2008 recession. As the county's population has surpassed 1 million, it has significantly urbanized, leaving only a few rural areas in the far west of the valley.",
    authors: [{ name: "", avatar: "/static/images/avatar/3.jpg" }],
  },
  {
    img: "https://picsum.photos/800/450?random=45",
    tag: "Settler",
    title: " Early Settlement and Pioneer Life in Salt Lake County",
    description:
      "The first white people to see the valley—that we know of—were trappers working for William H. Ashley in 1824-25. But the first permanent white settlers began to come in 1847, when the first Mormon wagon train arrived. Three women and three African American slaves were with that first group. ",
    authors: [
      { name: "", avatar: "/static/images/avatar/4.jpg" },
      { name: "", avatar: "/static/images/avatar/5.jpg" },
    ],
  },
  {
    img: saltlakeEconomy,
    tag: " Economy",
    title: "Economic Development and Industry in Salt Lake County",
    description:
      "Although cities and suburbs now cover much of the land, in some areas you can still find some traces of the farms that were once common in the county. Farmers in the county produced eggs, livestock, wheat, and garden vegetables.",
    authors: [{ name: "", avatar: "/static/images/avatar/2.jpg" }],
  },
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  height: "100%",
  backgroundColor: (theme.vars || theme).palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const SyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

// function Author({ authors }) {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         gap: 2,
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         padding: '16px',
//       }}
//     >
//       <Box
//         sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
//       >
//         <AvatarGroup max={3}>
//           {authors.map((author, index) => (
//             <Avatar
//               key={index}
//               alt={author.name}
//               src={author.avatar}
//               sx={{ width: 24, height: 24 }}
//             />
//           ))}
//         </AvatarGroup>
//         <Typography variant="caption">
//           {authors.map((author) => author.name).join(', ')}
//         </Typography>
//       </Box>
//       <Typography variant="caption">February 6, 2025</Typography>
//     </Box>
//   );
// }

// Author.propTypes = {
//   authors: PropTypes.arrayOf(
//     PropTypes.shape({
//       avatar: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
// };

export function Search() {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      {/* <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'search',
        }}
      /> */}
    </FormControl>
  );
}

export default function MainContent() {
  //const [open, setOpen] = React.useState(false);
  //const [openIndex, setOpenIndex] = React.useState(null);
  const [openDialogIndex, setOpenDialogIndex] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };
  // const handleClickOpen = (index, scrollType) => () => {
  //   setOpenIndex(index);
  //   setScroll(scrollType);
  // };
  const handleClickOpen = (index, scrollType) => () => {
    setOpenDialogIndex(index);
    setScroll(scrollType);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClose = () => {
  //   setOpenIndex(null);
  // };
  const handleClose = () => {
    setOpenDialogIndex(null);
  };

  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDialogIndex !== null) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialogIndex]);
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info("You clicked the filter chip.");
    window.scroll({
      top: 200,
      left: 100,
      behavior: "smooth",
    });
  };
  const handleClick1 = () => {
    console.info("You clicked the filter chip.");
    window.scroll({
      top: 800,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div>
          <Typography variant="h1" gutterBottom sx={{ color: "#fff" }}>
            Salt Lake County
          </Typography>
          <Typography sx={{ color: "#fff" }}>
            Your Gateway to Salt Lake's Trends & Analytics
          </Typography>
        </div>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2, // space between text and image
            color: "#fff",
          }}
        >
          <Typography sx={{ fontSize: "1.3rem", flex: 1 }}>
            <span className="text-2xl font-bold italic">
              Population Demography
            </span>
            <p>
              According to the 2020 United States census and 2020 American
              Community Survey, there were 1,185,238 people in Salt Lake County
              with a population density of 1,574.3 people per square mile
              (607.9/km2). Among non-Hispanic or Latino people, the racial
              makeup was 800,914 (67.6%) White, 21,976 (1.9%) African American,
              7,205 (0.6%) Native American, 50,241 (4.2%) Asian, 21,194 (1.8%)
              Pacific Islander, 5,537 (0.5%) from other races, and 46,083 (3.9%)
              from two or more races. 232,088 (19.6%) people were Hispanic or
              Latino. There were 595,608 (50.25%) males and 589,630 (49.75%)
              females, and the population distribution by age was 310,343
              (26.2%) under the age of 18, 740,417 (62.5%) from 18 to 64, and
              134,478 (11.3%) who were at least 65 years old. The median age was
              32.9 years.
            </p>
            <span className="text-2xl font-bold italic">At a Glance</span>
            <p>
              The future Salt Lake County area was settled by European Americans
              in 1847 when Mormon pioneers of the Church of Jesus Christ of
              Latter-day Saints fled religious persecution in the East. They
              arrived in the Salt Lake Valley after descending what settlers
              later called Emigration Canyon. Brigham Young, their leader,
              declared "This is the place" after seeing the valley. Compared to
              eastern regions, it seemed arid and unpromising to some of the
              migrants. Settlers used irrigation to develop agriculture and the
              flourishing, self-sufficient city known then as Great Salt Lake
              City. Thousands of Mormons joined them in the next several
              decades.
            </p>
          </Typography>
          <Box
            component="img"
            src={saltlakehighlight}
            alt="Historic Courthouse"
            sx={{
              width: { xs: "100px", md: "200px" }, // Responsive width
              height: "auto",
              borderRadius: 2,
              boxShadow: 3, // Adds subtle shadow
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          <Search />
          {/* <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "start", md: "center" },
            gap: 4,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              gap: 3,
              overflow: "auto",
            }}
          >
            <Chip onClick={handleClick} size="medium" label="About Us" />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Geography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label=" Demography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label=" Economy"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="History"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="Settler"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "row",
              gap: 1,
              width: { xs: "100%", md: "fit-content" },
              overflow: "auto",
            }}
          >
            <Search />
            {/* <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton> */}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(0, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(0)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 0 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[0].img}
                  sx={{
                    aspectRatio: "16 / 9",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />

                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[0].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[0].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[0].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Author authors={cardData[0].authors} /> */}
                {/* <Button onClick={handleClickOpen('paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
                {/* <Button onClick={handleClickOpen(0, 'paper')} sx={{ alignSelf: 'center' }}>
  Learn More...
</Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>

          <Dialog
            open={openDialogIndex === 0}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Salt Lake County: Utah’s Most Populous County and Urban Hub
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')} */}
                Salt Lake County is located in the U.S. state of Utah. As of the
                2020 United States census, the population was 1,185,238, making
                it the most populous county in Utah. Its county seat and largest
                city is Salt Lake City, the state capital. Salt Lake City, often
                shortened to Salt Lake or SLC, is also the central core of the
                larger of only two major urban areas located within the Great
                Basin (the other being Reno, Nevada). The county was created in
                1850. Salt Lake County is the 37th most populated county in the
                United States and is one of four counties in the Rocky Mountains
                to make it into the top 100.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(1, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(1)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 1 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[1].img}
                  aspect-ratio="16 / 9"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[1].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[1].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[1].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[1].authors} /> */}
                {/* <Button onClick={handleClickOpen(1,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 1}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Geography and Elevation of Salt Lake City
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                Salt Lake City has an area of 110.4 square miles (286 km2) and
                an average elevation of 4,327 feet (1,319 m) above sea level.
                The lowest point within the boundaries of the city is 4,210 feet
                (1,280 m) near the Jordan River and the Great Salt Lake, and the
                highest is Grandview Peak, at 9,410 feet (2,868 m).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(2, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(2)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 2 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[2].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[2].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[2].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[2].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[2].authors} /> */}
                {/* <Button onClick={handleClickOpen(2,'paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 2}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Population Growth and Demographics of Salt Lake County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                The population of Salt Lake County, Utah in 2022 was 1.2
                million, 3.2% up from the 1.1 million who lived there in 2018.
                For comparison, the US population grew 2% and Utah's population
                grew 7.2% during that period. In 2022, the largest racial or
                ethnic group in Salt Lake County was the white (non-Hispanic)
                group, which had a population of 817,101. Between 2018 and 2022,
                the Hispanic/Latino population had the most growth increasing by
                21,376 from 212,404 in 2018 to 233,780 in 2022. Among six age
                groups — 0 to 4, 5 to 19, 20 to 34, 35 to 49, 50 to 64, and 65
                and older — the 65+ group was the fastest growing between 2018
                and 2022 with its population increasing 15%. The 0 to 4 age
                group declined the most dropping 12.5% between 2018 and 2022.
                The median income for a household in the city was $36,944, and
                the median income for a family was $45,140. Males had a median
                income of $31,511 versus $26,403 for females. The per capita
                income for the city was $20,752. 15.3% of the population and
                10.4% of families were below the poverty line. Out of the total
                population, 18.7% of those under the age of 18 and 8.5% of those
                65 and older were living below the poverty line.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "100%",
              }}
            >
              <ButtonBase
                onClick={handleClickOpen(4, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(3)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 3 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[3].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[3].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[3].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[3].authors} /> */}
                  {/* <Button onClick={handleClickOpen(4,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 4}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Urban Expansion and Development in Salt Lake County
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    In the 1990s, the county's areas of rapid growth shifted
                    further south and west. Farm and pasturelands were developed
                    as suburbs. The cities of West Jordan, South Jordan,
                    Riverton, Herriman, and Draper are some of the
                    fastest-growing cities in the state. During the 1990s, Salt
                    Lake City gained population for the first time in 40 years.
                    <p>
                      Salt Lake City's selection as the host of the 2002 Winter
                      Olympics spurred a construction boom in the city that
                      continued after the Olympics, slowing only in 2008
                      recession. As the county's population has surpassed 1
                      million, it has significantly urbanized, leaving only a
                      few rural areas in the far west of the valley. Issues
                      facing the county today include congested transportation
                      and related air pollution.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
              <ButtonBase
                onClick={handleClickOpen(5, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(4)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 4 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[4].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[4].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[4].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[4].authors} /> */}
                  {/* <Button onClick={handleClickOpen(5,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 5}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Early Settlement and Pioneer Life in Salt Lake County
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    The first white people to see the valley—that we know
                    of—were trappers working for William H. Ashley in 1824-25.
                    But the first permanent white settlers began to come in
                    1847, when the first Mormon wagon train arrived. Three women
                    and three African American slaves were with that first
                    group.
                    <p>
                      The first group to arrive immediately planted crops, on
                      July 23. Leader Brigham Young directed men to explore the
                      valley and canyons, build a fort, and survey a new city.
                      In October of that same year, 17-year-old Mary Jane
                      Dilworth opened the first school in her tent.
                    </p>
                    <p>
                      In the next two years settlers founded a dozen towns in
                      the county. Because they lived so far from any other city,
                      the settlers had to make the things they needed. They
                      established industries to make everything from pottery to
                      paper. Of course, they also experimented with growing all
                      kinds of crops.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(3, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(5)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 5 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[5].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[5].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[5].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[5].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Button onClick={handleClickOpen(3,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 3}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Economic Development and Industry in Salt Lake County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                Although cities and suburbs now cover much of the land, in some
                areas you can still find some traces of the farms that were once
                common in the county. Farmers in the county produced eggs,
                livestock, wheat, and garden vegetables. The county also became
                a regional mining and smelting hub. Alta and Bingham had
                important mines, and Midvale and Murray had large smelters. The
                Salt Lake Mining and Stock Exchange in Salt Lake City was
                important to the business of mining.
                <p>
                  Printing and publishing began in 1850—and have continued. Some
                  goods manufactured in Salt Lake County today are
                  pharmaceuticals, candy, and other food products, computers,
                  military guidance systems, and artificial organs. The county
                  leads the state in trade, services, transportation,
                  communications, finance, insurance, and construction. Salt
                  Lake International Airport, major medical facilities, and
                  television broadcasting serve all of Utah and parts of the
                  Intermountain region. Government, including education, is the
                  leading employer in the county.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
