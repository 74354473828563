import React from 'react'

export default function OutOfState() {

    const schoolData = 
    [
        {
            "Bank Name": "Banterra Bank",
            "Address": "6965 UNION PK CTR DR STE 260, COTTONWOOD HEIGHTS, UT 84047",
            "CEO/President": "Robert J. May, President",
            "Contact No:": "385-645-3005"
        },
        {
            "Bank Name": "D.L. Evans Bank",
            "Address": "312 W MAIN ST, TREMONTON, UT 84337",
            "CEO/President": "John V. Evans, Jr., President",
            "Contact No:": "435-257-2265"
        },
        {
            "Bank Name": "Fortis Private Bank",
            "Address": "6975 UNION PK AVE STE 180, COTTONWOOD HEIGHTS, UT 84047",
            "CEO/President": "Joel Coursey, Chief Operating Officer",
            "Contact No:": "385-275-5603"
        },
        {
            "Bank Name": "Glacier Bank",
            "Address": "49 COMMONS LOOP, KALISPELL, MT 59901",
            "CEO/President": "Randy Chesler, President",
            "Contact No:": "208-415-5310"
        },
        {
            "Bank Name": "Goldman Sachs Bank USA",
            "Address": "222 S MAIN ST, SALT LAKE CITY, UT 84101",
            "CEO/President": "Carey Halio, Treasurer",
            "Contact No:": "801-884-1500"
        },
        {
            "Bank Name": "NBH Bank dba Hillcrest Bank",
            "Address": "810 N 500 W,BOUNTIFUL, UT 84010",
            "CEO/President": "David Stahl, Utah Market President",
            "Contact No:": "385-300-7250"
        },
        {
            "Bank Name": "Umpqua Bank",
            "Address": "10757 S RIVER FRONT PKWY STE 275, SOUTH JORDAN, UT 84095",
            "CEO/President": "Clint Stein, CEO",
            "Contact No:": "385-425-7321"
        },
        {
            "Bank Name": "Washington Federal",
            "Address": "505 E 200 S, SALT LAKE CITY, UT 84102",
            "CEO/President": "Brent Beardall, CEO",
            "Contact No:": "801-366-2265"
        }
    ]
      
  return (
    <div
    className=" mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        Out-of-State State Banks in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school['Bank Name']}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school['CEO/President']}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
