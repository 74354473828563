






import React from 'react';

const Table2 = () => {
    return (
        <div className="faq-item">
            <div className="faq-answer">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Age Group</th>
                                <th>All (Q4-2018)</th>
                                <th>Female Count (Q4-2018)</th>
                                <th>Male Count (Q4-2018)</th>
                                <th>All % (Q4-2018)</th>
                                <th>Female Share % (Q4-2018)</th>
                                <th>Male Share % (Q4-2018)</th>
                                <th>All (Q4-2023)</th>
                                <th>Female Count (Q4-2023)</th>
                                <th>Male Count (Q4-2023)</th>
                                <th>All % (Q4-2023)</th>
                                <th>Female Share % (Q4-2023)</th>
                                <th>Male Share % (Q4-2023)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>14-18</td>
                                <td>1,899</td>
                                <td>792</td>
                                <td>1,107</td>
                                <td>1.42%</td>
                                <td>2.00%</td>
                                <td>1.17%</td>
                                <td>2,519</td>
                                <td>1,196</td>
                                <td>1,323</td>
                                <td>1.63%</td>
                                <td>2.43%</td>
                                <td>1.26%</td>
                            </tr>
                            <tr>
                                <td>19-21</td>
                                <td>5,517</td>
                                <td>1,794</td>
                                <td>3,723</td>
                                <td>4.12%</td>
                                <td>4.53%</td>
                                <td>3.94%</td>
                                <td>6,536</td>
                                <td>2,406</td>
                                <td>4,130</td>
                                <td>4.24%</td>
                                <td>4.88%</td>
                                <td>3.94%</td>
                            </tr>
                            {/* Additional rows can be added here */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table2;
