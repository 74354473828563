// import React from 'react';
// import Table from 'react-bootstrap/Table';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const AeroTable19 = ({ data }) => {
//   // Extract labels and datasets for the line chart
//   const labels = data.map(item => item.education_label);

//   const chartData = {
//     labels,
//     datasets: [
//       {
//         label: 'Female',
//         data: data.map(item => item.female),
//         borderColor: 'rgb(255, 99, 132)',
//         backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         tension: 0.4,
//         fill: true,
//       },
//       {
//         label: 'Male',
//         data: data.map(item => item.male),
//         borderColor: 'rgb(54, 162, 235)',
//         backgroundColor: 'rgba(54, 162, 235, 0.2)',
//         tension: 0.4,
//         fill: true,
//       },
//       {
//         label: 'All Sexes',
//         data: data.map(item => item.all_sexes),
//         borderColor: 'rgb(75, 192, 192)',
//         backgroundColor: 'rgba(75, 192, 192, 0.2)',
//         tension: 0.4,
//         fill: true,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: 'Employment by Education and Gender (2021)',
//       },
//     },
//     scales: {
//       y: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Number of Employees',
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Education Level',
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h3 className="mt-3">Employment by Education and Gender (2021)</h3>

//       {/* Table Section */}
//       <Table striped bordered hover responsive className="mb-4">
//         <thead>
//           <tr>
//             <th>Education Level</th>
//             <th>All Sexes</th>
//             <th>Female</th>
//             <th>Male</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => (
//             <tr key={index}>
//               <td>{item.education_label}</td>
//               <td>{item.all_sexes}</td>
//               <td>{item.female}</td>
//               <td>{item.male}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Chart Section */}
//       {/* <div style={{ overflowX: 'auto', marginBottom: '20px' , display: 'flex', justifyContent: 'center' }}>
//       <div style={{ width: '800px', height: '500px' }}>
//         <Line data={chartData} options={chartOptions} />
//       </div>
//     </div> */}

//     <div
//   style={{
//     overflowX: "auto", // Enables horizontal scroll
//     display: "flex",
//     justifyContent: "center",
//     width: "100%", // Takes full width
//   }}
// >
//   <div
//     style={{
//       minWidth: "600px", // Minimum width to enable scroll
//       width: "1000px",   // Chart width, ensuring horizontal scroll on smaller screens
//       height: "400px",
//     }}
//   >
//     <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
//   </div>
// </div>
//     </div>
//   );
// };

// export default AeroTable19;

import React, { useRef } from "react";
import Table from "react-bootstrap/Table";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// Import images for PDF header and footer

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

const AeroTable19 = ({ data }) => {
  const pdfRef = useRef(); // Reference for PDF capture

  // Extract labels and datasets for the line chart
  const labels = data.map((item) => item.education_label);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Female",
        data: data.map((item) => item.female),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        tension: 0.4,
        fill: true,
      },
      {
        label: "Male",
        data: data.map((item) => item.male),
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        tension: 0.4,
        fill: true,
      },
      {
        label: "All Sexes",
        data: data.map((item) => item.all_sexes),
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    input.style.width = "1100px"; // Expand width for better PDF capture

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Header
      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.line(10, 35, 200, 35);

      // Main Content
      pdf.addImage(imgData, "PNG", 5, 38, imgWidth, imgHeight);

      // Footer
      pdf.line(10, 260, 200, 260);
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250211.v1.113", 148, 275);
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);
      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("education-employment-gender-trend.pdf");
      input.style.width = ""; // Reset width
    });
  };

  return (
    <div className="container">
      <div ref={pdfRef} className="content-to-export">
        <h3 className="mt-3">Employment by Education and Gender (2021)</h3>

        {/* Table Section */}
        <Table striped bordered hover responsive className="mb-4">
          <thead>
            <tr>
              <th>Education Level</th>
              <th>All Sexes</th>
              <th>Female</th>
              <th>Male</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.education_label}</td>
                <td>{item.all_sexes}</td>
                <td>{item.female}</td>
                <td>{item.male}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Chart Section */}
        {/* <div
          style={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              minWidth: "600px",
              width: "1000px",
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Line
              data={chartData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-4">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
          <span>
            <img className="w-[2rem]" src={pdf} alt="PDF Icon" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AeroTable19;
