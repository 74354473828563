import React, { useRef } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Table } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const CsAndItTable20 = ({ data }) => {
  const pdfRef = useRef();

  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const races = data.map((item) => item.race_label);
  const employment_2023 = data.map((item) => parseInt(item.y2023));
  const percentage_change = data.map((item) =>
    parseFloat(item.percentage_change)
  );

  const chartData = {
    labels: races,
    datasets: [
      {
        label: "Employment in 2023",
        data: employment_2023,
        backgroundColor: "rgba(54, 162, 235, 0.7)", // Soft Blue
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        yAxisID: "y-axis-employment",
      },
      {
        label: "Percentage Change",
        data: percentage_change,
        borderColor: "rgba(255, 99, 132, 1)", // Red
        backgroundColor: "rgba(255, 99, 132, 0.3)",
        borderWidth: 2,
        pointBackgroundColor: "white",
        pointBorderColor: "black",
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.3,
        type: "line",
        yAxisID: "y-axis-percentage",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment by Race & Percentage Change (2023)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Race",
        },
      },
      "y-axis-employment": {
        title: {
          display: true,
          text: "Employment Count",
        },
        beginAtZero: true,
        position: "left",
      },
      "y-axis-percentage": {
        title: {
          display: true,
          text: "Percentage Change",
        },
        beginAtZero: false,
        position: "right",
        ticks: {
          callback: function (value) {
            return value + "%"; // Adds % sign to percentage axis
          },
        },
        grid: {
          drawOnChartArea: false, // Prevents overlap with bars
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295);

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("csAndIt_table20.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="dashboard-container">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Employment by Race & Percentage Change (2023)</h2>

          {/* Render Table */}
          <div style={{ overflowX: "auto", marginBottom: "20px" }}>
            <Table
              border="1"
              cellPadding="10"
              style={{ width: "100%", minWidth: "600px" }}
            >
              <thead>
                <tr>
                  <th>Race</th>
                  <th>2018</th>
                  <th>2019</th>
                  <th>2020</th>
                  <th>2021</th>
                  <th>2022</th>
                  <th>2023</th>
                  <th>Percentage Change</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{row.race_label}</td>
                    <td>{row.y2018}</td>
                    <td>{row.y2019}</td>
                    <td>{row.y2020}</td>
                    <td>{row.y2021}</td>
                    <td>{row.y2022}</td>
                    <td>{row.y2023}</td>
                    <td
                      style={{
                        color: row.percentage_change >= 0 ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {row.percentage_change}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Grouped Bar + Line Chart */}
          <div
            style={{
              width: "90%",
              maxWidth: "900px",
              margin: "auto",
              overflowX: "auto",
            }}
          >
            <div style={{ width: "100%", minWidth: "600px", height: "400px" }}>
              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} onClick={downloadPDF} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default CsAndItTable20;
