import React from 'react'

export default function School4() {

    const schoolData = 
    [
        {
          "schoolName": "Birch Creek School",
          "numberOfStudents": 721,
          "grades": "PK-6"
        },
        {
          "schoolName": "Cache High",
          "numberOfStudents": 151,
          "grades": "5-12"
        },
        {
          "schoolName": "Cache Preschool",
          "numberOfStudents": 62,
          "grades": "PK-PK"
        },
        {
          "schoolName": "Canyon School",
          "numberOfStudents": 752,
          "grades": "PK-6"
        },
        {
          "schoolName": "Cedar Ridge School",
          "numberOfStudents": 744,
          "grades": "KG-6"
        },
        {
          "schoolName": "Green Canyon High School",
          "numberOfStudents": 1690,
          "grades": "8-12"
        },
        {
          "schoolName": "Greenville School",
          "numberOfStudents": 537,
          "grades": "PK-6"
        },
        {
          "schoolName": "Heritage School",
          "numberOfStudents": 758,
          "grades": "PK-6"
        },
        {
          "schoolName": "Lewiston School",
          "numberOfStudents": 533,
          "grades": "PK-6"
        },
        {
          "schoolName": "Lincoln School",
          "numberOfStudents": 516,
          "grades": "KG-6"
        },
        {
          "schoolName": "Millville School",
          "numberOfStudents": 484,
          "grades": "PK-6"
        },
        {
          "schoolName": "Mountain Crest High",
          "numberOfStudents": 1538,
          "grades": "8-12"
        },
        {
          "schoolName": "Mountainside School",
          "numberOfStudents": 500,
          "grades": "PK-6"
        },
        {
          "schoolName": "Nibley School",
          "numberOfStudents": 530,
          "grades": "PK-6"
        },
        {
          "schoolName": "North Cache Middle School",
          "numberOfStudents": 1119,
          "grades": "7-8"
        },
        {
          "schoolName": "North Park School",
          "numberOfStudents": 501,
          "grades": "PK-6"
        },
        {
          "schoolName": "Providence School",
          "numberOfStudents": 760,
          "grades": "KG-6"
        },
        {
          "schoolName": "Ridgeline High School",
          "numberOfStudents": 1813,
          "grades": "8-12"
        },
        {
          "schoolName": "River Heights School",
          "numberOfStudents": 622,
          "grades": "PK-6"
        },
        {
          "schoolName": "Sky View High",
          "numberOfStudents": 1517,
          "grades": "8-12"
        },
        {
          "schoolName": "South Cache Middle School",
          "numberOfStudents": 1063,
          "grades": "7-8"
        },
        {
          "schoolName": "Spring Creek Middle School",
          "numberOfStudents": 845,
          "grades": "7-8"
        },
        {
          "schoolName": "Summit School",
          "numberOfStudents": 738,
          "grades": "PK-6"
        },
        {
          "schoolName": "Sunrise School",
          "numberOfStudents": 782,
          "grades": "KG-6"
        },
        {
          "schoolName": "Wellsville School",
          "numberOfStudents": 512,
          "grades": "KG-6"
        },
        {
          "schoolName": "White Pine School",
          "numberOfStudents": 439,
          "grades": "PK-6"
        }
      ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Cache County
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
