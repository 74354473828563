import React, { useRef, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
import "../styles/Verticals.css";

import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { Link } from "react-router-dom";
import "../styles/Verticals.css";
const aerospace = "/img/defence.webp";
const agriculture = "/img/agriculture-food-natural-resources.jpg";
const business = "/img/business-finance-marketing.webp";
const engineering = "/img/engineering-technology.jpg";
const human = "/img/human-services.jpeg";
const transport = "/img/transportation-distribution-logistics.jpg";
const architecture = "/img/Architectural-Engineering-construction.jpg";
const computer = "/img/computer-science-it.webp";
const health = "/img/Heath-Science.jpg";
const law = "/img/law-public-safety-security.jpg";
const arts = "/img/arts-visual-communication.jpeg";
const education = "/img/education-training.webp";
const hospitality = "/img/hospitality-tourism.jpg";
const dataScience = "/img/Data-Science-and-Analytics.jpg";
const logo = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";

const manufacturing = "/img/manufacturing1.jpg";

export default function Verticals() {
  const [modalShow, setModalShow] = React.useState(false);

  const iconData = [
    {
      icon: "fa-fighter-jet",
      title: "Aerospace and Defense",
      alt: "Utah fighter aircraft",
      backgroundImage: `url(${aerospace})`,
      link: "/verticals/aerospace-and-defense",
    },
    {
      icon: "fa-industry",
      title: "Manufacturing",
      alt: "Utah Manufacturing Industry",
      backgroundImage: `url(${manufacturing})`,
      link: "/verticals/manufacturing",
    },
    {
      icon: "fa-seedling",
      title: "Food Industry",
      alt: "Utah agricultural landscape",
      backgroundImage: `url(${agriculture})`,
      link: "/verticals/food-industry",
    },
    {
      icon: "fa-cogs",
      title: "Engineering and Technology",
      alt: "Engineering & technology Utah",
      backgroundImage: `url(${engineering})`,
      link: "/verticals/engineering-and-technology",
    },
    {
      icon: "fa-laptop-code",
      title: "Computer Science and IT",
      alt: "Utah IT Industry",
      backgroundImage: `url(${computer})`,
      link: "/verticals/computer-science-and-it",
    },
    {
      icon: "fa-briefcase",
      title: "Business, Finance, and Marketing",
      alt: "Utah Business Data Anslysis",
      backgroundImage: `url(${business})`,
      link: "/verticals/business-finance-marketing",
    },

    {
      icon: "fa-hands-helping",
      title: "Human Services",
      alt: "Helping hands Utah",
      backgroundImage: `url(${human})`,
      link: "/verticals/human-services",
    },
    {
      icon: "fa-truck",
      title: "Transportation, Distribution, and Logistics",
      alt: "Utah Tansportation",
      backgroundImage: `url(${transport})`,
      link: "/verticals/transportation-distribution-logistics",
    },
    {
      icon: "fa-building",
      title: "Architecture and Construction",
      alt: "Constraction and building ",
      backgroundImage: `url(${architecture})`,
      link: "/verticals/architecture-and-construction",
    },
    {
      icon: "fa-heartbeat",
      title: "Health Science",
      alt: "Health science Utah",
      backgroundImage: `url(${health})`,
      link: "/verticals/health-science",
    },
    {
      icon: "fa-balance-scale",
      title: "Law, Public Safety, Corrections, and Security",
      alt: "Utah Law",
      backgroundImage: `url(${law})`,
      link: "/verticals/law-public-safety-corrections-security",
    },
    {
      icon: "fa-paint-brush",
      title: "Arts, Audio/Visual Technology, and Communication",
      alt: "Utah Art Creativity",
      backgroundImage: `url(${arts})`,
      link: "/verticals/arts-audio-visual-technology-and-communication",
    },
    {
      icon: "fa-graduation-cap",
      title: "Education and Training",
      alt: "Education & Training Data",
      backgroundImage: `url(${education})`,
      link: "/verticals/education-and-training",
    },
    {
      icon: "fa-concierge-bell",
      title: "Hospitality and Tourism",
      alt: "Utah Hospitality tourism",
      backgroundImage: `url(${hospitality})`,
      link: "/verticals/hospitality-and-tourism",
    },
    {
      icon: "fa-chart-diagram",
      title: "Data Science And Analytics",
      alt: "Utah Data Science and Analytics",
      backgroundImage: `url(${dataScience})`,
      link: "/verticals/data-science-and-analytics",
    },
  ];

  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Main content */}
      <div className="main-container bg-[#d2eaff]">
        <h2 className="title">
          {/* <i className="fas fa-info-circle"></i>  */}
          Verticals
        </h2>

        <div className="icon-container">
          {iconData.map((icon, index) => (
            <Link to={icon.link} key={index} style={{ textDecoration: "none" }}>
              <div
                className="icon-box"
                key={index}
                style={{
                  backgroundImage: icon.backgroundImage,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minWidth: "80px",
                }}
              >
                <i className={`fas ${icon.icon} `}></i>
                <h3 className="text-white">{icon.title}</h3>
                <img
                  src={icon.backgroundImage}
                  alt={icon.alt}
                  className="hidden-img"
                />
              </div>
            </Link>
          ))}
        </div>
      </div>

    
    </>
  );
}
