import React from 'react'

export default function School39() {

    const schoolData = 
    [
        {"schoolName": "Arrowhead School", "numberOfStudents": 552, "grades": "PK-5"},
        {"schoolName": "Bloomington Hills School", "numberOfStudents": 494, "grades": "PK-5"},
        {"schoolName": "Bloomington School", "numberOfStudents": 576, "grades": "PK-5"},
        {"schoolName": "Coral Canyon School", "numberOfStudents": 565, "grades": "PK-5"},
        {"schoolName": "Coral Cliffs School", "numberOfStudents": 135, "grades": "PK-5"},
        {"schoolName": "Crimson Cliffs High", "numberOfStudents": 1334, "grades": "10-12"},
        {"schoolName": "Crimson Cliffs Middle", "numberOfStudents": 978, "grades": "8-9"},
        {"schoolName": "Crimson View School", "numberOfStudents": 577, "grades": "PK-5"},
        {"schoolName": "Desert Canyons Elementary", "numberOfStudents": 510, "grades": "KG-5"},
        {"schoolName": "Desert Hills High", "numberOfStudents": 1210, "grades": "10-12"},
        {"schoolName": "Desert Hills Middle", "numberOfStudents": 895, "grades": "8-9"},
        {"schoolName": "Diamond Valley School", "numberOfStudents": 353, "grades": "PK-5"},
        {"schoolName": "Dixie High", "numberOfStudents": 1293, "grades": "10-12"},
        {"schoolName": "Dixie Middle", "numberOfStudents": 854, "grades": "8-9"},
        {"schoolName": "Enterprise High", "numberOfStudents": 546, "grades": "7-12"},
        {"schoolName": "Enterprise School", "numberOfStudents": 497, "grades": "PK-6"},
        {"schoolName": "Fossil Ridge Intermediate", "numberOfStudents": 734, "grades": "6-7"},
        {"schoolName": "Heritage School", "numberOfStudents": 520, "grades": "PK-5"},
        {"schoolName": "Horizon School", "numberOfStudents": 540, "grades": "PK-5"},
        {"schoolName": "Hurricane High", "numberOfStudents": 1201, "grades": "PK-12"},
        {"schoolName": "Hurricane Intermediate", "numberOfStudents": 784, "grades": "PK-7"},
        {"schoolName": "Hurricane Middle", "numberOfStudents": 864, "grades": "PK-9"},
        {"schoolName": "Hurricane School", "numberOfStudents": 706, "grades": "PK-5"},
        {"schoolName": "Lava Ridge Intermediate", "numberOfStudents": 815, "grades": "6-7"},
        {"schoolName": "Laverkin School", "numberOfStudents": 411, "grades": "PK-5"},
        {"schoolName": "Legacy School", "numberOfStudents": 565, "grades": "PK-5"},
        {"schoolName": "Little Valley School", "numberOfStudents": 670, "grades": "PK-5"},
        {"schoolName": "Majestic Fields School", "numberOfStudents": 716, "grades": "PK-5"},
        {"schoolName": "Millcreek High", "numberOfStudents": 160, "grades": "7-12"},
        {"schoolName": "Out Of State School", "numberOfStudents": 0, "grades": "PK-12"},
        {"schoolName": "Panorama School", "numberOfStudents": 496, "grades": "PK-5"},
        {"schoolName": "Paradise Canyon", "numberOfStudents": 478, "grades": "PK-5"},
        {"schoolName": "Pine View High", "numberOfStudents": 1143, "grades": "10-12"},
        {"schoolName": "Pine View Middle", "numberOfStudents": 779, "grades": "8-9"},
        {"schoolName": "Post Hs Self-Cont", "numberOfStudents": 78, "grades": "KG-12"},
        {"schoolName": "Red Mountain School", "numberOfStudents": 434, "grades": "PK-5"},
        {"schoolName": "Riverside School", "numberOfStudents": 610, "grades": "PK-5"},
        {"schoolName": "Sandstone School", "numberOfStudents": 506, "grades": "PK-5"},
        {"schoolName": "Santa Clara School", "numberOfStudents": 483, "grades": "PK-5"},
        {"schoolName": "Snow Canyon High", "numberOfStudents": 1266, "grades": "10-12"},
        {"schoolName": "Snow Canyon Middle", "numberOfStudents": 909, "grades": "PK-9"},
        {"schoolName": "South Mesa Elementary", "numberOfStudents": 565, "grades": "KG-5"},
        {"schoolName": "Southwest High", "numberOfStudents": 0, "grades": "9-12"},
        {"schoolName": "Springdale School", "numberOfStudents": 51, "grades": "PK-5"},
        {"schoolName": "Sunrise Ridge Intermediate", "numberOfStudents": 810, "grades": "6-7"},
        {"schoolName": "Sunset School", "numberOfStudents": 496, "grades": "PK-5"},
        {"schoolName": "Three Falls School", "numberOfStudents": 592, "grades": "PK-5"},
        {"schoolName": "Tonaquint Intermediate", "numberOfStudents": 735, "grades": "6-7"},
        {"schoolName": "Utah Online 7-12", "numberOfStudents": 1220, "grades": "6-12"},
        {"schoolName": "Utah Online K8", "numberOfStudents": 3428, "grades": "KG-8"},
        {"schoolName": "Washington Fields Intermediate", "numberOfStudents": 830, "grades": "6-7"},
        {"schoolName": "Washington Preschool", "numberOfStudents": 507, "grades": "PK-PK"},
        {"schoolName": "Washington School", "numberOfStudents": 463, "grades": "PK-5"},
        {"schoolName": "Water Canyon High", "numberOfStudents": 349, "grades": "7-12"},
        {"schoolName": "Water Canyon School", "numberOfStudents": 289, "grades": "PK-6"}
      ]
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Washington
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
