import AboutUs from "./components/pages/AboutUs";
import Faq from "./components/pages/Manufacturing";

import HomePage from "./components/pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./components/pages/ContactUs";
import { useRef } from "react";
import Verticals from "./components/pages/Verticals";
import Aerospace from "./components/pages/Aerospace";
import Agriculture from "./components/pages/Agriculture";
import BusinessFinance from "./components/pages/Business-Finance";
import Engineering from "./components/pages/Engineering";
import HumanServices from "./components/pages/Human-services";
import Transportation from "./components/pages/Transportation";
import Architecture from "./components/pages/Architecture-construction";
import ComputerScience from "./components/pages/Computer-science";
import HealthScience from "./components/pages/Health-science";
import LawPublicSafety from "./components/pages/Law-public-safety";
import Arts from "./components/pages/Arts";
import Education from "./components/pages/Education";
import Hospitality from "./components/pages/Hospitality";
import Version from "./components/pages/Version";
import AskBetsyAI from "./components/pages/AskBetsyAI";
import "bootstrap/dist/css/bootstrap.min.css";
import UtahAtGlance from "./components/pages/UtahAtGlance";
import Footer from "./components/pages/Footer";
import NewNavbar from "./components/pages/NewNavbar";
import { useLocation } from "react-router-dom";
import UtahAtGlance1 from "./components/pages/UathAtGlance1";
import DataSet from "./components/pages/DataSet";
import ArcGISMap from "./components/pages/ArcGISMap";
// import FourtySevenGMap from "./components/pages/FourtySevenGMap";
import GoogleMapWithUtah from "./components/pages/CountyMap";
import GenderPopulationPage from "./components/pages/PopulationDemography";
import UtahCities from "./components/pages/UtahCities";
import UtahUniversities from "./components/pages/UtahUniversities";
import UnemploymentRate from "./components/pages/UnemploymentRate";
import UtahJobs from "./components/pages/UtahJobs";
import UtahIndustries from "./components/pages/UtahIndustries";
import UtahMap from "./components/pages/UtahMap";
import UtahOlympic from "./components/pages/UtahOlympic";
import SchoolDistrict from "./components/pages/SchoolDistrict";
import School1 from "./components/pages/Schools/School1";
import School2 from "./components/pages/Schools/School2";
import School3 from "./components/pages/Schools/School3";
import School4 from "./components/pages/Schools/School4";
import School5 from "./components/pages/Schools/School5";
import School6 from "./components/pages/Schools/School6";
import School7 from "./components/pages/Schools/School7";
import School8 from "./components/pages/Schools/School8";
import School9 from "./components/pages/Schools/School9";
import School10 from "./components/pages/Schools/School10";
import School11 from "./components/pages/Schools/School11";
import School12 from "./components/pages/Schools/School12";
import School13 from "./components/pages/Schools/School13";
import School14 from "./components/pages/Schools/School14";
import School15 from "./components/pages/Schools/School15";
import School16 from "./components/pages/Schools/School16";
import School17 from "./components/pages/Schools/School17";
import School18 from "./components/pages/Schools/School18";
import School19 from "./components/pages/Schools/School19";
import School20 from "./components/pages/Schools/School20";
import School21 from "./components/pages/Schools/School21";
import School22 from "./components/pages/Schools/School22";
import School23 from "./components/pages/Schools/School23";
import School24 from "./components/pages/Schools/School24";
import School25 from "./components/pages/Schools/School25";
import School26 from "./components/pages/Schools/School26";
import School27 from "./components/pages/Schools/School27";
import School28 from "./components/pages/Schools/School28";
import School29 from "./components/pages/Schools/School29";
import School30 from "./components/pages/Schools/School30";
import School31 from "./components/pages/Schools/School31";
import School32 from "./components/pages/Schools/School32";
import School33 from "./components/pages/Schools/School33";
import School34 from "./components/pages/Schools/School34";
import School35 from "./components/pages/Schools/School35";
import School36 from "./components/pages/Schools/School36";
import School37 from "./components/pages/Schools/School37";
import School38 from "./components/pages/Schools/School38";
import School39 from "./components/pages/Schools/School39";
import School40 from "./components/pages/Schools/School40";
import School41 from "./components/pages/Schools/School41";
import ScrollToTop from "./components/pages/ScrollToTop";
import UtahGoverner from "./components/pages/UtahGovernor";
import CredentialsSignInPage from "./components/pages/Login";
import ContactUs1 from "./components/pages/ContactUs1";
import Torch404 from "./components/pages/Torch404";
import { useMatch } from "react-router-dom";
import GeothermalMap from "./components/pages/GeoThermal";
import NationalPark from "./components/pages/NationalPark";
import StateParks from "./components/pages/StateParks";
import NationalPark1 from "./components/pages/NationalPark/NationalPark1";
import NationalPark2 from "./components/pages/NationalPark/NationalPark2";
import NationalPark3 from "./components/pages/NationalPark/NationalPark3";
import NationalPark4 from "./components/pages/NationalPark/NationalPark4";
import NationalPark5 from "./components/pages/NationalPark/NationalPark5";
import { Login } from "@mui/icons-material";
import Login1 from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Airforce from "./components/pages/Airforce";
import ZipCode from "./components/pages/Zipcode";
import Airport1 from "./components/pages/Airports";
import Vertiport from "./components/pages/Vertiport";
import Blog from "./components/pages/Utah-blog";
import BlogSaltlake from "./components/pages/Blog-saltlake";
import BlogBever from "./components/pages/Blog-Bever";
import BlogBoxElder from "./components/pages/Blog-BoxElder";
import DataScience from "./components/pages/DataScience";
import BlogCacheCounty from "./components/pages/Blog-CacheCounty";
import UtahCounty from "./components/pages/UtahCounty";
import BlogCarbon from "./components/pages/Blog-Carbon";
import MLM from "./components/pages/MLM";
// import StationCharging from "./components/pages/StationCharging";
// import StationCharging from "./components/pages/Station-Charging";
import StationCharging1 from "./components/pages/StationCharging";
import Hospital from "./components/pages/Hospital";
import UtahHousing from "./components/pages/UtahHousing";
import VotersCount from "./components/pages/VotersCount";
import PlacesToVisit from "./components/pages/PlacesToVisit";
import PlacesNationalForest from "./components/pages/PlacesNationalForest";
import PlacesWildlifeRefuges from "./components/pages/PlacesWildlifeRefuges";
import PlacesRecreation from "./components/pages/PlacesRecreation";
import Placeshistory from "./components/pages/Placeshistory";
import PlaceMonument from "./components/pages/PlaceMonument";
import PlacesForestService from "./components/pages/PlacesForestService";
import PlacesLand from "./components/pages/PlacesLand";
import TotalVoters from "./components/pages/TotalVoters";
import ActiveVoters from "./components/pages/ActiveVoters";
import InActiveVoters from "./components/pages/InActiveVoters";
import Finance from "./components/pages/Finance";
import NationalBank from "./components/pages/NationalBank";
import OutOfState from "./components/pages/OutOfState";
import StateIndustrial from "./components/pages/StateIndustrial";
import FedralCredit from "./components/pages/FedralCredit";
import FedralSavings from "./components/pages/FedralSavings";
import StateCredit from "./components/pages/StateCredit";
import TrustCompany from "./components/pages/TrustCompany";
import StateBanks from "./components/pages/StateBanks";
import Copyright from "./components/pages/Copyright";
import AirQualityDashboard from "./components/pages/AirQuality";
// import Airport1 from "./components/pages/Airport";
//import Airport from "./components/pages/Airport";

// New component to handle routes and location-dependent logic
function AppContent({ swiperRef }) {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isTorch404 = useMatch("*");
  return (
    <div className="min-h-screen flex flex-col">
      <ScrollToTop />
      {isHomePage ? null : <NewNavbar />}
      <main className="flex-1 bg-[#d2eaff]">
        <HelmetProvider>
          <Routes>
            {/* Home */}
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>
                      Explore Utah by the Numbers | Utah Data | Statistics
                    </title>
                    <meta
                      property="og:title"
                      content=" Explore Utah by the Numbers | Utah Data | Statistics"
                    />
                    <meta
                      name="description"
                      content="Explore Utah data, analytics & insights on the economy, demographics & more. View key trends & the Utah map to make informed decisions at UtahData.org."
                    />
                    <link rel="canonical" href="https://utahdata.org/" />
                    <meta
                      property="og:description"
                      content="Explore Utah data, analytics & insights on the economy, demographics & more. View key trends & the Utah map to make informed decisions at UtahData.org."
                    />
                  </Helmet>
                  <HomePage swiperRef={swiperRef} />
                </>
              }
            />

            <Route path="/utah-at-a-glance/air-quality-index" 
               element={
                <>
                  <Helmet>
                    <title>
                    Utah Air Quality Index | Real-Time AQI Data | UtahData.org
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Air Quality Index | Real-Time AQI Data | UtahData.org"
                    />

                    <meta
                      name="description"
                      content="Check Utah's real-time Air Quality Index (AQI) on UtahData.org. Stay informed about air pollution levels and breathe healthier with up-to-date data."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/air-quality-index"
                    />
                    <meta
                      property="og:description"
                      content="Check Utah's real-time Air Quality Index (AQI) on UtahData.org. Stay informed about air pollution levels and breathe healthier with up-to-date data."
                    />
                  </Helmet>
                  <AirQualityDashboard />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/department-of-financial-institution"
              element={
                <>
                  <Helmet>
                    <title>
                    Utah Financial Institutions | Banks | Credit Unions | UtahData
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Financial Institutions | Banks | Credit Unions | UtahData"
                    />

                    <meta
                      name="description"
                      content="Explore Utah's financial institutions, including national & state banks, credit unions, savings associations, and trust companies. Get insights at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/department-of-financial-institution"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah's financial institutions, including national & state banks, credit unions, savings associations, and trust companies. Get insights at UtahData.org."
                    />
                  </Helmet>
                  <Finance />
                </>
              }
            />


<Route
              path="/copyright"
              element={<Copyright/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/national-bank"
              element={<NationalBank/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/state-bank"
              element={<StateBanks/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/out-of-state"
              element={<OutOfState/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/state-industrial"
              element={<StateIndustrial/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/fedral-credit"
              element={<FedralCredit/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/fedral-savings"
              element={<FedralSavings/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/state-credit"
              element={<StateCredit/>}
            />

<Route
              path="/utah-at-a-glance/department-of-financial-institution/trust-company"
              element={<TrustCompany/>}
            />
            

            {/* county wise pages */}

            {/* <Route
              path="/utah-at-a-glance/counties/utah-county"
              element={
                <>
                  <Blog />
                </>
              }
            /> */}
            <Route
              path="/counties/utah-county"
              element={
                <>
                  <UtahCounty />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-hospitals"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Hospitals | Locations | Services | Healthcare Info
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Hospitals | Locations | Services | Healthcare Info"
                    />

                    <meta
                      name="description"
                      content="Explore Utah hospitals, their locations, services, and healthcare facilities. Find essential medical resources at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-hospitals"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah hospitals, their locations, services, and healthcare facilities. Find essential medical resources at UtahData.org."
                    />
                  </Helmet>
                  <Hospital />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/utah-charging-stations"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah EV Charging Stations | Locations | Availability
                    </title>
                    <meta
                      property="og:title"
                      content="Utah EV Charging Stations | Locations | Availability"
                    />
                    <meta
                      name="description"
                      content="Find Utah EV charging stations by location and accessibility. Explore charging options for electric vehicles statewide at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-charging-stations"
                    />
                    <meta
                      property="og:description"
                      content="Find Utah EV charging stations by location and accessibility. Explore charging options for electric vehicles statewide at UtahData.org."
                    />
                  </Helmet>
                  <StationCharging1 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/counties/saltlake-county"
              element={
                <>
                  <BlogSaltlake />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/counties/beaver-county"
              element={
                <>
                  <BlogBever />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/counties/boxelder-county"
              element={
                <>
                  <BlogBoxElder />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/counties/cache-county"
              element={
                <>
                  <BlogCacheCounty />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/counties/carbon-county"
              element={
                <>
                  <BlogCarbon />
                </>
              }
            />

            {/* Verticals */}
            <Route
              path="/verticals"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Data Verticals | Explore Key Sectors | Trends
                    </title>
                    <meta
                      property="og:title"
                      content="  Utah Data Verticals | Explore Key Sectors | Trends"
                    />
                    <meta
                      name="description"
                      content="Explore UtahData.org verticals for in-depth, interactive Utah data insights on Utah's economy, demographics, education, and more—empowering informed decisions."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals"
                    />
                    <meta
                      property="og:description"
                      content="Explore UtahData.org verticals for in-depth, interactive Utah data insights on Utah's economy, demographics, education, and more—empowering informed decisions."
                    />
                  </Helmet>
                  <Verticals />
                </>
              }
            />
            <Route
              path="/verticals/aerospace-and-defense"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Aerospace & Defense | Industry Data | Insights
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Aerospace & Defense | Industry Data | Insights"
                    />
                    <meta
                      name="description"
                      content="Discover insights on Utah's aerospace and defense industry. Explore key data, trends, and statistics shaping innovation and growth in this vital sector."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/aerospace-and-defense"
                    />
                    <meta
                      property="og:description"
                      content="Discover insights on Utah's aerospace and defense industry. Explore key data, trends, and statistics shaping innovation and growth in this vital sector."
                    />
                  </Helmet>
                  <Aerospace />
                </>
              }
            />
            <Route
              path="/verticals/manufacturing"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Manufacturing Industry | Data, Growth | Analysis
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Manufacturing Industry | Data, Growth | Analysis"
                    />
                    <meta
                      name="description"
                      content="Explore Utah's manufacturing industry with key data on growth, employment, trends, and economic impact. Get insights into Utah's thriving manufacturing sector at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/manufacturing"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah's manufacturing industry with key data on growth, employment, trends, and economic impact. Get insights into Utah's thriving manufacturing sector at UtahData.org."
                    />
                  </Helmet>
                  <Faq />
                </>
              }
            />
            <Route
              path="/verticals/food-industry"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Food Industry Insights | Trends, Data | Market
                      Analysis
                    </title>
                    <meta
                      property="og:title"
                      content=" Utah Food Industry Insights | Trends, Data | Market
                      Analysis"
                    />
                    <meta
                      name="description"
                      content="Discover key insights into Utah’s food industry, including market trends, employment data, economic impact, and growth opportunities. Explore more at UtahData.org"
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/food-industry"
                    />
                    <meta
                      property="og:description"
                      content="Discover key insights into Utah’s food industry, including market trends, employment data, economic impact, and growth opportunities. Explore more at UtahData.org"
                    />
                  </Helmet>
                  <Agriculture />
                </>
              }
            />
            <Route
              path="/verticals/engineering-and-technology"
              element={
                <>
                  <Helmet>
                    <title>
                      Top Engineering & Technology Careers in Utah | growth |
                      Opportunities
                    </title>
                    <meta
                      property="og:title"
                      content="Top Engineering & Technology Careers in Utah | growth |
                      Opportunities"
                    />
                    <meta
                      name="description"
                      content="Explore Utah engineering industry, a hub of innovation and growth. Discover key trends, job opportunities, top companies, and technological advancements in Utah."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/engineering-and-technology"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah engineering industry, a hub of innovation and growth. Discover key trends, job opportunities, top companies, and technological advancements in Utah."
                    />
                  </Helmet>
                  <Engineering />
                </>
              }
            />
            <Route
              path="/verticals/business-finance-marketing"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/business-finance-marketing"
                    />
                  </Helmet>
                  <BusinessFinance />
                </>
              }
            />
            <Route
              path="/verticals/human-services"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/human-services"
                    />
                  </Helmet>
                  <HumanServices />
                </>
              }
            />
            <Route
              path="/verticals/transportation-distribution-logistics"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/transportation-distribution-logistics"
                    />
                  </Helmet>
                  <Transportation />
                </>
              }
            />
            <Route
              path="/verticals/architecture-and-construction"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/architecture-and-construction"
                    />
                  </Helmet>
                  <Architecture />
                </>
              }
            />
            <Route
              path="/verticals/computer-science-and-it"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/computer-science-and-it"
                    />
                  </Helmet>
                  <ComputerScience />
                </>
              }
            />
            <Route
              path="/verticals/health-science"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/health-science"
                    />
                  </Helmet>
                  <HealthScience />
                </>
              }
            />
            <Route
              path="/verticals/law-public-safety-corrections-security"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/law-public-safety-corrections-security"
                    />
                  </Helmet>
                  <LawPublicSafety />
                </>
              }
            />
            <Route
              path="/verticals/arts-audio-visual-technology-and-communication"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Arts Audio visual technology and communication
                    </title>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/arts-audio-visual-technology-and-communication"
                    />
                  </Helmet>
                  <Arts />
                </>
              }
            />
            <Route
              path="/verticals/education-and-training"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/education-and-training"
                    />
                  </Helmet>
                  <Education />
                </>
              }
            />
            <Route
              path="/verticals/hospitality-and-tourism"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/hospitality-and-tourism"
                    />
                  </Helmet>
                  <Hospitality />
                </>
              }
            />
            <Route
              path="/verticals/data-science-and-analytics"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/verticals/data-science-and-analytics"
                    />
                  </Helmet>
                  <DataScience />
                </>
              }
            />

            {/* Utah At A Glance */}
            <Route
              path="/utah-at-a-glance"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Data at a Glance: Comprehensive State Statistics |
                      Insights
                    </title>
                    <meta
                      property="og:title"
                      content="   Utah Data at a Glance: Comprehensive State Statistics |
                      Insights"
                    />
                    <meta
                      name="description"
                      content="Discover Utah at a glance with key insights on places to visit, Jobs, top universities, Schools in Utah, and Total Population."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance"
                    />
                    <meta
                      property="og:description"
                      content="Discover Utah at a glance with key insights on places to visit, Jobs, top universities, Schools in Utah, and Total Population."
                    />
                  </Helmet>
                  <UtahAtGlance />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/places"
              element={
                <>
                  <Helmet>
                    <title>
                    Utah's Parks, Forests | Wilderness Areas | UtahData
                    </title>
                    <meta
                      property="og:title"
                      content="Utah's Parks, Forests | Wilderness Areas | UtahData"
                    />

                    <meta
                      name="description"
                      content="Explore Utah's national parks, forests, wildlife refuges, historic sites, and wilderness areas. Discover protected lands and recreation spots at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/places"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah's national parks, forests, wildlife refuges, historic sites, and wilderness areas. Discover protected lands and recreation spots at UtahData.org."
                    />
                  </Helmet>
                  <PlacesToVisit />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/places/national-forests"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <PlacesNationalForest />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/places/national-wildlife-refuges"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <PlacesWildlifeRefuges />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/places/national-recreation-area"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <PlacesRecreation />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/places/national-historic-site"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <Placeshistory />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/places/national-monument"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <PlaceMonument />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/places/national-forest-services"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <PlacesForestService />
                </>
              }
            />

            <Route
              path="/utah-at-a-glance/places/land-management-wilderness"
              element={
                <>
                  <Helmet>
                    {/* <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    /> */}
                  </Helmet>
                  <PlacesLand />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-counties"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-counties"
                    />
                  </Helmet>
                  <GoogleMapWithUtah />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-cities"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Cities Data & Statistics | Population | Growth
                      Insight
                    </title>
                    <meta
                      property="og:title"
                      content=" Utah Cities Data & Statistics | Population | Growth
                      Insight"
                    />
                    <meta
                      name="description"
                      content="Explore key insights on Utah cities, including population, economy, demographics, and growth trends. Discover data-driven facts about major cities in Utah at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-cities"
                    />
                    <meta
                      property="og:description"
                      content="Explore key insights on Utah cities, including population, economy, demographics, and growth trends. Discover data-driven facts about major cities in Utah at UtahData.org."
                    />
                  </Helmet>
                  <UtahCities />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-gender-population"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Gender Population Data | Comprehensive Gender
                      Demographics | Insights
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Gender Population Data | Comprehensive Gender
                      Demographics | Insights"
                    />
                    <meta
                      name="description"
                      content="Explore Utah's gender population data, including male-to-female ratios, demographic trends, and societal insights. Get key statistics on Utah’s population at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-gender-population"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah's gender population data, including male-to-female ratios, demographic trends, and societal insights. Get key statistics on Utah’s population at UtahData.org."
                    />
                  </Helmet>
                  <GenderPopulationPage />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-jobs"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Job Market Trends | Employment Data | Industry
                      Insights
                    </title>
                    <meta
                      property="og:title"
                      content=" Utah Job Market Trends | Employment Data | Industry
                      Insights"
                    />
                    <meta
                      name="description"
                      content="Explore Utah’s job market with insights on employment trends, top industries, salaries, and career opportunities. Stay updated on workforce data at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-jobs"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah’s job market with insights on employment trends, top industries, salaries, and career opportunities. Stay updated on workforce data at UtahData.org."
                    />
                  </Helmet>
                  <UtahJobs />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-universities"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Universities & Colleges | Rankings | Enrollment |
                      Insights
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Universities & Colleges | Rankings | Enrollment |
                      Insights"
                    />
                    <meta
                      name="description"
                      content="Discover top universities in Utah with key insights on rankings, programs, tuition, and student demographics. Explore higher education trends at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-universities"
                    />
                    <meta
                      property="og:description"
                      content="Discover top universities in Utah with key insights on rankings, programs, tuition, and student demographics. Explore higher education trends at UtahData.org."
                    />
                  </Helmet>
                  <UtahUniversities />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-unemployment-rate"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Unemployment Rate | Trends, Statistics | Economic
                      Insights
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Unemployment Rate | Trends, Statistics | Economic
                      Insights"
                    />
                    <meta
                      name="description"
                      content="Discover the latest Utah unemployment rate trends, statistics, and insights. Stay updated with comprehensive data and analysis on Utah's job market."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-unemployment-rate"
                    />
                    <meta
                      property="og:description"
                      content="Discover the latest Utah unemployment rate trends, statistics, and insights. Stay updated with comprehensive data and analysis on Utah's job market."
                    />
                  </Helmet>
                  <UnemploymentRate />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-industries"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Industries Overview | Key Sectors | Economic Insights
                    </title>
                    <meta
                      property="og:title"
                      content=" Utah Industries Overview | Key Sectors | Economic Insights"
                    />
                    <meta
                      name="description"
                      content="Explore Utah's diverse industries, from tech and finance to manufacturing and tourism. Get insights into the economic drivers shaping Utah's robust economy."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-industries"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah's diverse industries, from tech and finance to manufacturing and tourism. Get insights into the economic drivers shaping Utah's robust economy."
                    />
                  </Helmet>
                  <UtahIndustries />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools"
              element={
                <>
                  <Helmet>
                    <title>
                      Top-Ranked Utah Schools Data | Public, Charter | Private
                      School Ratings
                    </title>
                    <meta
                      property="og:title"
                      content=" Top-Ranked Utah Schools Data | Public, Charter | Private
                      School Ratings"
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools"
                    />
                    <meta
                      name="description"
                      content="Explore top-ranked Utah schools Data, Find ratings for public, charter, and private institutions to choose the best educational options for your child"
                    />
                    <meta
                      property="og:description"
                      content="Explore top-ranked Utah schools Data, Find ratings for public, charter, and private institutions to choose the best educational options for your child"
                    />
                  </Helmet>
                  <SchoolDistrict />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/alpine"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/alpine"
                    />
                  </Helmet>
                  <School1 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/beaver"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/beaver"
                    />
                  </Helmet>
                  <School2 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/boxelder"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/boxelder"
                    />
                  </Helmet>
                  <School3 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/cache"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/cache"
                    />
                  </Helmet>
                  <School4 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/canyons"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/canyons"
                    />
                  </Helmet>
                  <School5 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/carbon"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/carbon"
                    />
                  </Helmet>
                  <School6 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/daggett"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/daggett"
                    />
                  </Helmet>
                  <School7 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/davis"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/davis"
                    />
                  </Helmet>
                  <School8 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/duchesne"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/duchesne"
                    />
                  </Helmet>
                  <School9 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/emery"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/emery"
                    />
                  </Helmet>
                  <School10 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/garfield"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/garfield"
                    />
                  </Helmet>
                  <School11 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/grand"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/grand"
                    />
                  </Helmet>
                  <School12 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/granite"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/granite"
                    />
                  </Helmet>
                  <School13 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/iron"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/iron"
                    />
                  </Helmet>
                  <School14 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/jordan"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/jordan"
                    />
                  </Helmet>
                  <School15 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/juab"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/juab"
                    />
                  </Helmet>
                  <School16 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/kane"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/kane"
                    />
                  </Helmet>
                  <School17 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/logan"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/logan"
                    />
                  </Helmet>
                  <School18 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/millard"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/millard"
                    />
                  </Helmet>
                  <School19 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/morgan"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/morgan"
                    />
                  </Helmet>
                  <School20 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/murray"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/murray"
                    />
                  </Helmet>
                  <School21 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/nebo"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/nebo"
                    />
                  </Helmet>
                  <School22 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/sanpete"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/sanpete"
                    />
                  </Helmet>
                  <School23 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/summit"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/summit"
                    />
                  </Helmet>
                  <School24 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/ogden"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/ogden"
                    />
                  </Helmet>
                  <School25 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/park"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/park"
                    />
                  </Helmet>
                  <School26 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/piute"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/piute"
                    />
                  </Helmet>
                  <School27 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/provo"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/provo"
                    />
                  </Helmet>
                  <School28 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/rich"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/rich"
                    />
                  </Helmet>
                  <School29 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/salt"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/salt"
                    />
                  </Helmet>
                  <School30 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/san"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/san"
                    />
                  </Helmet>
                  <School31 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/sevier"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/sevier"
                    />
                  </Helmet>
                  <School32 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/southSanpete"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/southSanpete"
                    />
                  </Helmet>
                  <School33 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/southSummit"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/southSummit"
                    />
                  </Helmet>
                  <School34 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/tintic"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/tintic"
                    />
                  </Helmet>
                  <School35 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/tooele"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/tooele"
                    />
                  </Helmet>
                  <School36 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/uintah"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/uintah"
                    />
                  </Helmet>
                  <School37 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/wasatch"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/wasatch"
                    />
                  </Helmet>
                  <School38 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/washington"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/washington"
                    />
                  </Helmet>
                  <School39 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/wayne"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/wayne"
                    />
                  </Helmet>
                  <School40 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-schools/weber"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-schools/weber"
                    />
                  </Helmet>
                  <School41 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-nationalparks"
              element={
                <>
                  <Helmet>
                    <title>
                      Explore Utah's National Parks Data | Top-Rated Arches |
                      Bryce Canyon
                    </title>
                    <meta
                      property="og:title"
                      content="Explore Utah's National Parks Data | Top-Rated Arches |
                      Bryce Canyon"
                    />
                    <meta
                      name="description"
                      content="Discover Utah's top national parks, including Arches, Bryce Canyon, and Zion. Explore stunning landscapes, hiking trails, and unique geological formations."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-nationalparks"
                    />
                    <meta
                      property="og:description"
                      content="Discover Utah's top national parks, including Arches, Bryce Canyon, and Zion. Explore stunning landscapes, hiking trails, and unique geological formations."
                    />
                  </Helmet>
                  <NationalPark />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-nationalparks/arches"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-nationalparks/arches"
                    />
                  </Helmet>
                  <NationalPark1 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-nationalparks/bryce-canyon"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-nationalparks/bryce-canyon"
                    />
                  </Helmet>
                  <NationalPark2 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-nationalparks/canyonland"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-nationalparks/canyonland"
                    />
                  </Helmet>
                  <NationalPark3 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-nationalparks/capitolreef"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-nationalparks/capitolreef"
                    />
                  </Helmet>
                  <NationalPark4 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-nationalparks/zion"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-nationalparks/zion"
                    />
                  </Helmet>
                  <NationalPark5 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-stateparks"
              element={
                <>
                  <Helmet>
                    <title>
                      Top-Rated Utah State Parks | Hiking, Camping | Outdoor
                      Adventures
                    </title>
                    <meta
                      property="og:title"
                      content="Top-Rated Utah State Parks | Hiking, Camping | Outdoor
                      Adventures"
                    />
                    <meta
                      name="description"
                      content="Discover Utah's stunning state parks, offering breathtaking landscapes, outdoor adventures, and family-friendly activities.Explore Utah Data To Know More."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-stateparks"
                    />
                    <meta
                      property="og:description"
                      content="Discover Utah's stunning state parks, offering breathtaking landscapes, outdoor adventures, and family-friendly activities.Explore Utah Data To Know More."
                    />
                  </Helmet>
                  <StateParks />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-geothermal-hotspots"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Geothermal Hotspots | Explore Renewable Energy
                      Sources
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Geothermal Hotspots | Explore Renewable Energy
                      Sources"
                    />
                    <meta
                      name="description"
                      content="Explore Utah's geothermal hotspots, harnessing natural heat for sustainable energy. Learn about geothermal power plants and their impact on Utah's energy landscape."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-geothermal-hotspots"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah's geothermal hotspots, harnessing natural heat for sustainable energy. Learn about geothermal power plants and their impact on Utah's energy landscape."
                    />
                  </Helmet>
                  <GeothermalMap />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-hill-airforce-base"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Hill Air Force Base | Key Facts | Historical Insights
                    </title>
                    <meta
                      property="og:title"
                      content=" Utah Hill Air Force Base | Key Facts | Historical Insights"
                    />
                    <meta
                      name="description"
                      content="Learn about Hill Air Force Base, a key military installation in Utah. Discover its history, mission, and impact on the local community and economy."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-hill-airforce-base"
                    />
                    <meta
                      property="og:description"
                      content="Learn about Hill Air Force Base, a key military installation in Utah. Discover its history, mission, and impact on the local community and economy."
                    />
                  </Helmet>
                  <Airforce />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-governor"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Governor | Leadership | Policies | Updates | UtahData
                    </title>
                    <meta
                      property="og:title"
                      content="  Utah Governor | Leadership | Policies | Updates | UtahData"
                    />

                    <meta
                      name="description"
                      content="Discover the Utah Governor’s leadership, policies, and government updates. Stay informed on governance and state initiatives at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-governor"
                    />
                    <meta
                      property="og:description"
                      content="Discover the Utah Governor’s leadership, policies, and government updates. Stay informed on governance and state initiatives at UtahData.org."
                    />
                  </Helmet>
                  <UtahGoverner />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-airports-heliports"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Airports & Heliports | Locations | Travel Info
                    </title>
                    <meta
                      property="og:title"
                      content="   Utah Airports & Heliports | Locations | Travel Info"
                    />

                    <meta
                      name="description"
                      content="Find details on Utah airports and heliports, including locations, facilities, and travel information. Explore aviation data at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-airports-heliports"
                    />
                    <meta
                      property="og:description"
                      content="Find details on Utah airports and heliports, including locations, facilities, and travel information. Explore aviation data at UtahData.org."
                    />
                  </Helmet>
                  <Airport1 />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-vertiports"
              element={
                <>
                  <Helmet>
                    <title>
                      utah Vertiports | Locations | Facilities | Operations
                    </title>
                    <meta
                      property="og:title"
                      content="  utah Vertiports | Locations | Facilities | Operations"
                    />

                    <meta
                      name="description"
                      content="Explore Utah vertiports, including locations, facilities, and operations. Learn about their role in advanced air mobility at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-vertiports"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah vertiports, including locations, facilities, and operations. Learn about their role in advanced air mobility at UtahData.org."
                    />
                  </Helmet>
                  <Vertiport />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-zipcodes"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Zip Codes | Find Postal Codes by City & County
                    </title>
                    <meta
                      property="og:title"
                      content="   Utah Zip Codes | Find Postal Codes by City & County"
                    />
                    <meta
                      name="description"
                      content="Utah zip codes by city and county. Get detailed postal code data for all regions in Utah, making location searches easy at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-zipcodes"
                    />
                    <meta
                      property="og:description"
                      content="Utah zip codes by city and county. Get detailed postal code data for all regions in Utah, making location searches easy at UtahData.org."
                    />
                  </Helmet>
                  <ZipCode />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-mlm"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah MLM | Multi-Level Marketing Companies | Insights
                    </title>
                    <meta
                      property="og:title"
                      content=" Utah MLM | Multi-Level Marketing Companies | Insights"
                    />
                    <meta
                      name="description"
                      content="Discover Utah’s multi-level marketing (MLM) industry, top companies, business insights, and its economic impact. Explore MLM data at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/utah-mlm"
                    />
                    <meta
                      property="og:description"
                      content="Discover Utah’s multi-level marketing (MLM) industry, top companies, business insights, and its economic impact. Explore MLM data at UtahData.org."
                    />
                  </Helmet>
                  <MLM />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-housing"
              element={
                <>
                  <Helmet>
                    <title>
                      Utah Housing | Market Trends | Prices | Living Insights
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Housing | Market Trends | Prices | Living Insights"
                    />

                    <meta
                      name="description"
                      content="Explore Utah’s housing market trends, home prices, and living insights. Get updates on real estate and affordability at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-housing"
                    />
                    <meta
                      property="og:description"
                      content="Explore Utah’s housing market trends, home prices, and living insights. Get updates on real estate and affordability at UtahData.org."
                    />
                  </Helmet>
                  <UtahHousing />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-voters-count"
              element={
                <>
                  <Helmet>
                    <title>
                    Utah Voters Count | UtahData.org
                    </title>
                    <meta
                      property="og:title"
                      content="Utah Voters Count | UtahData.org"
                    />

                    <meta
                      name="description"
                      content="Utah's active and non-active voter counts and trends. Stay updated with the latest voter data on UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-voters-count"
                    />
                    <meta
                      property="og:description"
                      content="Utah's active and non-active voter counts and trends. Stay updated with the latest voter data on UtahData.org."
                    />
                  </Helmet>
                  <VotersCount />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-voters-count/total-voters"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-voters-count/total-voters"
                    />
                  </Helmet>
                  <TotalVoters />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-voters-count/active-voters"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-voters-count/active-voters"
                    />
                  </Helmet>
                  <ActiveVoters />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/utah-voters-count/inactive-voters"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://utahdata.org/utah-at-a-glance/utah-voters-count/inactive-voters"
                    />
                  </Helmet>
                  <InActiveVoters />
                </>
              }
            />

            {/* Betsy */}
            <Route
              path="/betsy"
              element={
                <>
                  <Helmet>
                    <title>
                      Meet Betsy | AI Chatbot for Instant Queries & Assistance
                    </title>
                    <meta
                      property="og:title"
                      content="   Meet Betsy | AI Chatbot for Instant Queries & Assistance"
                    />
                    <meta
                      name="description"
                      content="Meet Betsy at UtahData.org—your guide to Utah’s data insights. Explore trends in demographics, economy, and more with user-friendly, interactive analytics."
                    />
                    <link rel="canonical" href="https://utahdata.org/betsy" />
                    <meta
                      property="og:description"
                      content="Meet Betsy at UtahData.org—your guide to Utah’s data insights. Explore trends in demographics, economy, and more with user-friendly, interactive analytics."
                    />
                  </Helmet>
                  <AskBetsyAI />
                </>
              }
            />

            {/* Olympics */}
            {/* <Route
              path="/slc-ut2034-count-down"
              element={
                <>
                  <Helmet>
                    <title>
                      Salt Lake City 2034 Countdown | Winter Olympics |
                      Paralympics
                    </title>
                    <meta
                      property="og:title"
                      content=" Salt Lake City 2034 Countdown | Winter Olympics |
                      Paralympics"
                    />
                    <meta
                      name="description"
                      content="Join the countdown to 2034 in Salt Lake City! Explore data, trends, and key milestones shaping the city's future growth and development at UtahData.org."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/slc-ut2034-count-down"
                    />
                    <meta
                      property="og:description"
                      content="Join the countdown to 2034 in Salt Lake City! Explore data, trends, and key milestones shaping the city's future growth and development at UtahData.org."
                    />
                  </Helmet>
                  <UtahOlympic />
                </>
              }
            /> */}

            {/* Contact Us */}
            <Route
              path="/contact-us"
              element={
                <>
                  <Helmet>
                    <title>Contact Us | Get in Touch with Utah Data Team</title>
                    <meta
                      property="og:title"
                      content="Contact Us | Get in Touch with Utah Data Team"
                    />
                    <meta
                      name="description"
                      content="Contact UtahData.org for inquiries, support, and feedback. We're here to help you access valuable data insights and hear your thoughts on Utah’s dynamic growth."
                    />
                    <link
                      rel="canonical"
                      href="https://utahdata.org/contact-us"
                    />
                    <meta
                      property="og:description"
                      content="Contact UtahData.org for inquiries, support, and feedback. We're here to help you access valuable data insights and hear your thoughts on Utah’s dynamic growth."
                    />
                  </Helmet>
                  <ContactUs1 />
                </>
              }
            />

            {/* About Us */}
            <Route
              path="/about"
              element={
                <>
                  <Helmet>
                    <title>
                      About UtahData.org | Explore Utah Data | Trends | Insights
                    </title>
                    <meta
                      property="og:title"
                      content="About UtahData.org | Explore Utah Data | Trends | Insights"
                    />
                    <meta
                      name="description"
                      content="UtahData.org empowers Utahns with interactive, user-friendly data insights that highlight our state's rich diversity and dynamic growth."
                    />
                    <link rel="canonical" href="https://utahdata.org/about" />
                    <meta
                      property="og:description"
                      content="UtahData.org empowers Utahns with interactive, user-friendly data insights that highlight our state's rich diversity and dynamic growth."
                    />
                  </Helmet>
                  <AboutUs />
                </>
              }
            />

            {/* Login */}
            <Route
              path="/login"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/login"
                    />
                  </Helmet>
                  <Login1 />
                </>
              }
            />
            <Route
              path="/login/dashboard"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/login/dashboard"
                    />
                  </Helmet>
                  <Dashboard />
                </>
              }
            />

            {/* Extras */}
            <Route
              path="/map"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://www.utahdata.org/map" />
                  </Helmet>
                  <UtahMap />
                </>
              }
            />
            <Route
              path="/utah-at-a-glance/unemployment-rate/dataset"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/utah-at-a-glance/unemployment-rate/dataset"
                    />
                  </Helmet>
                  <DataSet />
                </>
              }
            />
            <Route
              path="/version"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/version"
                    />
                  </Helmet>
                  <Version />
                </>
              }
            />
            <Route
              path="/bdx-arcgis-example-utah"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href="https://www.utahdata.org/bdx-arcgis-example-utah"
                    />
                  </Helmet>
                  <ArcGISMap />
                </>
              }
            />
            {/* <Route
              path="/47G"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://www.utahdata.org/47G" />
                  </Helmet>
                  <FourtySevenGMap />
                </>
              }
            /> */}
            <Route
              path="*"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://www.utahdata.org/" />
                  </Helmet>
                  <Torch404 />
                </>
              }
            />
          </Routes>
        </HelmetProvider>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  const swiperRef = useRef(null);

  return (
    <Router basename="/">
      {" "}
      {/* Move Router here to wrap the whole app */}
      <AppContent swiperRef={swiperRef} />
    </Router>
  );
}

export default App;
