// import React, { useEffect } from "react";

// const SocialMediaEmbed = ({ platform, embed }) => {
// //   useEffect(() => {
// //     // Dynamically load platform-specific scripts
// //     if (platform === "Instagram") {
// //       const script = document.createElement("script");
// //       script.src = "https://www.instagram.com/embed.js";
// //       script.async = true;
// //       document.body.appendChild(script);

// //       return () => {
// //         document.body.removeChild(script);
// //       };
// //     }

// //     if (platform === "Twitter (X)") {
// //       const script = document.createElement("script");
// //       script.src = "https://platform.twitter.com/widgets.js";
// //       script.async = true;
// //       document.body.appendChild(script);

// //       return () => {
// //         document.body.removeChild(script);
// //       };
// //     }

// //     if (platform === "Facebook") {
// //       const script = document.createElement("script");
// //       script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0";
// //       script.async = true;
// //       document.body.appendChild(script);

// //       return () => {
// //         document.body.removeChild(script);
// //       };
// //     }
// //   }, [platform]);









// useEffect(() => {
//     if (platform === "Instagram") {
//       const script = document.createElement("script");
//       script.async = true;
//       script.defer = true;
//       script.src = "https://www.instagram.com/embed.js";
//       document.body.appendChild(script);


//           if (platform === "Twitter (X)") {
//       const script = document.createElement("script");
//       script.src = "https://platform.twitter.com/widgets.js";
//       script.async = true;
//       document.body.appendChild(script);

//       return () => {
//         document.body.removeChild(script);
//       };
//     }

//       return () => {
//         document.body.removeChild(script);
//       };
//     }
//   }, [platform]);


//   return (
//     <div
//       className="social-media-embed"
//       dangerouslySetInnerHTML={{ __html: embed }}
//     />
//   );
// };

// export default SocialMediaEmbed;













import React, { useEffect } from "react";

const SocialMediaEmbed = ({ platform, embed }) => {
  useEffect(() => {
    if (platform === "Instagram") {
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = "https://www.instagram.com/embed.js";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    if (platform === "Twitter (X)") {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [platform]);

  return (
    <div
      className="social-media-embed"
      dangerouslySetInnerHTML={{ __html: embed }}
    />
  );
};

export default SocialMediaEmbed;
