
import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON";
import { Icon, Style, Stroke, Fill, Text } from "ol/style";
import { fromLonLat } from "ol/proj";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { get as getProjection } from "ol/proj";
import { containsExtent } from "ol/extent";
import "../styles/Geothermal.css";

const Zipcode = () => {
  const mapRef = useRef(null);
  const zipLayerRef = useRef(null);
  const countyLayerRef = useRef(null);
  const zipSourceRef = useRef(null);

  const [allZipData, setAllZipData] = useState([]); // Store merged ZIP & county data
  const [zipData, setZipData] = useState([]); // Table display data
  const [selectedCounty, setSelectedCounty] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (mapRef.current) return;

    const baseLayer = new TileLayer({ source: new OSM() });

    const countySource = new VectorSource({
      url: "/1us-county-boundaries (1).geojson",
      format: new GeoJSON(),
      strategy: bboxStrategy,
    });

    const countyLayer = new VectorLayer({
      source: countySource,
      style: (feature) =>
        new Style({
          stroke: new Stroke({ color: "black", width: 2 }),
          zIndex: 10,
          fill: new Fill({ color: "rgba(0, 0, 255, 0.1)" }),
          text: new Text({
            font: "bold 12px Arial",
            fill: new Fill({ color: "black" }),
            text: feature.get("name") || "No Name",
          }),
        }),
    });

    countyLayerRef.current = countyLayer;

    const zipSource = new VectorSource({
      url: "/1UtahZipCodeAreas_-8142737019969216814.geojson",
      format: new GeoJSON(),
      strategy: bboxStrategy,
    });

    const zipLayer = new VectorLayer({
      source: zipSource,
      style: (feature) => {
        const zipCode = feature.get("ZIP5") || "No Zip";
        const areaName = feature.get("NAME") || "No Area";
        const labelText = `${zipCode} - ${areaName}`;

        return new Style({
          stroke: new Stroke({ color: "red", width: 1 }),
          zIndex: 5,
          image: new Icon({
            // src: "/img/ziplogo.png", // Change this to your actual logo path
            src: "https://openlayers.org/en/latest/examples/data/icon.png",

            scale: 0.1, // Adjust size if needed
            anchor: [0.5, 1], // Positioning of the icon
          }),
          text: new Text({
            font: "bold 10px Arial",
            fill: new Fill({ color: "blue" }),
            text: labelText,
            //offsetY: -15,
          }),
        });
      },
      visible: false,
    });

    const map = new Map({
      target: "map",
      layers: [baseLayer, countyLayer, zipLayer],
      view: new View({
        center: fromLonLat([-111.641, 39.8608]),
        zoom: 6.5,
      }),
    });

    mapRef.current = map;
    zipLayerRef.current = zipLayer;
    zipSourceRef.current = zipSource;

   



    Promise.all([
      fetch("/1us-county-boundaries (1).geojson").then((res) => res.json()),
      fetch("/1UtahZipCodeAreas_-8142737019969216814.geojson").then((res) => res.json()),
    ]).then(([countyData, zipData]) => {
      const countyFeatures = new GeoJSON().readFeatures(countyData, {
        featureProjection: getProjection("EPSG:3857"),
      });
    
      const zipFeatures = new GeoJSON().readFeatures(zipData, {
        featureProjection: getProjection("EPSG:3857"),
      });
    
      // Match ZIP codes with counties based on intersection
      // const mappedZipData = zipFeatures.map((zip) => {
      //   const zipGeometry = zip.getGeometry();
      //   const countyMatch = countyFeatures.find((county) =>
      //     zipGeometry.intersectsExtent(county.getGeometry().getExtent())
      //   );
    
      //   return {
      //     zip: zip.get("ZIP5"),
      //     area: zip.get("NAME"),
      //     county: countyMatch ? countyMatch.get("name") : "Unknown",
      //     geometry: zipGeometry.getExtent(),
      //   };
      // });
    


      const mappedZipData = zipFeatures.map((zip) => {
        const zipGeometry = zip.getGeometry();
       // const zipCenter = zipGeometry.getInteriorPoint().getCoordinates(); // Get ZIP centroid
       let zipCenter;
       if (zipGeometry.getType() === "Polygon") {
         zipCenter = zipGeometry.getInteriorPoint().getCoordinates();
       } else if (zipGeometry.getType() === "MultiPolygon") {
         zipCenter = zipGeometry.getPolygons()[0].getInteriorPoint().getCoordinates();
       }
       
        const countyMatch = countyFeatures.find((county) =>
          county.getGeometry().intersectsCoordinate(zipCenter) // Match county by centroid
        );
      
        return {
          zip: zip.get("ZIP5"),
          area: zip.get("NAME"),
          county: countyMatch ? countyMatch.get("name") : "Unknown",
          geometry: zipGeometry.getExtent(),
        };
      });
      
    
    

      setAllZipData(mappedZipData);
      setZipData([...mappedZipData].sort((a, b) => a.area.localeCompare(b.area)));
    });

    map.on("click", (event) => {
      map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if (layer === countyLayer) {
          const countyName = feature.get("name");
          setSelectedCounty(countyName);

          const countyExtent = feature.getGeometry().getExtent();
          map.getView().fit(countyExtent, { duration: 1000, padding: [50, 50, 50, 50] });

          zipLayer.setVisible(true);
        }
      });
    });

    map.on("click", (event) => {
      const feature = map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if (layer === countyLayer) {
          return feature;
        }
      });

      if (!feature) {
        setSelectedCounty(null);
        setZipData(allZipData);
        zipLayer.setVisible(false);
      }
    });
  }, [allZipData]);

  const handleRowClick = (item) => {
    if (!mapRef.current || !zipLayerRef.current || !zipSourceRef.current) {
      console.warn("Map or layers not initialized.");
      return;
    }

    console.log("Row clicked:", item.zip);

    // Ensure ZIP layer is visible
    zipLayerRef.current.setVisible(true);

    // Wait a bit to ensure features are loaded
    setTimeout(() => {
      const features = zipSourceRef.current.getFeatures();
      console.log("Total ZIP features loaded after timeout:", features.length);

      if (!features.length) {
        console.warn("No ZIP features loaded.");
        return;
      }

      // Find the corresponding ZIP feature
      const zipFeature = features.find((feature) => feature.get("ZIP5") === item.zip);
      console.log("Feature found for ZIP:", zipFeature ? zipFeature.get("ZIP5") : "Not found");

      if (!zipFeature) {
        console.warn(`No feature found for ZIP: ${item.zip}`);
        return;
      }

      // Get area name from feature properties
      const areaName = zipFeature.get("NAME") || "Unknown Area";
      console.log(`ZIP: ${item.zip}, Area Name: ${areaName}`);

      // Reset all styles before applying the highlight
      features.forEach((feature) => feature.setStyle(null));

      // Apply highlight style to the selected feature
      zipFeature.setStyle(
        new Style({
          stroke: new Stroke({ color: "yellow", width: 3 }),
          fill: new Fill({ color: "rgba(255, 0, 0, 0.1)" }),
          zIndex: 20,
          text: new Text({
            font: "bold 12px Arial",
            fill: new Fill({ color: "black" }),
            text: `${item.zip}\n${areaName}`, // Display ZIP and Area Name
            offsetY: -15,
            padding: [2, 2, 2, 2],
            backgroundFill: new Fill({ color: "rgba(255,255,255,0.7)" }),
            backgroundStroke: new Stroke({ color: "black", width: 1 }),
          }),
        })
      );
      console.log("Style applied to ZIP feature:", item.zip, "Area:", areaName);

      // Immediately zoom into the ZIP feature
      const extent = zipFeature.getGeometry().getExtent();
      console.log("Zooming to extent:", extent);

      mapRef.current.getView().fit(extent, {
        duration: 1000,
        padding: [50, 50, 50, 50],
      });

      console.log("Zoom action triggered.");
    }, 200); // Small delay to ensure features are available
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
    if (!searchTerm) {
      setZipData(allZipData);
      return;
    }
  
    const filteredData = allZipData.filter(
      (item) =>
        item.zip.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.county.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    setZipData(filteredData);
  }, [searchTerm, allZipData]);

 

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: isMobile ? "column" : "row", // Column on mobile, row otherwise
          overflow: "hidden",
        }}
      >
        <div
          style={{
            flex: 1,
            marginTop: "8rem",
            overflowY: "auto",
            padding: "10px",
            borderRight: isMobile ? "none" : "1px solid gray", // Remove border on mobile
            borderBottom: isMobile ? "1px solid gray" : "none", // Add border below on mobile
            marginInlineStart: isMobile ? "0" : "0",
            //width:"20rem"
          }}
        >
          <h3 className="flex justify-center items-center">ZIP Code (Total 294)</h3>

          <input
      type="text"
      placeholder="Search by ZIP, Area, or County..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      style={{
        padding: "8px",
        width: "100%",
        marginBottom: "10px",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
         

          <table border="1" width="100%" className="">
  <thead>
    <tr>
      <th style={{ width: "33.33%" }}>ZIP Code</th>
      <th style={{ width: "33.33%" }}>Area</th>
      <th style={{ width: "33.33%" }}>County</th> {/* Add County Column */}
    </tr>
  </thead>
  <tbody>
    {zipData.map((item, index) => (
      <tr key={index} style={{ cursor: "pointer" }} className="hover:bg-blue-300" onClick={() => handleRowClick(item)}>
        <td>{item.zip}</td>
        <td>{item.area}</td>
        <td>{item.county}</td> {/* Display County Name */}
      </tr>
    ))}
  </tbody>
</table>

        </div>
        <div id="map" style={{ flex: 2, height: "100%" }}></div>
      </div>
    </div>
  );
};

export default Zipcode;