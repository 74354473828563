

// import React, { useState, useEffect, useRef } from "react";
// import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMap } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import "../styles/Geothermal.css";
// import geothermalData from "./GeoThermalJson.json";
// import airportData from "./Airport.json";

// const spot = "/img/airport2.png";

// // Utility to detect mobile view
// const isMobileView = () => window.innerWidth <= 778;

// // Custom marker icon with dynamic popup anchor
// const createIcon = () =>
//   new L.Icon({
//     iconUrl: spot,
//     iconSize: [35, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: isMobileView() ? [0, 10] : [1, -34],
//   });

// const MapFocus = ({ location }) => {
//   const map = useMap();
//   if (location) {
//     map.flyTo(location, isMobileView() ? 5 : 10);
//   }
//   return null;
// };

// const Airport1 = () => {
//   const [selectedLocation, setSelectedLocation] = useState(null);
//   const [utahBoundary, setUtahBoundary] = useState(null);
//   const markerRefs = useRef([]);

//   // Fetch Utah boundary GeoJSON dynamically
//   useEffect(() => {
//     fetch("/utah-boundary.geojson") // Fetch from public folder
//       .then((response) => response.json())
//       .then((data) => setUtahBoundary(data))
//       .catch((error) => console.error("Error loading Utah boundary:", error));
//   }, []);

//   const handleLocationClick = (location, index) => {
//     setSelectedLocation(location);
//     if (markerRefs.current[index]) {
//       markerRefs.current[index].openPopup();
//     }
//   };

//   return (
//     <div className="geothermal-map">
//       <div className="list-container">
//         <h2 className="flex items-center justify-center">Airports and Heliports (Total 138)</h2>
//         <h3>
//           <div className="list-item-content">
//           <span>City Name</span>
//           <span>Airport</span>
//             <span>County Name</span>
           
//             <span>Area/ZipCode</span>
//           </div>
//         </h3>
//         <ul>
//           {airportData.map((spot, index) => (
//             <li
//               key={index}
//               className="list-item"
//               onClick={() =>
//                 handleLocationClick(
//                   { lat: spot.LATITUDE, lng: spot.LONGITUDE, details: spot },
//                   index
//                 )
//               }
//             >
//               <div className="list-item-content">
//               <span>{spot.CITY_NAME}</span>
//               <span>{spot.FULLNAME}</span>
//                 <span>{spot.COUNTY_NAM}</span>
               
//                 <span>{spot.FAC_CYSTZP}</span>
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>

//       <div className="map-container">
//         <MapContainer
//           center={[39.32098, -111.093731]}
//           zoom={7.2}
//           minZoom={6}
//           maxBounds={[
//             [30, -105],
//             [50, -120],
//           ]}
//           maxBoundsViscosity={1.0}
//           className="map"
//         >
//           <TileLayer
//             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           />

//           {selectedLocation && <MapFocus location={[selectedLocation.lat, selectedLocation.lng]} />}

//           {/* Utah Boundary */}
//           {utahBoundary && (
//             <GeoJSON
//               data={utahBoundary}
//               style={() => ({
//                 color: "blue", // Outline color
//                 weight: 2,
//                 opacity: 1,
//                 fillColor: "lightblue",
//                 fillOpacity: 0.2,
//               })}
//             />
//           )}

//           {airportData.map((spot, index) => (
//             <Marker
//               key={index}
//               position={[spot.LATITUDE, spot.LONGITUDE]}
//               icon={createIcon()}
//               ref={(el) => (markerRefs.current[index] = el)}
//             >
//               <Popup>
//                 <b>
//                   <u>
//                     {spot.ICAO} - {spot.FULLNAME}
//                   </u>
//                 </b>
//                 <br />
//                 Site no.: {spot.SITE_NO} <br />
//                 State: {spot.STATE_NAME} <br />
//                 County: {spot.COUNTY_NAM} <br />
//                 City: {spot.CITY_NAME} <br />
//                 Airport: {spot.FULLNAME} <br />
//                 Elevation: {spot.ELEV} <br />
//                 Aero Chart: {spot.AERO_CHART} <br />
//                 Area/Zipcode: {spot.FAC_CYSTZP} <br />
//               </Popup>
//             </Marker>
//           ))}
//         </MapContainer>
//       </div>
//     </div>
//   );
// };

// export default Airport1;


















import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../styles/Geothermal.css";
import geothermalData from "./GeoThermalJson.json";
import airportData from "./Airport.json";

const spot = "/img/airport2.png";

const isMobileView = () => window.innerWidth <= 778;

const createIcon = () =>
  new L.Icon({
    iconUrl: spot,
    iconSize: [35, 41],
    iconAnchor: [12, 41],
    popupAnchor: isMobileView() ? [0, 10] : [1, -34],
  });

const MapFocus = ({ location }) => {
  const map = useMap();
  if (location) {
    map.flyTo(location, isMobileView() ? 5 : 10);
  }
  return null;
};

const Airport1 = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [utahBoundary, setUtahBoundary] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const markerRefs = useRef([]);

  useEffect(() => {
    fetch("/utah-boundary.geojson")
      .then((response) => response.json())
      .then((data) => setUtahBoundary(data))
      .catch((error) => console.error("Error loading Utah boundary:", error));
  }, []);

  const handleLocationClick = (location, index) => {
    setSelectedLocation(location);
    if (markerRefs.current[index]) {
      markerRefs.current[index].openPopup();
    }
  };

  const filteredData = airportData.filter(
    (spot) =>
      spot.CITY_NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot.FULLNAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot.COUNTY_NAM.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot.FAC_CYSTZP.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="geothermal-map">
      <div className="list-container">
        <h2 className="flex items-center justify-center">Airports and Heliports (Total 138)</h2>
        {/* <input
          type="text"
          placeholder="Search by City, Airport, County or ZipCode"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input flex justify-center items-center"
          
        /> */}
        <div className="flex justify-center items-center my-4">
  <input
    type="text"
    placeholder="Search by City, Airport, County or ZipCode"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    className="search-input p-2 border border-gray-300 rounded-md"
  />
</div>
        <h3>
          <div className="list-item-content">
            <span>City Name</span>
            <span>Airport</span>
            <span>County Name</span>
            <span>Area/ZipCode</span>
          </div>
        </h3>
        <ul>
          {filteredData.map((spot, index) => (
            <li
              key={index}
              className="list-item"
              onClick={() =>
                handleLocationClick(
                  { lat: spot.LATITUDE, lng: spot.LONGITUDE, details: spot },
                  index
                )
              }
            >
              <div className="list-item-content">
                <span>{spot.CITY_NAME}</span>
                <span>{spot.FULLNAME}</span>
                <span>{spot.COUNTY_NAM}</span>
                <span>{spot.FAC_CYSTZP}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="map-container">
        <MapContainer
          center={[39.32098, -111.093731]}
          zoom={7.2}
          minZoom={6}
          maxBounds={[[30, -105], [50, -120]]}
          maxBoundsViscosity={1.0}
          className="map"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {selectedLocation && <MapFocus location={[selectedLocation.lat, selectedLocation.lng]} />}

          {utahBoundary && (
            <GeoJSON
              data={utahBoundary}
              style={() => ({
                color: "blue",
                weight: 2,
                opacity: 1,
                fillColor: "lightblue",
                fillOpacity: 0.2,
              })}
            />
          )}

          {filteredData.map((spot, index) => (
            <Marker
              key={index}
              position={[spot.LATITUDE, spot.LONGITUDE]}
              icon={createIcon()}
              ref={(el) => (markerRefs.current[index] = el)}
            >
              <Popup>
                <b>
                  <u>
                    {spot.ICAO} - {spot.FULLNAME}
                  </u>
                </b>
                <br />
                Site no.: {spot.SITE_NO} <br />
                State: {spot.STATE_NAME} <br />
                County: {spot.COUNTY_NAM} <br />
                City: {spot.CITY_NAME} <br />
                Airport: {spot.FULLNAME} <br />
                Elevation: {spot.ELEV} <br />
                Aero Chart: {spot.AERO_CHART} <br />
                Area/Zipcode: {spot.FAC_CYSTZP} <br />
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default Airport1;
