import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import { Drawer, List, useMediaQuery } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ReactMarkdown from "react-markdown";
import "../styles/ChatWindow.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import config from "../config/config.json";
import ReportForm from "./Form";

const BETSYbot = "/img/BETSY.png";
const UserLogo = "/img/avatar.jpg";

function ChatWindow(props) {
  var initialQuery = props?.data;
  const [inputVal, setInputVal] = useState(initialQuery || "");
  const [messages, setMessages] = useState([]);
  const [showGreeting, setShowGreeting] = useState(true);
  const [showQuestions, setShowQuestions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [detailExists, setDetailExists] = useState(false);
  const [detail, setDetail] = useState("");
  const chatEndRef = useRef(null);
  const [dots, setDots] = useState("#EE905D");

  const pre_defined_questions = [
    "What is this website about?",
    "Share percentage Analysis of Women versus Men in Manufacturing Employment?",
    "How does the overall employment trend in manufacturing reflect diversity changes between 2018 and 2023?",
    "What are the total employment trends in Utah's manufacturing sector from 2018 to 2023?",
    "How has the female employment share in the manufacturing industry changed in 2023 compared to 2018?",
    "What percentage of the total workforce in 2023 is male in Utah's manufacturing sector?",
    "What are the differences in employment counts between males and females in Utah manufacturing from 2018 to 2023?",
    "How has male employment in Utah's manufacturing sector evolved over the past year?",
    "What trends are observed in the share of female workers in the Utah manufacturing sector?",
    "What is the total count of male employees in the Utah manufacturing workforce in 2023?",
  ];

  useEffect(() => {
    if (initialQuery) {
      chatbotFun(initialQuery);
      setInputVal("");
      initialQuery = "";
    }
  }, [initialQuery]);

  useEffect(() => {
    if (!showGreeting) {
      chatEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [messages, showGreeting]);

  const handleInputChange = (event) => {
    setInputVal(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      chatbotFun(inputVal);
      setInputVal("");
    }
  };

  const handleQuestionClick = (question) => {
    setInputVal(question);
    setShowGreeting(false);
    setShowQuestions(false);
    chatbotFun(question);
  };

  const chatbotFun = async (input = inputVal) => {
    if (!input) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: input },
    ]);

    setShowGreeting(false);
    setShowQuestions(false);
    setLoading(true);
    setDisableInput(true);

    try {
      const res = await fetch(config.API_URL + `/utahdatabetsy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: input,
        }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();

      if (data.detail) {
        setDetailExists(true);
        setDetail(data.detail);
        setMessages([]);
        setShowQuestions(false);
        setDisableInput("true");
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: data.summary,
            time: data.res_time,
            url: data.sources,
          },
        ]);
      }
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          text: "Failed to generate response: " + error,
        },
      ]);
    }

    setLoading(false);
    setDisableInput(false);
    setInputVal("");
  };

  const copyText = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      alert("Copied!");
    } catch (err) {
      alert("Failed to copy! Try again.");
    }
  };

  const regenerateResponse = async (lastUserInput) => {
    setLoading(true);

    try {
      const res = await fetch(config.API_URL + `/utahdatabetsy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: lastUserInput,
        }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      if (data.detail) {
        setDetailExists(true);
        setDetail(data.detail);
        setMessages([]);
        setShowQuestions(false);
        setDisableInput("true");
      } else {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            sender: "bot",
            text: data.summary,
            time: data.res_time,
            url: data.sources,
          };
          return updatedMessages;
        });
      }
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          text: "Failed to regenerate response: " + error,
        },
      ]);
    }

    setLoading(false);
  };

  const theme = createTheme({});

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const LoadingOverlay = ({ dots }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <div
        style={{
          display: "grid",
          placeItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src={BETSYbot}
          alt="BETSY Logo"
          style={{ height: 80, width: 80, marginBottom: "10px" }}
        />
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="common-font"
        >
          <Typography variant="h6" className="common-font">
            {" "}
            Preparing Answer
          </Typography>{" "}
          <Typography
            style={{ width: "25px", textAlign: "start" }}
            variant="h6"
          >
            {" "}
            {dots}
          </Typography>
        </div>
      </div>
    </div>
  );

  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    } else if (typeof data === "object") {
      return <pre>{JSON.stringify(data, null, 2)}</pre>;
    } else {
      return (
        <div className="markdown-container">
          <ReactMarkdown>{data}</ReactMarkdown>
        </div>
      );
    }
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [paddingRight, setPaddingRight] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  useEffect(() => {
    if (!showQuestions) {
      setPaddingRight("15%");
      setWidth("85hw");
      setHeight("auto");
    } else {
      setPaddingRight("0%");
      setWidth("100hw");
      setHeight("auto");
    }
  }, [showQuestions]);

  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the total number of pages
  const totalPages = Math.ceil(pre_defined_questions.length / itemsPerPage);

  // Slice the questions for the current page
  const questionsToDisplay = pre_defined_questions.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  // Function to go to the next page
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to go to the previous page
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const link = detail.match(urlRegex)?.[0];

  // Remove the URL from the detail
  const textWithoutLink = detail.replace(urlRegex, "").trim();

  // Split the remaining text by `.`
  const sentences = textWithoutLink
    .split(".")
    .filter((sentence) => sentence.trim());

  // Function to go to the previous page
  const handleClose = () => {
    setDetailExists(false);
    setShowGreeting(true);
    setShowQuestions(true);
  };

  return (
    <ThemeProvider theme={theme}>
      {!showGreeting && !detailExists ? (
        <Drawer
          variant={isSmallScreen ? "temporary" : "permanent"}
          open={!isSmallScreen}
          sx={{
            "& .MuiDrawer-paper": {
              width: 325,
              boxSizing: "border-box",
              backgroundColor: "#ddefff",
            },
          }}
          anchor="right"
        >
          {" "}
          <List
            sx={{
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "100px",
            }}
          >
            <h3 align="center" className="common_font">
              FAQ's
            </h3>
            {questionsToDisplay.map((question, index) => (
              <p
                key={index}
                className="bordered"
                onClick={() => handleQuestionClick(question)}
              >
                {question}
              </p>
            ))}
          </List>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <IconButton
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              <ArrowBackIcon />
            </IconButton>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage + 1} of {totalPages}
            </span>
            <IconButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
            >
              <ArrowForwardIcon />
            </IconButton>
          </div>
        </Drawer>
      ) : (
        <></>
      )}
      <div className="mt-[11rem] bg-[#d2eaff]">
        <div
          style={{
            minHeight: "68vh",
          }}
          className={!showQuestions && !detailExists ? "main-content" : ""}
        >
          {" "}
          {detailExists || showQuestions ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <center>
                <div>
                  <img src={BETSYbot} alt="Betsy-icon" width={80} />
                  <h4 style={{ color: "#1976d2" }}>Hello I'm Betsy!</h4>
                  {showQuestions && (
                    <h3 style={{ color: "#1976d2" }}>
                      Some questions you can ask me.
                    </h3>
                  )}
                </div>
              </center>
            </div>
          ) : (
            <></>
          )}
          {loading && <LoadingOverlay dots={dots} />}
          {showQuestions && (
            <div
              style={{
                padding: "1rem",
                height: "max-content",
                width: "70%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "auto",
              }}
            >
              {pre_defined_questions.slice(0, 8).map((question, index) => (
                <Button
                  key={index}
                  variant="contained"
                  className="common-font !w-[30rem] sm:!w-[16rem] !h-[12rem] sm:!h-[9rem]"
                  color="primary"
                  onClick={() => handleQuestionClick(question)}
                  style={{
                    textTransform: "none",
                    margin: "0.5rem",
                    textAlign: "center",
                    padding: "7px",
                  }}
                >
                  {question}
                </Button>
              ))}
            </div>
          )}
          {detailExists && (
            <div
              style={{
                padding: "1rem",
                height: "max-content",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "auto",
                position: "relative", // Added for close button positioning
              }}
            >
              <div
                error-message
                className="common-font"
                style={{
                  textTransform: "none",
                  margin: "0.5rem",
                  textAlign: "center",
                  padding: "40px 20px",
                  fontSize: "18px",
                  width: "40%",
                  height: "auto",
                  color: "#000",
                  background: "#fff",
                  borderRadius: "12px",
                  position: "relative", // Added for relative positioning of close button
                }}
              >
                {/* Close Button */}
                <button
                  onClick={handleClose} // Add your close handler logic
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "transparent",
                    border: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "#000",
                  }}
                  aria-label="Close"
                >
                  &times; {/* Unicode for close icon */}
                </button>

                <i
                  className="fa fa-exclamation-triangle fa-3x mb-3"
                  aria-hidden="true"
                ></i>
                {sentences.map((sentence, index) => (
                  <div key={index}>{sentence.trim() && sentence.trim()} .</div>
                ))}
                {link && (
                  <>
                    <ReportForm />
                  </>
                )}
              </div>
            </div>
          )}
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
                alignItems: "center",
                marginBottom: "0.5rem",
                width: "100%",
              }}
            >
              {message.sender === "bot" && (
                <img
                  src={BETSYbot}
                  alt="BETSY"
                  style={{
                    height: 40,
                    width: 40,
                    marginRight: "0.5rem",
                    borderRadius: "50%",
                    position: "relative",
                  }}
                />
              )}
              {message.text ===
              "Limits of Betsy AI usage exceeded, please try again after a few hours." ? (
                <div
                  style={{
                    maxWidth: "60%",
                    padding: "0.75rem",
                    borderRadius: "8px",
                    backgroundColor:
                      message.sender === "user" ? "#64afef" : "#eab308",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {renderData(message.text)}
                  {message.sender === "bot" && (
                    <>
                      {index === messages.length - 1 && (
                        <>
                          <ContentCopyIcon
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              color: "#000",
                            }}
                            onClick={() => copyText(message.text)}
                          />
                          <span>
                            <AutorenewIcon
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                color: "#000",
                              }}
                              onClick={() =>
                                regenerateResponse(
                                  messages[messages.length - 2].text
                                )
                              }
                            />
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    maxWidth: "60%",
                    padding: "0.75rem",
                    borderRadius: "8px",
                    backgroundColor:
                      message.sender === "user" ? "#64afef" : "#1565c0",
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  {renderData(message.text)}
                  {message.sender === "bot" && (
                    <>
                      <p>
                        <b>Response Time:</b> {message.time} secs
                      </p>
                      <p>
                        <b>Sources:</b>{" "}
                        {Array.isArray(message.url) &&
                          message.url.map((link, index) => (
                            <div key={index} style={{ marginBottom: "5px" }}>
                              <a
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                tabIndex="-1"
                                style={{ color: "#ffffff" }}
                                className="link-container"
                              >
                                {link}
                              </a>
                            </div>
                          ))}
                      </p>
                      {index === messages.length - 1 && (
                        <>
                          <ContentCopyIcon
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              color: "#ffffff",
                            }}
                            onClick={() => copyText(message.text)}
                          />
                          <span>
                            <AutorenewIcon
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                color: "#ffffff",
                              }}
                              onClick={() =>
                                regenerateResponse(
                                  messages[messages.length - 2].text
                                )
                              }
                            />
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              {message.sender === "user" && (
                <img
                  src={UserLogo}
                  alt="User"
                  style={{
                    height: 40,
                    width: 40,
                    marginLeft: "0.5rem",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
          ))}
          {/* {!link || showQuestions ? ( */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <TextField
              style={{
                width: "55%",
                backgroundColor: "#fff",
                marginLeft: "5px",
                marginBottom: "20px",
              }}
              label="Type a message for Betsy"
              variant="outlined"
              className="common-font"
              helperText="* Betsy can make mistakes, please check and validate responses"
              value={inputVal}
              onKeyPress={handleKeyPress}
              onChange={handleInputChange}
              InputLabelProps={{
                className: "common-font",
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      chatbotFun(inputVal);
                      setInputVal("");
                    }}
                    edge="end"
                    disabled={detailExists}
                    sx={{
                      color: disableInput ? "gray" : "#1565c0",
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                ),
                style: { paddingRight: 10 },
                classes: { input: "common-font" },
              }}
              FormHelperTextProps={{
                className: "common-font helpertext",
              }}
              disabled={detailExists}
            />
          </div>
          {/* ) : (
            <></>
          )} */}
          {!showGreeting && <div ref={chatEndRef} />}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ChatWindow;
