import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import config from "../config/config.json";
import "../styles/FaqSection.css";
import { useEffect } from "react";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TableNo35 from "../Tables/TableNo35";
import { useLocation } from "react-router-dom";
import EmploymentSummaryChart from "../FoodIndustryTables/EmploymentSummaryChart";
import EmploymentGrowthChart from "../FoodIndustryTables/EmploymentGrowthChart";
import YearlyTotalChart from "../FoodIndustryTables/YearlyTotalChart";
import YearlyChartData from "../FoodIndustryTables/YearlyChartData";
import CalculateSexPercentage from "../FoodIndustryTables/CalculateSexPercentage";
import AgeGroupGrowth from "../FoodIndustryTables/AgeGroupGrowth";
import MaleToFemaleRatioChart from "../FoodIndustryTables/MaleToFemaleRatioChart";
import PercentageShareChart from "../FoodIndustryTables/PercentageShareChart";
import CalculateGrowthRate from "../FoodIndustryTables/CalculateGrowthRate";
import AgeGroupEmployeeChart from "../FoodIndustryTables/AgeGroupEmployeeChart";
import SpecificAgeGroupEmployeeChart from "../FoodIndustryTables/SpecificAgeGroupEmployeeChart";
import AgeGroupDoughnutChart from "../FoodIndustryTables/AgeGroupDoughnutChart";
import AsianAlone3DChart from "../FoodIndustryTables/AsianAlone3DChart";
import YearlyChange3DChart from "../FoodIndustryTables/YearlyChange3DChart";
import RaceGrowth3DChart from "../FoodIndustryTables/RaceGrowth3DChart";
import BlackAAPercentageChangeChart from "../FoodIndustryTables/BlackAAPercentageChangeChart";
import AmericanIndianAsianTotalsChart from "../FoodIndustryTables/AmericanIndianAsianTotalsChart";
import EducationGrowthChart from "../FoodIndustryTables/EducationGrowthChart";
import BachelorDegreeGrowthChart from "../FoodIndustryTables/BachelorDegreeGrowthChart";
import FemaleMaleYearlyChart from "../FoodIndustryTables/FemaleMaleYearlyChart";
import FemaleMaleRatioChart from "../FoodIndustryTables/FemaleMaleRatioChart";
import EducationPercentageChangeChart from "../FoodIndustryTables/EducationPercentageChangeChart";
import EducationTotalsStackedChart from "../FoodIndustryTables/EducationTotalsStackedChart";
import EducationTotals2020Chart from "../FoodIndustryTables/EducationTotals2020Chart";
import EducationGenderChangeChart from "../FoodIndustryTables/EducationGenderChangeChart";

export const FoodIndustryFaqSection = ({ searchTerm }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Employment-Trend");

  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [
    data_get_employment_summary_by_sex,
    set_data_get_employment_summary_by_sex,
  ] = useState([]);
  const [data_get_employment_growth, set_data_get_employment_growth] = useState(
    []
  );
  const [data_calculate_yearly_totals, set_data_calculate_yearly_totals] =
    useState([]);
  const [data_calculate_sex_yearly_data, set_data_calculate_sex_yearly_data] =
    useState([]);
  const [data_calculate_sex_percentage, set_data_calculate_sex_percentage] =
    useState([]);
  const [data_age_group_growth, set_data_age_group_growth] = useState([]);
  const [data_calculate_growth_rate, set_data_calculate_growth_rate] = useState(
    []
  );
  const [data_calculate_percentage_share, set_data_calculate_percentage_share] =
    useState([]);
  const [
    data_calculate_male_to_female_ratio,
    set_data_calculate_male_to_female_ratio,
  ] = useState([]);
  const [data_get_agegroup_employee_data, set_data_get_agegroup_employee_data] =
    useState([]);
  const [
    data_get_specific_agegroup_employee_data,
    set_data_get_specific_agegroup_employee_data,
  ] = useState([]);
  const [data_get_2023_agegroup_data, set_data_get_2023_agegroup_data] =
    useState([]);
  const [data_asian_alone_totals, set_data_asian_alone_totals] = useState([]);
  const [data_all_races_yearly_change, set_data_all_races_yearly_change] =
    useState([]);
  const [data_race_growth, set_data_race_growth] = useState([]);
  const [data_black_aa_percentage_change, set_data_black_aa_percentage_change] =
    useState([]);
  const [
    data_american_indian_asian_totals,
    set_data_american_indian_asian_totals,
  ] = useState([]);
  const [data_education_growth, set_data_education_growth] = useState([]);
  const [data_bachelor_degree_growth, set_data_bachelor_degree_growthh] =
    useState([]);
  const [data_female_male_yearly, set_data_female_male_yearly] = useState([]);
  const [
    data_calculate_female_male_ratio,
    set_data_calculate_female_male_ratio,
  ] = useState([]);
  const [
    data_calculate_education_percentage_change,
    set_data_calculate_education_percentage_change,
  ] = useState([]);
  const [data_simple_education_totals, set_data_simple_education_totals] =
    useState([]);
  const [data_education_totals_2020, set_data_education_totals_2020] = useState(
    []
  );
  const [data_education_gender_change, set_data_education_gender_change] =
    useState([]);

  useEffect(() => {
    axios
      .get(config.APP_URL + "/food_industry/food_verticals")
      .then((response) => {
        if (response.data.success) {
          set_data_get_employment_summary_by_sex(
            response.data.data.data_get_employment_summary_by_sex
          );
          set_data_get_employment_growth(
            response.data.data.data_get_employment_growth
          );
          set_data_calculate_yearly_totals(
            response.data.data.data_calculate_yearly_totals
          );
          set_data_calculate_sex_yearly_data(
            response.data.data.data_calculate_sex_yearly_data
          );
          set_data_calculate_sex_percentage(
            response.data.data.data_calculate_sex_percentage
          );
          set_data_age_group_growth(response.data.data.data_age_group_growth);
          set_data_calculate_percentage_share(
            response.data.data.data_calculate_percentage_share
          );
          set_data_calculate_male_to_female_ratio(
            response.data.data.data_calculate_male_to_female_ratio
          );
          set_data_calculate_growth_rate(
            response.data.data.data_calculate_growth_rate
          );
          set_data_get_agegroup_employee_data(
            response.data.data.data_get_agegroup_employee_data
          );
          set_data_get_specific_agegroup_employee_data(
            response.data.data.data_get_specific_agegroup_employee_data
          );
          set_data_get_2023_agegroup_data(
            response.data.data.data_get_2023_agegroup_data
          );
          set_data_asian_alone_totals(
            response.data.data.data_asian_alone_totals
          );
          set_data_all_races_yearly_change(
            response.data.data.data_all_races_yearly_change
          );
          set_data_race_growth(response.data.data.data_race_growth);
          set_data_black_aa_percentage_change(
            response.data.data.data_black_aa_percentage_change
          );
          set_data_american_indian_asian_totals(
            response.data.data.data_american_indian_asian_totals
          );
          set_data_education_growth(response.data.data.data_education_growth);
          set_data_bachelor_degree_growthh(
            response.data.data.data_bachelor_degree_growth
          );
          set_data_female_male_yearly(
            response.data.data.data_female_male_yearly
          );
          set_data_calculate_female_male_ratio(
            response.data.data.data_calculate_female_male_ratio
          );
          set_data_calculate_education_percentage_change(
            response.data.data.data_calculate_education_percentage_change
          );
          set_data_simple_education_totals(
            response.data.data.data_simple_education_totals
          );
          set_data_education_totals_2020(
            response.data.data.data_education_totals_2020
          );
          set_data_education_gender_change(
            response.data.data.data_education_gender_change
          );
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const question = searchParams.get("question");

    if (tab) setActiveTab(tab);
    if (question) setExpandedQuestion(decodeURIComponent(question));
  }, [location.search]);

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setExpandedQuestion(null); // Reset expanded question when switching tabs
  };

  return (
    <div className="w-[85vw] sm:w-[65vw] h-auto p-1 pt-4 mt-[3rem] border border-gray-300 rounded-lg shadow-lg  justify-center items-center mx-auto">
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        {/* employment trend */}
        <Tab
          eventKey="Employment-Trend"
          title="Employment Trend"
          id="Employment-Trend"
        >
          <Accordion>
            <Accordion.Item
              eventKey="Share percentage Analysis of Women versus Men in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Share percentage Analysis of Women versus Men in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the overall trend in the total food industry metric
                  from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EmploymentSummaryChart
                  data={data_get_employment_summary_by_sex}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How does the overall employment trend in manufacturing reflect diversity changes between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the overall employment trend in manufacturing reflect diversity changes between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Is the growth rate consistent across all categories (All
                  Sexes, Female, Male)?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EmploymentGrowthChart data={data_get_employment_growth} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What are the total employment trends in Utah's manufacturing sector from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What are the total employment trends in Utah's manufacturing sector from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What is the average annual metric for females and males
                  over the period 2018-2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <YearlyTotalChart data={data_calculate_yearly_totals} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the female employment share in the manufacturing industry changed in 2023 compared to 2018?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the female employment share in the manufacturing industry changed in 2023 compared to 2018?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. How does the trend for males compare to females over the
                  given years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <YearlyChartData data={data_calculate_sex_yearly_data} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What percentage of the total workforce in 2023 is male in Utah's manufacturing sector?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What percentage of the total workforce in 2023 is male in Utah's manufacturing sector?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the percentage increase or decrease in the male and
                  female metric from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <CalculateSexPercentage data={data_calculate_sex_percentage} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Age Analysis */}
        <Tab eventKey="Age Analysis" title="Age Analysis" id="Age-Analysis">
          <Accordion>
            <Accordion.Item
              eventKey="What is the percentage share of males and females in each age group during 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage share of males and females in each age group during 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which age group showed the fastest growth in the number of
                  workers from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <AgeGroupGrowth data={data_age_group_growth} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the total number of workers across all age groups?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the total number of workers across all age groups?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the male-to-female ratio for each age group in each
                  year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <MaleToFemaleRatioChart
                  data={data_calculate_male_to_female_ratio}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey=" What percentage of the total workforce is female across all age groups?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  " What percentage of the total workforce is female across all age groups?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What is the percentage share of each age group in the total
                  workforce for 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <PercentageShareChart data={data_calculate_percentage_share} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the Age Profile for Women in Manufacturing versus Men?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the Age Profile for Women in Manufacturing versus Men?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the year-over-year growth rate for the 19-21 age
                  group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <CalculateGrowthRate data={data_calculate_growth_rate} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which age group has the highest number of male workers?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group has the highest number of male workers?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the average workforce size for each age group over
                  the years 2018-2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  style={{
                    width: "90%",
                    maxWidth: "800px",
                    margin: "auto",
                  }}
                >
                  <AgeGroupEmployeeChart
                    data={data_get_agegroup_employee_data}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. How do the trends in the 14-18 and 19-21 age groups compare
                  over the six-year period?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <SpecificAgeGroupEmployeeChart
                  data={data_get_specific_agegroup_employee_data}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey=" Which age group has the highest percentage of female workers in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  " Which age group has the highest percentage of female workers in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. Which age group had the highest number of workers in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <AgeGroupDoughnutChart data={data_get_2023_agegroup_data} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Education Analysis */}
        <Tab
          eventKey="Education Analysis"
          title="Education Analysis"
          id="Education-Analysis"
        >
          <Accordion>
            <Accordion.Item
              eventKey="Education Profile of Women in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Education Profile of Women in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which education category had the highest employment growth
                  in 2023 compared to 2018?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EducationGrowthChart data={data_education_growth} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Education Profile of Men in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Education Profile of Men in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What was the percentage increase in the number of employed
                  females with a Bachelor's degree or advanced degree from 2018
                  to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <BachelorDegreeGrowthChart data={data_bachelor_degree_growth} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the percentage share of women in manufacturing changed from 2018 to 2023 across different education levels?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the percentage share of women in manufacturing changed from 2018 to 2023 across different education levels?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. In which year was the difference between the number of
                  employed females and males with a high school or equivalent
                  education the smallest?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <FemaleMaleYearlyChart data={data_female_male_yearly} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Education Profile of Women versus Men Percentage in Manufacturing Employment"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Education Profile of Women versus Men Percentage in Manufacturing Employment"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What was the ratio of employed females to employed males
                  with a Bachelor's degree or advanced degree in 2022?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <FemaleMaleRatioChart data={data_calculate_female_male_ratio} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which education level saw the largest increase in female employment in the manufacturing sector between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which education level saw the largest increase in female employment in the manufacturing sector between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the percentage share of each education category in
                  total employment for each year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EducationPercentageChangeChart
                  data={data_calculate_education_percentage_change}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What was the average number of employed males in all
                  education categories from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the average number of employed males in all education categories from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. What was the average number of employed males in all
                  education categories from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EducationTotalsStackedChart
                  data={data_simple_education_totals}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which education category has the most consistent increase
                  in the number of employed females and males from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which education category has the most consistent increase in the number of employed females and males from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7: Which education category has the most consistent increase
                  in the number of employed females and males from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EducationGenderChangeChart
                  data={data_education_gender_change}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which gender has shown a higher growth rate in employment in the manufacturing sector for individuals with a high school education?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which gender has shown a higher growth rate in employment in the manufacturing sector for individuals with a high school education?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  8: Which education category had the highest number of employed
                  females in 2020?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <EducationTotals2020Chart data={data_education_totals_2020} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Race Analysis */}
        <Tab eventKey="Race Analysis" title="Race Analysis" id="Race-Analysis">
          <Accordion>
            <Accordion.Item
              eventKey="How does the distribution of employment share compare across different race groups in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the distribution of employment share compare across different race groups in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the cumulative employment count for "Asian Alone"
                  from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <AsianAlone3DChart data={data_asian_alone_totals} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the ratio of employment counts for 2018 vs 2023 for each race group?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the ratio of employment counts for 2018 vs 2023 for each race group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the employment count deviation for "All Races" over
                  the years?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <YearlyChange3DChart data={data_all_races_yearly_change} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the percentage change in employment share for each race group from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage change in employment share for each race group from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. Which racial category saw the largest increase in
                  employment between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <RaceGrowth3DChart data={data_race_growth} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How do the employment changes across race groups compare for the years 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How do the employment changes across race groups compare for the years 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the percentage growth in employment for "Black or
                  African American Alone" between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <BlackAAPercentageChangeChart
                  data={data_black_aa_percentage_change}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the employment count comparison between
                  American Indian or Alaska Native Alone and Asian Alone for
                  each year?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the employment count comparison between American Indian or Alaska Native Alone and Asian Alone for each year?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the employment count comparison between "American
                  Indian or Alaska Native Alone" and "Asian Alone" for each
                  year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <AmericanIndianAsianTotalsChart
                  data={data_american_indian_asian_totals}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Ethinicity Analysis */}
        {/* <Tab
          eventKey="Ethinicity Analysis"
          title="Ethinicity Analysis"
          id="Ethinicity-Analysis"
        >
          <Accordion>
            <Accordion.Item
              eventKey="Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the percentage growth of 'Hispanic or Latino' from
                  2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What was the average count for 'Not Hispanic or Latino'
                  from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What was the cumulative count for 'Hispanic or Latino'
                  across all years?
                </span>
              </Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. Which ethnicity saw the smallest growth from 2021 to 2022?
                </span>
              </Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What was the percentage growth of total count from
                  2018 to 2023 for all ethnicities?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the percentage growth of total count from 2018 to 2023 for all ethnicities?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What was the percentage growth of total count from 2018 to
                  2023 for all ethnicities?
                </span>
              </Accordion.Header>
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab> */}
      </Tabs>
    </div>
  );
};
