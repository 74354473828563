// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { Global } from '@emotion/react';
// import { styled } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import { grey } from '@mui/material/colors';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';
// import Typography from '@mui/material/Typography';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';

// const drawerBleeding = 56;

// const Root = styled('div')(({ theme }) => ({
//     // width: 1000,
//   height: '100%',
  
//   backgroundColor: '',
//   ...theme.applyStyles('dark', {
//     backgroundColor: theme.palette.background.default,
//   }),
// }));

// // const Root = styled('div')(({ theme }) => ({
// //     // width: 1000,
// //     height: '100%', // Full height of the viewport
// //     backgroundColor: grey[100],
// //     display: 'flex',
// //     justifyContent: 'center', // Centers horizontally
// //     alignItems: 'center', // Centers vertically
// //     margin: 'auto', // Ensures it stays centered if inside another container
// //     ...theme.applyStyles('dark', {
// //       backgroundColor: theme.palette.background.default,
// //     }),
// //   }));
  

// const StyledBox = styled('div')(({ theme }) => ({
//   backgroundColor: '#fff',
//   ...theme.applyStyles('dark', {
//     backgroundColor: grey[800],
//   }),
// }));

// const Puller = styled('div')(({ theme }) => ({
//   width: 40,
//   height: 6,
//   backgroundColor: grey[300],
//   borderRadius: 3,
//   position: 'absolute',
//   top: 8,
//   left: 'calc(50% - 15px)',
//   ...theme.applyStyles('dark', {
//     backgroundColor: grey[900],
//   }),
// }));

// function SwipeableEdgeDrawer(props) {
//   const { window } = props;
//   const [open, setOpen] = React.useState(false);

//   const toggleDrawer = (newOpen) => () => {
//     setOpen(newOpen);
//   };

//   // This is used only for the example
//   const container = window !== undefined ? () => window().document.body : undefined;

//   return (
//     <Root>
//       <CssBaseline />
//       <Global
//         styles={{
//           '.MuiDrawer-root > .MuiPaper-root': {
//             height: `calc(60% - ${drawerBleeding}px)`,
//             overflow: 'visible',
//           },
          
//         }}
//       />
//       <Box sx={{ textAlign: 'center', pt: 1 }}>
//         <Button onClick={toggleDrawer(true)} className='!text-4xl !text-blue-800 !font-bold !underline !shadow-2xl'>Hill AFB Mission Partner Organizations</Button>
//       </Box>
//       <SwipeableDrawer
//         container={container}
//         anchor="bottom"
//         open={open}
//         onClose={toggleDrawer(false)}
//         onOpen={toggleDrawer(true)}
//         swipeAreaWidth={drawerBleeding}
//         disableSwipeToOpen={false}
//         ModalProps={{
//           keepMounted: true,
//         }}
//       >
//         <StyledBox
//           sx={{
//             position: 'absolute',
//             top: -drawerBleeding,
//             borderTopLeftRadius: 8,
//             borderTopRightRadius: 8,
//             visibility: 'visible',
//             right: 0,
//             left: 0,
//           }}
//         >
//           <Puller />
//           <Typography sx={{ p: 2, color: 'text.secondary' }}>28 results</Typography>
//         </StyledBox>
//         <StyledBox sx={{ px: 2, pb: 2, height: '100%', overflow: 'auto' }}>
//           <Skeleton variant="rectangular" height="100%" />
//         </StyledBox>
//       </SwipeableDrawer>
//     </Root>
//   );
// }

// SwipeableEdgeDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

// export default SwipeableEdgeDrawer;















import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: '',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled('div')(({ theme }) => ({
  width: 40,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900],
  }),
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(60% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <Box sx={{ textAlign: 'center', pt: 1 }}>
        <Button
          onClick={toggleDrawer(true)}
          className="!text-4xl !text-blue-800 !font-bold !underline"
        >
          Hill AFB Mission Partner Organizations
        </Button>
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: 'text.secondary' }}>
            Hill Airforce Mission Partners
          </Typography>
        </StyledBox>
        <StyledBox sx={{ px: 2, pb: 2, height: '100%', overflow: 'auto' }}>
          {/* Static Text Inside the Drawer */}
          <Typography variant="h6" sx={{ mb: 2 }}>
             75th Air Base Wing (75 ABW)- Installation Host
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Ogden Air Logistics Complex (OO-ALC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             748th Supply Chain Management Group (748 SCMG)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Automatic Test Systems Directorate (of Air Force Life Cycle Management Center)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Munitions Division (of Armament Directorate, AFLCMC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Aerospace Enabler Division (of Battle Management Directorate, AFLCMC)
          </Typography> 
          <Typography variant="h6" sx={{ mb: 2 }}>
             Air Force Advance Composites Office (of Engineering Directorate, AFLCMC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             F-35 Program Office Operating Location (of F-35 Joint Program Office)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             A-10 Division (of Fighter-Bomber Directorate, AFLCMC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             F-16 Division (of Fighter-Bomber Directorate, AFLCMC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             F-22 Division (of Fighter-Bomber Directorate, AFLCMC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Product Data Services Division (of Logistics Directorate, AFLCMC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Ground-Based Strategic Deterrent Systems Directorate (of Air Force Nuclear Weapons Center)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Minuteman III Systems Directorate (of AFNWC)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            388th Fighter Wing (F-35)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            419th Fighter Wing (AF Reserve Associate to 388 FW)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             729th Air Control Squadron
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Defense Logistics Agency- Hill AFB, UT (DDHU)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Defense Information Systems Agency (DISA)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Utah Test and Training Range (UTTR)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Hill Aerospace Museum (Aerospace Heritage Foundation of Utah)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Defense Commissary Agency (DeCA)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Army and Air Force Exchange Service (AAFES)
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Air Force Aid Society
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             American Red Cross
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             Air Force Recruiting Service
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             United States Postal Service
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
             United States Army Corps of Engineers (USACE)
          </Typography>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
