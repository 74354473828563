import React, { useRef } from "react";
import { Bubble } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Table } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const utahData = "/img/1.png";
const kpi = "/img/KPI-DASH 2.png";
const bodex = "/img/bodexLogo.png";
const pdf = "/img/pdf1.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const CsAndItTable12 = ({ data }) => {
  const pdfRef = useRef();

  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  const colors = [
    "rgba(54, 162, 235, 0.6)", // Blue
    "rgba(255, 99, 132, 0.6)", // Red
    "rgba(75, 192, 192, 0.6)", // Teal
    "rgba(255, 159, 64, 0.6)", // Orange
    "rgba(153, 102, 255, 0.6)", // Purple
    "rgba(255, 205, 86, 0.6)", // Yellow
  ];

  const borderColors = [
    "rgba(54, 162, 235, 1)", // Blue
    "rgba(255, 99, 132, 1)", // Red
    "rgba(75, 192, 192, 1)", // Teal
    "rgba(255, 159, 64, 1)", // Orange
    "rgba(153, 102, 255, 1)", // Purple
    "rgba(255, 205, 86, 1)", // Yellow
  ];

  const bubbleData = data.map((item, index) => ({
    x: item.year,
    y: parseFloat(item.percentage),
    r: Math.sqrt(parseInt(item.all_education_categories)) / 15, // Bubble size scaled down
    backgroundColor: colors[index % colors.length], // Assign different color to each bubble
    borderColor: borderColors[index % borderColors.length], // Border color matching bubble
  }));

  const chartData = {
    datasets: bubbleData.map((bubble, index) => ({
      label: `Year ${data[index].year}`,
      data: [bubble],
      backgroundColor: bubble.backgroundColor,
      borderColor: bubble.borderColor,
      borderWidth: 1,
    })),
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employment by Education Level (%) (2018-2023)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
        min: 2017, // Ensures axis starts near 0
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        min: 0,
        max: 40, // Limits Y-axis to 40%
        title: {
          display: true,
          text: "Percentage of Employment with Bachelor's or Advanced Degree (%)",
        },
        ticks: {
          callback: function (value) {
            return value + "%"; // Adds % sign to Y-axis
          },
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;

    const originalWidth = input.style.width;
    input.style.width = "1100px";
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);

      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString();
      pdf.setFontSize(12);
      pdf.text(`Downloaded on: ${formattedDate}`, 150, 295);

      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);

      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, footerStartY, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, footerStartY + 10);

      pdf.addImage(bodex, "PNG", 68, footerStartY + 4, 44, 10);
      pdf.text("using", 112, footerStartY + 10);
      pdf.addImage(kpi, "PNG", 125, footerStartY, 20, 20);

      pdf.text("20250211.v1.113", 148, footerStartY + 10);

      const currentURL = window.location.href;
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);

      pdf.save("csAndIt_table12.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="dashboard-container">
      <div ref={pdfRef} className="content-to-export">
        <div style={{ padding: "20px" }}>
          <h2>Employment by Education Level (%) (2018-2023)</h2>

          {/* Render Table */}
          <div style={{ overflowX: "auto", marginBottom: "20px" }}>
            <Table
              border="1"
              cellPadding="10"
              style={{ width: "100%", minWidth: "500px" }}
            >
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Total Employment</th>
                  <th>Bachelor's or Advanced Degree Employment (%)</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{row.year}</td>
                    <td>{row.all_education_categories}</td>
                    <td
                      style={{
                        color: row.percentage >= 35 ? "blue" : "green",
                        fontWeight: "bold",
                      }}
                    >
                      {row.percentage}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Bubble Chart */}
          <div
            style={{
              width: "90%",
              maxWidth: "900px",
              margin: "auto",
              overflowX: "auto",
            }}
          >
            <div style={{ width: "100%", minWidth: "600px", height: "400px" }}>
              <Bubble data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF </span>
          <span>
            <img className="w-[2rem]" src={pdf} onClick={downloadPDF} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default CsAndItTable12;
