import React from 'react'

export default function School8() {

    const schoolData = 
    [
        {
          "schoolName": "Adams School",
          "numberOfStudents": 652,
          "grades": "PK-6"
        },
        {
          "schoolName": "Adelaide School",
          "numberOfStudents": 419,
          "grades": "PK-6"
        },
        {
          "schoolName": "Antelope School",
          "numberOfStudents": 693,
          "grades": "PK-6"
        },
        {
          "schoolName": "Bluff Ridge School",
          "numberOfStudents": 866,
          "grades": "PK-6"
        },
        {
          "schoolName": "Boulton School",
          "numberOfStudents": 506,
          "grades": "PK-6"
        },
        {
          "schoolName": "Bountiful High",
          "numberOfStudents": 1482,
          "grades": "10-12"
        },
        {
          "schoolName": "Bountiful Jr High",
          "numberOfStudents": 630,
          "grades": "7-9"
        },
        {
          "schoolName": "Bountiful School",
          "numberOfStudents": 478,
          "grades": "KG-6"
        },
        {
          "schoolName": "Buffalo Point School",
          "numberOfStudents": 938,
          "grades": "PK-6"
        },
        {
          "schoolName": "Canyon Creek School",
          "numberOfStudents": 832,
          "grades": "PK-6"
        },
        {
          "schoolName": "Catalyst Center",
          "numberOfStudents": 0,
          "grades": "9-12"
        },
        {
          "schoolName": "Centennial Jr High",
          "numberOfStudents": 1033,
          "grades": "7-9"
        },
        {
          "schoolName": "Centerville Jr High",
          "numberOfStudents": 976,
          "grades": "7-9"
        },
        {
          "schoolName": "Centerville School",
          "numberOfStudents": 342,
          "grades": "PK-6"
        },
        {
          "schoolName": "Central Davis Jr High",
          "numberOfStudents": 1009,
          "grades": "7-9"
        },
        {
          "schoolName": "Clearfield High",
          "numberOfStudents": 2030,
          "grades": "10-12"
        },
        {
          "schoolName": "Clinton School",
          "numberOfStudents": 464,
          "grades": "PK-6"
        },
        {
          "schoolName": "Columbia School",
          "numberOfStudents": 569,
          "grades": "KG-6"
        },
        {
          "schoolName": "Cook School",
          "numberOfStudents": 896,
          "grades": "PK-6"
        },
        {
          "schoolName": "Creekside School",
          "numberOfStudents": 613,
          "grades": "PK-6"
        },
        {
          "schoolName": "Crestview School",
          "numberOfStudents": 362,
          "grades": "PK-6"
        },
        {
          "schoolName": "Davis Connect 7-12",
          "numberOfStudents": 340,
          "grades": "7-12"
        },
        {
          "schoolName": "Davis Connect 7-8",
          "numberOfStudents": 0,
          "grades": "7-8"
        },
        {
          "schoolName": "Davis Connect K-6",
          "numberOfStudents": 167,
          "grades": "KG-8"
        },
        {
          "schoolName": "Davis High",
          "numberOfStudents": 2175,
          "grades": "10-12"
        },
        {
          "schoolName": "Doxey School",
          "numberOfStudents": 301,
          "grades": "PK-6"
        },
        {
          "schoolName": "Eagle Bay School",
          "numberOfStudents": 536,
          "grades": "PK-6"
        },
        {
          "schoolName": "East Layton School",
          "numberOfStudents": 624,
          "grades": "PK-6"
        },
        {
          "schoolName": "Ellison Park School",
          "numberOfStudents": 624,
          "grades": "PK-6"
        },
        {
          "schoolName": "Endeavour School",
          "numberOfStudents": 740,
          "grades": "KG-6"
        },
        {
          "schoolName": "Fairfield Jr High",
          "numberOfStudents": 1026,
          "grades": "7-9"
        },
        {
          "schoolName": "Family Enrichment Center",
          "numberOfStudents": 220,
          "grades": "PK-PK"
        },
        {
          "schoolName": "Farmington High",
          "numberOfStudents": 2083,
          "grades": "10-12"
        },
        {
          "schoolName": "Farmington Jr High",
          "numberOfStudents": 1293,
          "grades": "7-9"
        },
        {
          "schoolName": "Farmington School",
          "numberOfStudents": 498,
          "grades": "KG-6"
        },
        {
          "schoolName": "Foxboro School",
          "numberOfStudents": 608,
          "grades": "PK-6"
        },
        {
          "schoolName": "Fremont School",
          "numberOfStudents": 268,
          "grades": "PK-6"
        },
        {
          "schoolName": "H C Burton School",
          "numberOfStudents": 671,
          "grades": "PK-6"
        },
        {
          "schoolName": "Heritage School",
          "numberOfStudents": 737,
          "grades": "PK-6"
        },
        {
          "schoolName": "Hill Field School",
          "numberOfStudents": 502,
          "grades": "PK-6"
        },
        {
          "schoolName": "Holbrook School",
          "numberOfStudents": 525,
          "grades": "PK-6"
        },
        {
          "schoolName": "Holt School",
          "numberOfStudents": 458,
          "grades": "PK-6"
        },
        {
          "schoolName": "Kay'S Creek Elementary",
          "numberOfStudents": 688,
          "grades": "KG-6"
        },
        {
          "schoolName": "Kaysville Jr High",
          "numberOfStudents": 965,
          "grades": "7-9"
        },
        {
          "schoolName": "Kaysville School",
          "numberOfStudents": 524,
          "grades": "PK-6"
        },
        {
          "schoolName": "King School",
          "numberOfStudents": 511,
          "grades": "PK-6"
        },
        {
          "schoolName": "Knowlton School",
          "numberOfStudents": 718,
          "grades": "PK-6"
        },
        {
          "schoolName": "Lakeside School",
          "numberOfStudents": 842,
          "grades": "PK-6"
        },
        {
          "schoolName": "Layton High",
          "numberOfStudents": 2242,
          "grades": "10-12"
        },
        {
          "schoolName": "Layton School",
          "numberOfStudents": 573,
          "grades": "KG-6"
        },
        {
          "schoolName": "Legacy Jr High",
          "numberOfStudents": 1088,
          "grades": "7-9"
        },
        {
          "schoolName": "Lincoln School",
          "numberOfStudents": 703,
          "grades": "PK-6"
        },
        {
          "schoolName": "Meadowbrook School",
          "numberOfStudents": 419,
          "grades": "PK-6"
        },
        {
          "schoolName": "Millcreek Jr High",
          "numberOfStudents": 689,
          "grades": "7-9"
        },
        {
          "schoolName": "Morgan School",
          "numberOfStudents": 640,
          "grades": "PK-6"
        },
        {
          "schoolName": "Mountain High",
          "numberOfStudents": 200,
          "grades": "10-12"
        },
        {
          "schoolName": "Mountain View School",
          "numberOfStudents": 781,
          "grades": "PK-6"
        },
        {
          "schoolName": "Mueller Park Jr High",
          "numberOfStudents": 1089,
          "grades": "7-9"
        },
        {
          "schoolName": "Muir School",
          "numberOfStudents": 670,
          "grades": "KG-6"
        },
        {
          "schoolName": "North Davis Jr High",
          "numberOfStudents": 924,
          "grades": "7-9"
        },
        {
          "schoolName": "North Layton Jr High",
          "numberOfStudents": 1009,
          "grades": "7-9"
        },
        {
          "schoolName": "Northridge High",
          "numberOfStudents": 1954,
          "grades": "10-12"
        },
        {
          "schoolName": "Oak Hills School",
          "numberOfStudents": 404,
          "grades": "PK-6"
        },
        {
          "schoolName": "Odyssey School",
          "numberOfStudents": 603,
          "grades": "KG-6"
        },
        {
          "schoolName": "Orchard School",
          "numberOfStudents": 587,
          "grades": "KG-6"
        },
        {
          "schoolName": "Parkside School",
          "numberOfStudents": 530,
          "grades": "PK-6"
        },
        {
          "schoolName": "Reading School",
          "numberOfStudents": 452,
          "grades": "PK-6"
        },
        {
          "schoolName": "Ridgeline School",
          "numberOfStudents": 762,
          "grades": "PK-6"
        },
        {
          "schoolName": "Riverview School",
          "numberOfStudents": 561,
          "grades": "KG-6"
        },
        {
          "schoolName": "Rose Creek School",
          "numberOfStudents": 410,
          "grades": "PK-6"
        },
        {
          "schoolName": "Snow Horse School",
          "numberOfStudents": 680,
          "grades": "KG-6"
        },
        {
          "schoolName": "South Clearfield School",
          "numberOfStudents": 478,
          "grades": "KG-6"
        },
        {
          "schoolName": "South Davis Jr High",
          "numberOfStudents": 1232,
          "grades": "7-9"
        },
        {
          "schoolName": "South Weber School",
          "numberOfStudents": 501,
          "grades": "KG-6"
        },
        {
          "schoolName": "Stewart School",
          "numberOfStudents": 394,
          "grades": "PK-6"
        },
        {
          "schoolName": "Sunset Jr High",
          "numberOfStudents": 865,
          "grades": "7-9"
        },
        {
          "schoolName": "Sunset School",
          "numberOfStudents": 361,
          "grades": "PK-6"
        },
        {
          "schoolName": "Syracuse High",
          "numberOfStudents": 2227,
          "grades": "10-12"
        },
        {
          "schoolName": "Syracuse Jr High",
          "numberOfStudents": 1314,
          "grades": "7-9"
        },
        {
          "schoolName": "Syracuse School",
          "numberOfStudents": 692,
          "grades": "PK-6"
        },
        {
          "schoolName": "Tolman School",
          "numberOfStudents": 437,
          "grades": "PK-6"
        },
        {
          "schoolName": "Vae View School",
          "numberOfStudents": 453,
          "grades": "KG-6"
        },
        {
          "schoolName": "Valley View School",
          "numberOfStudents": 571,
          "grades": "KG-6"
        },
        {
          "schoolName": "Viking School",
          "numberOfStudents": 432,
          "grades": "PK-6"
        },
        {
          "schoolName": "Wasatch School",
          "numberOfStudents": 380,
          "grades": "PK-6"
        },
        {
          "schoolName": "West Bountiful School",
          "numberOfStudents": 536,
          "grades": "KG-6"
        },
        {
          "schoolName": "West Clinton School",
          "numberOfStudents": 529,
          "grades": "KG-6"
        },
        {
          "schoolName": "West Davis Jr High",
          "numberOfStudents": 0,
          "grades": "7-9"
        },
        {
          "schoolName": "West Point Jr High",
          "numberOfStudents": 1311,
          "grades": "7-9"
        },
        {
          "schoolName": "West Point School",
          "numberOfStudents": 846,
          "grades": "KG-6"
        },
        {
          "schoolName": "West Weber School",
          "numberOfStudents": 376,
          "grades": "PK-6"
        },
        {
          "schoolName": "Whitesides School",
          "numberOfStudents": 467,
          "grades": "PK-6"
        },
        {
          "schoolName": "Windridge School",
          "numberOfStudents": 653,
          "grades": "PK-6"
        },
        {
          "schoolName": "Woods Cross High",
          "numberOfStudents": 1449,
          "grades": "10-12"
        },
        {
          "schoolName": "Woods Cross School",
          "numberOfStudents": 500,
          "grades": "PK-6"
        },
        {
          "schoolName": "Woodstock School",
          "numberOfStudents": 399,
          "grades": "PK-6"
        }
      ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Davis
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
