import React from 'react'

export default function School25() {

    const schoolData = 
    [
        {"schoolName": "Ben Lomond High", "numberOfStudents": 1169, "grades": "10-12"},
        {"schoolName": "Bonneville School", "numberOfStudents": 251, "grades": "PK-6"},
        {"schoolName": "Contracted Special Education", "numberOfStudents": 0, "grades": "KG-12"},
        {"schoolName": "East Ridge Elementary School", "numberOfStudents": 720, "grades": "PK-6"},
        {"schoolName": "George Washington High", "numberOfStudents": 238, "grades": "7-12"},
        {"schoolName": "Heritage School", "numberOfStudents": 638, "grades": "KG-6"},
        {"schoolName": "Highland Junior High", "numberOfStudents": 715, "grades": "7-9"},
        {"schoolName": "Hillcrest School", "numberOfStudents": 336, "grades": "PK-6"},
        {"schoolName": "James Madison School", "numberOfStudents": 321, "grades": "PK-6"},
        {"schoolName": "Liberty Elementary School", "numberOfStudents": 419, "grades": "PK-6"},
        {"schoolName": "Lincoln School", "numberOfStudents": 461, "grades": "PK-6"},
        {"schoolName": "Malan'S Peak Secondary", "numberOfStudents": 38, "grades": "7-12"},
        {"schoolName": "Mound Fort Junior High", "numberOfStudents": 627, "grades": "7-9"},
        {"schoolName": "Mount Ogden Junior High", "numberOfStudents": 858, "grades": "7-9"},
        {"schoolName": "New Bridge School", "numberOfStudents": 614, "grades": "PK-6"},
        {"schoolName": "Odyssey School", "numberOfStudents": 448, "grades": "PK-6"},
        {"schoolName": "Ogden High", "numberOfStudents": 1128, "grades": "10-12"},
        {"schoolName": "Ogden Online Elementary", "numberOfStudents": 0, "grades": "KG-6"},
        {"schoolName": "Ogden Online Secondary", "numberOfStudents": 0, "grades": "7-12"},
        {"schoolName": "Ogden Preschool", "numberOfStudents": 163, "grades": "PK-KG"},
        {"schoolName": "Polk School", "numberOfStudents": 439, "grades": "KG-6"},
        {"schoolName": "Shadow Valley School", "numberOfStudents": 587, "grades": "KG-6"},
        {"schoolName": "Spec. Educ. Atc", "numberOfStudents": 0, "grades": "PK-12"},
        {"schoolName": "Wasatch School", "numberOfStudents": 351, "grades": "KG-6"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Ogden
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
