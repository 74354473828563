import React from 'react'

export default function School41() {

    const schoolData = 
    [
        {"schoolName": "Bates School", "numberOfStudents": 728, "grades": "KG-6"},
        {"schoolName": "Bonneville High", "numberOfStudents": 1361, "grades": "10-12"},
        {"schoolName": "Burch Creek School", "numberOfStudents": 559, "grades": "KG-6"},
        {"schoolName": "Canyon View Preschool", "numberOfStudents": 98, "grades": "PK-PK"},
        {"schoolName": "Country View School", "numberOfStudents": 572, "grades": "KG-6"},
        {"schoolName": "Farr West School", "numberOfStudents": 788, "grades": "KG-6"},
        {"schoolName": "Freedom School", "numberOfStudents": 819, "grades": "KG-6"},
        {"schoolName": "Fremont High", "numberOfStudents": 2160, "grades": "10-12"},
        {"schoolName": "Green Acres School", "numberOfStudents": 546, "grades": "KG-6"},
        {"schoolName": "H Guy Child School", "numberOfStudents": 410, "grades": "KG-6"},
        {"schoolName": "Hooper School", "numberOfStudents": 656, "grades": "KG-6"},
        {"schoolName": "Horizon Academy", "numberOfStudents": 80, "grades": "6-12"},
        {"schoolName": "Kanesville School", "numberOfStudents": 988, "grades": "KG-6"},
        {"schoolName": "Lakeview School", "numberOfStudents": 441, "grades": "KG-6"},
        {"schoolName": "Lomond View School", "numberOfStudents": 483, "grades": "KG-6"},
        {"schoolName": "Majestic School", "numberOfStudents": 771, "grades": "KG-6"},
        {"schoolName": "Midland School", "numberOfStudents": 568, "grades": "PK-6"},
        {"schoolName": "Municipal School", "numberOfStudents": 331, "grades": "KG-6"},
        {"schoolName": "New High Weber", "numberOfStudents": 0, "grades": ""},
        {"schoolName": "North Ogden Jr High", "numberOfStudents": 936, "grades": "7-9"},
        {"schoolName": "North Ogden School", "numberOfStudents": 560, "grades": "KG-6"},
        {"schoolName": "North Park School", "numberOfStudents": 467, "grades": "KG-6"},
        {"schoolName": "Orchard Springs", "numberOfStudents": 480, "grades": "KG-6"},
        {"schoolName": "Orion Jr High", "numberOfStudents": 1063, "grades": "7-9"},
        {"schoolName": "Pioneer School", "numberOfStudents": 414, "grades": "KG-6"},
        {"schoolName": "Plain City School", "numberOfStudents": 638, "grades": "KG-6"},
        {"schoolName": "Riverdale School", "numberOfStudents": 416, "grades": "KG-6"},
        {"schoolName": "Rocky Mountain Jr High", "numberOfStudents": 1225, "grades": "7-9"},
        {"schoolName": "Roosevelt School", "numberOfStudents": 502, "grades": "KG-6"},
        {"schoolName": "Roy High", "numberOfStudents": 1834, "grades": "10-12"},
        {"schoolName": "Roy Jr High", "numberOfStudents": 1008, "grades": "7-9"},
        {"schoolName": "Roy School", "numberOfStudents": 503, "grades": "KG-6"},
        {"schoolName": "Sand Ridge Jr High", "numberOfStudents": 857, "grades": "7-9"},
        {"schoolName": "Silver Ridge Elementary", "numberOfStudents": 625, "grades": "KG-6"},
        {"schoolName": "Snowcrest Jr High", "numberOfStudents": 280, "grades": "7-9"},
        {"schoolName": "South Ogden Jr High", "numberOfStudents": 846, "grades": "7-9"},
        {"schoolName": "T.H. Bell Jr High", "numberOfStudents": 670, "grades": "7-9"},
        {"schoolName": "Two Rivers High", "numberOfStudents": 218, "grades": "7-12"},
        {"schoolName": "Uintah School", "numberOfStudents": 681, "grades": "KG-6"},
        {"schoolName": "Valley School", "numberOfStudents": 617, "grades": "KG-6"},
        {"schoolName": "Valley View School", "numberOfStudents": 336, "grades": "KG-6"},
        {"schoolName": "Wahlquist Jr High", "numberOfStudents": 1260, "grades": "7-9"},
        {"schoolName": "Washington Terrace School", "numberOfStudents": 493, "grades": "KG-6"},
        {"schoolName": "Weber High", "numberOfStudents": 2037, "grades": "10-12"},
        {"schoolName": "Weber Hq Preschool", "numberOfStudents": 0, "grades": "PK-PK"},
        {"schoolName": "Weber Innovation High School", "numberOfStudents": 390, "grades": "6-12"},
        {"schoolName": "Weber New Elementary", "numberOfStudents": 0, "grades": ""},
        {"schoolName": "Weber New Middle School", "numberOfStudents": 0, "grades": ""},
        {"schoolName": "Weber Online 7-8", "numberOfStudents": 0, "grades": "7-8"},
        {"schoolName": "Weber Online 9-12", "numberOfStudents": 0, "grades": "9-12"},
        {"schoolName": "Weber Online K-6", "numberOfStudents": 65, "grades": "KG-6"},
        {"schoolName": "West Haven School", "numberOfStudents": 540, "grades": "KG-6"},
        {"schoolName": "West Weber School", "numberOfStudents": 568, "grades": "KG-6"}
      ]
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Weber
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
