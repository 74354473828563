import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import config from "../config/config.json";

const ReportForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    reportType: "",
    comment: "",
  });
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  console.log(formData);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${config.APP_URL}/betsy/enquiries`,
        formData
      );
      setMsg(response.data.message);
      setFormData({ name: "", email: "", reportType: "", comment: "" });
    } catch (error) {
      console.error("Error submitting form:", error);
      setErr("Failed to submit the form. Please try again.");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "end",
          marginTop: "20px",
        }}
      >
        <TextField
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          label="Enter Your Name"
          variant="standard"
          disabled={msg}
        />
        <TextField
          id="email"
          name="email"
          label="Enter Your Email"
          variant="standard"
          value={formData.email}
          onChange={handleChange}
          sx={{ marginLeft: "15px" }}
          disabled={msg}
        />
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={msg}
          className="bg-[#f0a500] text-blue-950 rounded-md hover:bg-yellow-600 transition duration-300 flex items-center justify-center gap-2 px-4 py-2"
          style={{ height: "40px" }}
        >
          <i className="fas fa-paper-plane text-blue-950"></i>
          <span>Send</span>
        </button>
      </div>
      <div style={{ marginTop: "30px" }}>
        {msg && (
          <p style={{ color: "green" }}>
            <i class="fa fa-info-circle" aria-hidden="true"></i> &nbsp;
            <span>{msg}</span>
          </p>
        )}
        {err && (
          <p style={{ color: "red" }}>
            <i class="fa fa-info-circle" aria-hidden="true"></i> &nbsp;
            <span>{err}</span>
          </p>
        )}
      </div>
    </>
  );
};

export default ReportForm;
