import React from 'react'
const statePark = "/img/service.webp";

function PlacesForestService() {
    const park = [
      {
          "NAME": "Ashdown Gorge",
          "MANAGEMENT AREA": "Dixie National Forest",
          "Address": "Brian Head, UT 84719, United States",
          "Phone No:": "435-865-3700",
          "Website": "https://www.fs.usda.gov/recarea/dixie/recarea/?recid=24848",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "Box-Death Hollow Wilderness",
          "MANAGEMENT AREA": "Dixie National Forest",
          "Address": "Escalante, UT 84726, United States",
          "Phone No:": "435-826-5499",
          "Website": "https://www.fs.usda.gov/recarea/dixie/recarea/?recid=70912",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "Cottonwood Forest Wilderness",
          "MANAGEMENT AREA": "Dixie National Forest",
          "Address": "Washington, UT 84780, United States",
          "Phone No:": "435-688-3246",
          "Website": "https://wilderness.net/visit-wilderness/?ID=718",
          "Established on": "2009"
      },
      {
          "NAME": "Dark Canyon Wilderness",
          "MANAGEMENT AREA": "Manti-La Sal National Forest",
          "Address": "Monticello, UT 84535, United States",
          "Phone No:": "435-637-2817",
          "Website": "https://www.fs.usda.gov/recarea/mantilasal/recarea/?recid=81864",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "Deseret Peak Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Dugway, UT 84022, United States",
          "Phone No:": "435-843-4001",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/about-forest/districts/?cid=stelprdb5043523",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "High Uintas Wilderness",
          "MANAGEMENT AREA": "Ashley National Forest",
          "Address": "North Duchesne, UT 84021, United States",
          "Phone No:": "435-738-1100",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/about-forest/districts/?cid=fsem_035477",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "Lone Peak Wildernesss",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Alpine, UT 84004, United States",
          "Phone No:": "801-733-2660",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/about-forest/districts/?cid=fsem_035453",
          "Established on": "24 February 1978"
      },
      {
          "NAME": "Mount Naomi Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Richmond, UT 84333, United States",
          "Phone No:": "435-755-3620",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/recreation/wintersports/?cid=fsem_035454",
          "Established on": "1984"
      },
      {
          "NAME": "Mount Nebo Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Nebo Loop Road Payson, UT 84651",
          "Phone No:": "435-623-2735",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/specialplaces/?cid=fsem_035455",
          "Established on": "1984"
      },
      {
          "NAME": "Mount Olympus Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Salt Lake City, UT 84109, United States",
          "Phone No:": "801-733-2660",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/about-forest/districts/?cid=fsem_035478",
          "Established on": "1984"
      },
      {
          "NAME": "Mount Timpanogos Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Provo, UT 84604, United States",
          "Phone No:": "801-785-3563",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/specialplaces/?cid=fsem_035456",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "Pine Valley Mountain Wilderness",
          "MANAGEMENT AREA": "Dixie National Forest",
          "Address": "Washington County, Utah, United States",
          "Phone No:": "435-688-3246",
          "Website": "https://wilderness.net/visit-wilderness/?ID=459",
          "Established on": "18 September 1984"
      },
      {
          "NAME": "Twin Peaks Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Cottonwood, UT 84121, United States",
          "Phone No:": "801-236-3400",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/about-forest/districts/?cid=fsem_035482",
          "Established on": "1984"
      },
      {
          "NAME": "Wellsville Mountains Wilderness",
          "MANAGEMENT AREA": "Uinta-Wasatch-Cache National Forest",
          "Address": "Mendon, UT 84325, United States",
          "Phone No:": "801-999-2103",
          "Website": "https://www.fs.usda.gov/detail/uwcnf/recreation/wintersports/?cid=fsem_035479",
          "Established on": "1984"
      }
  ]
    return (
        <div>
          {/* <div>
         <div
        className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
        style={{ backgroundColor: 'rgb(210, 234, 255)' }}
      >
        <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
          <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          State Parks in Utah
          <div className='mt-2 font-bold text-2xl'>(46 State Parks)</div>
          </div> */}
    
          <div
            className="min-h-screen mt-[1rem]  flex flex-col items-center justify-center"
            style={{ backgroundColor: "rgb(210, 234, 255)" }}
          >
            <div className="overflow-x-auto w-full mb-[3rem]">
              {/* <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
          National Parks in Utah
          <div className='mt-2 font-bold text-2xl'>(5 National Parks)</div> */}
              <div
                className=" bg-[#d2eaff] flex flex-col items-center justify-center"
                aria-label="Utah aquatic scenery"
                style={{
                  backgroundImage: `url(${statePark})`, // Replace with your image path
                  backgroundSize: "cover", // Ensures the image covers the entire container
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                  width: "100%", // Ensures the container takes full width of the screen
                  height: "59vh", // Makes the container span the full height of the viewport
                }}
              >
                <h2
                  className="text-white text-center px-4 text-5xl sm:text-6xl md:text-6xl lg:text-7xl mt-7"
                  style={{
                    color: "white",
                    textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
                    lineHeight: "1.2", // Adjusts line spacing
                  }}
                >
                  United States Forest Service Wilderness Areas
                </h2>
                <div className="mt-2 font-bold text-2xl" style={{ color: "#fff" }}>
                  
                </div>
    
                <span
                  className="text-center px-4 text-2xl sm:text-2xl md:text-3xl lg:text-4xl mt-4"
                  style={{
                    color: "#fff",
                    fontFamily: "SignPainter", // Use the SignPainter font
                    textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
                    lineHeight: "1.5", // Adjusts line spacing
                  }}
                >
                  Explore Utah By The Numbers
                </span>
              </div>
            </div>
    
            <div className="overflow-x-auto w-[95%] bg-white shadow-md rounded-lg p-4">
              <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
                <thead>
                  <tr>
                    {/* <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      S.no
                    </th> */}
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Name
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                        Management Area
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Address
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                      Contact No
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                      Website
                    </th>
                    <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                      Established on
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {park.map((park) => (
                    <tr key={park.sn} className="hover:bg-gray-100">
                      {/* <td className="border border-gray-300 px-4 py-2 text-center ">
                        {park["S.no"]}
                      </td> */}
    
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {park.NAME}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park['MANAGEMENT AREA']}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {park.Address}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["Phone No:"]}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center ">
                        <a
                          href={park.Website}
                          target="_blank"
                          rel=" noopener noreferrer"
                        >
                          {park.Website}
                        </a>
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {park["Established on"]}
                      </td>
    
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
}

export default PlacesForestService
