import React from "react";
import VotersChart from "./ActiveVotersChart";

const votersData = [
  { Fields: "Constitution", "No. of Voters": "6,516" },
  { Fields: "Democratic", "No. of Voters": "2,41,335" },
  { Fields: "Green", "No. of Voters": "645" },
  { Fields: "Independent American", "No. of Voters": "73,547" },
  { Fields: "Libertarian", "No. of Voters": "18,798" },
  { Fields: "No Labels", "No. of Voters": "10,795" },
  { Fields: "Republican", "No. of Voters": "9,32,949" },
  { Fields: "United Utah", "No. of Voters": "2,196" },
  { Fields: "Utah Forward", "No. of Voters": "168" },
  { Fields: "Unaffiliated", "No. of Voters": "4,73,104" },
  { Fields: "Total Active Voters", "No. of Voters": "17,60,053" },
];

const ActiveVoters = () => {
  return (
    <div
      style={{
        background: "#1e293b",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: "14vh",
      }}
    >
      <h1 style={{ color: "#ffc107", marginBottom: "20px" }}>
        Active Voters Chart
      </h1>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            minWidth: "600px",
          }}
        >
          <VotersChart data={votersData} />
        </div>
      </div>
    </div>
  );
};

export default ActiveVoters;
