// // // // import React, { useState, useEffect } from 'react';
// // // // import axios from 'axios';
// // // // import { Line } from 'react-chartjs-2';
// // // // import {
// // // //   Chart as ChartJS,
// // // //   CategoryScale,
// // // //   LinearScale,
// // // //   PointElement,
// // // //   LineElement,
// // // //   Title,
// // // //   Tooltip,
// // // //   Legend,
// // // // } from 'chart.js';

// // // // // Register ChartJS components
// // // // ChartJS.register(
// // // //   CategoryScale,
// // // //   LinearScale,
// // // //   PointElement,
// // // //   LineElement,
// // // //   Title,
// // // //   Tooltip,
// // // //   Legend
// // // // );

// // // // const AirQualityDashboard = () => {
// // // //   const [searchTerm, setSearchTerm] = useState('');
// // // //   const [countyOptions, setCountyOptions] = useState([]);
// // // //   const [selectedCounty, setSelectedCounty] = useState(null);
// // // //   const [airQualityData, setAirQualityData] = useState([]);
// // // //   const [loading, setLoading] = useState(false);
// // // //   const [error, setError] = useState(null);

// // // //   // List of Utah counties for reference
// // // //   const utahCounties = [
// // // //     "Beaver", "Box Elder", "Cache", "Carbon", "Daggett", "Davis", 
// // // //     "Duchesne", "Emery", "Garfield", "Grand", "Iron", "Juab", 
// // // //     "Kane", "Millard", "Morgan", "Piute", "Rich", "Salt Lake", 
// // // //     "San Juan", "Sanpete", "Sevier", "Summit", "Tooele", "Uintah", 
// // // //     "Utah", "Wasatch", "Washington", "Wayne", "Weber"
// // // //   ];

// // // //   // Search for county coordinates
// // // //   const searchCounty = async () => {
// // // //     if (!searchTerm.trim()) return;
    
// // // //     setLoading(true);
// // // //     setError(null);
    
// // // //     try {
// // // //       const response = await axios.get(
// // // //         `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm}&limit=5&appid=83c8416a7968c9903e469321a38a08f5`
// // // //       );
      
// // // //       // Filter results to only include Utah, USA locations
// // // //       const utahResults = response.data.filter(
// // // //         location => location.state === "Utah" && location.country === "US"
// // // //       );
      
// // // //       setCountyOptions(utahResults);
      
// // // //       if (utahResults.length === 0) {
// // // //         setError('No matching Utah county found');
// // // //       }
// // // //     } catch (err) {
// // // //       setError('Failed to fetch county data');
// // // //       console.error(err);
// // // //     } finally {
// // // //       setLoading(false);
// // // //     }
// // // //   };

// // // //   // Fetch air quality data for selected county
// // // //   const fetchAirQuality = async (lat, lon) => {
// // // //     setLoading(true);
// // // //     setError(null);
    
// // // //     try {
// // // //       // In a real app, you would fetch historical data here
// // // //       // For demo, we'll simulate data for the past 7 days
// // // //       const response = await axios.get(
// // // //         `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=83c8416a7968c9903e469321a38a08f5`
// // // //       );
      
// // // //       // Simulate historical data (since the API only returns current data)
// // // //       const currentData = response.data.list[0];
// // // //       const simulatedData = generateHistoricalData(currentData);
      
// // // //       setAirQualityData(simulatedData);
// // // //     } catch (err) {
// // // //       setError('Failed to fetch air quality data');
// // // //       console.error(err);
// // // //     } finally {
// // // //       setLoading(false);
// // // //     }
// // // //   };

// // // //   // Helper function to generate simulated historical data
// // // //   const generateHistoricalData = (currentData) => {
// // // //     const days = 7;
// // // //     const data = [];
    
// // // //     for (let i = days; i >= 0; i--) {
// // // //       const date = new Date();
// // // //       date.setDate(date.getDate() - i);
      
// // // //       // Add some random variation to simulate historical data
// // // //       const variation = 0.9 + Math.random() * 0.2; // Between 0.9 and 1.1
      
// // // //       data.push({
// // // //         date: date.toLocaleDateString(),
// // // //         aqi: Math.round(currentData.main.aqi * variation),
// // // //         components: {
// // // //           co: currentData.components.co * variation,
// // // //           no2: currentData.components.no2 * variation,
// // // //           o3: currentData.components.o3 * variation,
// // // //           pm2_5: currentData.components.pm2_5 * variation,
// // // //         }
// // // //       });
// // // //     }
    
// // // //     return data;
// // // //   };

// // // //   // Handle county selection
// // // //   const handleCountySelect = (county) => {
// // // //     setSelectedCounty(county);
// // // //     setSearchTerm(county.name);
// // // //     setCountyOptions([]);
// // // //     fetchAirQuality(county.lat, county.lon);
// // // //   };

// // // //   // Prepare chart data
// // // //   const chartData = {
// // // //     labels: airQualityData.map(item => item.date),
// // // //     datasets: [
// // // //       {
// // // //         label: 'Air Quality Index (AQI)',
// // // //         data: airQualityData.map(item => item.aqi),
// // // //         borderColor: 'rgb(75, 192, 192)',
// // // //         backgroundColor: 'rgba(75, 192, 192, 0.5)',
// // // //         tension: 0.1,
// // // //       },
// // // //       {
// // // //         label: 'PM2.5 (µg/m³)',
// // // //         data: airQualityData.map(item => item.components.pm2_5),
// // // //         borderColor: 'rgb(255, 99, 132)',
// // // //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
// // // //         tension: 0.1,
// // // //       }
// // // //     ],
// // // //   };

// // // //   const chartOptions = {
// // // //     responsive: true,
// // // //     plugins: {
// // // //       legend: {
// // // //         position: 'top',
// // // //       },
// // // //       title: {
// // // //         display: true,
// // // //         text: `Air Quality Data for ${selectedCounty?.name || 'Selected County'}`,
// // // //       },
// // // //     },
// // // //     scales: {
// // // //       y: {
// // // //         beginAtZero: false,
// // // //       },
// // // //     },
// // // //   };

// // // //   return (
// // // //     <div className="container mx-auto p-4 max-w-4xl">
// // // //       <h1 className="text-2xl font-bold mb-6">Utah County Air Quality Dashboard</h1>
      
// // // //       <div className="mb-6">
// // // //         <div className="flex gap-2 mt-[6rem]">
// // // //           <input
// // // //             type="text"
// // // //             value={searchTerm}
// // // //             onChange={(e) => setSearchTerm(e.target.value)}
// // // //             placeholder="Search for a Utah county..."
// // // //             className="flex-grow p-2 border rounded"
// // // //             list="utahCounties"
// // // //           />
// // // //           <datalist id="utahCounties">
// // // //             {utahCounties.map(county => (
// // // //               <option key={county} value={county} />
// // // //             ))}
// // // //           </datalist>
// // // //           <button
// // // //             onClick={searchCounty}
// // // //             disabled={loading}
// // // //             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
// // // //           >
// // // //             {loading ? 'Searching...' : 'Search'}
// // // //           </button>
// // // //         </div>
        
// // // //         {error && <p className="text-red-500 mt-2">{error}</p>}
        
// // // //         {countyOptions.length > 0 && (
// // // //           <div className="mt-2 border rounded max-h-60 overflow-y-auto">
// // // //             {countyOptions.map((county, index) => (
// // // //               <div
// // // //                 key={index}
// // // //                 className="p-2 hover:bg-gray-100 cursor-pointer"
// // // //                 onClick={() => handleCountySelect(county)}
// // // //               >
// // // //                 {county.name}, {county.state}, {county.country}
// // // //               </div>
// // // //             ))}
// // // //           </div>
// // // //         )}
// // // //       </div>
      
// // // //       {loading && airQualityData.length === 0 && (
// // // //         <div className="text-center py-8">Loading air quality data...</div>
// // // //       )}
      
// // // //       {airQualityData.length > 0 && (
// // // //         <div className="bg-white p-4 rounded shadow">
// // // //           <div className="mb-4">
// // // //             <h2 className="text-xl font-semibold">
// // // //               {selectedCounty.name}, Utah Air Quality
// // // //             </h2>
// // // //             <p className="text-gray-600">
// // // //               Latitude: {selectedCounty.lat}, Longitude: {selectedCounty.lon}
// // // //             </p>
// // // //           </div>
          
// // // //           <div className="h-80 mb-8">
// // // //             <Line data={chartData} options={chartOptions} />
// // // //           </div>
          
// // // //           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// // // //             <div className="bg-gray-50 p-4 rounded">
// // // //               <h3 className="font-semibold mb-2">Latest Readings</h3>
// // // //               <ul className="space-y-1">
// // // //                 <li>AQI: {airQualityData[airQualityData.length - 1].aqi}</li>
// // // //                 <li>PM2.5: {airQualityData[airQualityData.length - 1].components.pm2_5.toFixed(2)} µg/m³</li>
// // // //                 <li>Ozone: {airQualityData[airQualityData.length - 1].components.o3.toFixed(2)} µg/m³</li>
// // // //                 <li>NO₂: {airQualityData[airQualityData.length - 1].components.no2.toFixed(2)} µg/m³</li>
// // // //               </ul>
// // // //             </div>
            
// // // //             <div className="bg-gray-50 p-4 rounded">
// // // //               <h3 className="font-semibold mb-2">AQI Scale</h3>
// // // //               <ul className="space-y-1">
// // // //                 <li>1 - Good</li>
// // // //                 <li>2 - Fair</li>
// // // //                 <li>3 - Moderate</li>
// // // //                 <li>4 - Poor</li>
// // // //                 <li>5 - Very Poor</li>
// // // //               </ul>
// // // //             </div>
// // // //           </div>
// // // //         </div>
// // // //       )}
// // // //     </div>
// // // //   );
// // // // };

// // // // export default AirQualityDashboard;

















// // // // // import React, { useState, useEffect } from 'react';
// // // // // import axios from 'axios';
// // // // // import { Line } from 'react-chartjs-2';
// // // // // import {
// // // // //   Chart as ChartJS,
// // // // //   CategoryScale,
// // // // //   LinearScale,
// // // // //   PointElement,
// // // // //   LineElement,
// // // // //   Title,
// // // // //   Tooltip,
// // // // //   Legend,
// // // // // } from 'chart.js';

// // // // // // Register ChartJS components
// // // // // ChartJS.register(
// // // // //   CategoryScale,
// // // // //   LinearScale,
// // // // //   PointElement,
// // // // //   LineElement,
// // // // //   Title,
// // // // //   Tooltip,
// // // // //   Legend
// // // // // );

// // // // // const AirQualityDashboard = () => {
// // // // //   const API_KEY = '83c8416a7968c9903e469321a38a08f5'; // Replace with your actual API key
// // // // //   const [searchTerm, setSearchTerm] = useState('');
// // // // //   const [countyOptions, setCountyOptions] = useState([]);
// // // // //   const [selectedCounty, setSelectedCounty] = useState(null);
// // // // //   const [airQualityData, setAirQualityData] = useState(null);
// // // // //   const [loading, setLoading] = useState(false);
// // // // //   const [error, setError] = useState(null);

// // // // //   // List of Utah counties for reference
// // // // //   const utahCounties = [
// // // // //     "Beaver", "Box Elder", "Cache", "Carbon", "Daggett", "Davis", 
// // // // //     "Duchesne", "Emery", "Garfield", "Grand", "Iron", "Juab", 
// // // // //     "Kane", "Millard", "Morgan", "Piute", "Rich", "Salt Lake", 
// // // // //     "San Juan", "Sanpete", "Sevier", "Summit", "Tooele", "Uintah", 
// // // // //     "Utah", "Wasatch", "Washington", "Wayne", "Weber"
// // // // //   ];

// // // // //   // Search for county coordinates
// // // // //   const searchCounty = async () => {
// // // // //     if (!searchTerm.trim()) return;
    
// // // // //     setLoading(true);
// // // // //     setError(null);
// // // // //     setCountyOptions([]);
// // // // //     setSelectedCounty(null);
// // // // //     setAirQualityData(null);
    
// // // // //     try {
// // // // //       const response = await axios.get(
// // // // //         `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm},Utah,US&limit=5&appid=${API_KEY}`
// // // // //       );
      
// // // // //       // Filter results to only include Utah, US locations
// // // // //       const utahResults = response.data.filter(
// // // // //         location => location.state === "Utah" && location.country === "US"
// // // // //       );
      
// // // // //       setCountyOptions(utahResults);
      
// // // // //       if (utahResults.length === 0) {
// // // // //         setError('No matching Utah county found');
// // // // //       }
// // // // //     } catch (err) {
// // // // //       setError('Failed to fetch county data');
// // // // //       console.error(err);
// // // // //     } finally {
// // // // //       setLoading(false);
// // // // //     }
// // // // //   };

// // // // //   // Fetch air quality data for selected county
// // // // //   const fetchAirQuality = async (lat, lon) => {
// // // // //     setLoading(true);
// // // // //     setError(null);
    
// // // // //     try {
// // // // //       const response = await axios.get(
// // // // //         `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// // // // //       );
      
// // // // //       setAirQualityData(response.data);
// // // // //     } catch (err) {
// // // // //       setError('Failed to fetch air quality data');
// // // // //       console.error(err);
// // // // //     } finally {
// // // // //       setLoading(false);
// // // // //     }
// // // // //   };

// // // // //   // Handle county selection
// // // // //   const handleCountySelect = (county) => {
// // // // //     setSelectedCounty(county);
// // // // //     setSearchTerm(county.name);
// // // // //     setCountyOptions([]);
// // // // //     fetchAirQuality(county.lat, county.lon);
// // // // //   };

// // // // //   // Prepare chart data (using current data + forecast if available)
// // // // //   const prepareChartData = () => {
// // // // //     if (!airQualityData) return null;
    
// // // // //     // Current data point
// // // // //     const current = airQualityData.list[0];
// // // // //     const currentDate = new Date(current.dt * 1000).toLocaleString();
    
// // // // //     return {
// // // // //       labels: [currentDate],
// // // // //       datasets: [
// // // // //         {
// // // // //           label: 'Air Quality Index (AQI)',
// // // // //           data: [current.main.aqi],
// // // // //           borderColor: 'rgb(75, 192, 192)',
// // // // //           backgroundColor: 'rgba(75, 192, 192, 0.5)',
// // // // //         },
// // // // //         {
// // // // //           label: 'PM2.5 (µg/m³)',
// // // // //           data: [current.components.pm2_5],
// // // // //           borderColor: 'rgb(255, 99, 132)',
// // // // //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
// // // // //         }
// // // // //       ],
// // // // //     };
// // // // //   };

// // // // //   const chartOptions = {
// // // // //     responsive: true,
// // // // //     plugins: {
// // // // //       legend: {
// // // // //         position: 'top',
// // // // //       },
// // // // //       title: {
// // // // //         display: true,
// // // // //         text: `Air Quality for ${selectedCounty?.name || 'Selected County'}`,
// // // // //       },
// // // // //     },
// // // // //     scales: {
// // // // //       y: {
// // // // //         beginAtZero: false,
// // // // //       },
// // // // //     },
// // // // //   };

// // // // //   return (
// // // // //     <div className="container mx-auto p-4 max-w-4xl">
// // // // //       <h1 className="text-2xl font-bold mb-6">Utah County Air Quality Dashboard</h1>
      
// // // // //       <div className="mb-6">
// // // // //         <div className="flex gap-2 mt-[8rem]">
// // // // //           <input
// // // // //             type="text"
// // // // //             value={searchTerm}
// // // // //             onChange={(e) => setSearchTerm(e.target.value)}
// // // // //             onKeyDown={(e) => e.key === 'Enter' && searchCounty()}
// // // // //             placeholder="Search for a Utah county..."
// // // // //             className="flex-grow p-2 border rounded"
// // // // //             list="utahCounties"
// // // // //           />
// // // // //           <datalist id="utahCounties">
// // // // //             {utahCounties.map(county => (
// // // // //               <option key={county} value={county} />
// // // // //             ))}
// // // // //           </datalist>
// // // // //           <button
// // // // //             onClick={searchCounty}
// // // // //             disabled={loading}
// // // // //             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
// // // // //           >
// // // // //             {loading ? 'Searching...' : 'Search'}
// // // // //           </button>
// // // // //         </div>
        
// // // // //         {error && <p className="text-red-500 mt-2">{error}</p>}
        
// // // // //         {countyOptions.length > 0 && (
// // // // //           <div className="mt-2 border rounded max-h-60 overflow-y-auto">
// // // // //             {countyOptions.map((county, index) => (
// // // // //               <div
// // // // //                 key={index}
// // // // //                 className="p-2 hover:bg-gray-100 cursor-pointer"
// // // // //                 onClick={() => handleCountySelect(county)}
// // // // //               >
// // // // //                 {county.name}, Utah
// // // // //               </div>
// // // // //             ))}
// // // // //           </div>
// // // // //         )}
// // // // //       </div>
      
// // // // //       {loading && !airQualityData && (
// // // // //         <div className="text-center py-8">Loading data...</div>
// // // // //       )}
      
// // // // //       {airQualityData && (
// // // // //         <div className="bg-white p-4 rounded shadow">
// // // // //           <div className="mb-4">
// // // // //             <h2 className="text-xl font-semibold">
// // // // //               {selectedCounty.name}, Utah Air Quality
// // // // //             </h2>
// // // // //             <p className="text-gray-600">
// // // // //               Latitude: {selectedCounty.lat.toFixed(4)}, Longitude: {selectedCounty.lon.toFixed(4)}
// // // // //             </p>
// // // // //           </div>
          
// // // // //           <div className="h-80 mb-8">
// // // // //             <Line data={prepareChartData()} options={chartOptions} />
// // // // //           </div>
          
// // // // //           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// // // // //             <div className="bg-gray-50 p-4 rounded">
// // // // //               <h3 className="font-semibold mb-2">Current Air Quality</h3>
// // // // //               <ul className="space-y-1">
// // // // //                 <li>AQI: {airQualityData.list[0].main.aqi}</li>
// // // // //                 <li>PM2.5: {airQualityData.list[0].components.pm2_5.toFixed(2)} µg/m³</li>
// // // // //                 <li>Ozone: {airQualityData.list[0].components.o3.toFixed(2)} µg/m³</li>
// // // // //                 <li>NO₂: {airQualityData.list[0].components.no2.toFixed(2)} µg/m³</li>
// // // // //               </ul>
// // // // //             </div>
            
// // // // //             <div className="bg-gray-50 p-4 rounded">
// // // // //               <h3 className="font-semibold mb-2">AQI Scale</h3>
// // // // //               <ul className="space-y-1">
// // // // //                 <li>1 - Good</li>
// // // // //                 <li>2 - Fair</li>
// // // // //                 <li>3 - Moderate</li>
// // // // //                 <li>4 - Poor</li>
// // // // //                 <li>5 - Very Poor</li>
// // // // //               </ul>
// // // // //             </div>
// // // // //           </div>
// // // // //         </div>
// // // // //       )}
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default AirQualityDashboard;










// // // // import React, { useState, useEffect } from 'react';
// // // // import axios from 'axios';
// // // // import { Line } from 'react-chartjs-2';
// // // // import {
// // // //   Chart as ChartJS,
// // // //   CategoryScale,
// // // //   LinearScale,
// // // //   PointElement,
// // // //   LineElement,
// // // //   Title,
// // // //   Tooltip,
// // // //   Legend,
// // // // } from 'chart.js';

// // // // // Register ChartJS components
// // // // ChartJS.register(
// // // //   CategoryScale,
// // // //   LinearScale,
// // // //   PointElement,
// // // //   LineElement,
// // // //   Title,
// // // //   Tooltip,
// // // //   Legend
// // // // );

// // // // const AirQualityDashboard = () => {
// // // //   const API_KEY = '83c8416a7968c9903e469321a38a08f5';
// // // //   const [searchTerm, setSearchTerm] = useState('');
// // // //   const [countyOptions, setCountyOptions] = useState([]);
// // // //   const [selectedCounty, setSelectedCounty] = useState(null);
// // // //   const [airQualityData, setAirQualityData] = useState(null);
// // // //   const [loading, setLoading] = useState(false);
// // // //   const [error, setError] = useState(null);
// // // //   const [historicalData, setHistoricalData] = useState([]);

// // // //   // Complete list of Utah counties with coordinates
// // // //   const utahCountiesWithCoords = [
// // // //     { name: "Beaver", lat: 38.2766, lon: -112.641 },
// // // //     { name: "Box Elder", lat: 41.5219, lon: -113.083 },
// // // //     { name: "Cache", lat: 41.7379, lon: -111.744 },
// // // //     { name: "Carbon", lat: 39.6489, lon: -110.589 },
// // // //     { name: "Daggett", lat: 40.8877, lon: -109.512 },
// // // //     { name: "Davis", lat: 40.9900, lon: -112.111 },
// // // //     { name: "Duchesne", lat: 40.297, lon: -110.426 },
// // // //     { name: "Emery", lat: 38.9966, lon: -110.701 },
// // // //     { name: "Garfield", lat: 37.8547, lon: -111.443 },
// // // //     { name: "Grand", lat: 38.9900, lon: -109.568 },
// // // //     { name: "Iron", lat: 37.8594, lon: -113.289 },
// // // //     { name: "Juab", lat: 39.7025, lon: -112.784 },
// // // //     { name: "Kane", lat: 37.2853, lon: -111.888 },
// // // //     { name: "Millard", lat: 39.0736, lon: -113.101 },
// // // //     { name: "Morgan", lat: 41.0899, lon: -111.573 },
// // // //     { name: "Piute", lat: 38.3366, lon: -112.127 },
// // // //     { name: "Rich", lat: 41.6324, lon: -111.244 },
// // // //     { name: "Salt Lake", lat: 40.6676, lon: -111.924 },
// // // //     { name: "San Juan", lat: 37.6266, lon: -109.805 },
// // // //     { name: "Sanpete", lat: 39.3739, lon: -111.576 },
// // // //     { name: "Sevier", lat: 38.7478, lon: -111.804 },
// // // //     { name: "Summit", lat: 40.8686, lon: -110.955 },
// // // //     { name: "Tooele", lat: 40.4488, lon: -113.131 },
// // // //     { name: "Uintah", lat: 40.1256, lon: -109.518 },
// // // //     { name: "Utah", lat: 40.1197, lon: -111.670 },
// // // //     { name: "Wasatch", lat: 40.3306, lon: -111.169 },
// // // //     { name: "Washington", lat: 37.2804, lon: -113.505 },
// // // //     { name: "Wayne", lat: 38.3247, lon: -110.903 },
// // // //     { name: "Weber", lat: 41.2697, lon: -111.914 }
// // // //   ];

// // // //   // Search for county - first checks local data, then API
// // // //   const searchCounty = async () => {
// // // //     if (!searchTerm.trim()) return;
    
// // // //     setLoading(true);
// // // //     setError(null);
// // // //     setCountyOptions([]);
// // // //     setSelectedCounty(null);
// // // //     setAirQualityData(null);
    
// // // //     try {
// // // //       // First try local matches
// // // //       const localMatches = utahCountiesWithCoords.filter(county =>
// // // //         county.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
// // // //         searchTerm.toLowerCase().includes(county.name.toLowerCase())
// // // //       );
      
// // // //       if (localMatches.length > 0) {
// // // //         setCountyOptions(localMatches);
// // // //         return;
// // // //       }
      
// // // //       // Fallback to API if no local matches
// // // //       const response = await axios.get(
// // // //         `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm},Utah,US&limit=5&appid=${API_KEY}`
// // // //       );
      
// // // //       const utahResults = response.data.filter(
// // // //         location => location.state === "Utah" && location.country === "US"
// // // //       );
      
// // // //       if (utahResults.length === 0) {
// // // //         setError('No matching Utah county found');
// // // //       } else {
// // // //         setCountyOptions(utahResults);
// // // //       }
// // // //     } catch (err) {
// // // //       setError('Failed to fetch county data');
// // // //       console.error(err);
// // // //     } finally {
// // // //       setLoading(false);
// // // //     }
// // // //   };

// // // //   // Fetch air quality data
// // // //   const fetchAirQuality = async (lat, lon) => {
// // // //     setLoading(true);
// // // //     setError(null);
    
// // // //     try {
// // // //       // Fetch current air quality
// // // //       const currentResponse = await axios.get(
// // // //         `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// // // //       );
      
// // // //       // Fetch forecast (if available)
// // // //       const forecastResponse = await axios.get(
// // // //         `https://api.openweathermap.org/data/2.5/air_pollution/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// // // //       );
      
// // // //       // Combine current and forecast data
// // // //       const combinedData = {
// // // //         current: currentResponse.data.list[0],
// // // //         forecast: forecastResponse.data.list.slice(0, 5) // Get next 5 periods
// // // //       };
      
// // // //       setAirQualityData(combinedData);
      
// // // //       // Generate historical data (simulated)
// // // //       generateHistoricalData(combinedData.current);
// // // //     } catch (err) {
// // // //       setError('Failed to fetch air quality data');
// // // //       console.error(err);
// // // //     } finally {
// // // //       setLoading(false);
// // // //     }
// // // //   };

// // // //   // Simulate historical data (since API only provides current/forecast)
// // // //   const generateHistoricalData = (currentData) => {
// // // //     const days = 7;
// // // //     const data = [];
    
// // // //     for (let i = days; i >= 0; i--) {
// // // //       const date = new Date();
// // // //       date.setDate(date.getDate() - i);
      
// // // //       // Add variation to simulate historical data
// // // //       const variation = 0.8 + Math.random() * 0.4; // Between 0.8 and 1.2
      
// // // //       data.push({
// // // //         date: date.toLocaleDateString(),
// // // //         aqi: Math.max(1, Math.min(5, Math.round(currentData.main.aqi * variation))),
// // // //         components: {
// // // //           co: currentData.components.co * variation,
// // // //           no2: currentData.components.no2 * variation,
// // // //           o3: currentData.components.o3 * variation,
// // // //           pm2_5: currentData.components.pm2_5 * variation,
// // // //         }
// // // //       });
// // // //     }
    
// // // //     setHistoricalData(data);
// // // //   };

// // // //   // Handle county selection
// // // //   const handleCountySelect = (county) => {
// // // //     setSelectedCounty(county);
// // // //     setSearchTerm(county.name);
// // // //     setCountyOptions([]);
// // // //     fetchAirQuality(county.lat, county.lon);
// // // //   };

// // // //   // Prepare chart data
// // // //   const prepareChartData = () => {
// // // //     const labels = [
// // // //       ...historicalData.map(item => item.date),
// // // //       ...(airQualityData?.forecast?.map((_, i) => `Day +${i + 1}`) || [])
// // // //     ];
    
// // // //     const aqiData = [
// // // //       ...historicalData.map(item => item.aqi),
// // // //       ...(airQualityData?.forecast?.map(item => item.main.aqi) || [])
// // // //     ];
    
// // // //     const pm25Data = [
// // // //       ...historicalData.map(item => item.components.pm2_5),
// // // //       ...(airQualityData?.forecast?.map(item => item.components.pm2_5) || [])
// // // //     ];
    
// // // //     return {
// // // //       labels,
// // // //       datasets: [
// // // //         {
// // // //           label: 'Air Quality Index (AQI)',
// // // //           data: aqiData,
// // // //           borderColor: 'rgb(75, 192, 192)',
// // // //           backgroundColor: 'rgba(75, 192, 192, 0.5)',
// // // //           tension: 0.1,
// // // //         },
// // // //         {
// // // //           label: 'PM2.5 (µg/m³)',
// // // //           data: pm25Data,
// // // //           borderColor: 'rgb(255, 99, 132)',
// // // //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
// // // //           tension: 0.1,
// // // //         }
// // // //       ],
// // // //     };
// // // //   };

// // // //   const chartOptions = {
// // // //     responsive: true,
// // // //     plugins: {
// // // //       legend: {
// // // //         position: 'top',
// // // //       },
// // // //       title: {
// // // //         display: true,
// // // //         text: `Air Quality for ${selectedCounty?.name || 'Selected County'}`,
// // // //       },
// // // //     },
// // // //     scales: {
// // // //       y: {
// // // //         beginAtZero: false,
// // // //         min: 0,
// // // //         max: 5,
// // // //         ticks: {
// // // //           stepSize: 1
// // // //         }
// // // //       },
// // // //     },
// // // //   };

// // // //   // Get AQI description
// // // //   const getAqiDescription = (aqi) => {
// // // //     switch(aqi) {
// // // //       case 1: return 'Good';
// // // //       case 2: return 'Fair';
// // // //       case 3: return 'Moderate';
// // // //       case 4: return 'Poor';
// // // //       case 5: return 'Very Poor';
// // // //       default: return 'Unknown';
// // // //     }
// // // //   };

// // // //   return (
// // // //     <div className="container mx-auto p-4 max-w-4xl">
// // // //       <h1 className="text-2xl font-bold mb-6">Utah County Air Quality Dashboard</h1>
      
// // // //       <div className="mb-6">
// // // //         <div className="flex gap-2 mt-[8rem]">
// // // //           <input
// // // //             type="text"
// // // //             value={searchTerm}
// // // //             onChange={(e) => setSearchTerm(e.target.value)}
// // // //             onKeyDown={(e) => e.key === 'Enter' && searchCounty()}
// // // //             placeholder="Search for a Utah county..."
// // // //             className="flex-grow p-2 border rounded"
// // // //             list="utahCounties"
// // // //           />
// // // //           <datalist id="utahCounties">
// // // //             {utahCountiesWithCoords.map(county => (
// // // //               <option key={county.name} value={county.name} />
// // // //             ))}
// // // //           </datalist>
// // // //           <button
// // // //             onClick={searchCounty}
// // // //             disabled={loading}
// // // //             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
// // // //           >
// // // //             {loading ? 'Searching...' : 'Search'}
// // // //           </button>
// // // //         </div>
        
// // // //         {error && <p className="text-red-500 mt-2">{error}</p>}
        
// // // //         {countyOptions.length > 0 && (
// // // //           <div className="mt-2 border rounded max-h-60 overflow-y-auto">
// // // //             {countyOptions.map((county, index) => (
// // // //               <div
// // // //                 key={index}
// // // //                 className="p-2 hover:bg-gray-100 cursor-pointer"
// // // //                 onClick={() => handleCountySelect(county)}
// // // //               >
// // // //                 {county.name}, Utah (Lat: {county.lat.toFixed(4)}, Lon: {county.lon.toFixed(4)})
// // // //               </div>
// // // //             ))}
// // // //           </div>
// // // //         )}
// // // //       </div>
      
// // // //       {loading && !airQualityData && (
// // // //         <div className="text-center py-8">Loading data...</div>
// // // //       )}
      
// // // //       {airQualityData && (
// // // //         <div className="bg-white p-4 rounded shadow">
// // // //           <div className="mb-4">
// // // //             <h2 className="text-xl font-semibold">
// // // //               {selectedCounty.name} County Air Quality
// // // //             </h2>
// // // //             <p className="text-gray-600">
// // // //               Coordinates: {selectedCounty.lat.toFixed(4)}, {selectedCounty.lon.toFixed(4)}
// // // //             </p>
// // // //           </div>
          
// // // //           <div className="h-80 mb-8">
// // // //             <Line data={prepareChartData()} options={chartOptions} />
// // // //           </div>
          
// // // //           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// // // //             <div className="bg-gray-50 p-4 rounded">
// // // //               <h3 className="font-semibold mb-2">Current Air Quality</h3>
// // // //               <div className="mb-2">
// // // //                 <span className="font-medium">AQI: </span>
// // // //                 <span className={`font-bold ${
// // // //                   airQualityData.current.main.aqi === 1 ? 'text-green-600' :
// // // //                   airQualityData.current.main.aqi === 2 ? 'text-yellow-600' :
// // // //                   airQualityData.current.main.aqi === 3 ? 'text-orange-600' :
// // // //                   'text-red-600'
// // // //                 }`}>
// // // //                   {airQualityData.current.main.aqi} ({getAqiDescription(airQualityData.current.main.aqi)})
// // // //                 </span>
// // // //               </div>
// // // //               <ul className="space-y-1">
// // // //                 <li>PM2.5: {airQualityData.current.components.pm2_5.toFixed(2)} µg/m³</li>
// // // //                 <li>Ozone: {airQualityData.current.components.o3.toFixed(2)} µg/m³</li>
// // // //                 <li>NO₂: {airQualityData.current.components.no2.toFixed(2)} µg/m³</li>
// // // //                 <li>CO: {airQualityData.current.components.co.toFixed(2)} µg/m³</li>
// // // //               </ul>
// // // //             </div>
            
// // // //             <div className="bg-gray-50 p-4 rounded">
// // // //               <h3 className="font-semibold mb-2">Forecast</h3>
// // // //               {airQualityData.forecast.length > 0 ? (
// // // //                 <ul className="space-y-2">
// // // //                   {airQualityData.forecast.slice(0, 3).map((item, index) => (
// // // //                     <li key={index}>
// // // //                       <span className="font-medium">Day +{index + 1}: </span>
// // // //                       <span className={`${
// // // //                         item.main.aqi === 1 ? 'text-green-600' :
// // // //                         item.main.aqi === 2 ? 'text-yellow-600' :
// // // //                         item.main.aqi === 3 ? 'text-orange-600' :
// // // //                         'text-red-600'
// // // //                       }`}>
// // // //                         {getAqiDescription(item.main.aqi)}
// // // //                       </span>
// // // //                       <span className="text-sm text-gray-600 ml-2">
// // // //                         (PM2.5: {item.components.pm2_5.toFixed(2)} µg/m³)
// // // //                       </span>
// // // //                     </li>
// // // //                   ))}
// // // //                 </ul>
// // // //               ) : (
// // // //                 <p>No forecast data available</p>
// // // //               )}
              
// // // //               <div className="mt-4">
// // // //                 <h4 className="font-semibold mb-1">AQI Scale</h4>
// // // //                 <ul className="text-sm space-y-1">
// // // //                   <li className="text-green-600">1 - Good</li>
// // // //                   <li className="text-yellow-600">2 - Fair</li>
// // // //                   <li className="text-orange-600">3 - Moderate</li>
// // // //                   <li className="text-red-600">4 - Poor</li>
// // // //                   <li className="text-red-800">5 - Very Poor</li>
// // // //                 </ul>
// // // //               </div>
// // // //             </div>
// // // //           </div>
// // // //         </div>
// // // //       )}
// // // //     </div>
// // // //   );
// // // // };

// // // // export default AirQualityDashboard;










// // // import React, { useState, useEffect } from 'react';
// // // import axios from 'axios';
// // // import { Line } from 'react-chartjs-2';
// // // import {
// // //   Chart as ChartJS,
// // //   CategoryScale,
// // //   LinearScale,
// // //   PointElement,
// // //   LineElement,
// // //   Title,
// // //   Tooltip,
// // //   Legend,
// // // } from 'chart.js';

// // // // Register ChartJS components
// // // ChartJS.register(
// // //   CategoryScale,
// // //   LinearScale,
// // //   PointElement,
// // //   LineElement,
// // //   Title,
// // //   Tooltip,
// // //   Legend
// // // );

// // // const AirQualityDashboard = () => {
// // //   const API_KEY = '83c8416a7968c9903e469321a38a08f5';
// // //   const [searchTerm, setSearchTerm] = useState('');
// // //   const [countyOptions, setCountyOptions] = useState([]);
// // //   const [selectedCounty, setSelectedCounty] = useState(null);
// // //   const [airQualityData, setAirQualityData] = useState(null);
// // //   const [loading, setLoading] = useState(false);
// // //   const [error, setError] = useState(null);
// // //   const [historicalData, setHistoricalData] = useState([]);

// // //   // Complete list of Utah counties with coordinates
// // //   const utahCountiesWithCoords = [
// // //     { name: "Beaver", lat: 38.2766, lon: -112.641 },
// // //     { name: "Box Elder", lat: 41.5219, lon: -113.083 },
// // //     { name: "Cache", lat: 41.7379, lon: -111.744 },
// // //     { name: "Carbon", lat: 39.6489, lon: -110.589 },
// // //     { name: "Daggett", lat: 40.8877, lon: -109.512 },
// // //     { name: "Davis", lat: 40.9900, lon: -112.111 },
// // //     { name: "Duchesne", lat: 40.297, lon: -110.426 },
// // //     { name: "Emery", lat: 38.9966, lon: -110.701 },
// // //     { name: "Garfield", lat: 37.8547, lon: -111.443 },
// // //     { name: "Grand", lat: 38.9900, lon: -109.568 },
// // //     { name: "Iron", lat: 37.8594, lon: -113.289 },
// // //     { name: "Juab", lat: 39.7025, lon: -112.784 },
// // //     { name: "Kane", lat: 37.2853, lon: -111.888 },
// // //     { name: "Millard", lat: 39.0736, lon: -113.101 },
// // //     { name: "Morgan", lat: 41.0899, lon: -111.573 },
// // //     { name: "Piute", lat: 38.3366, lon: -112.127 },
// // //     { name: "Rich", lat: 41.6324, lon: -111.244 },
// // //     { name: "Salt Lake", lat: 40.6676, lon: -111.924 },
// // //     { name: "San Juan", lat: 37.6266, lon: -109.805 },
// // //     { name: "Sanpete", lat: 39.3739, lon: -111.576 },
// // //     { name: "Sevier", lat: 38.7478, lon: -111.804 },
// // //     { name: "Summit", lat: 40.8686, lon: -110.955 },
// // //     { name: "Tooele", lat: 40.4488, lon: -113.131 },
// // //     { name: "Uintah", lat: 40.1256, lon: -109.518 },
// // //     { name: "Utah", lat: 40.1197, lon: -111.670 },
// // //     { name: "Wasatch", lat: 40.3306, lon: -111.169 },
// // //     { name: "Washington", lat: 37.2804, lon: -113.505 },
// // //     { name: "Wayne", lat: 38.3247, lon: -110.903 },
// // //     { name: "Weber", lat: 41.2697, lon: -111.914 }
// // //   ];

// // //   // Load Salt Lake County data by default
// // //   useEffect(() => {
// // //     const saltLakeCounty = utahCountiesWithCoords.find(c => c.name === "Salt Lake");
// // //     if (saltLakeCounty) {
// // //       setSelectedCounty(saltLakeCounty);
// // //       fetchAirQuality(saltLakeCounty.lat, saltLakeCounty.lon);
// // //     }
// // //   }, []);

// // //   // Search for county - first checks local data, then API
// // //   const searchCounty = async () => {
// // //     if (!searchTerm.trim()) return;
    
// // //     setLoading(true);
// // //     setError(null);
// // //     setCountyOptions([]);
    
// // //     try {
// // //       // First try local matches
// // //       const localMatches = utahCountiesWithCoords.filter(county =>
// // //         county.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
// // //         searchTerm.toLowerCase().includes(county.name.toLowerCase())
// // //       );
      
// // //       if (localMatches.length > 0) {
// // //         setCountyOptions(localMatches);
// // //         return;
// // //       }
      
// // //       // Fallback to API if no local matches
// // //       const response = await axios.get(
// // //         `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm},Utah,US&limit=5&appid=${API_KEY}`
// // //       );
      
// // //       const utahResults = response.data.filter(
// // //         location => location.state === "Utah" && location.country === "US"
// // //       );
      
// // //       if (utahResults.length === 0) {
// // //         setError('No matching Utah county found');
// // //       } else {
// // //         setCountyOptions(utahResults);
// // //       }
// // //     } catch (err) {
// // //       setError('Failed to fetch county data');
// // //       console.error(err);
// // //     } finally {
// // //       setLoading(false);
// // //     }
// // //   };

// // //   // Fetch air quality data
// // //   const fetchAirQuality = async (lat, lon) => {
// // //     setLoading(true);
// // //     setError(null);
    
// // //     try {
// // //       // Fetch current air quality
// // //       const currentResponse = await axios.get(
// // //         `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// // //       );
      
// // //       // Fetch forecast (if available)
// // //       const forecastResponse = await axios.get(
// // //         `https://api.openweathermap.org/data/2.5/air_pollution/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// // //       );
      
// // //       // Combine current and forecast data
// // //       const combinedData = {
// // //         current: currentResponse.data.list[0],
// // //         forecast: forecastResponse.data.list.slice(0, 5) // Get next 5 periods
// // //       };
      
// // //       setAirQualityData(combinedData);
      
// // //       // Generate historical data (simulated)
// // //       generateHistoricalData(combinedData.current);
// // //     } catch (err) {
// // //       setError('Failed to fetch air quality data');
// // //       console.error(err);
// // //     } finally {
// // //       setLoading(false);
// // //     }
// // //   };

// // //   // Simulate historical data (since API only provides current/forecast)
// // //   const generateHistoricalData = (currentData) => {
// // //     const days = 7;
// // //     const data = [];
    
// // //     for (let i = days; i >= 0; i--) {
// // //       const date = new Date();
// // //       date.setDate(date.getDate() - i);
      
// // //       // Add variation to simulate historical data
// // //       const variation = 0.8 + Math.random() * 0.4; // Between 0.8 and 1.2
      
// // //       data.push({
// // //         date: date.toLocaleDateString(),
// // //         aqi: Math.max(1, Math.min(5, Math.round(currentData.main.aqi * variation))),
// // //         components: {
// // //           co: currentData.components.co * variation,
// // //           no2: currentData.components.no2 * variation,
// // //           o3: currentData.components.o3 * variation,
// // //           pm2_5: currentData.components.pm2_5 * variation,
// // //         }
// // //       });
// // //     }
    
// // //     setHistoricalData(data);
// // //   };

// // //   // Handle county selection
// // //   const handleCountySelect = (county) => {
// // //     setSelectedCounty(county);
// // //     setSearchTerm(county.name);
// // //     setCountyOptions([]);
// // //     fetchAirQuality(county.lat, county.lon);
// // //   };

// // //   // Prepare chart data
// // //   const prepareChartData = () => {
// // //     const labels = [
// // //       ...historicalData.map(item => item.date),
// // //       ...(airQualityData?.forecast?.map((_, i) => `Day +${i + 1}`) || [])
// // //     ];
    
// // //     const aqiData = [
// // //       ...historicalData.map(item => item.aqi),
// // //       ...(airQualityData?.forecast?.map(item => item.main.aqi) || [])
// // //     ];
    
// // //     const pm25Data = [
// // //       ...historicalData.map(item => item.components.pm2_5),
// // //       ...(airQualityData?.forecast?.map(item => item.components.pm2_5) || [])
// // //     ];
    
// // //     return {
// // //       labels,
// // //       datasets: [
// // //         {
// // //           label: 'Air Quality Index (AQI)',
// // //           data: aqiData,
// // //           borderColor: 'rgb(75, 192, 192)',
// // //           backgroundColor: 'rgba(75, 192, 192, 0.5)',
// // //           tension: 0.1,
// // //         },
// // //         {
// // //           label: 'PM2.5 (µg/m³)',
// // //           data: pm25Data,
// // //           borderColor: 'rgb(255, 99, 132)',
// // //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
// // //           tension: 0.1,
// // //         }
// // //       ],
// // //     };
// // //   };

// // //   const chartOptions = {
// // //     responsive: true,
// // //     maintainAspectRatio: false,
// // //     plugins: {
// // //       legend: {
// // //         position: 'top',
// // //       },
// // //       title: {
// // //         display: true,
// // //         text: `Air Quality for ${selectedCounty?.name || 'Selected County'}`,
// // //       },
// // //     },
// // //     scales: {
// // //       y: {
// // //         beginAtZero: false,
// // //         min: 0,
// // //         max: 5,
// // //         ticks: {
// // //           stepSize: 1
// // //         }
// // //       },
// // //     },
// // //   };

// // //   // Get AQI description
// // //   const getAqiDescription = (aqi) => {
// // //     switch(aqi) {
// // //       case 1: return 'Good';
// // //       case 2: return 'Fair';
// // //       case 3: return 'Moderate';
// // //       case 4: return 'Poor';
// // //       case 5: return 'Very Poor';
// // //       default: return 'Unknown';
// // //     }
// // //   };

// // //   return (
// // //     <div className="container mx-auto p-4 max-w-4xl">
// // //       <h1 className="text-2xl font-bold mb-6 mt-[7rem]">Utah County Air Quality Dashboard</h1>
      
// // //       {/* Search Section */}
// // //       <div className="mb-6 bg-white p-4 rounded shadow">
// // //         <h2 className="text-lg font-semibold mb-3">Search for Another County</h2>
// // //         <div className="flex gap-2">
// // //           <input
// // //             type="text"
// // //             value={searchTerm}
// // //             onChange={(e) => setSearchTerm(e.target.value)}
// // //             onKeyDown={(e) => e.key === 'Enter' && searchCounty()}
// // //             placeholder="Enter a Utah county name..."
// // //             className="flex-grow p-2 border rounded"
// // //             list="utahCounties"
// // //           />
// // //           <datalist id="utahCounties">
// // //             {utahCountiesWithCoords.map(county => (
// // //               <option key={county.name} value={county.name} />
// // //             ))}
// // //           </datalist>
// // //           <button
// // //             onClick={searchCounty}
// // //             disabled={loading}
// // //             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
// // //           >
// // //             {loading ? 'Searching...' : 'Search'}
// // //           </button>
// // //         </div>
        
// // //         {error && <p className="text-red-500 mt-2">{error}</p>}
        
// // //         {countyOptions.length > 0 && (
// // //           <div className="mt-2 border rounded max-h-60 overflow-y-auto">
// // //             {countyOptions.map((county, index) => (
// // //               <div
// // //                 key={index}
// // //                 className="p-2 hover:bg-gray-100 cursor-pointer"
// // //                 onClick={() => handleCountySelect(county)}
// // //               >
// // //                 {county.name}, Utah
// // //               </div>
// // //             ))}
// // //           </div>
// // //         )}
// // //       </div>
      
// // //       {/* Main Air Quality Display */}
// // //       {loading && !airQualityData ? (
// // //         <div className="text-center py-8">Loading data...</div>
// // //       ) : (
// // //         <div className="bg-white p-4 rounded shadow">
// // //           <div className="mb-4">
// // //             <h2 className="text-xl font-semibold">
// // //               {selectedCounty?.name || 'Selected County'} Air Quality
// // //             </h2>
// // //             {selectedCounty && (
// // //               <p className="text-gray-600">
// // //                 Coordinates: {selectedCounty.lat.toFixed(4)}, {selectedCounty.lon.toFixed(4)}
// // //               </p>
// // //             )}
// // //           </div>
          
// // //           {airQualityData ? (
// // //             <>
// // //               <div className="h-[500px] mb-8">
// // //                 <Line data={prepareChartData()} options={chartOptions} />
// // //               </div>
              
// // //               <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// // //                 <div className="bg-gray-50 p-4 rounded">
// // //                   <h3 className="font-semibold mb-2">Current Air Quality</h3>
// // //                   <div className="mb-2">
// // //                     <span className="font-medium">AQI: </span>
// // //                     <span className={`font-bold ${
// // //                       airQualityData.current.main.aqi === 1 ? 'text-green-600' :
// // //                       airQualityData.current.main.aqi === 2 ? 'text-yellow-600' :
// // //                       airQualityData.current.main.aqi === 3 ? 'text-orange-600' :
// // //                       'text-red-600'
// // //                     }`}>
// // //                       {airQualityData.current.main.aqi} ({getAqiDescription(airQualityData.current.main.aqi)})
// // //                     </span>
// // //                   </div>
// // //                   <ul className="space-y-1">
// // //                     <li>PM2.5: {airQualityData.current.components.pm2_5.toFixed(2)} µg/m³</li>
// // //                     <li>Ozone: {airQualityData.current.components.o3.toFixed(2)} µg/m³</li>
// // //                     <li>NO₂: {airQualityData.current.components.no2.toFixed(2)} µg/m³</li>
// // //                     <li>CO: {airQualityData.current.components.co.toFixed(2)} µg/m³</li>
// // //                   </ul>
// // //                 </div>
                
// // //                 <div className="bg-gray-50 p-4 rounded">
// // //                   <h3 className="font-semibold mb-2">Forecast</h3>
// // //                   {airQualityData.forecast.length > 0 ? (
// // //                     <ul className="space-y-2">
// // //                       {airQualityData.forecast.slice(0, 3).map((item, index) => (
// // //                         <li key={index}>
// // //                           <span className="font-medium">Day +{index + 1}: </span>
// // //                           <span className={`${
// // //                             item.main.aqi === 1 ? 'text-green-600' :
// // //                             item.main.aqi === 2 ? 'text-yellow-600' :
// // //                             item.main.aqi === 3 ? 'text-orange-600' :
// // //                             'text-red-600'
// // //                           }`}>
// // //                             {getAqiDescription(item.main.aqi)}
// // //                           </span>
// // //                           <span className="text-sm text-gray-600 ml-2">
// // //                             (PM2.5: {item.components.pm2_5.toFixed(2)} µg/m³)
// // //                           </span>
// // //                         </li>
// // //                       ))}
// // //                     </ul>
// // //                   ) : (
// // //                     <p>No forecast data available</p>
// // //                   )}
                  
// // //                   <div className="mt-4">
// // //                     <h4 className="font-semibold mb-1">AQI Scale</h4>
// // //                     <ul className="text-sm space-y-1">
// // //                       <li className="text-green-600">1 - Good</li>
// // //                       <li className="text-yellow-600">2 - Fair</li>
// // //                       <li className="text-orange-600">3 - Moderate</li>
// // //                       <li className="text-red-600">4 - Poor</li>
// // //                       <li className="text-red-800">5 - Very Poor</li>
// // //                     </ul>
// // //                   </div>
// // //                 </div>
// // //               </div>
// // //             </>
// // //           ) : (
// // //             <div className="text-center py-8">Loading air quality data...</div>
// // //           )}
// // //         </div>
// // //       )}
// // //     </div>
// // //   );
// // // };

// // // export default AirQualityDashboard;











// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import { Line } from 'react-chartjs-2';
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   PointElement,
// //   LineElement,
// //   Title,
// //   Tooltip,
// //   Legend,
// // } from 'chart.js';

// // // Register ChartJS components
// // ChartJS.register(
// //   CategoryScale,
// //   LinearScale,
// //   PointElement,
// //   LineElement,
// //   Title,
// //   Tooltip,
// //   Legend
// // );

// // const AirQualityDashboard = () => {
// //   const API_KEY = '83c8416a7968c9903e469321a38a08f5';
// //   const [searchTerm, setSearchTerm] = useState('');
// //   const [countyOptions, setCountyOptions] = useState([]);
// //   const [selectedCounty, setSelectedCounty] = useState(null);
// //   const [airQualityData, setAirQualityData] = useState(null);
// //   const [loading, setLoading] = useState(false);
// //   const [error, setError] = useState(null);
// //   const [historicalData, setHistoricalData] = useState([]);

// //   // Complete list of Utah counties with coordinates
// //   const utahCountiesWithCoords = [
// //     { name: "Beaver", lat: 38.2766, lon: -112.641 },
// //     { name: "Box Elder", lat: 41.5219, lon: -113.083 },
// //     { name: "Cache", lat: 41.7379, lon: -111.744 },
// //     { name: "Carbon", lat: 39.6489, lon: -110.589 },
// //     { name: "Daggett", lat: 40.8877, lon: -109.512 },
// //     { name: "Davis", lat: 40.9900, lon: -112.111 },
// //     { name: "Duchesne", lat: 40.297, lon: -110.426 },
// //     { name: "Emery", lat: 38.9966, lon: -110.701 },
// //     { name: "Garfield", lat: 37.8547, lon: -111.443 },
// //     { name: "Grand", lat: 38.9900, lon: -109.568 },
// //     { name: "Iron", lat: 37.8594, lon: -113.289 },
// //     { name: "Juab", lat: 39.7025, lon: -112.784 },
// //     { name: "Kane", lat: 37.2853, lon: -111.888 },
// //     { name: "Millard", lat: 39.0736, lon: -113.101 },
// //     { name: "Morgan", lat: 41.0899, lon: -111.573 },
// //     { name: "Piute", lat: 38.3366, lon: -112.127 },
// //     { name: "Rich", lat: 41.6324, lon: -111.244 },
// //     { name: "Salt Lake", lat: 40.6676, lon: -111.924 },
// //     { name: "San Juan", lat: 37.6266, lon: -109.805 },
// //     { name: "Sanpete", lat: 39.3739, lon: -111.576 },
// //     { name: "Sevier", lat: 38.7478, lon: -111.804 },
// //     { name: "Summit", lat: 40.8686, lon: -110.955 },
// //     { name: "Tooele", lat: 40.4488, lon: -113.131 },
// //     { name: "Uintah", lat: 40.1256, lon: -109.518 },
// //     { name: "Utah", lat: 40.1197, lon: -111.670 },
// //     { name: "Wasatch", lat: 40.3306, lon: -111.169 },
// //     { name: "Washington", lat: 37.2804, lon: -113.505 },
// //     { name: "Wayne", lat: 38.3247, lon: -110.903 },
// //     { name: "Weber", lat: 41.2697, lon: -111.914 }
// //   ];

// //   // Load Salt Lake County data by default
// //   useEffect(() => {
// //     const saltLakeCounty = utahCountiesWithCoords.find(c => c.name === "Salt Lake");
// //     if (saltLakeCounty) {
// //       setSelectedCounty(saltLakeCounty);
// //       fetchAirQuality(saltLakeCounty.lat, saltLakeCounty.lon);
// //     }
// //   }, []);

// //   // Search for county - first checks local data, then API
// //   const searchCounty = async () => {
// //     if (!searchTerm.trim()) return;
    
// //     setLoading(true);
// //     setError(null);
// //     setCountyOptions([]);
    
// //     try {
// //       // First try local matches
// //       const localMatches = utahCountiesWithCoords.filter(county =>
// //         county.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
// //         searchTerm.toLowerCase().includes(county.name.toLowerCase())
// //       );
      
// //       if (localMatches.length > 0) {
// //         setCountyOptions(localMatches);
// //         return;
// //       }
      
// //       // Fallback to API if no local matches
// //       const response = await axios.get(
// //         `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm},Utah,US&limit=5&appid=${API_KEY}`
// //       );
      
// //       const utahResults = response.data.filter(
// //         location => location.state === "Utah" && location.country === "US"
// //       );
      
// //       if (utahResults.length === 0) {
// //         setError('No matching Utah county found');
// //       } else {
// //         setCountyOptions(utahResults);
// //       }
// //     } catch (err) {
// //       setError('Failed to fetch county data');
// //       console.error(err);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   // Fetch air quality data
// //   const fetchAirQuality = async (lat, lon) => {
// //     setLoading(true);
// //     setError(null);
    
// //     try {
// //       // Fetch current air quality
// //       const currentResponse = await axios.get(
// //         `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// //       );
      
// //       // Fetch forecast (if available)
// //       const forecastResponse = await axios.get(
// //         `https://api.openweathermap.org/data/2.5/air_pollution/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}`
// //       );
      
// //       // Combine current and forecast data
// //       const combinedData = {
// //         current: currentResponse.data.list[0],
// //         forecast: forecastResponse.data.list.slice(0, 5) // Get next 5 periods
// //       };
      
// //       setAirQualityData(combinedData);
      
// //       // Generate historical data (simulated)
// //       generateHistoricalData(combinedData.current);
// //     } catch (err) {
// //       setError('Failed to fetch air quality data');
// //       console.error(err);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   // Simulate historical data (since API only provides current/forecast)
// //   const generateHistoricalData = (currentData) => {
// //     const days = 7;
// //     const data = [];
    
// //     for (let i = days; i >= 0; i--) {
// //       const date = new Date();
// //       date.setDate(date.getDate() - i);
      
// //       // Add variation to simulate historical data
// //       const variation = 0.8 + Math.random() * 0.4; // Between 0.8 and 1.2
      
// //       data.push({
// //         date: date.toLocaleDateString(),
// //         aqi: Math.max(1, Math.min(5, Math.round(currentData.main.aqi * variation))),
// //         components: {
// //           co: currentData.components.co * variation,
// //           no2: currentData.components.no2 * variation,
// //           o3: currentData.components.o3 * variation,
// //           pm2_5: currentData.components.pm2_5 * variation,
// //         }
// //       });
// //     }
    
// //     setHistoricalData(data);
// //   };

// //   // Handle county selection
// //   const handleCountySelect = (county) => {
// //     setSelectedCounty(county);
// //     setSearchTerm(county.name);
// //     setCountyOptions([]);
// //     fetchAirQuality(county.lat, county.lon);
// //   };

// //   // Prepare chart data
// //   const prepareChartData = () => {
// //     const labels = [
// //       ...historicalData.map(item => item.date),
// //       ...(airQualityData?.forecast?.map((_, i) => `Day +${i + 1}`) || [])
// //     ];
    
// //     const aqiData = [
// //       ...historicalData.map(item => item.aqi),
// //       ...(airQualityData?.forecast?.map(item => item.main.aqi) || [])
// //     ];
    
// //     const pm25Data = [
// //       ...historicalData.map(item => item.components.pm2_5),
// //       ...(airQualityData?.forecast?.map(item => item.components.pm2_5) || [])
// //     ];
    
// //     return {
// //       labels,
// //       datasets: [
// //         {
// //           label: 'Air Quality Index (AQI)',
// //           data: aqiData,
// //           borderColor: 'rgb(75, 192, 192)',
// //           backgroundColor: 'rgba(75, 192, 192, 0.5)',
// //           tension: 0.1,
// //           yAxisID: 'y',
// //         },
// //         {
// //           label: 'PM2.5 (µg/m³)',
// //           data: pm25Data,
// //           borderColor: 'rgb(255, 99, 132)',
// //           backgroundColor: 'rgba(255, 99, 132, 0.5)',
// //           tension: 0.1,
// //           yAxisID: 'y1',
// //         }
// //       ],
// //     };
// //   };

// //   // Calculate dynamic max for AQI scale
// //   const calculateAqiMax = () => {
// //     if (!airQualityData || !historicalData.length) return 5;
    
// //     const allAqiValues = [
// //       ...historicalData.map(item => item.aqi),
// //       ...(airQualityData.forecast?.map(item => item.main.aqi) || [])
// //     ];
    
// //     return Math.max(5, ...allAqiValues);
// //   };

// //   const chartOptions = {
// //     responsive: true,
// //     maintainAspectRatio: false,
// //     plugins: {
// //       legend: {
// //         position: 'top',
// //       },
// //       title: {
// //         display: true,
// //         text: `Air Quality for ${selectedCounty?.name || 'Selected County'}`,
// //       },
// //       tooltip: {
// //         mode: 'index',
// //         intersect: false,
// //       },
// //     },
// //     scales: {
// //       y: {
// //         type: 'linear',
// //         display: true,
// //         position: 'left',
// //         title: {
// //           display: true,
// //           text: 'AQI (1-5 scale)',
// //         },
// //         min: 0,
// //         max: calculateAqiMax(),
// //         ticks: {
// //           stepSize: 1
// //         }
// //       },
// //       y1: {
// //         type: 'linear',
// //         display: true,
// //         position: 'right',
// //         title: {
// //           display: true,
// //           text: 'PM2.5 (µg/m³)',
// //         },
// //         grid: {
// //           drawOnChartArea: false,
// //         },
// //       },
// //     },
// //     interaction: {
// //       mode: 'nearest',
// //       axis: 'x',
// //       intersect: false,
// //     },
// //   };

// //   // Get AQI description
// //   const getAqiDescription = (aqi) => {
// //     switch(aqi) {
// //       case 1: return 'Good';
// //       case 2: return 'Fair';
// //       case 3: return 'Moderate';
// //       case 4: return 'Poor';
// //       case 5: return 'Very Poor';
// //       default: return 'Unknown';
// //     }
// //   };

// //   return (
// //     <div className="container mx-auto p-4 max-w-4xl">
// //       <h1 className="text-2xl font-bold mb-6 mt-[7rem]">Utah County Air Quality Dashboard</h1>
      
// //       {/* Search Section */}
// //       <div className="mb-6 bg-white p-4 rounded shadow">
// //         <h2 className="text-lg font-semibold mb-3">Search for Another County</h2>
// //         <div className="flex gap-2">
// //           <input
// //             type="text"
// //             value={searchTerm}
// //             onChange={(e) => setSearchTerm(e.target.value)}
// //             onKeyDown={(e) => e.key === 'Enter' && searchCounty()}
// //             placeholder="Enter a Utah county name..."
// //             className="flex-grow p-2 border rounded"
// //             list="utahCounties"
// //           />
// //           <datalist id="utahCounties">
// //             {utahCountiesWithCoords.map(county => (
// //               <option key={county.name} value={county.name} />
// //             ))}
// //           </datalist>
// //           <button
// //             onClick={searchCounty}
// //             disabled={loading}
// //             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
// //           >
// //             {loading ? 'Searching...' : 'Search'}
// //           </button>
// //         </div>
        
// //         {error && <p className="text-red-500 mt-2">{error}</p>}
        
// //         {countyOptions.length > 0 && (
// //           <div className="mt-2 border rounded max-h-60 overflow-y-auto">
// //             {countyOptions.map((county, index) => (
// //               <div
// //                 key={index}
// //                 className="p-2 hover:bg-gray-100 cursor-pointer"
// //                 onClick={() => handleCountySelect(county)}
// //               >
// //                 {county.name}, Utah
// //               </div>
// //             ))}
// //           </div>
// //         )}
// //       </div>
      
// //       {/* Main Air Quality Display */}
// //       {loading && !airQualityData ? (
// //         <div className="text-center py-8">Loading data...</div>
// //       ) : (
// //         <div className="bg-white p-4 rounded shadow">
// //           <div className="mb-4">
// //             <h2 className="text-xl font-semibold">
// //               {selectedCounty?.name || 'Selected County'} Air Quality
// //             </h2>
// //             {selectedCounty && (
// //               <p className="text-gray-600">
// //                 Coordinates: {selectedCounty.lat.toFixed(4)}, {selectedCounty.lon.toFixed(4)}
// //               </p>
// //             )}
// //           </div>
          
// //           {airQualityData ? (
// //             <>
// //               <div className="h-[500px] mb-8">
// //                 <Line data={prepareChartData()} options={chartOptions} />
// //               </div>
              
// //               <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
// //                 <div className="bg-gray-50 p-4 rounded">
// //                   <h3 className="font-semibold mb-2">Current Air Quality</h3>
// //                   <div className="mb-2">
// //                     <span className="font-medium">AQI: </span>
// //                     <span className={`font-bold ${
// //                       airQualityData.current.main.aqi === 1 ? 'text-green-600' :
// //                       airQualityData.current.main.aqi === 2 ? 'text-yellow-600' :
// //                       airQualityData.current.main.aqi === 3 ? 'text-orange-600' :
// //                       'text-red-600'
// //                     }`}>
// //                       {airQualityData.current.main.aqi} ({getAqiDescription(airQualityData.current.main.aqi)})
// //                     </span>
// //                   </div>
// //                   <ul className="space-y-1">
// //                     <li>PM2.5: {airQualityData.current.components.pm2_5.toFixed(2)} µg/m³</li>
// //                     <li>Ozone: {airQualityData.current.components.o3.toFixed(2)} µg/m³</li>
// //                     <li>NO₂: {airQualityData.current.components.no2.toFixed(2)} µg/m³</li>
// //                     <li>CO: {airQualityData.current.components.co.toFixed(2)} µg/m³</li>
// //                   </ul>
// //                 </div>
                
// //                 <div className="bg-gray-50 p-4 rounded">
// //                   <h3 className="font-semibold mb-2">Forecast</h3>
// //                   {airQualityData.forecast.length > 0 ? (
// //                     <ul className="space-y-2">
// //                       {airQualityData.forecast.slice(0, 3).map((item, index) => (
// //                         <li key={index}>
// //                           <span className="font-medium">Day +{index + 1}: </span>
// //                           <span className={`${
// //                             item.main.aqi === 1 ? 'text-green-600' :
// //                             item.main.aqi === 2 ? 'text-yellow-600' :
// //                             item.main.aqi === 3 ? 'text-orange-600' :
// //                             'text-red-600'
// //                           }`}>
// //                             {getAqiDescription(item.main.aqi)}
// //                           </span>
// //                           <span className="text-sm text-gray-600 ml-2">
// //                             (PM2.5: {item.components.pm2_5.toFixed(2)} µg/m³)
// //                           </span>
// //                         </li>
// //                       ))}
// //                     </ul>
// //                   ) : (
// //                     <p>No forecast data available</p>
// //                   )}
                  
// //                   <div className="mt-4">
// //                     <h4 className="font-semibold mb-1">AQI Scale</h4>
// //                     <ul className="text-sm space-y-1">
// //                       <li className="text-green-600">1 - Good</li>
// //                       <li className="text-yellow-600">2 - Fair</li>
// //                       <li className="text-orange-600">3 - Moderate</li>
// //                       <li className="text-red-600">4 - Poor</li>
// //                       <li className="text-red-800">5 - Very Poor</li>
// //                     </ul>
// //                   </div>
// //                 </div>
// //               </div>
// //             </>
// //           ) : (
// //             <div className="text-center py-8">Loading air quality data...</div>
// //           )}
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default AirQualityDashboard;










// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register ChartJS components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const AirQualityDashboard = () => {
//   const API_KEY = '83c8416a7968c9903e469321a38a08f5';
//   const [searchTerm, setSearchTerm] = useState('');
//   const [countyOptions, setCountyOptions] = useState([]);
//   const [selectedCounty, setSelectedCounty] = useState(null);
//   const [airQualityData, setAirQualityData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [historicalData, setHistoricalData] = useState([]);

//   // Complete list of Utah counties with coordinates
//   const utahCountiesWithCoords = [
//     { name: "Beaver", lat: 38.2766, lon: -112.641 },
//     { name: "Box Elder", lat: 41.5219, lon: -113.083 },
//     { name: "Cache", lat: 41.7379, lon: -111.744 },
//     { name: "Carbon", lat: 39.6489, lon: -110.589 },
//     { name: "Daggett", lat: 40.8877, lon: -109.512 },
//     { name: "Davis", lat: 40.9900, lon: -112.111 },
//     { name: "Duchesne", lat: 40.297, lon: -110.426 },
//     { name: "Emery", lat: 38.9966, lon: -110.701 },
//     { name: "Garfield", lat: 37.8547, lon: -111.443 },
//     { name: "Grand", lat: 38.9900, lon: -109.568 },
//     { name: "Iron", lat: 37.8594, lon: -113.289 },
//     { name: "Juab", lat: 39.7025, lon: -112.784 },
//     { name: "Kane", lat: 37.2853, lon: -111.888 },
//     { name: "Millard", lat: 39.0736, lon: -113.101 },
//     { name: "Morgan", lat: 41.0899, lon: -111.573 },
//     { name: "Piute", lat: 38.3366, lon: -112.127 },
//     { name: "Rich", lat: 41.6324, lon: -111.244 },
//     { name: "Salt Lake", lat: 40.6676, lon: -111.924 },
//     { name: "San Juan", lat: 37.6266, lon: -109.805 },
//     { name: "Sanpete", lat: 39.3739, lon: -111.576 },
//     { name: "Sevier", lat: 38.7478, lon: -111.804 },
//     { name: "Summit", lat: 40.8686, lon: -110.955 },
//     { name: "Tooele", lat: 40.4488, lon: -113.131 },
//     { name: "Uintah", lat: 40.1256, lon: -109.518 },
//     { name: "Utah", lat: 40.1197, lon: -111.670 },
//     { name: "Wasatch", lat: 40.3306, lon: -111.169 },
//     { name: "Washington", lat: 37.2804, lon: -113.505 },
//     { name: "Wayne", lat: 38.3247, lon: -110.903 },
//     { name: "Weber", lat: 41.2697, lon: -111.914 }
//   ];

//   // Load Salt Lake County data by default
//   useEffect(() => {
//     const saltLakeCounty = utahCountiesWithCoords.find(c => c.name === "Salt Lake");
//     if (saltLakeCounty) {
//       setSelectedCounty(saltLakeCounty);
//       fetchAirQuality(saltLakeCounty.lat, saltLakeCounty.lon);
//     }
//   }, []);

//   // Search for county - first checks local data, then API
//   const searchCounty = async () => {
//     if (!searchTerm.trim()) return;
    
//     setLoading(true);
//     setError(null);
//     setCountyOptions([]);
    
//     try {
//       // First try local matches
//       const localMatches = utahCountiesWithCoords.filter(county =>
//         county.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         searchTerm.toLowerCase().includes(county.name.toLowerCase())
//       );
      
//       if (localMatches.length > 0) {
//         setCountyOptions(localMatches);
//         return;
//       }
      
//       // Fallback to API if no local matches
//       const response = await axios.get(
//         `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm},Utah,US&limit=5&appid=${API_KEY}`
//       );
      
//       const utahResults = response.data.filter(
//         location => location.state === "Utah" && location.country === "US"
//       );
      
//       if (utahResults.length === 0) {
//         setError('No matching Utah county found');
//       } else {
//         setCountyOptions(utahResults);
//       }
//     } catch (err) {
//       setError('Failed to fetch county data');
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch air quality data
//   const fetchAirQuality = async (lat, lon) => {
//     setLoading(true);
//     setError(null);
    
//     try {
//       // Fetch current air quality
//       const currentResponse = await axios.get(
//         `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${API_KEY}`
//       );
      
//       // Fetch forecast (if available)
//       const forecastResponse = await axios.get(
//         `https://api.openweathermap.org/data/2.5/air_pollution/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}`
//       );
      
//       // Combine current and forecast data
//       const combinedData = {
//         current: currentResponse.data.list[0],
//         forecast: forecastResponse.data.list.slice(0, 5) // Get next 5 periods
//       };
      
//       setAirQualityData(combinedData);
      
//       // Generate historical data (simulated)
//       generateHistoricalData(combinedData.current);
//     } catch (err) {
//       setError('Failed to fetch air quality data');
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Simulate historical data (since API only provides current/forecast)
//   const generateHistoricalData = (currentData) => {
//     const days = 7;
//     const data = [];
    
//     for (let i = days; i >= 0; i--) {
//       const date = new Date();
//       date.setDate(date.getDate() - i);
      
//       // Add variation to simulate historical data
//       const variation = 0.8 + Math.random() * 0.4; // Between 0.8 and 1.2
      
//       data.push({
//         date: date.toLocaleDateString(),
//         aqi: Math.max(1, Math.min(5, Math.round(currentData.main.aqi * variation))),
//         components: {
//           co: currentData.components.co * variation,
//           no2: currentData.components.no2 * variation,
//           o3: currentData.components.o3 * variation,
//           pm2_5: currentData.components.pm2_5 * variation,
//         }
//       });
//     }
    
//     setHistoricalData(data);
//   };

//   // Handle county selection
//   const handleCountySelect = (county) => {
//     setSelectedCounty(county);
//     setSearchTerm(county.name);
//     setCountyOptions([]);
//     fetchAirQuality(county.lat, county.lon);
//   };

//   // Prepare chart data
//   const prepareChartData = () => {
//     const labels = [
//       ...historicalData.map(item => item.date),
//       ...(airQualityData?.forecast?.map((_, i) => `Day +${i + 1}`) || [])
//     ];
    
//     const aqiData = [
//       ...historicalData.map(item => item.aqi),
//       ...(airQualityData?.forecast?.map(item => item.main.aqi) || [])
//     ];
    
//     const pm25Data = [
//       ...historicalData.map(item => item.components.pm2_5),
//       ...(airQualityData?.forecast?.map(item => item.components.pm2_5) || [])
//     ];
    
//     return {
//       labels,
//       datasets: [
//         {
//           label: 'Air Quality Index (AQI)',
//           data: aqiData,
//           borderColor: 'rgb(75, 192, 192)',
//           backgroundColor: 'rgba(75, 192, 192, 0.5)',
//           tension: 0.1,
//           yAxisID: 'y',
//         },
//         {
//           label: 'PM2.5 (µg/m³)',
//           data: pm25Data,
//           borderColor: 'rgb(255, 99, 132)',
//           backgroundColor: 'rgba(255, 99, 132, 0.5)',
//           tension: 0.1,
//           yAxisID: 'y1',
//         }
//       ],
//     };
//   };

//   // Calculate dynamic max for AQI scale
//   const calculateAqiMax = () => {
//     if (!airQualityData || !historicalData.length) return 5;
    
//     const allAqiValues = [
//       ...historicalData.map(item => item.aqi),
//       ...(airQualityData.forecast?.map(item => item.main.aqi) || [])
//     ];
    
//     return Math.max(5, ...allAqiValues);
//   };

//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: `Air Quality for ${selectedCounty?.name || 'Selected County'}`,
//         font: {
//           size: 14
//         }
//       },
//       tooltip: {
//         mode: 'index',
//         intersect: false,
//       },
//     },
//     scales: {
//       y: {
//         type: 'linear',
//         display: true,
//         position: 'left',
//         title: {
//           display: true,
//           text: 'AQI (1-5 scale)',
//         },
//         min: 0,
//         max: calculateAqiMax(),
//         ticks: {
//           stepSize: 1
//         }
//       },
//       y1: {
//         type: 'linear',
//         display: true,
//         position: 'right',
//         title: {
//           display: true,
//           text: 'PM2.5 (µg/m³)',
//         },
//         grid: {
//           drawOnChartArea: false,
//         },
//       },
//     },
//     interaction: {
//       mode: 'nearest',
//       axis: 'x',
//       intersect: false,
//     },
//   };

//   // Get AQI description
//   const getAqiDescription = (aqi) => {
//     switch(aqi) {
//       case 1: return 'Good';
//       case 2: return 'Fair';
//       case 3: return 'Moderate';
//       case 4: return 'Poor';
//       case 5: return 'Very Poor';
//       default: return 'Unknown';
//     }
//   };

//   return (
//     <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
//       <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Utah County Air Quality Dashboard</h1>
      
//       {/* Search Section */}
//       <div className="mb-6 bg-white p-3 sm:p-4 rounded shadow">
//         <h2 className="text-base sm:text-lg font-semibold mb-2 sm:mb-3">Search for Another County</h2>
//         <div className="flex flex-col sm:flex-row gap-2">
//           <div className="flex-1 relative">
//             <input
//               type="text"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               onKeyDown={(e) => e.key === 'Enter' && searchCounty()}
//               placeholder="Enter a Utah county name..."
//               className="w-full p-2 border rounded"
//               list="utahCounties"
//             />
//             <datalist id="utahCounties">
//               {utahCountiesWithCoords.map(county => (
//                 <option key={county.name} value={county.name} />
//               ))}
//             </datalist>
//           </div>
//           <button
//             onClick={searchCounty}
//             disabled={loading}
//             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 whitespace-nowrap"
//           >
//             {loading ? 'Searching...' : 'Search'}
//           </button>
//         </div>
        
//         {error && <p className="text-red-500 text-sm sm:text-base mt-2">{error}</p>}
        
//         {countyOptions.length > 0 && (
//           <div className="mt-2 border rounded max-h-60 overflow-y-auto">
//             {countyOptions.map((county, index) => (
//               <div
//                 key={index}
//                 className="p-2 hover:bg-gray-100 cursor-pointer text-sm sm:text-base"
//                 onClick={() => handleCountySelect(county)}
//               >
//                 {county.name}, Utah
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
      
//       {/* Main Air Quality Display */}
//       {loading && !airQualityData ? (
//         <div className="text-center py-8">Loading data...</div>
//       ) : (
//         <div className="bg-white p-3 sm:p-4 rounded shadow">
//           <div className="mb-4">
//             <h2 className="text-lg sm:text-xl font-semibold">
//               {selectedCounty?.name || 'Selected County'} Air Quality
//             </h2>
//             {selectedCounty && (
//               <p className="text-gray-600 text-sm sm:text-base">
//                 Coordinates: {selectedCounty.lat.toFixed(4)}, {selectedCounty.lon.toFixed(4)}
//               </p>
//             )}
//           </div>
          
//           {airQualityData ? (
//             <>
//               <div className="h-[300px] sm:h-[400px] md:h-[500px] mb-6 sm:mb-8">
//                 <Line data={prepareChartData()} options={chartOptions} />
//               </div>
              
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4">
//                 <div className="bg-gray-50 p-3 sm:p-4 rounded">
//                   <h3 className="font-semibold mb-2 text-sm sm:text-base">Current Air Quality</h3>
//                   <div className="mb-2">
//                     <span className="font-medium">AQI: </span>
//                     <span className={`font-bold ${
//                       airQualityData.current.main.aqi === 1 ? 'text-green-600' :
//                       airQualityData.current.main.aqi === 2 ? 'text-yellow-600' :
//                       airQualityData.current.main.aqi === 3 ? 'text-orange-600' :
//                       'text-red-600'
//                     }`}>
//                       {airQualityData.current.main.aqi} ({getAqiDescription(airQualityData.current.main.aqi)})
//                     </span>
//                   </div>
//                   <ul className="space-y-1 text-sm sm:text-base">
//                     <li>PM2.5: {airQualityData.current.components.pm2_5.toFixed(2)} µg/m³</li>
//                     <li>Ozone: {airQualityData.current.components.o3.toFixed(2)} µg/m³</li>
//                     <li>NO₂: {airQualityData.current.components.no2.toFixed(2)} µg/m³</li>
//                     <li>CO: {airQualityData.current.components.co.toFixed(2)} µg/m³</li>
//                   </ul>
//                 </div>
                
//                 <div className="bg-gray-50 p-3 sm:p-4 rounded">
//                   <h3 className="font-semibold mb-2 text-sm sm:text-base">Forecast</h3>
//                   {airQualityData.forecast.length > 0 ? (
//                     <ul className="space-y-2">
//                       {airQualityData.forecast.slice(0, 3).map((item, index) => (
//                         <li key={index} className="text-sm sm:text-base">
//                           <span className="font-medium">Day +{index + 1}: </span>
//                           <span className={`${
//                             item.main.aqi === 1 ? 'text-green-600' :
//                             item.main.aqi === 2 ? 'text-yellow-600' :
//                             item.main.aqi === 3 ? 'text-orange-600' :
//                             'text-red-600'
//                           }`}>
//                             {getAqiDescription(item.main.aqi)}
//                           </span>
//                           <span className="text-xs sm:text-sm text-gray-600 ml-2">
//                             (PM2.5: {item.components.pm2_5.toFixed(2)} µg/m³)
//                           </span>
//                         </li>
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-sm sm:text-base">No forecast data available</p>
//                   )}
                  
//                   <div className="mt-3 sm:mt-4">
//                     <h4 className="font-semibold mb-1 text-xs sm:text-sm">AQI Scale</h4>
//                     <ul className="text-xs sm:text-sm space-y-1">
//                       <li className="text-green-600">1 - Good</li>
//                       <li className="text-yellow-600">2 - Fair</li>
//                       <li className="text-orange-600">3 - Moderate</li>
//                       <li className="text-red-600">4 - Poor</li>
//                       <li className="text-red-800">5 - Very Poor</li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <div className="text-center py-8 text-sm sm:text-base">Loading air quality data...</div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default AirQualityDashboard;
















import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AirQualityDashboard = () => {
  const API_KEY = '83c8416a7968c9903e469321a38a08f5';
  const [searchTerm, setSearchTerm] = useState('');
  const [countyOptions, setCountyOptions] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [airQualityData, setAirQualityData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);

  // Complete list of Utah counties with coordinates
  const utahCountiesWithCoords = [
    { name: "Beaver", lat: 38.2766, lon: -112.641 },
    { name: "Box Elder", lat: 41.5219, lon: -113.083 },
    { name: "Cache", lat: 41.7379, lon: -111.744 },
    { name: "Carbon", lat: 39.6489, lon: -110.589 },
    { name: "Daggett", lat: 40.8877, lon: -109.512 },
    { name: "Davis", lat: 40.9900, lon: -112.111 },
    { name: "Duchesne", lat: 40.297, lon: -110.426 },
    { name: "Emery", lat: 38.9966, lon: -110.701 },
    { name: "Garfield", lat: 37.8547, lon: -111.443 },
    { name: "Grand", lat: 38.9900, lon: -109.568 },
    { name: "Iron", lat: 37.8594, lon: -113.289 },
    { name: "Juab", lat: 39.7025, lon: -112.784 },
    { name: "Kane", lat: 37.2853, lon: -111.888 },
    { name: "Millard", lat: 39.0736, lon: -113.101 },
    { name: "Morgan", lat: 41.0899, lon: -111.573 },
    { name: "Piute", lat: 38.3366, lon: -112.127 },
    { name: "Rich", lat: 41.6324, lon: -111.244 },
    { name: "Salt Lake", lat: 40.6676, lon: -111.924 },
    { name: "San Juan", lat: 37.6266, lon: -109.805 },
    { name: "Sanpete", lat: 39.3739, lon: -111.576 },
    { name: "Sevier", lat: 38.7478, lon: -111.804 },
    { name: "Summit", lat: 40.8686, lon: -110.955 },
    { name: "Tooele", lat: 40.4488, lon: -113.131 },
    { name: "Uintah", lat: 40.1256, lon: -109.518 },
    { name: "Utah", lat: 40.1197, lon: -111.670 },
    { name: "Wasatch", lat: 40.3306, lon: -111.169 },
    { name: "Washington", lat: 37.2804, lon: -113.505 },
    { name: "Wayne", lat: 38.3247, lon: -110.903 },
    { name: "Weber", lat: 41.2697, lon: -111.914 }
  ];

  // Load Salt Lake County data by default
  useEffect(() => {
    const saltLakeCounty = utahCountiesWithCoords.find(c => c.name === "Salt Lake");
    if (saltLakeCounty) {
      setSelectedCounty(saltLakeCounty);
      fetchAirQuality(saltLakeCounty.lat, saltLakeCounty.lon);
    }
  }, []);

  // Search for county - first checks local data, then API
  const searchCounty = async () => {
    if (!searchTerm.trim()) return;
    
    setLoading(true);
    setError(null);
    setCountyOptions([]);
    
    try {
      // First try local matches
      const localMatches = utahCountiesWithCoords.filter(county =>
        county.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        searchTerm.toLowerCase().includes(county.name.toLowerCase())
      );
      
      if (localMatches.length > 0) {
        setCountyOptions(localMatches);
        return;
      }
      
      // Fallback to API if no local matches
      const response = await axios.get(
        `https://api.openweathermap.org/geo/1.0/direct?q=${searchTerm},Utah,US&limit=5&appid=${API_KEY}`
      );
      
      const utahResults = response.data.filter(
        location => location.state === "Utah" && location.country === "US"
      );
      
      if (utahResults.length === 0) {
        setError('No matching Utah county found');
      } else {
        setCountyOptions(utahResults);
      }
    } catch (err) {
      setError('Failed to fetch county data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch air quality data
  const fetchAirQuality = async (lat, lon) => {
    setLoading(true);
    setError(null);
    
    try {
      // Fetch current air quality
      const currentResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${API_KEY}`
      );
      
      // Fetch forecast (if available)
      const forecastResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/air_pollution/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}`
      );
      
      // Combine current and forecast data
      const combinedData = {
        current: currentResponse.data.list[0],
        forecast: forecastResponse.data.list.slice(0, 5) // Get next 5 periods
      };
      
      setAirQualityData(combinedData);
      
      // Generate historical data (simulated)
      generateHistoricalData(combinedData.current);
    } catch (err) {
      setError('Failed to fetch air quality data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Simulate historical data (since API only provides current/forecast)
  const generateHistoricalData = (currentData) => {
    const days = 7;
    const data = [];
    
    for (let i = days; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      
      // Add variation to simulate historical data
      const variation = 0.8 + Math.random() * 0.4; // Between 0.8 and 1.2
      
      data.push({
        date: date.toLocaleDateString(),
        aqi: Math.max(1, Math.min(5, Math.round(currentData.main.aqi * variation))),
        components: {
          co: currentData.components.co * variation,
          no2: currentData.components.no2 * variation,
          o3: currentData.components.o3 * variation,
          pm2_5: currentData.components.pm2_5 * variation,
        }
      });
    }
    
    setHistoricalData(data);
  };

  // Handle county selection
  const handleCountySelect = (county) => {
    setSelectedCounty(county);
    setSearchTerm(county.name);
    setCountyOptions([]);
    fetchAirQuality(county.lat, county.lon);
  };

  // Prepare chart data
  const prepareChartData = () => {
    const labels = [
      ...historicalData.map(item => item.date),
      ...(airQualityData?.forecast?.map((_, i) => `Day +${i + 1}`) || [])
    ];
    
    const aqiData = [
      ...historicalData.map(item => item.aqi),
      ...(airQualityData?.forecast?.map(item => item.main.aqi) || [])
    ];
    
    const pm25Data = [
      ...historicalData.map(item => item.components.pm2_5),
      ...(airQualityData?.forecast?.map(item => item.components.pm2_5) || [])
    ];
    
    return {
      labels,
      datasets: [
        {
          label: 'Air Quality Index (AQI)',
          data: aqiData,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          tension: 0.1,
          yAxisID: 'y',
        },
        {
          label: 'PM2.5 (µg/m³)',
          data: pm25Data,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          tension: 0.1,
          yAxisID: 'y1',
        }
      ],
    };
  };

  // Calculate dynamic max for AQI scale
  const calculateAqiMax = () => {
    if (!airQualityData || !historicalData.length) return 5;
    
    const allAqiValues = [
      ...historicalData.map(item => item.aqi),
      ...(airQualityData.forecast?.map(item => item.main.aqi) || [])
    ];
    
    return Math.max(5, ...allAqiValues);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Air Quality for ${selectedCounty?.name || 'Selected County'}`,
        font: {
          size: 14
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'AQI (1-5 scale)',
        },
        min: 0,
        max: calculateAqiMax(),
        ticks: {
          stepSize: 1
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'PM2.5 (µg/m³)',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
  };

  // Get AQI description
  const getAqiDescription = (aqi) => {
    switch(aqi) {
      case 1: return 'Good';
      case 2: return 'Fair';
      case 3: return 'Moderate';
      case 4: return 'Poor';
      case 5: return 'Very Poor';
      default: return 'Unknown';
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 mt-[7rem] sm:mt-[10rem]">Utah County Air Quality Dashboard</h1>
      
      {/* Search Section */}
      <div className="mb-6 bg-white p-3 sm:p-4 rounded shadow">
        <h2 className="text-base sm:text-lg font-semibold mb-2 sm:mb-3">Search for Another County</h2>
        <div className="flex flex-col sm:flex-row gap-2">
          <div className="flex-1 relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && searchCounty()}
              placeholder="Enter a Utah county name..."
              className="w-full p-2 border rounded"
              list="utahCounties"
            />
            <datalist id="utahCounties">
              {utahCountiesWithCoords.map(county => (
                <option key={county.name} value={county.name} />
              ))}
            </datalist>
          </div>
          <button
            onClick={searchCounty}
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300 whitespace-nowrap"
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>
        
        {error && <p className="text-red-500 text-sm sm:text-base mt-2">{error}</p>}
        
        {countyOptions.length > 0 && (
          <div className="mt-2 border rounded max-h-60 overflow-y-auto">
            {countyOptions.map((county, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-100 cursor-pointer text-sm sm:text-base"
                onClick={() => handleCountySelect(county)}
              >
                {county.name}, Utah
              </div>
            ))}
          </div>
        )}
      </div>
      
      {/* Main Air Quality Display */}
      {loading && !airQualityData ? (
        <div className="text-center py-8">Loading data...</div>
      ) : (
        <div className="bg-white p-3 sm:p-4 rounded shadow">
          <div className="mb-4">
            <h2 className="text-lg sm:text-xl font-semibold">
              {selectedCounty?.name || 'Selected County'} Air Quality
            </h2>
            {selectedCounty && (
              <p className="text-gray-600 text-sm sm:text-base">
                Coordinates: {selectedCounty.lat.toFixed(4)}, {selectedCounty.lon.toFixed(4)}
              </p>
            )}
          </div>
          
          {airQualityData ? (
            <>
              <div className="h-[300px] sm:h-[400px] md:h-[500px] mb-6 sm:mb-8">
                <Line data={prepareChartData()} options={chartOptions} />
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4">
                <div className="bg-gray-50 p-3 sm:p-4 rounded">
                  <h3 className="font-semibold mb-2 text-sm sm:text-base">Current Air Quality</h3>
                  <div className="mb-2">
                    <span className="font-medium">AQI: </span>
                    <span className={`font-bold ${
                      airQualityData.current.main.aqi === 1 ? 'text-green-600' :
                      airQualityData.current.main.aqi === 2 ? 'text-yellow-600' :
                      airQualityData.current.main.aqi === 3 ? 'text-orange-600' :
                      'text-red-600'
                    }`}>
                      {airQualityData.current.main.aqi} ({getAqiDescription(airQualityData.current.main.aqi)})
                    </span>
                  </div>
                  <ul className="space-y-1 text-sm sm:text-base">
                    <li>PM2.5: {airQualityData.current.components.pm2_5.toFixed(2)} µg/m³</li>
                    <li>Ozone: {airQualityData.current.components.o3.toFixed(2)} µg/m³</li>
                    <li>NO₂: {airQualityData.current.components.no2.toFixed(2)} µg/m³</li>
                    <li>CO: {airQualityData.current.components.co.toFixed(2)} µg/m³</li>
                  </ul>
                </div>
                
                <div className="bg-gray-50 p-3 sm:p-4 rounded">
                  <h3 className="font-semibold mb-2 text-sm sm:text-base">Forecast</h3>
                  {airQualityData.forecast.length > 0 ? (
                    <ul className="space-y-2">
                      {airQualityData.forecast.slice(0, 3).map((item, index) => (
                        <li key={index} className="text-sm sm:text-base">
                          <span className="font-medium">Day +{index + 1}: </span>
                          <span className={`${
                            item.main.aqi === 1 ? 'text-green-600' :
                            item.main.aqi === 2 ? 'text-yellow-600' :
                            item.main.aqi === 3 ? 'text-orange-600' :
                            'text-red-600'
                          }`}>
                            {getAqiDescription(item.main.aqi)}
                          </span>
                          <span className="text-xs sm:text-sm text-gray-600 ml-2">
                            (PM2.5: {item.components.pm2_5.toFixed(2)} µg/m³)
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-sm sm:text-base">No forecast data available</p>
                  )}
                  
                  <div className="mt-3 sm:mt-4">
                    <h4 className="font-semibold mb-1 text-xs sm:text-sm">AQI Scale</h4>
                    <ul className="text-xs sm:text-sm space-y-1">
                      <li className="text-green-600">1 - Good</li>
                      <li className="text-yellow-600">2 - Fair</li>
                      <li className="text-orange-600">3 - Moderate</li>
                      <li className="text-red-600">4 - Poor</li>
                      <li className="text-red-800">5 - Very Poor</li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center py-8 text-sm sm:text-base">Loading air quality data...</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AirQualityDashboard;