import React from 'react'

export default function School13() {

    const schoolData = 
    [
        {"schoolName": "Academy Park School", "numberOfStudents": 370, "grades": "PK-6"},
        {"schoolName": "Arcadia School", "numberOfStudents": 447, "grades": "PK-6"},
        {"schoolName": "Beehive School", "numberOfStudents": 435, "grades": "PK-6"},
        {"schoolName": "Bennion Jr High", "numberOfStudents": 1000, "grades": "6-9"},
        {"schoolName": "Bennion School", "numberOfStudents": 453, "grades": "PK-6"},
        {"schoolName": "Bonneville Jr High", "numberOfStudents": 604, "grades": "6-8"},
        {"schoolName": "Calvin S. Smith School", "numberOfStudents": 603, "grades": "PK-6"},
        {"schoolName": "Churchill Jr High", "numberOfStudents": 556, "grades": "6-8"},
        {"schoolName": "Copper Hills School", "numberOfStudents": 479, "grades": "PK-6"},
        {"schoolName": "Cottonwood High", "numberOfStudents": 1585, "grades": "9-12"},
        {"schoolName": "Cottonwood School", "numberOfStudents": 387, "grades": "PK-6"},
        {"schoolName": "Crestview School", "numberOfStudents": 473, "grades": "PK-6"},
        {"schoolName": "Cyprus High", "numberOfStudents": 2709, "grades": "9-12"},
        {"schoolName": "David Gourley School", "numberOfStudents": 477, "grades": "PK-6"},
        {"schoolName": "Diamond Ridge School", "numberOfStudents": 574, "grades": "KG-6"},
        {"schoolName": "Douglas T. Orchard School", "numberOfStudents": 461, "grades": "PK-6"},
        {"schoolName": "Eastwood School", "numberOfStudents": 332, "grades": "PK-5"},
        {"schoolName": "Eisenhower Jr High", "numberOfStudents": 1091, "grades": "6-9"},
        {"schoolName": "Elk Run Elementary", "numberOfStudents": 629, "grades": "PK-6"},
        {"schoolName": "Evergreen Jr High", "numberOfStudents": 651, "grades": "6-9"},
        {"schoolName": "Fox Hills School", "numberOfStudents": 570, "grades": "KG-6"},
        {"schoolName": "Gearld Wright School", "numberOfStudents": 526, "grades": "PK-6"},
        {"schoolName": "Granger High", "numberOfStudents": 3481, "grades": "8-12"},
        {"schoolName": "Granger School", "numberOfStudents": 755, "grades": "PK-6"},
        {"schoolName": "Granite Connection High", "numberOfStudents": 482, "grades": "KG-12"},
        {"schoolName": "Granite Park Jr High", "numberOfStudents": 871, "grades": "6-8"},
        {"schoolName": "Granite Preschool", "numberOfStudents": 0, "grades": "PK-PK"},
        {"schoolName": "Granite Technical Institute", "numberOfStudents": 126, "grades": "7-12"},
        {"schoolName": "Harry S. Truman School", "numberOfStudents": 367, "grades": "PK-6"},
        {"schoolName": "Hartvigsen School", "numberOfStudents": 72, "grades": "KG-8"},
        {"schoolName": "Headstart Preschool Special Education", "numberOfStudents": 6, "grades": "PK-KG"},
        {"schoolName": "Hillsdale School", "numberOfStudents": 633, "grades": "PK-6"},
        {"schoolName": "Hillside School", "numberOfStudents": 499, "grades": "PK-6"},
        
            {"schoolName": "Howard R. Driggs School", "numberOfStudents": 466, "grades": "PK-6"},
            {"schoolName": "Hunter High", "numberOfStudents": 2595, "grades": "9-12"},
            {"schoolName": "Hunter Jr High", "numberOfStudents": 732, "grades": "7-8"},
            {"schoolName": "Hunter School", "numberOfStudents": 490, "grades": "PK-6"},
            {"schoolName": "Jackling School", "numberOfStudents": 461, "grades": "PK-6"},
            {"schoolName": "James E. Moss School", "numberOfStudents": 506, "grades": "PK-6"},
            {"schoolName": "Jim Bridger School", "numberOfStudents": 369, "grades": "PK-6"},
            {"schoolName": "John C. Fremont School", "numberOfStudents": 379, "grades": "PK-6"},
            {"schoolName": "John F. Kennedy Jr High", "numberOfStudents": 857, "grades": "6-8"},
            {"schoolName": "Kearns High", "numberOfStudents": 2382, "grades": "9-12"},
            {"schoolName": "Kearns Jr High", "numberOfStudents": 778, "grades": "6-8"},
            {"schoolName": "Lake Ridge School", "numberOfStudents": 422, "grades": "PK-6"},
            {"schoolName": "Lincoln School", "numberOfStudents": 456, "grades": "PK-6"},
            {"schoolName": "Magna School", "numberOfStudents": 583, "grades": "PK-6"},
            {"schoolName": "Mill Creek School", "numberOfStudents": 352, "grades": "PK-6"},
            {"schoolName": "Monroe School", "numberOfStudents": 563, "grades": "PK-6"},
            {"schoolName": "Morningside School", "numberOfStudents": 573, "grades": "KG-6"},
            {"schoolName": "Neil Armstrong Academy", "numberOfStudents": 744, "grades": "PK-6"},
            {"schoolName": "Oakridge School", "numberOfStudents": 313, "grades": "KG-5"},
            {"schoolName": "Oakwood School", "numberOfStudents": 349, "grades": "PK-6"},
            {"schoolName": "Olene Walker Elementary", "numberOfStudents": 453, "grades": "PK-6"},
            {"schoolName": "Olympus High", "numberOfStudents": 2123, "grades": "9-12"},
            {"schoolName": "Olympus Jr High", "numberOfStudents": 784, "grades": "5-9"},
            {"schoolName": "Philo T. Farnsworth School", "numberOfStudents": 546, "grades": "PK-6"},
            {"schoolName": "Pioneer School", "numberOfStudents": 478, "grades": "PK-6"},
            {"schoolName": "Pleasant Green School", "numberOfStudents": 445, "grades": "PK-6"},
            {"schoolName": "Plymouth School", "numberOfStudents": 562, "grades": "PK-6"},
            {"schoolName": "Redwood School", "numberOfStudents": 486, "grades": "PK-6"},
            {"schoolName": "Robert Frost School", "numberOfStudents": 355, "grades": "PK-6"},
            {"schoolName": "Rolling Meadows School", "numberOfStudents": 472, "grades": "PK-6"},
            {"schoolName": "Rosecrest School", "numberOfStudents": 327, "grades": "PK-6"},
            {"schoolName": "Scott M Matheson Jr High", "numberOfStudents": 1081, "grades": "7-8"},
            {"schoolName": "Silver Hills School", "numberOfStudents": 385, "grades": "PK-6"},
            {"schoolName": "Skyline High", "numberOfStudents": 2124, "grades": "7-12"},
            {"schoolName": "South Kearns School", "numberOfStudents": 449, "grades": "PK-6"},
            {"schoolName": "Spring Lane School", "numberOfStudents": 298, "grades": "PK-6"},
            {"schoolName": "Stansbury School", "numberOfStudents": 623, "grades": "PK-6"},
            {"schoolName": "Taylorsville High", "numberOfStudents": 2770, "grades": "9-12"},
            {"schoolName": "Taylorsville School", "numberOfStudents": 381, "grades": "PK-6"},
            {"schoolName": "Thomas Jefferson Jr High", "numberOfStudents": 989, "grades": "6-8"},
            {"schoolName": "Thomas W. Bacchus School", "numberOfStudents": 335, "grades": "KG-6"},
            {"schoolName": "Twin Peaks School", "numberOfStudents": 270, "grades": "PK-6"},
            {"schoolName": "Upland Terrace School", "numberOfStudents": 520, "grades": "PK-5"},
            {"schoolName": "Valley Crest School", "numberOfStudents": 494, "grades": "PK-6"},
            {"schoolName": "Valley Jr High", "numberOfStudents": 639, "grades": "7-8"},
            {"schoolName": "Vista School", "numberOfStudents": 517, "grades": "PK-9"},
            {"schoolName": "Wasatch Jr High", "numberOfStudents": 787, "grades": "6-8"},
            {"schoolName": "Western Hills School", "numberOfStudents": 300, "grades": "PK-6"},
            {"schoolName": "West Kearns School", "numberOfStudents": 578, "grades": "PK-6"},
            {"schoolName": "West Lake Jr High", "numberOfStudents": 701, "grades": "7-8"},
            {"schoolName": "West Valley School", "numberOfStudents": 735, "grades": "PK-6"},
            {"schoolName": "Whittier School", "numberOfStudents": 499, "grades": "PK-6"},
            {"schoolName": "William Penn School", "numberOfStudents": 523, "grades": "KG-6"},
            {"schoolName": "Woodrow Wilson School", "numberOfStudents": 461, "grades": "PK-6"},
            {"schoolName": "Woodstock School", "numberOfStudents": 559, "grades": "PK-6"},
            {"schoolName": "Youth Educational Support School", "numberOfStudents": 74, "grades": "KG-12"}
          
          
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Granite
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
