import React from "react";

const statePark = "/img/hospital.jpg";

const a = "/img/Western Peaks Specialty Hospital.png";
const b = "/img/Valley View Medical Center.png";
const c = "/img/Utah Valley Specialty Hospital.jpeg";
const d = "/img/Utah Valley Hospital.png";
const e = "/img/Utah State Hospital.jpeg";
const f = "/img/University of Utah Hospital.png";
const g = "/img/Uintah Basin Medical Center.jpeg";
const h = "/img/Timpanogos Regional Hospital.png";
const i = "/img/The Orthopedic Specialty Clinic at TOSH.png";
const j = "/img/St. George Regional Hospital.png";
const k = "/img/Spanish Fork Hospital.jpeg";
const l = "/img/Shriners Hospitals for Children.png";
const m = "/img/Sevier Valley Hospital.jpeg";
const n = "/img/Sanpete Valley Hospital.png";
const o = "/img/San Juan Hospital.png";
const p = "/img/Salt Lake Specialty Hospital.png";
const q = "/img/Salt Lake Behavioral Health Hospital.png";
const r = "/img/Saint Mark's Hospital.png";
const s = "/img/Riverton Hospital.jpeg";
const t = "/img/Primary Children's Hospital.png";
const u = "/img/Park City Hospital.jpeg";
const v = "/img/Orem Community Hospital.png";
const w = "/img/Ogden Regional Medical Center.jpeg";
const x = "/img/Northern Utah Rehabilitation Hospital.png";
const y = "/img/Mountain West Medical Center.png";
const z = "/img/Mountain View Hospital - Payson.png";
const a1 = "/img/Moab Regional Hospital.png";
const b1 = "/img/McKay-Dee Hospital.jpeg";
const c1 = "/img/Marian Center_St. Joseph's Villa of SLC.png";
const d1 = "/img/Lone Peak Hospital.jpeg";
const e1 = "/img/Logan Regional Hospital.png";
const f1 = "/img/LDS Hospital.png";
const g1 = "/img/Layton Hospital.jpeg";
const h1 = "/img/Lakeview Hospital.jpeg";
const i1 = "/img/Kane County Human Resource SSD (Hospital).jpeg";
const j1 = "/img/Intermountain Medical Center.jpeg";
const k1 = "/img/Huntsman Mental Health Institute.png";
const l1 = "/img/Huntsman Cancer Institute.png";
const m1 = "/img/Heber Valley Medical Center.jpeg";
const n1 = "/img/Gunnison Valley Hospital.png";
const o1 = "/img/George E. Wahlen Department of Veterans Affairs Medical Center.jpeg";
const p1 = "/img/Garfield Memorial Hospital Utah.png";
const q1 = "/img/Fillmore Community Medical Center.png";
const r1 = "/img/Encompass Health Rehabilitation Hospital.png";
const s1 = "/img/Delta Community Medical Center.png";
const t1 = "/img/CommonSpirit-Holy Cross Hospital-Salt Lake.jpeg";
const u1 = "/img/CommonSpirit-Holy Cross Hospital-Jordan Valley Utah.png";
const v1 = "/img/CommonSpirit-Holy Cross Hospital-Davis.jpeg";
const w1 = "/img/CommonSpirit-Holy Cross Hospital - Mountain Point.png";
const x1 = "/img/Common Spirit-Holy Cross Hospital-Jordan Valley West.jpeg";
const y1 = "/img/Central Valley Medical Center.png";
const z1 = "/img/Center for Change .png";
const a2 = "/img/Cedar City Hospital.jpeg";
const b2 = "/img/Castleview Hospital.png";
const c2 = "/img/Cache Valley Hospital.jpeg";
const d2 = "/img/Brigham City Community Hospital.jpeg";
const e2 = "/img/Blue Mountain Hospital.png";
const f2 = "/img/Benchmark Behavioral Health Systems North.jpeg";
const g2 = "/img/Beaver Valley Hospital.jpeg";
const h2 = "/img/Bear River Valley Hospital.png";
const i2 = "/img/Aspen Grove Behavioral Hospital.png";
const j2 = "/img/Ashley Regional Medical Center.png";
const k2 = "/img/American Fork Hospital.jpeg";
const l2 = "/img/Alta View Hospital.jpeg";

function Hospital() {
  const park = [
    {
        "Hospital Name": "Alta View Hospital",
        "City": "Sandy",
        "Address": "9660 S 1300 E, Sandy, UT 84094",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 501-2600",
        "Beds": "57",
        "Website": "https://intermountainhealthcare.org/locations/alta-view-hospital",
        "Image": l2
    },
    {
        "Hospital Name": "American Fork Hospital",
        "City": "American Fork",
        "Address": "170 N 1100 E, American Fork, UT 84003",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 855-3300",
        "Beds": "90",
        "Website": "https://intermountainhealthcare.org/locations/american-fork-hospital",
        "Image": k2
    },
    {
        "Hospital Name": "Ashley Regional Medical Center",
        "City": "Vernal",
        "Address": "150 W 100 N, Vernal, UT 84078",
        "Owner": "LifePoint Hospitals",
        "Phone No:": "(435) 789-3342",
        "Beds": "39",
        "Website": "https://www.ashleyregional.com",
        "Image": j2
    },
    {
        "Hospital Name": "Aspen Grove Behavioral Hospital",
        "City": "Orem",
        "Address": "1350 E 750 N St, Orem, UT 84097",
        "Owner": "Independent (UHS)",
        "Phone No:": "(801)-852-2273",
        "Beds": "80",
        "Website": "https://aspengrovehospital.com" ,"Image": i2
    },
    {
        "Hospital Name": "Bear River Valley Hospital",
        "City": "Tremonton",
        "Address": "905 N 1000 W, Tremonton, UT 84337",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435)-207-4671",
        "Beds": "16",
        "Website": "https://intermountainhealthcare.org/locations/bear-river-valley-hospital" ,"Image": h2
    },
    {
        "Hospital Name": "Beaver Valley Hospital",
        "City": "Beaver",
        "Address": "1109 N 100 W St, Beaver, UT 84713",
        "Owner": "City of Beaver",
        "Phone No:": "(435)-438-7100",
        "Beds": "49",
        "Website": "https://beaverutah.net/community/beaver-valley-hospital/" ,"Image": g2
    },
    {
        "Hospital Name": "Benchmark Behavioral Health Systems North",
        "City": "Woods Cross",
        "Address": "592 W 1350 S,Woods Cross, UT 84010",
        "Owner": "Independent (Psychiatric Solutions)",
        "Phone No:": "(801) 299-5300",
        "Beds": "84",
        "Website": "https://bbhsnet.com" ,"Image": f2
    },
    {
        "Hospital Name": "Blue Mountain Hospital",
        "City": "Blanding",
        "Address": "802 S 200 W, Blanding, UT 84511",
        "Owner": "Blue Mountain Hospital, Inc.",
        "Phone No:": "(435)-678-3993",
        "Beds": "11",
        "Website": "https://www.bmhutah.org" ,"Image": e2
    },
    {
        "Hospital Name": "Brigham City Community Hospital",
        "City": "Brigham City",
        "Address": "950 Medical Dr, Brigham City, UT 84302",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(435) 734-9471",
        "Beds": "49",
        "Website": "https://www.mountainstar.com/locations/brigham-city-community-hospital" ,"Image": d2
    },
    {
        "Hospital Name": "Cache Valley Hospital",
        "City": "North Logan",
        "Address": "2380 N 400 E, North Logan, UT 84341",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(435) 713-9700",
        "Beds": "28",
        "Website": "https://www.mountainstar.com/locations/cache-valley-hospital" ,"Image": c2
    },
    {
        "Hospital Name": "Castleview Hospital",
        "City": "Price",
        "Address": "300 Hospital Dr, Price, UT 84501",
        "Owner": "LifePoint Hospitals",
        "Phone No:": "(435) 637-4800",
        "Beds": "39",
        "Website": "https://www.castleviewhospital.net" ,"Image": b2
    },
    {
        "Hospital Name": "Cedar City Hospital",
        "City": "Cedar City",
        "Address": "1303 N Main St, Cedar City, UT 84721",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435)-868-5000",
        "Beds": "48",
        "Website": "https://intermountainhealthcare.org/locations/cedar-city-hospital" ,"Image": a2
    },
    {
        "Hospital Name": "Central Valley Medical Center",
        "City": "Nephi",
        "Address": "48 W 1500 N, Nephi, UT 84648",
        "Owner": "Juab County",
        "Phone No:": "(435)-623-3000",
        "Beds": "17",
        "Website": "https://www.centralvalleymedicalcenter.com/locations/central-valley-medical-center/" ,"Image": y1
    },
    {
        "Hospital Name": "Center for Change ",
        "City": "Orem",
        "Address": "1790 N State St, Orem, UT 84057",
        "Owner": "Independent (Universal Health Services, Inc.)",
        "Phone No:": "(801) 224-8255",
        "Beds": "58",
        "Website": "https://centerforchange.com" ,"Image": z1
    },
    {
        "Hospital Name": "Common Spirit-Holy Cross Hospital-Jordan Valley West",
        "City": "West Valley City",
        "Address": "3460 S 4155 W, West Valley City, UT 84120",
        "Owner": "CommonSpirit Health",
        "Phone No:": "(801) 964-3100",
        "Beds": "102",
        "Website": "https://www.mountain.commonspirit.org/location/holy-cross-hospital-west-valley" ,"Image": x1
    },
    {
        "Hospital Name": "CommonSpirit - Holy Cross Hospital - Mountain Point",
        "City": "Lehi",
        "Address": "3000 Triumph Blvd, Lehi, UT 84043",
        "Owner": "CommonSpirit Health",
        "Phone No:": "(385) 345-3000",
        "Beds": "40",
        "Website": "https://www.mountain.commonspirit.org/location/holy-cross-hospital-mountain-point" ,"Image": w1
    },
    {
        "Hospital Name": "CommonSpirit - Holy Cross Hospital - Salt Lake",
        "City": "Salt Lake City",
        "Address": "1050 E S Temple St, Salt Lake City, UT 84102",
        "Owner": "CommonSpirit Health",
        "Phone No:": "(801) 350-4111",
        "Beds": "158",
        "Website": "https://www.mountain.commonspirit.org/location/holy-cross-hospital-salt-lake" ,"Image": v1
    },
    {
        "Hospital Name": "CommonSpirit-Holy Cross Hospital-Davis",
        "City": "Layton",
        "Address": "1600 W Antelope Dr, Layton, UT 84041",
        "Owner": "CommonSpirit Health",
        "Phone No:": "(801) 807-1000",
        "Beds": "220",
        "Website": "https://www.mountain.commonspirit.org/location/holy-cross-hospital-davis" ,"Image": u1
    },
    {
        "Hospital Name": "CommonSpirit-Holy Cross Hospital-Jordan Valley",
        "City": "West Jordan",
        "Address": "3580 W 9000 S, West Jordan, UT 84088",
        "Owner": "CommonSpirit Health",
        "Phone No:": "(801) 561-8888",
        "Beds": "193",
        "Website": "https://www.mountain.commonspirit.org/location/holy-cross-hospital-jordan-valley" ,"Image": t1
    },
    {
        "Hospital Name": "Delta Community Medical Center",
        "City": "Delta",
        "Address": "126 White Sage Ave, Delta, UT 84624",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 864-5591",
        "Beds": "17",
        "Website": "https://intermountainhealthcare.org/locations/delta-community-hospital" ,"Image": s1
    },
    {
        "Hospital Name": "Encompass Health Rehabilitation Hospital",
        "City": "Sandy",
        "Address": "8074 S 1300 E, Sandy, UT 84094",
        "Owner": "Encompass Health",
        "Phone No:": "(801) 561-3400",
        "Beds": "84",
        "Website": "https://encompasshealth.com" ,"Image": r1
    },
    {
        "Hospital Name": "Fillmore Community Medical Center",
        "City": "Fillmore",
        "Address": "674 UT-99, Fillmore, UT 84631",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 743-5591",
        "Beds": "18",
        "Website": "https://intermountainhealthcare.org/locations/fillmore-community-hospital" ,"Image": q1
    },
    {
        "Hospital Name": "Garfield Memorial Hospital",
        "City": "Panguitch",
        "Address": "200 N 400 E St, Panguitch, UT 84759",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 676-8811",
        "Beds": "41",
        "Website": "https://intermountainhealthcare.org/locations/garfield-memorial-hospital" ,"Image": p1
    },
    {
        "Hospital Name": "Gunnison Valley Hospital",
        "City": "Gunnison",
        "Address": "64 E 100 N St, Gunnison, UT 84634",
        "Owner": "Independent",
        "Phone No:": "(435)-528-7246",
        "Beds": "24",
        "Website": "https://www.gvhospital.org" ,"Image": n1
    },
    {
        "Hospital Name": "Heber Valley Medical Center",
        "City": "Heber",
        "Address": "454 East Medical Way, Heber City, UT 84032",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 654-2500",
        "Beds": "19",
        "Website": "https://intermountainhealthcare.org/locations/heber-valley-hospital" ,"Image": m1
    },
    {
        "Hospital Name": "Huntsman Cancer Institute",
        "City": "Salt Lake City",
        "Address": "2000 Cir of Hope Dr, Salt Lake City, UT 84112",
        "Owner": "University of Utah",
        "Phone No:": "(801)-587-7000",
        "Beds": "48",
        "Website": "https://healthcare.utah.edu/huntsmancancerinstitute/" ,"Image": l1
    },
    {
        "Hospital Name": "Huntsman Mental Health Institute",
        "City": "Salt Lake City",
        "Address": "501 Chipeta Way, Salt Lake City, UT 84108",
        "Owner": "University of Utah",
        "Phone No:": "(801) 583-2500",
        "Beds": "170",
        "Website": "https://healthcare.utah.edu/locations/hmhi" ,"Image": k1
    },
    {
        "Hospital Name": "George E. Wahlen Department of Veterans Affairs Medical Center",
        "City": "Salt Lake City",
        "Address": "500 Foothill Dr, Salt Lake City, UT 84148",
        "Owner": "University of Utah",
        "Phone No:": "(801)-582-1565",
        "Beds": "121",
        "Website": "https://www.va.gov/salt-lake-city-health-care/" ,"Image": o1
    },
    {
        "Hospital Name": "Intermountain Medical Center",
        "City": "Murray",
        "Address": "5121 Cottonwood St, Murray, UT 84107",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801)-507-7000",
        "Beds": "504",
        "Website": "https://intermountainhealthcare.org/locations/intermountain-medical-center" ,"Image": j1
    },
    {
        "Hospital Name": "Kane County Human Resource SSD (Hospital)",
        "City": "Kanab",
        "Address": "355 NORTH MAIN KANAB, UT 84741",
        "Owner": "Special Service District of the State of Utah",
        "Phone No:": "(435)-644-5811",
        "Beds": "38",
        "Website": "https://kchosp.net/announcements/2017/kane-county-human-resources-special-service-district/" ,"Image": i1
    },
    {
        "Hospital Name": "Lakeview Hospital",
        "City": "Bountiful",
        "Address": "630 Medical Dr, Bountiful, UT 84010",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(801) 299-2200",
        "Beds": "128",
        "Website": "https://www.mountainstar.com/locations/lakeview-hospital" ,"Image": h1
    },
    {
        "Hospital Name": "Layton Hospital",
        "City": "Layton",
        "Address": "201 W Layton Pkwy, Layton, UT 84041",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 543-6000",
        "Beds": "43",
        "Website": "https://intermountainhealthcare.org/locations/layton-hospital" ,"Image": g1
    },
    {
        "Hospital Name": "LDS Hospital",
        "City": "Salt Lake City",
        "Address": "8th Ave, C St E, Salt Lake City, UT 84143",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 408-1100",
        "Beds": "262",
        "Website": "https://intermountainhealthcare.org/locations/lds-hospital" ,"Image": f1
    },
    {
        "Hospital Name": "Logan Regional Hospital",
        "City": "Logan",
        "Address": "1400 N 500 E, Logan, UT 84341",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435)-716-2240",
        "Beds": "148",
        "Website": "https://intermountainhealthcare.org/locations/logan-regional-hospital" ,"Image": e1
    },
    {
        "Hospital Name": "Lone Peak Hospital",
        "City": "Draper",
        "Address": "11925 State St, Draper, UT 84020",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(801) 545-8000",
        "Beds": "61",
        "Website": "https://www.mountainstar.com/locations/lone-peak-hospital" ,"Image": d1
    },
    {
        "Hospital Name": "Marian Center/St. Joseph's Villa of SLC",
        "City": "Salt Lake City",
        "Address": "451 Bishop Federal Ln, Salt Lake City, UT 84115",
        "Owner": "Independent (St Joseph's)",
        "Phone No:": "(801)-468-6856",
        "Beds": "14",
        "Website": "https://themariancenter.net" ,"Image": c1
    },
    {
        "Hospital Name": "McKay-Dee Hospital",
        "City": "Ogden",
        "Address": "4401 Harrison Blvd, Ogden, UT 84403",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 627-2800",
        "Beds": "310",
        "Website": "https://intermountainhealthcare.org/locations/mckay-dee-hospital" ,"Image": b1
    },
    {
        "Hospital Name": "Moab Regional Hospital",
        "City": "Moab",
        "Address": "450 Williams Way, Moab, UT 84532",
        "Owner": "Independent",
        "Phone No:": "(435)-210-6860",
        "Beds": "17",
        "Website": "https://mrhmoab.org" ,"Image": a1
    },
    {
        "Hospital Name": "Mountain View Hospital - Payson",
        "City": "Payson",
        "Address": "1000 E 100 N, Payson, UT 84651",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(801) 465-9201",
        "Beds": "124",
        "Website": "https://www.mountainstar.com/locations/mountain-view-hospital-payson" ,"Image": z
    },
    {
        "Hospital Name": "Mountain West Medical Center",
        "City": "Tooele",
        "Address": "2055 N Main St, Tooele, UT 84074",
        "Owner": "Independent (Quorum Health)",
        "Phone No:": "(435) 843-3600",
        "Beds": "44",
        "Website": "https://mountainwestmc.com" ,"Image": y
    },
    {
        "Hospital Name": "Northern Utah Rehabilitation Hospital",
        "City": "South Ogden",
        "Address": "5825 Harrison Blvd, South Ogden, UT 84403",
        "Owner": "Independent (Ernest Health)",
        "Phone No:": "(801) 475-5254",
        "Beds": "40",
        "Website": "https://nurh.ernesthealth.com" ,"Image": x
    },
    {
        "Hospital Name": "Ogden Regional Medical Center",
        "City": "Ogden",
        "Address": "5475 S 500 E, Ogden, UT 84405",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(801) 479-2111",
        "Beds": "238",
        "Website": "https://www.mountainstar.com/locations/ogden-regional-medical-center" ,"Image": w
    },
    {
        "Hospital Name": "Orem Community Hospital",
        "City": "Orem",
        "Address": "331 N 400 W St, Orem, UT 84057",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 224-4080",
        "Beds": "18",
        "Website": "https://intermountainhealthcare.org/locations/orem-community-hospital" ,"Image": v
    },
    {
        "Hospital Name": "Park City Hospital",
        "City": "Park City",
        "Address": "900 Round Valley Dr, Park City, UT 84060",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 658-7000",
        "Beds": "37",
        "Website": "https://intermountainhealthcare.org/locations/park-city-hospital" ,"Image": u
    },
    {
        "Hospital Name": "Primary Children's Hospital",
        "City": "Salt Lake City",
        "Address": "100 Mario Capecchi Dr, Salt Lake City, UT 84113",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 662-1000",
        "Beds": "289",
        "Website": "https://intermountainhealthcare.org/locations/primary-childrens-salt-lake-city" ,"Image": t
    },
    {
        "Hospital Name": "Riverton Hospital",
        "City": "Riverton",
        "Address": "3741 W 12600 S, Riverton, UT 84065",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 285-4000",
        "Beds": "97",
        "Website": "https://intermountainhealthcare.org/locations/riverton-hospital" ,"Image": s
    },
    {
        "Hospital Name": "St. George Regional Hospital",
        "City": "St. George",
        "Address": "1380 E Medical Center Dr, St. George, UT 84790",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 251-1000",
        "Beds": "284",
        "Website": "https://intermountainhealthcare.org/locations/st-george-regional-hospital" ,"Image": j
    },
    {
        "Hospital Name": "Saint Mark's Hospital",
        "City": "Salt Lake City",
        "Address": "1200 E 3900 S, Millcreek, UT 84124",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(801) 268-7111",
        "Beds": "325",
        "Website": "https://www.mountainstar.com/locations/st-marks-hospital" ,"Image": r
    },
    {
        "Hospital Name": "Salt Lake Behavioral Health Hospital",
        "City": "South Salt Lake",
        "Address": "3802 S 700 E, Salt Lake City, UT 84106",
        "Owner": "Universal Health Services",
        "Phone No:": "(801) 264-6000",
        "Beds": "118",
        "Website": "https://saltlakebehavioralhealth.com" ,"Image": q
    },
    {
        "Hospital Name": "Salt Lake Specialty Hospital",
        "City": "Murray",
        "Address": "4252 Birkhill Blvd, Murray, UT 84107",
        "Owner": "Salt Lake Specialty Hospital",
        "Phone No:": "(385) 425-0050",
        "Beds": "38",
        "Website": "https://slspecialty.org" ,"Image": p
    },
    {
        "Hospital Name": "San Juan Hospital",
        "City": "Monticello",
        "Address": "380 W 100 N, Monticello, UT 84535",
        "Owner": "Independent",
        "Phone No:": "(435)-587-2116",
        "Beds": "198",
        "Website": "https://sanjuanhealth.org" ,"Image": o
    },
    {
        "Hospital Name": "Sanpete Valley Hospital",
        "City": "Mt. Pleasant",
        "Address": "1100 S Medical Dr, Mt Pleasant, UT 84647",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 462-2441",
        "Beds": "18",
        "Website": "https://intermountainhealthcare.org/locations/sanpete-valley-hospital" ,"Image": n
    },
    {
        "Hospital Name": "Sevier Valley Hospital",
        "City": "Richfield",
        "Address": "1000 N Main St, Richfield, UT 84701",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435) 893-4100",
        "Beds": "24",
        "Website": "https://intermountainhealthcare.org/locations/sevier-valley-hospital" ,"Image": m
    },
    {
        "Hospital Name": "Shriners Hospitals for Children",
        "City": "Salt Lake City",
        "Address": "1275 E Fairfax Rd, Salt Lake City, UT 84103",
        "Owner": "Shriners Hospitals for Children",
        "Phone No:": "(801)-536-3500",
        "Beds": "40",
        "Website": "https://www.shrinerschildrens.org/en/locations/salt-lake-city" ,"Image": l
    },
    {
        "Hospital Name": "Spanish Fork Hospital",
        "City": "Spanish Fork",
        "Address": "765 E Mkt Pl Dr, Spanish Fork, UT 84660",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(385) 344-5000",
        "Beds": "33",
        "Website": "https://intermountainhealthcare.org/locations/spanish-fork-hospital" ,"Image": k
    },
    {
        "Hospital Name": "The Orthopedic Specialty Clinic at TOSH",
        "City": "Murray",
        "Address": "5848 Fashion Boulevard, Suite 110. Murray, UT 84107",
        "Owner": "Independent",
        "Phone No:": "(801) 314-4188",
        "Beds": "14",
        "Website": "https://www.toshmd.com" ,"Image": i
    },
    {
        "Hospital Name": "Timpanogos Regional Hospital",
        "City": "Orem",
        "Address": "750 W 800 N, Orem, UT 84057",
        "Owner": "MountainStar Healthcare",
        "Phone No:": "(801) 714-6000",
        "Beds": "122",
        "Website": "https://www.mountainstar.com/locations/timpanogos-regional-hospital" ,"Image": h
    },
    {
        "Hospital Name": "Uintah Basin Medical Center",
        "City": "Roosevelt",
        "Address": "250 W 300 N, Roosevelt, UT 84066",
        "Owner": "Independent",
        "Phone No:": "(435)-722-4691",
        "Beds": "42",
        "Website": "https://ubh.org" ,"Image": g
    },
    {
        "Hospital Name": "University of Utah Hospital",
        "City": "Salt Lake City",
        "Address": "50 Medical Dr N, Salt Lake City, UT 84132",
        "Owner": "University of Utah",
        "Phone No:": "(801) 581-2121",
        "Beds": "425",
        "Website": "https://healthcare.utah.edu/locations/hospital" ,"Image": f
    },
    {
        "Hospital Name": "Utah State Hospital",
        "City": "Provo",
        "Address": "1300 E Center St, Provo, UT 84606",
        "Owner": "Utah Department of Human Services",
        "Phone No:": "(801) 344-4400",
        "Beds": "324",
        "Website": "https://ush.utah.gov" ,"Image": e
    },
    {
        "Hospital Name": "Utah Valley Hospital",
        "City": "Provo",
        "Address": "1034 No 500 W, Provo, UT 84604",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(801) 373-7850",
        "Beds": "395",
        "Website": "https://intermountainhealthcare.org/locations/utah-valley-hospital" ,"Image": d
    },
    {
        "Hospital Name": "Utah Valley Specialty Hospital",
        "City": "Provo",
        "Address": "306 W River Bend Ln, Provo, UT 84604",
        "Owner": "Ernest Health",
        "Phone No:": "(801) 226-8880",
        "Beds": "40",
        "Website": "https://uvsh.ernesthealth.com" ,"Image": c
    },
    {
        "Hospital Name": "Valley View Medical Center",
        "City": "Cedar City",
        "Address": "1333 N Main St Suite 4/5, Cedar City, UT 84721",
        "Owner": "Intermountain Healthcare",
        "Phone No:": "(435)-868-5566",
        "Beds": "48",
        "Website": "https://intermountainhealthcare.org/locations/valley-view-family-medicine" ,"Image": b
    },
    {
        "Hospital Name": "Western Peaks Specialty Hospital",
        "City": "Bountiful",
        "Address": "485 500 S, Bountiful, UT 84010",
        "Owner": "Community",
        "Phone No:": "(801)-299-4800",
        "Beds": "43",
        "Website": "https://www.sdch.com/locations/western-peaks-specialty-hospital/",
        "Image": a
    }
]

  return (
    <div>
      {/* <div>
     <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      State Parks in Utah
      <div className='mt-2 font-bold text-2xl'>(46 State Parks)</div>
      </div> */}

      <div
        className="min-h-screen mt-[1rem]  flex flex-col items-center justify-center"
        style={{ backgroundColor: "rgb(210, 234, 255)" }}
      >
        <div className="overflow-x-auto w-full mb-[3rem]">
          {/* <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      National Parks in Utah
      <div className='mt-2 font-bold text-2xl'>(5 National Parks)</div> */}
          <div
            className=" bg-[#d2eaff] flex flex-col items-center justify-center"
            aria-label="Utah aquatic scenery"
            style={{
              backgroundImage: `url(${statePark})`, // Replace with your image path
              backgroundSize: "cover", // Ensures the image covers the entire container
              backgroundPosition: "center", // Centers the image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
              width: "100%", // Ensures the container takes full width of the screen
              height: "45vh", // Makes the container span the full height of the viewport
            }}
          >
            <h2
              className="text-white text-center px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl mt-7"
              style={{
                color: "white",
                textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)", // Makes the text stand out
                lineHeight: "1.2", // Adjusts line spacing
              }}
            >
              Utah Hospital
            </h2>
            <div className="mt-2 font-bold text-2xl" style={{ color: "#fff" }}>
              (64 Hospitals)
            </div>

            <span
              className="text-center px-4 text-lg sm:text-2xl md:text-3xl lg:text-4xl mt-4"
              style={{
                color: "#fff",
                fontFamily: "SignPainter", // Use the SignPainter font
                textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Adds subtle shadow
                lineHeight: "1.5", // Adjusts line spacing
              }}
            >
              Explore Utah By The Numbers
            </span>
          </div>
        </div>

        <div className="overflow-x-auto w-[95%] bg-white shadow-md rounded-lg p-4">
          <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
            <thead>
              <tr>
                {/* <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  S.no
                </th> */}
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Hospital Name
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  City
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Address
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">
                  Owner
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  Contact No
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  Beds
                </th>
                <th className="border border-gray-300 bg-gray-300 px-4 py-2">
                  Website
                </th>
              </tr>
            </thead>
            <tbody>
              {park.map((park) => (
                <tr key={park.sn} className="hover:bg-gray-100">
                  {/* <td className="border border-gray-300 px-4 py-2 text-center ">
                    {park["S.no"]}
                  </td> */}

                  {/* <td className="border border-gray-300 px-4 py-2 text-center"> */}
                  {/* <td className="border border-gray-300 px-4 py-2 flex text-center items-center">
                  <img
                      src={park.Image}
                      className="w-[6rem] h-auto mx-[3rem]"
                    ></img>
                    {park["Hospital Name"]}
                  </td> */}
                  <td className="border border-gray-300 px-4 py-2 flex flex-col items-center text-center">
  <img src={park.Image} className="w-[6rem] h-auto mb-2" />
  <span className="whitespace-normal break-words">{park["Hospital Name"]}</span>
</td>

                  <td className="border border-gray-300 px-4 py-2">
                    {park.City}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {park.Address}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {park.Owner}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {park["Phone No:"]}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {park.Beds}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center ">
                    <a
                      href={park.Website}
                      target="_blank"
                      rel=" noopener noreferrer"
                    >
                      {park.Website}
                    </a>
                  </td>

                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Hospital;
