import React from 'react'

export default function StateCredit() {

    const schoolData = 
    [
        {
            "Name": "Alpine Credit Union",
            "Address": "1510 N STATE ST, OREM, UT 84057",
            "President": "Paul Atkinson, President",
            "Contact No:": "801-225-0256"
        },
        {
            "Name": "Beckstrand & Associates Emp. CU",
            "Address": "6322 S 3000 E #160, SALT LAKE CITY, UT 84121",
            "President": "Julie Jackson, President",
            "Contact No:": "801-944-7722"
        },
        {
            "Name": "Education First Credit Union",
            "Address": "1250 COUNTRY HILL DR, OGDEN, UT 84403",
            "President": "Kent Greenfield, President",
            "Contact No:": "801-392-4877"
        },
        {
            "Name": "Firefighters Credit Union",
            "Address": "124 W 1400 S STE 101, SALT LAKE CITY, UT 84115-5227",
            "President": "Thomas R. Gourdin, President",
            "Contact No:": "801-487-3219"
        },
        {
            "Name": "Freedom Credit Union",
            "Address": "815 N FREEDOM BLVD, PROVO, UT 84604",
            "President": "Ken Payne, President",
            "Contact No:": "801-375-2120"
        },
        {
            "Name": "Hi-Land Credit Union",
            "Address": "5366 S EXECUTIVE PK DR, MURRAY, UT 84117",
            "President": "Misty Miller, President",
            "Contact No:": "801-261-8909"
        },
        {
            "Name": "HollyFrontier Employees Cr. Union",
            "Address": "876 W HERITAGE POINT LN, PO BOX 666, BOUNTIFUL, UT 84011",
            "President": "Meagan Nattress, President",
            "Contact No:": "801-298-1024"
        },
        {
            "Name": "Kings Peak Credit Union",
            "Address": "180 N 300 E, ROOSEVELT, UT 84066-2005",
            "President": "Ryan Reynolds, President",
            "Contact No:": "435-722-3795"
        },
        {
            "Name": "Members First Credit Union",
            "Address": "120 E 1000 S, PO BOX 657, BRIGHAM CITY, UT 84302-0657",
            "President": "Darryn Hodgson, President",
            "Contact No:": "435-723-5231"
        },
        {
            "Name": "Millard County Credit Union",
            "Address": "109 S 300 E, DELTA, UT 84624",
            "President": "Scott Lemmon, President",
            "Contact No:": "800-845-5824"
        },
        {
            "Name": "National J.A.C.L. Credit Union",
            "Address": "3776 S HIGHLAND DR, SALT LAKE CITY, UT 84106",
            "President": "Dean Hirabayashi, President",
            "Contact No:": "801-424-5225"
        },
        {
            "Name": "Nebo Credit Union",
            "Address": "730 E 300 S, SPRINGVILLE, UT 84663",
            "President": "Dale Phelps, President",
            "Contact No:": "801-491-3691"
        },
        {
            "Name": "P & S Credit Union",
            "Address": "2250 S REDWOOD RD STE 6, SALT LAKE CITY, UT 84119",
            "President": "Tammy Morgan, President",
            "Contact No:": "801-973-0575"
        },
        {
            "Name": "Pacific Horizon Credit Union",
            "Address": "96 E CENTER, PO BOX 166, SPRINGVILLE, UT 84663-0166",
            "President": "Mike Clayson, President",
            "Contact No:": "801-489-3605"
        },
        {
            "Name": "Presto Lewiston Employees Cr. Union",
            "Address": "1110 E 200 S, LEWISTON, UT 84320",
            "President": "Gaylene Stone, President",
            "Contact No:": "435-258-2431"
        },
        {
            "Name": "Provo Police & Fire Department CU",
            "Address": "250 W CTR ST STE 114, PO BOX 1402, PROVO, UT 84603",
            "President": "Sheldon Lindsay, President",
            "Contact No:": "801-377-5634"
        },
        {
            "Name": "S E A Credit Union",
            "Address": "275 E 800 N, PO BOX 355, RICHFIELD, UT 84701-0355",
            "President": "Brent Prisbrey, President",
            "Contact No:": "435-201-2085"
        },
        {
            "Name": "San Juan Credit Union",
            "Address": "792 S 200 W, BLANDING, UT 84511-0199",
            "President": "Leah Lyman, President",
            "Contact No:": "435-678-2124"
        },
        {
            "Name": "South Sanpete Credit Union",
            "Address": "39 S MAIN, MANTI, UT 84642",
            "President": "Jake Hill, President",
            "Contact No:": "435-835-2261"
        },
        {
            "Name": "Uintah Credit Union",
            "Address": "783 W HWY 40, PO BOX 805, VERNAL, UT 84078-0805",
            "President": "Dan Olsen, President",
            "Contact No:": "435-789-8353"
        },
        {
            "Name": "Utah Heritage Credit Union",
            "Address": "84 W MAIN, PO BOX 50, MORONI, UT 84646",
            "President": "Tami Olsen, President",
            "Contact No:": "435-436-8288"
        },
        {
            "Name": "Utah Power Credit Union",
            "Address": "957 E 6600 S, MURRAY, UT 84121",
            "President": "Ryan Pollick, President",
            "Contact No:": "801-708-8900"
        }
    ]
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        State Credit Banks in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.Name}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school['President']}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
