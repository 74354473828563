import React, { useRef, useEffect } from "react";
import "../styles/AboutUs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/Aerospace.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileScreenButton,
  faCogs,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import CsAndItFaqSecton from "./CsAndItFaqSection";

const computer = "/img/computer.jpg";

export default function ComputerScience() {
  const navbarRef = useRef(null);

  // Close the navbar on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse =
          navbarRef.current.querySelector(".navbar-collapse");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show"); // Close the navbar
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper flex flex-col min-h-screen">
      {/* Main Content */}
      <div
        className="flex-grow bg-[#d2eaff] pt-[13vh] mt-[10vh] relative"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${computer})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text-overlay text-white px-4 py-8 flex flex-col justify-center items-center h-full">
          <h2 className="title text-[#ffc107]">
            <i className=" text-[#ffc107]"></i>{" "}
            <h1>
              <span className="text-[#ffc107]">Computer Science and IT</span>
            </h1>
          </h2>
        </div>
      </div>

      <div className="bg-[#d2eaff] py-[6rem] mt-[-12rem]">
        <div className="container bg-[#d2eaff]">
          <div className="content">
            <h2 className="content-title">
              {/* <FontAwesomeIcon icon={faIndustry} />  */}
              <span className="fas fa-computer"></span>
              <span>Computer Science and IT</span>
            </h2>
            <p className="content-highlight">
              <FontAwesomeIcon icon={faMobileScreenButton} /> Involves working
              with Technology
            </p>
            <hr className="content-separator" />
            <p className="content-description">
              <FontAwesomeIcon icon={faCogs} /> Utah has many schools that offer
              computer science and information technology (CS and IT) programs,
              including the University of Utah, Utah Valley University, and Utah
              Tech University. The program includes courses in software
              engineering, discrete mathematics, object-oriented programming,
              algorithms and data structures, and computer organization.
            </p>
            <p className="content-description">
              <FontAwesomeIcon icon={faLightbulb} /> Dive into our comprehensive
              reports and insights to uncover how Computer Science And IT is
              propelling innovation and shaping the future in Utah.
            </p>
            <div className="content-call-to-action">
              <button
                className="bg-[#F0A500] "
                onClick={() => {
                  window.scrollTo({
                    top: 1000,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                View Detailed Reports
              </button>
            </div>
          </div>
        </div>
        <CsAndItFaqSecton />
      </div>
    </div>
  );
}
