// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Table } from 'react-bootstrap';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const TableNo35 = () => {
//   // Data for the table and chart
//   const employmentData = [
//     { ethnicityLabel: 'All Ethnicities', emp2018: 134044, emp2022: 153477, emp2023: 154153 },
//     { ethnicityLabel: 'Hispanic or Latino', emp2018: 25385, emp2022: 30655, emp2023: 31600 },
//     { ethnicityLabel: 'Not Hispanic or Latino', emp2018: 108486, emp2022: 122637, emp2023: 122389 },
//   ];

//   // Chart data
//   const chartData = {
//     labels: employmentData.map(item => item.ethnicityLabel),
//     datasets: [
//       {
//         label: 'Emp. Count (2018)',
//         data: employmentData.map(item => item.emp2018),
//         backgroundColor: 'rgba(75, 192, 192, 0.6)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Emp. Count (2022)',
//         data: employmentData.map(item => item.emp2022),
//         backgroundColor: 'rgba(54, 162, 235, 0.6)',
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1,
//       },
//       {
//         label: 'Emp. Count (2023)',
//         data: employmentData.map(item => item.emp2023),
//         backgroundColor: 'rgba(153, 102, 255, 0.6)',
//         borderColor: 'rgba(153, 102, 255, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//         ticks: {
//           callback: (value) => value.toLocaleString(), // Format Y-axis with commas
//         },
//       },
//     },
//     plugins: {
//       tooltip: {
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw.toLocaleString()}`, // Show commas in tooltip values
//         },
//       },
//     },
//   };

//   return (
//     <div className="container">
//       <h2>Employment Count by Ethnicity (2018, 2022, 2023)</h2>

//       {/* Responsive Table */}
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Ethnicity Label</th>
//             <th>Emp. Count (2018)</th>
//             <th>Emp. Count (2022)</th>
//             <th>Emp. Count (2023)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {employmentData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.ethnicityLabel}</td>
//               <td>{item.emp2018.toLocaleString()}</td>
//               <td>{item.emp2022.toLocaleString()}</td>
//               <td>{item.emp2023.toLocaleString()}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Bar Chart */}
//       <div
//           style={{
//             overflowX: "auto", // Enables horizontal scroll
//             display: "flex",
//             justifyContent: "center",
//             width: "100%", // Takes full width
//           }}
//         >
//           <div
//             style={{
//               minWidth: "600px", // Minimum width to enable scroll
//               width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
//               height: "400px",
//             }}
//           >
//         <Bar data={chartData} options={options} />
//       </div>
//       </div>
//     </div>
//   );
// };

// export default TableNo35;

import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

const TableNo35 = () => {
  const pdfRef = useRef(); // Ref for capturing PDF content

  const employmentData = [
    {
      ethnicityLabel: "All Ethnicities",
      emp2018: 134044,
      emp2022: 153477,
      emp2023: 154153,
    },
    {
      ethnicityLabel: "Hispanic or Latino",
      emp2018: 25385,
      emp2022: 30655,
      emp2023: 31600,
    },
    {
      ethnicityLabel: "Not Hispanic or Latino",
      emp2018: 108486,
      emp2022: 122637,
      emp2023: 122389,
    },
  ];

  const chartData = {
    labels: employmentData.map((item) => item.ethnicityLabel),
    datasets: [
      {
        label: "Emp. Count (2018)",
        data: employmentData.map((item) => item.emp2018),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Emp. Count (2022)",
        data: employmentData.map((item) => item.emp2022),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Emp. Count (2023)",
        data: employmentData.map((item) => item.emp2023),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label}: ${context.raw.toLocaleString()}`,
        },
      },
    },
  };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1100px";

    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(utahData, "PNG", 60, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 95, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 200, 35);

      const headerHeight = 38;
      pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);

      pdf.line(10, 260, 200, 260);
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 33, 265, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.addImage(bodex, "PNG", 68, 269, 44, 10);
      pdf.text("using", 112, 275);
      pdf.addImage(kpi, "PNG", 125, 265, 20, 20);
      pdf.text("20250211.v1.113", 148, 275);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      pdf.save("employment-count-by-ethnicity.pdf");
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container">
      <h2>Employment Count by Ethnicity (2018, 2022, 2023)</h2>
      <div ref={pdfRef}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Ethnicity Label</th>
              <th>Emp. Count (2018)</th>
              <th>Emp. Count (2022)</th>
              <th>Emp. Count (2023)</th>
            </tr>
          </thead>
          <tbody>
            {employmentData.map((item, index) => (
              <tr key={index}>
                <td>{item.ethnicityLabel}</td>
                <td>{item.emp2018.toLocaleString()}</td>
                <td>{item.emp2022.toLocaleString()}</td>
                <td>{item.emp2023.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* <div style={{ overflowX: "auto", display: "flex", justifyContent: "center", width: "100%" }}>
          <div style={{ minWidth: "600px", width: "1000px", height: "400px" }}> */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default TableNo35;
