import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
// import * as React from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonBase } from "@mui/material";

const beaver = "/img/Beaver-County-Building.jpg";
const beavergeography = "/img/beaver-valley.jpg";
const beaverDemography = "/img/beaver.jpeg";
const beaverEconomy = "/img/Beaver-Economy.jpg";
const beaverhighlight = "/img/Beaver-County.png";

const cardData = [
  {
    img: beaver,
    tag: "About Beaver",
    title: "Beaver County: Rich History and Natural Abundance",
    description:
      "Beaver County is a county in west central Utah, United States. As of the 2020 United States Census, the population was 7,072,up from the 2010 figure of 6,629.Its county seat and largest city is Beaver. The county was named for the abundance of beaver in the area.",
    authors: [
      { name: "", avatar: "" },
      { name: "", avatar: "" },
    ],
  },
  {
    img: beavergeography,
    tag: "Geography",
    title: "Geography and Elevation of Beaver City",
    description:
      "Beaver County lies on the west side of Utah. Its west border abuts the east border of the state of Nevada. The west part of the county consists of low rolling hills punctuated by isolated mountains. The east edge of the county runs to the crest of a north–south-running mountain ridge.",
    authors: [{ name: "", avatar: "/static/images/avatar/6.jpg" }],
  },
  {
    img: beaverDemography,
    tag: " Demography",
    title: "Population Growth and Demographics of Beaver County",
    description:
      "The population of Beaver County, Utah in 2022 was 7,327, 10.1% up from the 6,657 who lived there in 2010. For comparison, the US population grew 7.7% and Utahs population grew 21.8% during that period.",
    authors: [{ name: "", avatar: "/static/images/avatar/7.jpg" }],
  },
  {
    img: "https://picsum.photos/800/450?random=4",
    tag: "History",
    title: "History of Beaver County, Utah: Formation and Territorial Changes",
    description:
      "Explorers of European descent first visited present-day Beaver County in the 1776 Domínguez-Escalante Expedition. The proposed territory of Deseret (soon changed to Utah Territory) began with the arrival of Mormon pioneers in 1847. After the immediate Great Salt Lake City area was settled, settlers moved into more outlying areas, including the future Beaver County area. The county was created by the Utah territorial legislature from a section of Iron County on January 5, 1856, before the settlement of Beaver town was founded later that year. ",
    authors: [{ name: "", avatar: "/static/images/avatar/3.jpg" }],
  },
  {
    img: "https://picsum.photos/800/450?random=45",
    tag: "Minning",
    title:
      " Mining History of Beaver County: From Early Lead to the Horn Silver Boom",
    description:
      "In 1858 the Lincoln Mine northwest of Minersville may have been the first mine opened in Utah. The mine produced lead, which was smelted and shipped to Salt Lake City to make ammunition—useful for Mormons who thought they would have to fight Johnston’s Army. ",
    authors: [
      { name: "", avatar: "/static/images/avatar/4.jpg" },
      { name: "", avatar: "/static/images/avatar/5.jpg" },
    ],
  },
  {
    img: beaverEconomy,
    tag: " Economy",
    title: "Economic Development and Industry in Beaver County",
    description:
      "In Beaver City, the Beaver Woolen Mills turned out woolen goods from the 1870s to the turn of the century. The mill sold many of its products, especially blankets, to the miners in Frisco. ",
    authors: [{ name: "", avatar: "/static/images/avatar/2.jpg" }],
  },
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  height: "100%",
  backgroundColor: (theme.vars || theme).palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const SyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

// function Author({ authors }) {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         gap: 2,
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         padding: '16px',
//       }}
//     >
//       <Box
//         sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
//       >
//         <AvatarGroup max={3}>
//           {authors.map((author, index) => (
//             <Avatar
//               key={index}
//               alt={author.name}
//               src={author.avatar}
//               sx={{ width: 24, height: 24 }}
//             />
//           ))}
//         </AvatarGroup>
//         <Typography variant="caption">
//           {authors.map((author) => author.name).join(', ')}
//         </Typography>
//       </Box>
//       <Typography variant="caption">February 6, 2025</Typography>
//     </Box>
//   );
// }

// Author.propTypes = {
//   authors: PropTypes.arrayOf(
//     PropTypes.shape({
//       avatar: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
// };

export function Search() {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      {/* <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'search',
        }}
      /> */}
    </FormControl>
  );
}

export default function MainContent() {
  //const [open, setOpen] = React.useState(false);
  //const [openIndex, setOpenIndex] = React.useState(null);
  const [openDialogIndex, setOpenDialogIndex] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };
  // const handleClickOpen = (index, scrollType) => () => {
  //   setOpenIndex(index);
  //   setScroll(scrollType);
  // };
  const handleClickOpen = (index, scrollType) => () => {
    setOpenDialogIndex(index);
    setScroll(scrollType);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClose = () => {
  //   setOpenIndex(null);
  // };
  const handleClose = () => {
    setOpenDialogIndex(null);
  };

  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDialogIndex !== null) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialogIndex]);
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info("You clicked the filter chip.");
    window.scroll({
      top: 200,
      left: 100,
      behavior: "smooth",
    });
  };
  const handleClick1 = () => {
    console.info("You clicked the filter chip.");
    window.scroll({
      top: 800,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div>
          <Typography variant="h1" gutterBottom sx={{ color: "#fff" }}>
            Beaver County
          </Typography>
          <Typography sx={{ color: "#fff" }}>
            Your Gateway to Beaver's Trends & Analytics
          </Typography>
        </div>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2, // space between text and image
            color: "#fff",
          }}
        >
          <Typography sx={{ fontSize: "1.3rem", flex: 1 }}>
            <span className="text-2xl font-bold italic">
              Population Demography
            </span>
            <p>
              According to the 2020 United States census and 2020 American
              Community Survey, there were 7,072 people in Beaver County with a
              population density of 2.7 people per square mile (1.1/km2). Among
              non-Hispanic or Latino people, the racial makeup was 5,717 (80.8%)
              White, 23 (0.3%) African American, 70 (1.0%) Native American, 32
              (0.5%) Asian, 21 (0.3%) Pacific Islander, 8 (0.1%) from other
              races, and 135 (1.9%) from two or more races. 1,066 (15.1%) people
              were Hispanic or Latino. There were 3,758 (53.14%) males and 3,314
              (46.86%) females, and the population distribution by age was 2,162
              (30.6%) under the age of 18, 3,862 (54.6%) from 18 to 64, and
              1,048 (14.8%) who were at least 65 years old. The median age was
              34.4 years.
            </p>
            <span className="text-2xl font-bold italic">At a Glance</span>
            <p>
              Explorers of European descent first visited present-day Beaver
              County in the 1776 Domínguez-Escalante Expedition. The proposed
              territory of Deseret (soon changed to Utah Territory) began with
              the arrival of Mormon pioneers in 1847. After the immediate Great
              Salt Lake City area was settled, settlers moved into more outlying
              areas, including the future Beaver County area. The county was
              created by the Utah territorial legislature from a section of Iron
              County on January 5, 1856, before the settlement of Beaver town
              was founded later that year. The county was named for the animal,
              which was plentiful there.[5] The county boundary as delineated by
              that act included areas in present-day Colorado and Nevada.
            </p>
          </Typography>
          <Box
            component="img"
            src={beaverhighlight}
            alt="Historic Courthouse"
            sx={{
              width: { xs: "100px", md: "200px" }, // Responsive width
              height: "auto",
              borderRadius: 2,
              boxShadow: 3, // Adds subtle shadow
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          <Search />
          {/* <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "start", md: "center" },
            gap: 4,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              gap: 3,
              overflow: "auto",
            }}
          >
            <Chip onClick={handleClick} size="medium" label="About Us" />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Geography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label=" Demography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label=" Economy"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="History"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="Minning"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "row",
              gap: 1,
              width: { xs: "100%", md: "fit-content" },
              overflow: "auto",
            }}
          >
            <Search />
            {/* <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton> */}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(0, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(0)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 0 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[0].img}
                  sx={{
                    aspectRatio: "16 / 9",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />

                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[0].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[0].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[0].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Author authors={cardData[0].authors} /> */}
                {/* <Button onClick={handleClickOpen('paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
                {/* <Button onClick={handleClickOpen(0, 'paper')} sx={{ alignSelf: 'center' }}>
  Learn More...
</Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>

          <Dialog
            open={openDialogIndex === 0}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Beaver County: Rich History and Natural Abundance
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')} */}
                Beaver County is a county in west central Utah, United States.
                As of the 2020 United States Census, the population was 7,072,up
                from the 2010 figure of 6,629.Its county seat and largest city
                is Beaver. The county was named for the abundance of beaver in
                the area.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(1, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(1)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 1 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[1].img}
                  aspect-ratio="16 / 9"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[1].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[1].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[1].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[1].authors} /> */}
                {/* <Button onClick={handleClickOpen(1,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 1}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Geography and Elevation of Beaver City
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <p>
                  Beaver County lies on the west side of Utah. Its west border
                  abuts the east border of the state of Nevada. The west part of
                  the county consists of low rolling hills punctuated by
                  isolated mountains. The east edge of the county runs to the
                  crest of a north–south-running mountain ridge. The terrain
                  slopes to the west and north; its highest point is a mountain
                  crest on its east border, at 12,011 ft (3,661 m) ASL. The
                  county has a total area of 2,592 square miles (6,710 km2), of
                  which 2,590 square miles (6,700 km2) is land and 2.1 square
                  miles (5.4 km2) (0.08%) is water.
                </p>
                <p>
                  The Tushar Mountains lies on the eastern boundary of the
                  county, reaching to 12,000 feet (3,700 m) in elevation and
                  providing water for the farming communities of Beaver and
                  Manderfield. To the west, barren desert valleys typify the
                  scenery, separated by mountains lightly forested with
                  junipers.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(2, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(2)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 2 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[2].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[2].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[2].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[2].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[2].authors} /> */}
                {/* <Button onClick={handleClickOpen(2,'paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 2}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Population Growth and Demographics of Beaver County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                The population of Beaver County, Utah in 2022 was 7,327, 10.1%
                up from the 6,657 who lived there in 2010. For comparison, the
                US population grew 7.7% and Utah's population grew 21.8% during
                that period. In 2022, the largest racial or ethnic group in
                Beaver County was the white (non-Hispanic) group, which had a
                population of 6,070. Between 2010 and 2022, the white
                (non-Hispanic) population had the most growth increasing by 344
                from 5,726 in 2010 to 6,070 in 2022. Among six age groups — 0 to
                4, 5 to 19, 20 to 34, 35 to 49, 50 to 64, and 65 and older — the
                65+ group was the fastest growing between 2010 and 2022 with its
                population increasing 34.7%. The 0 to 4 age group declined the
                most dropping 21.5% between 2010 and 2022. The median income for
                a Beaver County household was $66,705 and the median family
                income was $75,066, with a per-capita income of $24,540. The
                median income for males that were full-time employees was
                $53,514 and for females $36,696. 4.1% of the population and 2.9%
                of families were below the poverty line.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "100%",
              }}
            >
              <ButtonBase
                onClick={handleClickOpen(4, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(3)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 3 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[3].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[3].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[3].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[3].authors} /> */}
                  {/* <Button onClick={handleClickOpen(4,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 4}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  History of Beaver County, Utah: Formation and Territorial
                  Changes
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    Explorers of European descent first visited present-day
                    Beaver County in the 1776 Domínguez-Escalante Expedition.
                    The proposed territory of Deseret (soon changed to Utah
                    Territory) began with the arrival of Mormon pioneers in
                    1847. After the immediate Great Salt Lake City area was
                    settled, settlers moved into more outlying areas, including
                    the future Beaver County area. The county was created by the
                    Utah territorial legislature from a section of Iron County
                    on January 5, 1856, before the settlement of Beaver town was
                    founded later that year. The county was named for the
                    animal, which was plentiful there.[5] The county boundary as
                    delineated by that act included areas in present-day
                    Colorado and Nevada. The defined boundary was altered on
                    January 16, 1861, by the creation of two counties in present
                    Nevada. The county area was effectively reduced on February
                    28, 1861, by the US Congress when it created Colorado
                    Territory, taking all of the Beaver County areas east of 109
                    degrees longitude. The county's boundary was enlarged on
                    January 17, 1861, by the addition of parcels from Millard,
                    Sanpete, and St. Mary's counties. However, its west area was
                    reduced by the creation of the Nevada Territory on July 14,
                    1862 (this adjustment was altered on May 5, 1866, by the
                    action of the US Congress, effectively ceding all Beaver
                    County area west of 114 degrees longitude to Nevada
                    counties). The county area was also reduced on January 16,
                    1865, when Piute County was created from Beaver
                    territory.[6] A quarter of the county's workers are employed
                    by hog processor Smithfield Foods.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
              <ButtonBase
                onClick={handleClickOpen(5, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(4)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 4 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[4].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[4].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[4].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[4].authors} /> */}
                  {/* <Button onClick={handleClickOpen(5,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 5}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Mining History of Beaver County: From Early Lead to the Horn
                  Silver Boom
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    In 1858 the Lincoln Mine northwest of Minersville may have
                    been the first mine opened in Utah. The mine produced lead,
                    which was smelted and shipped to Salt Lake City to make
                    ammunition—useful for Mormons who thought they would have to
                    fight Johnston’s Army.
                    <p>
                      During the 1870s, miners staked many claims and organized
                      mining districts. A couple of prospectors discovered a
                      rich silver vein that became the fabulous Horn Silver
                      Mine. Frisco, a wild boomtown nearby, sprang up in 1876.
                      The Horn attracted famous investors, such as J. Pierpont
                      Morgan
                    </p>
                    <p>
                      The Frisco boom lasted only a decade. In the early 20th
                      century, the Cactus Mine, near the town of Newhouse, west
                      of Frisco, produced gold, silver, copper, and other
                      minerals.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(3, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(5)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 5 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[5].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[5].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[5].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[5].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Button onClick={handleClickOpen(3,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 3}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Economic Development and Industry in Beaver County
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                Although the early settlers planted crops and grazed livestock,
                the county prospered in the 19th century because of a unique
                blend of mining, transportation, and trade in addition to
                farming.
                <p>
                  In Beaver City, the Beaver Woolen Mills turned out woolen
                  goods from the 1870s to the turn of the century. The mill sold
                  many of its products, especially blankets, to the miners in
                  Frisco. The Beaver Co-op store, once one of the largest
                  mercantile establishments south of Salt Lake City, opened in
                  1872. It too flourished because of mine and railroad workers.
                  Since the 1980s, three geothermal generating plants have
                  operated in Beaver County. These plants turn the energy of the
                  county’s hot springs into electricity. Recreation also adds to
                  the economy.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
