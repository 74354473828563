



import React from 'react';

const Table3 = () => {
    return (
        <div className="faq-item">
            <div className="faq-answer">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Education Level</th>
                                <th>Q4-2018</th>
                                <th>Q4-2019</th>
                                <th>Q4-2020</th>
                                <th>Q4-2021</th>
                                <th>Q4-2022</th>
                                <th>Q4-2023</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Less than High School</td>
                                <td>12,437</td>
                                <td>12,943</td>
                                <td>13,120</td>
                                <td>13,891</td>
                                <td>14,237</td>
                                <td>14,560</td>
                            </tr>
                            <tr>
                                <td>High School Graduate</td>
                                <td>45,678</td>
                                <td>46,222</td>
                                <td>47,009</td>
                                <td>48,345</td>
                                <td>50,230</td>
                                <td>51,003</td>
                            </tr>
                            <tr>
                                <td>Bachelor’s Degree</td>
                                <td>32,456</td>
                                <td>33,109</td>
                                <td>34,998</td>
                                <td>36,234</td>
                                <td>37,902</td>
                                <td>39,001</td>
                            </tr>
                            {/* Additional rows can be added here */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table3;
