




import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../styles/Geothermal.css";
import geothermalData from "./GeoThermalJson.json";

const spot = "/img/location1.png";



// Utility to detect mobile view
const isMobileView = () => window.innerWidth <= 778; // Adjust breakpoint as needed

// Custom marker icon with dynamic popup anchor
const createIcon = () =>
  new L.Icon({
    iconUrl: spot,
    iconSize: [35, 41],
    iconAnchor: [12, 41],
    popupAnchor: isMobileView() ? [0, 10] : [1, -34], // Below marker for mobile, above for others
  });


// Custom marker icon
const icon = new L.Icon({
  iconUrl: spot,
  iconSize: [35, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

// Helper component to pan the map
const MapFocus = ({ location }) => {
  const map = useMap();
  if (location && window.innerWidth <= 778) {
    map.flyTo(location, 5); // Fly to the selected location with zoom level 10
  }
  if (location && window.innerWidth > 778) {
    map.flyTo(location, 10); // Fly to the selected location with zoom level 10
  }
  return null;
};

const GeothermalMap = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const markerRefs = useRef([]); // Create refs for all markers
  const [searchTerm, setSearchTerm] = useState("");

  const handleLocationClick = (location, index) => {
    setSelectedLocation(location); // Update selected location

    // Open the corresponding popup
    if (markerRefs.current[index]) {
      markerRefs.current[index].openPopup();
    }
  };

  const filteredData = geothermalData.filter(
    (spot) =>
      spot.COUNTY.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot.REGION_LOC.toLowerCase().includes(searchTerm.toLowerCase()) ||
      spot.SOURCE.toLowerCase().includes(searchTerm.toLowerCase()) 
      
  );

  return (
    <div className="geothermal-map">
      {/* Left-side list */}
      <div className="list-container">
        <h2>Geothermal Hotspots <span className="text-sm font-base">(*Not all hotspots are marked, only those with fully verified datas are displayed.)</span></h2>
        <div className="flex justify-center items-center my-4">
        <input
    type="text"
    placeholder="Search by County, Region or Source"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    className="search-input p-2 border border-gray-300 rounded-md"
  />
  </div>
        <h3>
        <div className="list-item-content">
        <span>Map No.</span>
        <span>County</span>
        <span>Region</span>
        <span>Source</span>
        </div>
        </h3>
        <ul>
          {filteredData
            .filter((spot) => spot.DEPTH)
            .filter((spot) => spot.FLOW)
            .map((spot, index) => (
              <li
                key={index}
                className="list-item"
                onClick={() =>
                  handleLocationClick(
                    { lat: spot.LAT, lng: spot.LONG, details: spot },
                    index
                  )
                }
              >
                <div className="list-item-content">
                <span>{spot.MAPNO}</span>
                <span>{spot.COUNTY}</span>
                  <span>{spot.REGION_LOC || `Hotspot ${index + 1}`}</span>
                  <span>{spot.SOURCE}</span>
                 
                </div>
              </li>
            ))}
        </ul>
      </div>

      {/* Map */}
      <div className="map-container ">
        <MapContainer
          center={[39.32098, -111.093731]}
          zoom={7}
          minZoom={6}
          maxBounds={[
            [30, -105], // Southwest corner
            [50, -120], // Northeast corner
          ]}
          maxBoundsViscosity={1.0}
          className="map"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {selectedLocation && (
            <MapFocus location={[selectedLocation.lat, selectedLocation.lng]} />
          )}
          {filteredData
            .filter((spot) => spot.DEPTH)
            .filter((spot) => spot.FLOW)
            .map((spot, index) => (
              <Marker
                key={index}
                position={[spot.LAT, spot.LONG]}
                // icon={icon}
                // ref={(el) => (markerRefs.current[index] = el)} // Save reference to marker
                icon={createIcon()} // Use dynamic icon here
                ref={(el) => (markerRefs.current[index] = el)}
              >
                <Popup>
                  <b>
                    <u>Geothermal Hotspot</u>
                  </b>
                  <br />
                  Region: {spot.REGION_LOC} <br />
                  County: {spot.COUNTY} <br />
                  Type: {spot.TYPE}
                  <br />
                  Map No.: {spot.MAPNO}
                  <br />
                  Depth: {spot.DEPTH}
                  <br />
                  Class: {spot.CLASS}
                  <br />
                  Reference: {spot.REFERENCE}
                  <br />
                  Source: {spot.SOURCE}
                  <br />
                  Location: {spot.LOCATION}
                  <br />
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default GeothermalMap;
