import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
// import * as React from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonBase } from "@mui/material";

const utah = "/img/utah-county.webp";
const utahValley = "/img/utah-valley.jpg";
const utahDemography = "/img/utah-demography.jpg";
const utahEconomy = "/img/utah-economy.jpg";
const utahHighlight = "/img/Utah-County.png";

const cardData = [
  {
    img: utah,
    tag: "About Utah",
    title: "Utah County: The Heart of Growth Beyond Salt Lake City",
    description:
      "Utah County is the second-most populous county in the U.S. state of Utah. The county seat and largest city is Provo, which is the state fourth-largest city, and the largest outside of Salt Lake County.",
    authors: [
      { name: "", avatar: "" },
      { name: "", avatar: "" },
    ],
  },
  {
    img: utahValley,
    tag: "Geography",
    title: "Utah Valley: A Scenic Hub Between Mountains and Lake",
    description:
      "Utah Valley lies at the center of the county, lined by the mountains of the Wasatch Range on the east. Utah Lake occupies a large part of the valley. The elevation ranges from 4,487 feet (1,368 m) above sea level at the lake to 11,928 feet (3,636 m) at the peak of Mount Nebo.",
    authors: [{ name: "", avatar: "/static/images/avatar/6.jpg" }],
  },
  {
    img: utahDemography,
    tag: "Utah Demography",
    title: "Utah County: Population Surge, Demographic Shifts & Income Trends",
    description:
      "The population of Utah County, Utah in 2022 was 702,434, 35.1% up from the 520,033 who lived there in 2010. For comparison, the US population grew 7.7% and Utah population grew 21.8% during that period.In 2022, the largest racial or ethnic group in Utah County was the white (non-Hispanic) group, which had a population of 563,518. Between 2010 and 2022, the white (non-Hispanic) population had the most growth increasing by 125,177 from 438,341 in 2010 to 563,518 in 2022.Among six age groups — 0 to 4, 5 to 19, 20 to 34, 35 to 49, 50 to 64, and 65 and older — the 20 to 34 group was the fastest growing between 2010 and 2022 with its population increasing 35.4%. The 0 to 4 age group grew the least increasing 1% between 2010 and 2022.The median income for a Utah County household was $77,057 and the median family income was $83,938, with a per-capita income of $27,365. The median income for males that were full-time employees was $60,356 and for females $37,391. 10.0% of the population and 6.9% of families were below the poverty line.",
    authors: [{ name: "", avatar: "/static/images/avatar/7.jpg" }],
  },
  {
    img: "https://picsum.photos/800/450?random=4",
    tag: "History",
    title: "Utah Valley: From Early Expeditions to the Birth of Provo",
    description:
      "The Dominguez-Escalante expedition passed through Utah Valley in 1776. The Timpanogots Utes in the valley welcomed the padres and helped them.",
    authors: [{ name: "", avatar: "/static/images/avatar/3.jpg" }],
  },
  {
    img: "https://picsum.photos/800/450?random=45",
    tag: "Settler",
    title: "Mormon Pioneers and the Ute Struggle in Utah Valley",
    description:
      "A party of Mormons explored the valley in 1847. In January 1849 Brigham Young sent a fishing party to catch fish for the hungry settlers in Salt Lake Valley. ",
    authors: [
      { name: "", avatar: "/static/images/avatar/4.jpg" },
      { name: "", avatar: "/static/images/avatar/5.jpg" },
    ],
  },
  {
    img: utahEconomy,
    tag: "Utah Economy",
    title:
      "Utah County: From Orchard Roots to Industrial and Academic Innovation",
    description:
      "The settlers farmed, growing crops, fruit, and sugar beets. Orem was once full of orchards. Today, developers have taken out the orchards and built roads, commercial centers, and subdivisions.",
    authors: [{ name: "", avatar: "/static/images/avatar/2.jpg" }],
  },
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  height: "100%",
  backgroundColor: (theme.vars || theme).palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const SyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

// function Author({ authors }) {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         gap: 2,
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         padding: '16px',
//       }}
//     >
//       <Box
//         sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
//       >
//         <AvatarGroup max={3}>
//           {authors.map((author, index) => (
//             <Avatar
//               key={index}
//               alt={author.name}
//               src={author.avatar}
//               sx={{ width: 24, height: 24 }}
//             />
//           ))}
//         </AvatarGroup>
//         <Typography variant="caption">
//           {authors.map((author) => author.name).join(', ')}
//         </Typography>
//       </Box>
//       <Typography variant="caption">February 6, 2025</Typography>
//     </Box>
//   );
// }

// Author.propTypes = {
//   authors: PropTypes.arrayOf(
//     PropTypes.shape({
//       avatar: PropTypes.string.isRequired,
//       name: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
// };

export function Search() {
  return (
    <FormControl sx={{ width: { xs: "100%", md: "25ch" } }} variant="outlined">
      {/* <OutlinedInput
        size="small"
        id="search"
        placeholder="Search…"
        sx={{ flexGrow: 1 }}
        startAdornment={
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <SearchRoundedIcon fontSize="small" />
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'search',
        }}
      /> */}
    </FormControl>
  );
}

export default function MainContent() {
  //const [open, setOpen] = React.useState(false);
  //const [openIndex, setOpenIndex] = React.useState(null);
  const [openDialogIndex, setOpenDialogIndex] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };
  // const handleClickOpen = (index, scrollType) => () => {
  //   setOpenIndex(index);
  //   setScroll(scrollType);
  // };
  const handleClickOpen = (index, scrollType) => () => {
    setOpenDialogIndex(index);
    setScroll(scrollType);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClose = () => {
  //   setOpenIndex(null);
  // };
  const handleClose = () => {
    setOpenDialogIndex(null);
  };

  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDialogIndex !== null) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialogIndex]);
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  const handleClick = () => {
    console.info("You clicked the filter chip.");
    window.scroll({
      top: 200,
      left: 100,
      behavior: "smooth",
    });
  };
  const handleClick1 = () => {
    console.info("You clicked the filter chip.");
    window.scroll({
      top: 800,
      left: 100,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div>
          <Typography variant="h1" gutterBottom sx={{ color: "#fff" }}>
            Utah County
          </Typography>
          <Typography sx={{ color: "#fff" }}>
            Your Gateway to Utah’s Trends & Analytics
          </Typography>
        </div>
        {/* <div>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: '' }}>
          The legislature of the State of Deseret created a county on January 31, 1850, to govern the civic affairs of Utah Valley, which by the 1850s was 
          bustling with newly arrived settlers. The county name is derived from the valley name, which is derived from the Spanish name (Yuta) for the Ute Indians. 
          The State of Deseret dissolved soon after (April 5, 1851), but the counties it had set in place continued. There is little record of any official activity 
          conducted by the fledgling county until April 18, 1852, when a full slate of county officials was published, and recordkeeping began. The first courthouse 
          was built in central Provo in 1866–67. It was soon outgrown and was replaced by a second courthouse (1872–73). By the 1920s, this building was also cramped,
           and the decision was made to erect a combined city-county building, which was completed in 1926.
          </Typography>
        </div> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2, // space between text and image
            color: "#fff",
          }}
        >
          <Typography sx={{ fontSize: "1.3rem", flex: 1 }}>
            <span className="text-2xl font-bold italic">
              Population Demography
            </span>
            <p>
              The population of Utah County, Utah in 2022 was 702,434, 35.1% up
              from the 520,033 who lived there in 2010. For comparison, the US
              population grew 7.7% and Utah's population grew 21.8% during that
              period. In 2022, the largest racial or ethnic group in Utah County
              was the white (non-Hispanic) group, which had a population of
              563,518. Between 2010 and 2022, the white (non-Hispanic)
              population had the most growth increasing by 125,177 from 438,341
              in 2010 to 563,518 in 2022.
            </p>
            <span className="text-2xl font-bold italic">At a Glance</span>
            <p>
              The legislature of the State of Deseret created a county on
              January 31, 1850, to govern the civic affairs of Utah Valley,
              which by the 1850s was bustling with newly arrived settlers. The
              county name is derived from the valley name, which is derived from
              the Spanish name (Yuta) for the Ute Indians. The State of Deseret
              dissolved soon after (April 5, 1851), but the counties it had set
              in place continued. There is little record of any official
              activity conducted by the fledgling county until April 18, 1852,
              when a full slate of county officials was published, and
              recordkeeping began. The first courthouse was built in central
              Provo in 1866–67. It was soon outgrown and was replaced by a
              second courthouse (1872–73). By the 1920s, this building was also
              cramped, and the decision was made to erect a combined city-county
              building, which was completed in 1926.
            </p>
          </Typography>
          <Box
            component="img"
            src={utahHighlight}
            alt="Historic Courthouse"
            sx={{
              width: { xs: "100px", md: "200px" }, // Responsive width
              height: "auto",
              borderRadius: 2,
              boxShadow: 3, // Adds subtle shadow
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: "row",
            gap: 1,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
          }}
        >
          <Search />
          {/* <IconButton size="small" aria-label="RSS feed">
          <RssFeedRoundedIcon />
        </IconButton> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "100%",
            justifyContent: "space-between",
            alignItems: { xs: "start", md: "center" },
            gap: 4,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              gap: 3,
              overflow: "auto",
            }}
          >
            <Chip onClick={handleClick} size="medium" label="About Us" />
            <Chip
              onClick={handleClick}
              size="medium"
              label="Geography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="Utah Demography"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="Utah Economy"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="History"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <Chip
              onClick={handleClick1}
              size="medium"
              label="Settler"
              sx={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "row",
              gap: 1,
              width: { xs: "100%", md: "fit-content" },
              overflow: "auto",
            }}
          >
            <Search />
            {/* <IconButton size="small" aria-label="RSS feed">
            <RssFeedRoundedIcon />
          </IconButton> */}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(0, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(0)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 0 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[0].img}
                  sx={{
                    aspectRatio: "16 / 9",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />

                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[0].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[0].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[0].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Author authors={cardData[0].authors} /> */}
                {/* <Button onClick={handleClickOpen('paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
                {/* <Button onClick={handleClickOpen(0, 'paper')} sx={{ alignSelf: 'center' }}>
  Learn More...
</Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>

          <Dialog
            open={openDialogIndex === 0}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Utah County: The Heart of Growth Beyond Salt Lake City
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')} */}
                Utah County is the second-most populous county in the U.S. state
                of Utah. The county seat and largest city is Provo, which is the
                state's fourth-largest city, and the largest outside of Salt
                Lake County. Utah Lake is a remnant of the vast ancient Lake
                Bonneville. Though large in size, Utah Lake is very shallow—18
                feet at its deepest point. The grassy Utah Valley became
                farmlands after settlement. Now the farmlands are gradually
                being developed into subdivisions and commercial areas.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 6 }}>
            <ButtonBase
              onClick={handleClickOpen(1, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(1)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 1 ? "Mui-focused" : ""}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[1].img}
                  aspect-ratio="16 / 9"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[1].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[1].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[1].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[1].authors} /> */}
                {/* <Button onClick={handleClickOpen(1,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 1}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Utah Valley: A Scenic Hub Between Mountains and Lake
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                Utah Valley lies at the center of the county, lined by the
                mountains of the Wasatch Range on the east. Utah Lake occupies a
                large part of the valley. The elevation ranges from 4,487 feet
                (1,368 m) above sea level at the lake to 11,928 feet (3,636 m)
                at the peak of Mount Nebo. The most striking geographical
                features of Utah County are the Wasatch Mountains along the
                eastern boundary and Utah Lake, the state’s largest fresh-water
                lake, to the west. The high mountains, rising over 11,000 feet,
                receive heavy snowfall, which feeds the rivers and creeks that
                flow into the lake. Utah Lake is a remnant of the vast ancient
                Lake Bonneville. Though large in size, Utah Lake is very
                shallow—18 feet at its deepest point. The grassy Utah Valley
                became farmlands after settlement. Now the farmlands are
                gradually being developed into subdivisions and commercial
                areas.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(2, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(2)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 2 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[2].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[2].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[2].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[2].description}
                  </StyledTypography>
                </SyledCardContent>
                {/* <Author authors={cardData[2].authors} /> */}
                {/* <Button onClick={handleClickOpen(2,'paper')} sx={{ alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 2}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Utah County: Population Surge, Demographic Shifts & Income Trends
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                The population of Utah County, Utah in 2022 was 702,434, 35.1%
                up from the 520,033 who lived there in 2010. For comparison, the
                US population grew 7.7% and Utah's population grew 21.8% during
                that period. In 2022, the largest racial or ethnic group in Utah
                County was the white (non-Hispanic) group, which had a
                population of 563,518. Between 2010 and 2022, the white
                (non-Hispanic) population had the most growth increasing by
                125,177 from 438,341 in 2010 to 563,518 in 2022. Among six age
                groups — 0 to 4, 5 to 19, 20 to 34, 35 to 49, 50 to 64, and 65
                and older — the 20 to 34 group was the fastest growing between
                2010 and 2022 with its population increasing 35.4%. The 0 to 4
                age group grew the least increasing 1% between 2010 and 2022.
                The median income for a Utah County household was $77,057 and
                the median family income was $83,938, with a per-capita income
                of $27,365. The median income for males that were full-time
                employees was $60,356 and for females $37,391. 10.0% of the
                population and 6.9% of families were below the poverty line.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
          <Grid size={{ xs: 12, md: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "100%",
              }}
            >
              <ButtonBase
                onClick={handleClickOpen(4, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(3)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 3 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[3].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[3].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[3].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[3].authors} /> */}
                  {/* <Button onClick={handleClickOpen(4,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 4}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Utah Valley: From Early Expeditions to the Birth of Provo
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    The Dominguez-Escalante expedition passed through Utah
                    Valley in 1776. The Timpanogots Utes in the valley welcomed
                    the padres and helped them. Fur trappers stopped at the lake
                    in the 1820s. Etienne Provost was one of them—and Provo was
                    named after him. John C. Fremont visited the area in 1844.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
              <ButtonBase
                onClick={handleClickOpen(5, "paper")}
                sx={{ width: "100%", height: "100%" }}
              >
                <SyledCard
                  variant="outlined"
                  onFocus={() => handleFocus(4)}
                  onBlur={handleBlur}
                  tabIndex={0}
                  className={focusedCardIndex === 4 ? "Mui-focused" : ""}
                  sx={{ height: "100%" }}
                >
                  <SyledCardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <Typography
                        gutterBottom
                        variant="caption"
                        component="div"
                      >
                        {cardData[4].tag}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {cardData[4].title}
                      </Typography>
                      <StyledTypography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {cardData[4].description}
                      </StyledTypography>
                    </div>
                  </SyledCardContent>
                  {/* <Author authors={cardData[4].authors} /> */}
                  {/* <Button onClick={handleClickOpen(5,'paper')} sx={{  alignSelf: 'center', mt:-6 }}>
    Learn More...
  </Button> */}
                </SyledCard>
              </ButtonBase>
              <Dialog
                open={openDialogIndex === 5}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  Mormon Pioneers and the Ute Struggle in Utah Valley
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    A party of Mormons explored the valley in 1847. In January
                    1849 Brigham Young sent a fishing party to catch fish for
                    the hungry settlers in Salt Lake Valley. That same year,
                    other settlers moved to Utah Valley and established a fort
                    at the present site of Provo. The Utes who lived in the
                    valley resented the pioneers taking their land. Violent
                    conflicts arose, but no matter how hard they fought for
                    their land, the Utes couldn’t win. They lost their land and
                    had to move to the reservation in the Uinta Basin.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {/* <Button onClick={handleClose}>Subscribe</Button> */}
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <ButtonBase
              onClick={handleClickOpen(3, "paper")}
              sx={{ width: "100%", height: "100%" }}
            >
              <SyledCard
                variant="outlined"
                onFocus={() => handleFocus(5)}
                onBlur={handleBlur}
                tabIndex={0}
                className={focusedCardIndex === 5 ? "Mui-focused" : ""}
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  alt="green iguana"
                  image={cardData[5].img}
                  sx={{
                    height: { sm: "auto", md: "50%" },
                    aspectRatio: { sm: "16 / 9", md: "" },
                  }}
                />
                <SyledCardContent>
                  <Typography gutterBottom variant="caption" component="div">
                    {cardData[5].tag}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {cardData[5].title}
                  </Typography>
                  <StyledTypography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {cardData[5].description}
                  </StyledTypography>
                </SyledCardContent>

                {/* <Button onClick={handleClickOpen(3,'paper')} sx={{  alignSelf: 'center' }}>
    Learn More...
  </Button> */}
              </SyledCard>
            </ButtonBase>
          </Grid>
          <Dialog
            open={openDialogIndex === 3}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Utah County: From Orchard Roots to Industrial and Academic
              Innovation
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                The settlers farmed, growing crops, fruit, and sugar beets. Orem
                was once full of orchards. Today, developers have taken out the
                orchards and built roads, commercial centers, and subdivisions.
                Utah County has fostered important industries. The Provo Woolen
                Mill became the state’s first large manufacturing plant in 1873.
                In the late 1800s and early 1900s mines in American Fork Canyon
                and in the Tintic Mining District produced much wealth. During
                World War II, the United States needed a lot of steel to build
                ships, airplanes, and more. Geneva Steel was built on the east
                shore of Utah Lake during this time. The government chose this
                location because they thought that steel plants closer to the
                coast could be destroyed by the country’s enemies. Geneva Steel
                employed many people in the county for many years. In 2001 it
                closed. The buildings were demolished. Brigham Young University,
                established in 1875 as the Brigham Young Academy, has grown to
                be a major university. BYU and Utah Valley University have an
                important influence on the county.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
