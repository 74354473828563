import Accordion from "react-bootstrap/Accordion";
import "../styles/AerospaceFaqSection.css";
import { useEffect } from "react";
import React, { useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import config from "../config/config.json";
import { useLocation } from "react-router-dom";
import AeroTable1 from "../AerospaceTables/AeroTable1";
import axios from "axios";
import AeroTable2 from "../AerospaceTables/AeroTable2";
import AeroTable3 from "../AerospaceTables/AeroTable3";
import AeroTable4 from "../AerospaceTables/AeroTable4";
import AeroTable5 from "../AerospaceTables/AeroTable5";
import AeroSpace6 from "../AerospaceTables/AeroTable6";
import AeroTable7 from "../AerospaceTables/AeroTable7";
import AeroTable8 from "../AerospaceTables/AeroTable8";
import AeroTable10 from "../AerospaceTables/AeroTable10";
import AeroTable11 from "../AerospaceTables/AeroTable11";
import AeroTable12 from "../AerospaceTables/AeroTable12";
import AeroTable13 from "../AerospaceTables/AeroTable13";
import AeroTable14 from "../AerospaceTables/AeroTable14";
import AeroTable15 from "../AerospaceTables/AeroTable15";
import AeroTable16 from "../AerospaceTables/AeroTable16";
import AeroTable17 from "../AerospaceTables/AeroTable17";
import AeroTable18 from "../AerospaceTables/AeroTable18";
import AeroTable19 from "../AerospaceTables/AeroTable19";
import AeroTable20 from "../AerospaceTables/AeroTable20";
import AeroTable21 from "../AerospaceTables/AeroTable21";
import AeroTable22 from "../AerospaceTables/AeroTable22";
import AeroTable9 from "../AerospaceTables/AeroTable9";
import AeroTable23 from "../AerospaceTables/AeroTable23";
import AeroTable24 from "../AerospaceTables/AeroTable24";
import AeroTable25 from "../AerospaceTables/AeroTable25";
import AeroTable26 from "../AerospaceTables/AeroTable26";
import AeroTable27 from "../AerospaceTables/AeroTable27";
import AeroTable28 from "../AerospaceTables/AeroTable28";

export const AerospaceFaqSection = ({ searchTerm }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Employment-Trend");

  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [expandedSubQuestion, setExpandedSubQuestion] = useState(null);

  const [employmentShareByGender, setemploymentShareByGender] = useState(null);
  const [employmentDataBySex, setEmploymentDataBySex] = useState(null);
  const [
    employmentDataaerospaceEmploymentTrend,
    setemploymentDataaerospaceEmploymentTrend,
  ] = useState(null);
  const [
    employmentDataByGrowthRateByGender,
    setemploymentDataByGrowthRateByGender,
  ] = useState(null);
  const [
    employmentDataByWomenEmploymentTrend,
    setemploymentDataByWomenEmploymentTrend,
  ] = useState(null);
  const [
    employmentByAgeAndGender,
    setemploymentByAgeAndGender,
  ] = useState(null);
  const [employmentByAgeGroupPercentage, setemploymentByAgeGroupPercentage] =
    useState(null);
  const [
    employmentByAgeAndGenderWithAverage,
    setemploymentByAgeAndGenderWithAverage,
  ] = useState(null);
  const [averageMaleWorkersByAgeGroup, setaverageMaleWorkersByAgeGroup] =
    useState(null);
  const [averageYearlyCountByGender, setaverageYearlyCountByGender] =
    useState(null);
  const [genderAgeGroupRatioByYear, setgenderAgeGroupRatioByYear] =
    useState(null);
  const [maleEmployment2023ByAgeGroup, setmaleEmployment2023ByAgeGroup] =
    useState(null);
  const [genderEmployment2023, setgenderEmployment2023] = useState(null);
  const [data_getGendergrowth_percentage, setdata_getGendergrowth_percentage] =
    useState(null);
  const [
    data_ad_get_employment_trend_2022_2023,
    setdata_ad_get_employment_trend_2022_2023,
  ] = useState(null);
  const [
    data_ad_get_all_education_employment_data,
    setdata_ad_get_all_education_employment_data,
  ] = useState(null);
  const [
    data_ad_get_bachelors_employment_data,
    setdata_ad_get_bachelors_employment_data,
  ] = useState(null);
  const [
    data_ad_get_education_employment_data,
    setdata_ad_get_education_employment_data,
  ] = useState(null);
  const [
    data_ad_get_employment_by_education_and_gender_2021,
    setdata_ad_get_employment_by_education_and_gender_2021,
  ] = useState(null);
  const [
    data_ad_get_high_school_employment_2023,
    setdata_ad_get_high_school_employment_2023,
  ] = useState(null);
  const [
    data_ad_get_high_school_employment_by_years,
    setdata_ad_get_high_school_employment_by_years,
  ] = useState(null);
  const [data_ad_get_male_female_ratio, setdata_ad_get_male_female_ratio] =
    useState(null);


    const [
      data_ad_get_employment_by_year_and_race_per,
      setdata_ad_get_employment_by_year_and_race_per
    ] = useState(null)

    const [data_ad_get_employment_by_year_and_race_with_avg,
      setdata_ad_get_employment_by_year_and_race_with_avg
    ]=useState(null);


    const [data_ad_get_total_employment_by_year,
      setdata_ad_get_total_employment_by_year
    ]= useState(null);

    const [data_ad_get_employment_change_by_year_and_race ,
      setdata_ad_get_employment_change_by_year_and_race
    ]= useState(null);


    const [data_ad_get_total_and_cumulative_employment_by_year , 
      setdata_ad_get_total_and_cumulative_employment_by_year
    ] = useState(null);


    const [data_ad_get_employment_by_year_and_race , setdata_ad_get_employment_by_year_and_race]= useState(null);

  useEffect(() => {
    axios
      .get(config.APP_URL + "/reports/employment-reports")
      .then((response) => {
        if (response.data.success) {
          setemploymentShareByGender(response.data.data.employmentShareByGender);
          setEmploymentDataBySex(response.data.data.employmentBySexLabel);
          setemploymentDataaerospaceEmploymentTrend(
            response.data.data.aerospaceEmploymentTrend
          );
          setemploymentDataByGrowthRateByGender(
            response.data.data.growthRateByGender
          );
          setemploymentDataByWomenEmploymentTrend(
            response.data.data.womenEmploymentTrend
          );
          setemploymentByAgeAndGender(
            response.data.data.employmentByAgeAndGender
          );
          setemploymentByAgeGroupPercentage(
            response.data.data.employmentByAgeGroupPercentage
          );
          setemploymentByAgeAndGenderWithAverage(
            response.data.data.employmentByAgeAndGenderWithAverage
          );
          setaverageMaleWorkersByAgeGroup(
            response.data.data.averageMaleWorkersByAgeGroup
          );
          setaverageYearlyCountByGender(
            response.data.data.averageYearlyCountByGender
          );
          setgenderAgeGroupRatioByYear(
            response.data.data.genderAgeGroupRatioByYear
          );
          setmaleEmployment2023ByAgeGroup(
            response.data.data.maleEmployment2023ByAgeGroup
          );
          setgenderEmployment2023(response.data.data.genderEmployment2023);
          setdata_getGendergrowth_percentage(
            response.data.data.data_getGendergrowth_percentage
          );
          setdata_ad_get_employment_trend_2022_2023(
            response.data.data.data_ad_get_employment_trend_2022_2023
          );
          setdata_ad_get_all_education_employment_data(
            response.data.data.data_ad_get_all_education_employment_data
          );
          setdata_ad_get_bachelors_employment_data(
            response.data.data.data_ad_get_bachelors_employment_data
          );
          setdata_ad_get_education_employment_data(
            response.data.data.data_ad_get_education_employment_data
          );
          setdata_ad_get_employment_by_education_and_gender_2021(
            response.data.data
              .data_ad_get_employment_by_education_and_gender_2021
          );
          setdata_ad_get_high_school_employment_2023(
            response.data.data.data_ad_get_high_school_employment_2023
          );
          setdata_ad_get_high_school_employment_by_years(
            response.data.data.data_ad_get_high_school_employment_by_years
          );
          setdata_ad_get_male_female_ratio(
            response.data.data.data_ad_get_male_female_ratio
          );

          setdata_ad_get_high_school_employment_by_years(response.data.data.data_ad_get_high_school_employment_by_years)

        

          setemploymentByAgeGroupPercentage(response.data.data.employmentByAgeGroupPercentage);


          setdata_ad_get_employment_by_year_and_race_per( response.data.data.data_ad_get_employment_by_year_and_race_per)

          setdata_ad_get_employment_by_year_and_race_with_avg(response.data.data.data_ad_get_employment_by_year_and_race_with_avg)

          setdata_ad_get_total_employment_by_year(response.data.data.data_ad_get_total_employment_by_year)

          setdata_ad_get_employment_change_by_year_and_race(response.data.data.data_ad_get_employment_change_by_year_and_race)

          setdata_ad_get_total_and_cumulative_employment_by_year(response.data.data.data_ad_get_total_and_cumulative_employment_by_year)


          setdata_ad_get_employment_by_year_and_race(response.data.data.data_ad_get_employment_by_year_and_race);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const question = searchParams.get("question");

    if (tab) setActiveTab(tab);
    if (question) setExpandedQuestion(decodeURIComponent(question));
  }, [location.search]);

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    setExpandedQuestion(null); // Reset expanded question when switching tabs
  };

  return (
    <div className="w-[90vw] h-auto p-1 pt-4 mt-[3rem] border border-gray-300 rounded-lg shadow-lg  justify-center items-center mx-auto">
      <Tabs
        id="controlled-tab-example"
        className="mb-3"
        activeKey={activeTab}
        onSelect={handleTabSelect}
      >
        {/* employment trend */}

        <Tab
          eventKey="Employment-Trend"
          title="Employment Trend"
          id="Employment-Trend"
        >
          <Accordion>
            <Accordion.Item
              eventKey="What are the differences in employment counts between males and females in Utah's Aerospace and Defense manufacturing from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What are the differences in employment counts between males and females in Utah's Aerospace and Defense manufacturing from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. How have the employment trends for men and women in Utah's Aerospace and Defense manufacturing industry varied from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {employmentDataBySex ? (
                  <AeroTable2 apiData={employmentDataBySex} />
                ) : (
                  <p>Loading...</p>
                )}
                {/* <AeroTable2/> */}

                {/* <Table1/> */}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What are the total employment trends in Utah's Aerospace and Defense manufacturing sector from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What are the total employment trends in Utah's Aerospace and Defense manufacturing sector from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What are the total employment trends in Utah's Aerospace
                  and Defense manufacturing sector from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {employmentDataaerospaceEmploymentTrend ? (
                  <AeroTable3
                    apiData={employmentDataaerospaceEmploymentTrend}
                  />
                ) : (
                  <p>Loading...</p>
                )}
                {/* <TableNo8/> */}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What are the growth rates for each:(i) Total Employment(ii) Women(iii) Men"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What are the growth rates for each:(i) Total Employment(ii) Women(iii) Men"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What are the growth rates for each: (i) Total Employment
                  (ii) Women (iii) Men
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {employmentDataByGrowthRateByGender ? (
                  <AeroTable4 apiData={employmentDataByGrowthRateByGender} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the Women employment changed  in Aerospace Parts Manufacturing since 2018?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the Women employment changed  in Aerospace Parts Manufacturing since 2018?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. How has the Women employment changed in Aerospace Parts
                  Manufacturing since 2018?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {/* <TableNo17/> */}
                {employmentDataByWomenEmploymentTrend ? (
                  <AeroTable5 apiData={employmentDataByWomenEmploymentTrend} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How has the share of Women in manufacturing changed since 2018?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How has the share of Women in manufacturing changed since 2018?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. How has the share of Women in manufacturing changed since
                  2018?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {employmentByAgeAndGender ? (
                  <AeroSpace6 data={employmentByAgeAndGender} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Age Analysis */}

        <Tab eventKey="Age Analysis" title="Age Analysis" id="Age-Analysis">
          {/* <Accordion defaultActiveKey={['0']} alwaysOpen className="w-full"> */}
          <Accordion>
            <Accordion.Item
              eventKey="How does the age range of women in manufacturing differ from that of men?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the age range of women in manufacturing differ from that of men?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. How does the age range of women in manufacturing differ from that of men?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {/* <AeroTable1/> */}
                {/* <AeroTable1/> */}
                {employmentShareByGender ? (
                  <AeroTable1 data={employmentShareByGender} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the overall female workforce percentage among all age groups?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the overall female workforce percentage among all age groups?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What is the overall female workforce percentage among all age groups?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {employmentByAgeGroupPercentage ? (
                  <AeroTable7 data={employmentByAgeGroupPercentage} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the average count of males and females in each age group across the years? "
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the average count of males and females in each age group across the years? "
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What is the average count of males and females in each age
                  group across the years?{" "}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {employmentByAgeAndGenderWithAverage ? (
                  <AeroTable8 data={employmentByAgeAndGenderWithAverage} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which age group has the highest number of male workers?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group has the highest number of male workers?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. Which age group has the highest number of male workers?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {averageMaleWorkersByAgeGroup ? (
                  <AeroTable9 data={averageMaleWorkersByAgeGroup} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which year had the highest total male and female population across all age groups?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which year had the highest total male and female population across all age groups?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. Which year had the highest total male and female population
                  across all age groups?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {averageYearlyCountByGender ? (
                  <AeroTable10 data={averageYearlyCountByGender} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What is the male-to-female ratio for each age group in each year?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the male-to-female ratio for each age group in each year?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. What is the male-to-female ratio for each age group in each
                  year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {genderAgeGroupRatioByYear ? (
                  <AeroTable11 data={genderAgeGroupRatioByYear} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does male employment differ between the 14-18 and 22-24 age groups in the year 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  7. How does male employment differ between the 14-18 and 22-24
                  age groups in the year 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {maleEmployment2023ByAgeGroup ? (
                  <AeroTable12 data={maleEmployment2023ByAgeGroup} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which age group has the highest percentage of female workers in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which age group has the highest percentage of female workers in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  8. Which age group has the highest percentage of female
                  workers in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {genderEmployment2023 ? (
                  <AeroTable13 data={genderEmployment2023} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="What is the percentage increase or decrease in the male and female populations from 2018 to 2023 for each age group?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the percentage increase or decrease in the male and female populations from 2018 to 2023 for each age group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  9. What is the percentage increase or decrease in the male
                 populations from 2018 to 2023 for each age group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_getGendergrowth_percentage ? (
                  <AeroTable14 data={data_getGendergrowth_percentage} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Are there any noticeable trends in the younger age groups (14-18, 19-21) compared to older groups (22-24, 25-34)?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Are there any noticeable trends in the younger age groups (14-18, 19-21) compared to older groups (22-24, 25-34)?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  10. Are there any noticeable trends in the younger age groups
                  (14-18, 19-21) compared to older groups (22-24, 25-34)?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_employment_trend_2022_2023 ? (
                  <AeroTable15 data={data_ad_get_employment_trend_2022_2023} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="In which year did the male or female population reach its peak for each age group?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "In which year did the male or female population reach its peak for each age group?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  11. In which year did the male or female population reach its
                  peak for each age group?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_male_female_ratio ? (
                  <AeroTable22 data={data_ad_get_male_female_ratio} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

   

        {/* Education Analysis */}

        <Tab
          eventKey="Education Analysis"
          title="Education Analysis"
          id="Education-Analysis"
        >
          <Accordion>


          <Accordion.Item
              eventKey="What is the trend of total educational attainment from 2018 to 2023 for all genders?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the trend of total educational attainment from 2018 to 2023 for all genders?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. What is the trend of total educational attainment from 2018
                  to 2023 for all genders?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_education_employment_data ? (
                  <AeroTable18 data={data_ad_get_education_employment_data} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

          
            <Accordion.Item
              eventKey="How does the percentage of females attaining a bachelor's or advanced degree change year by year?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the percentage of females attaining a bachelor's or advanced degree change year by year?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. How does the count of females attaining a bachelor's
                  or advanced degree change year by year?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_bachelors_employment_data ? (
                  <AeroTable17 data={data_ad_get_bachelors_employment_data} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item
              eventKey="What was the total count of All Education Categories by gender each year from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the total count of All Education Categories by gender each year from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What was the total count of "All Education Categories" by
                  gender each year from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_all_education_employment_data ? (
                  <AeroTable16
                    data={data_ad_get_all_education_employment_data}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>


           
            <Accordion.Item
              eventKey="What is the highest academic qualification obtained by each gender in 2021?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the highest academic qualification obtained by each gender in 2021?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. What is the highest academic qualification obtained by each gender in 2021?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_employment_by_education_and_gender_2021 ? (
                  <AeroTable19
                    data={data_ad_get_employment_by_education_and_gender_2021}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What is the gender-based breakdown of high school or equivalent degree attainment in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the gender-based breakdown of high school or equivalent degree attainment in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the gender-based breakdown of high school or equivalent degree attainment in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_high_school_employment_2023 ? (
                  <AeroTable20 data={data_ad_get_high_school_employment_2023} />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How does the attainment of high school or equivalent education vary between males and females over the years? "
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the attainment of high school or equivalent education vary between males and females over the years? "
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. How does the attainment of high school or equivalent
                  education vary between males and females over the years?{" "}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {data_ad_get_high_school_employment_by_years ? (
                  <AeroTable21
                    data={data_ad_get_high_school_employment_by_years}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>






             {/* Race Analysis */}

         <Tab eventKey="Race Analysis" title="Race Analysis" id="Race-Analysis">
          <Accordion>
            <Accordion.Item
              eventKey=" Which race group had the highest population in 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  " Which race group had the highest population in 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                1. Which race group had the highest population in 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>

              {data_ad_get_employment_by_year_and_race_per ? (
                  <AeroTable23
                    data={data_ad_get_employment_by_year_and_race_per}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What was the percentage increase in population for the Asian Alone group from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the percentage increase in population for the Asian Alone group from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. What was the percentage increase in population for the "Asian Alone" group from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>

              {data_ad_get_employment_by_year_and_race_with_avg ? (
                  <AeroTable24
                    data={data_ad_get_employment_by_year_and_race_with_avg}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="What was the average American Indian or Alaska Native Alone population from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What was the average American Indian or Alaska Native Alone population from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. What was the average "American Indian or Alaska Native Alone" population from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>

              {data_ad_get_total_employment_by_year ? (
                  <AeroTable25
                    data={data_ad_get_total_employment_by_year}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Did any racial minority groups experience population decline in recent years?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Did any racial minority groups experience population decline in recent years?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  4. Did any racial minority groups experience population decline in recent years?
                </span>
              </Accordion.Header>
              <Accordion.Body>

              {data_ad_get_employment_change_by_year_and_race ? (
                  <AeroTable26
                    data={data_ad_get_employment_change_by_year_and_race}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item
              eventKey="What is the cumulative population increase across all races from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "What is the cumulative population increase across all races from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  5. What is the cumulative population increase across all races from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body>

              {data_ad_get_total_and_cumulative_employment_by_year ? (
                  <AeroTable27
                    data={data_ad_get_total_and_cumulative_employment_by_year}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item
              eventKey="Which racial group had the smallest population in 2020?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which racial group had the smallest population in 2020?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  6. Which racial group had the smallest population in 2020?
                </span>
              </Accordion.Header>
              <Accordion.Body>

              {data_ad_get_employment_by_year_and_race ? (
                  <AeroTable28
                    data={data_ad_get_employment_by_year_and_race}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab> 

        {/* Ethinicity Analysis */}

        {/* <Tab
          eventKey="Ethinicity Analysis"
          title="Ethinicity Analysis"
          id="Ethinicity-Analysis"
        >
          <Accordion activeKey={expandedQuestion}>
            <Accordion.Item
              eventKey="Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the largest decrease in employment share percentage between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  1. Which ethnic group had the largest decrease in employment
                  share percentage between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body><TableNo15/></Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "Which ethnic group had the lowest growth in employment numbers from 2018 to 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  2. Which ethnic group had the lowest growth in employment
                  numbers from 2018 to 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body><TableNo34/></Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
              className="pb-3"
              onClick={() =>
                setExpandedQuestion(
                  "How does the change in employment numbers for Hispanic or Latino workers compare to the overall change for all ethnicities between 2018 and 2023?"
                )
              }
            >
              <Accordion.Header className="custom-accordion-header">
                <span className="accordion-title">
                  3. How does the change in employment numbers for Hispanic or
                  Latino workers compare to the overall change for all
                  ethnicities between 2018 and 2023?
                </span>
              </Accordion.Header>
              <Accordion.Body><TableNo35/></Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab> */}
      </Tabs>
    </div>
  );
};
