import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import the autoTable plugin
const utahData = "/img/1.png";
const bodex = "/img/bodexLogo.png";
const kpi = "/img/KPI-DASH 2.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TableNo37 = () => {
  const pdfRef = useRef();

  const industryData = [
    { sector: "Aerospace Product and Parts Manufacturing", employment: 10564 },
    {
      sector: "Agriculture, Construction, and Mining Machinery Manufacturing",
      employment: 859,
    },
    {
      sector: "Alumina and Aluminum Production and Processing",
      employment: 584,
    },
    { sector: "Animal Food Manufacturing", employment: 1279 },
    { sector: "Animal Slaughtering and Processing", employment: 4116 },
    {
      sector: "Apparel Accessories and Other Apparel Manufacturing",
      employment: 157,
    },
    { sector: "Apparel Knitting Mills", employment: 41 },
    {
      sector: "Architectural and Structural Metals Manufacturing",
      employment: 7098,
    },
    { sector: "Audio and Video Equipment Manufacturing", employment: 276 },
    { sector: "Bakeries and Tortilla Manufacturing", employment: 4002 },
    { sector: "Basic Chemical Manufacturing", employment: 1249 },
    { sector: "Beverage Manufacturing", employment: 1359 },
    {
      sector: "Medical Equipment and Supplies Manufacturing",
      employment: 12902,
    },
    { sector: "Motor Vehicle Parts Manufacturing", employment: 4380 },
    { sector: "Pharmaceutical and Medicine Manufacturing", employment: 8005 },
    { sector: "Plastics Product Manufacturing", employment: 7519 },
    { sector: "Printing and Related Support Activities", employment: 5120 },
    {
      sector: "Semiconductor and Other Electronic Component Manufacturing",
      employment: 3173,
    },
    {
      sector: "Textile and Fabric Finishing and Fabric Coating Mills",
      employment: 349,
    },
    {
      sector:
        "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing",
      employment: 573,
    },
  ];

  const chartData = {
    labels: industryData.map((item) => item.sector),
    datasets: [
      {
        label: "Employment Count",
        data: industryData.map((item) => item.employment),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "#4B5563",
          font: { size: 12 },
        },
        title: {
          display: true,
          text: "Employment Count",
          color: "#4B5563",
          font: { size: 14 },
        },
      },
      x: {
        ticks: {
          color: "#4B5563",
          font: { size: 12 },
          autoSkip: false, // Ensure all labels are shown
          maxRotation: 90,
          minRotation: 45,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: { font: { size: 12 } },
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.dataset.label}: ${context.raw.toLocaleString()}`,
        },
        bodyFont: { size: 12 },
      },
    },
  };

  // const downloadPDF = () => {
  //   const input = pdfRef.current;
  //   const originalWidth = input.style.width;
  //   input.style.width = "1500px"; // Temporarily widen the container for better rendering

  //   html2canvas(input, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("landscape", "mm", "a4");

  //     const imgWidth = 297; // A4 width in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(utahData, 'PNG', 90, 4, 30, 30);
  //     pdf.setFontSize(26);
  //     pdf.text('UTahData.org', 125, 22);
  //     pdf.link(50, 15, 60, 10, { url: 'https://utahdata.org' });

  //     pdf.setFontSize(12);
  //     pdf.setLineWidth(0.5);
  //     pdf.line(10, 35, 285, 35); // Line after header

  //     // First page content (table with first 16 elements)
  //   //  pdf.addImage(imgData, "PNG", 5, 5, imgWidth, imgHeight);

  //     // Table data for first page (first 16 items)
  //     const firstPageData = industryData.slice(0, 16);
  //     const firstPageTableData = firstPageData.map(item => [item.sector, item.employment]);

  //     // Add table to the first page
  //     pdf.text('Employment by Industry (Table)', 135, 45);
  //     pdf.autoTable({
  //       startY: 50,
  //       head: [['Industry Sector', 'Employment Count']],
  //       body: firstPageTableData,
  //     });

  //     // Footer for the first page
  //     pdf.line(10, 260, 200, 260); // Line before footer
  //     const footerStartY = 265;
  //     pdf.addImage(utahData, 'PNG', 33, 265, 20, 20);
  //     pdf.setFontSize(10);
  //     pdf.setTextColor(0, 122, 255);
  //     pdf.text('Built by', 55, 275);
  //     pdf.addImage(bodex, 'PNG', 68, 269, 44, 10);
  //     pdf.text('using', 112, 275);
  //     pdf.addImage(kpi, 'PNG', 125, 265, 20, 20);
  //     pdf.text('20250211.v1.113', 148, 275);
  //     pdf.line(10, 35, 200, 35);

  //     const currentURL = window.location.href;
  //     const currentDate = new Date().toLocaleDateString();
  //     pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
  //     pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

  //     // Second page content (remaining 4 items)
  //     pdf.addPage();
  //     const secondPageData = industryData.slice(16, 20);
  //     const secondPageTableData = secondPageData.map(item => [item.sector, item.employment]);

  //     pdf.text('Employment by Industry (Table)', 95, 45);
  //     pdf.autoTable({
  //       startY: 15,
  //       head: [['Industry Sector', 'Employment Count']],
  //       body: secondPageTableData,
  //     });

  //     // Add chart to second page
  //     pdf.text('Employment by Industry (Chart)', 135, 100);
  //    // pdf.addImage(imgData, "PNG", 5, 110, imgWidth, imgHeight);

  //     pdf.save("industry-employment-data.pdf");
  //     input.style.width = originalWidth;
  //   });
  // };

  const downloadPDF = () => {
    const input = pdfRef.current;
    const originalWidth = input.style.width;
    input.style.width = "1500px"; // Temporarily widen the container for better rendering

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png"); // This is the image of the whole content (table + chart)
      const pdf = new jsPDF("landscape", "mm", "a4");

      const imgWidth = 297; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add header and first page content
      pdf.addImage(imgData, "PNG", 5, 35, imgWidth, imgHeight); // Adds the table and chart image to the first page
      pdf.setFontSize(12);
      // pdf.text('Employment by Industry (Table)', 135, 45);

      // Table data for first page (first 16 items)
      const firstPageData = industryData.slice(0, 16);
      const firstPageTableData = firstPageData.map((item) => [
        item.sector,
        item.employment,
      ]);

      // Add table to the first page
      // pdf.autoTable({
      //   startY: 50,
      //   head: [['Industry Sector', 'Employment Count']],
      //   body: firstPageTableData,
      // });
      pdf.addImage(utahData, "PNG", 90, 4, 30, 30);
      pdf.setFontSize(26);
      pdf.text("UTahData.org", 125, 22);
      pdf.link(50, 15, 60, 10, { url: "https://utahdata.org" });

      pdf.setFontSize(12);
      pdf.setLineWidth(0.5);
      pdf.line(10, 35, 285, 35); // Line after header
      // Footer for the first page
      pdf.line(10, 260, 200, 260); // Line before footer
      // const footerStartY = 265;
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 55, 275);
      pdf.text("using", 112, 275);
      pdf.text("20250211.v1.113", 148, 275);
      pdf.line(10, 35, 200, 35);

      // const currentURL = window.location.href;
      // const currentDate = new Date().toLocaleDateString();
      //  pdf.text(`Downloaded from: ${currentURL}`, 10, 295);
      // pdf.text(`Downloaded on: ${currentDate}`, 150, 295);

      // Add second page content (remaining 4 items)
      pdf.addPage();
      const secondPageData = industryData.slice(16, 20);
      const secondPageTableData = secondPageData.map((item) => [
        item.sector,
        item.employment,
      ]);
      pdf.addImage(imgData, "PNG", 5, -185, imgWidth, imgHeight + 20);
      pdf.line(10, 170, 280, 170); // Line before footer
      const footerStartY = 265;
      pdf.addImage(utahData, "PNG", 73, 175, 20, 20);
      pdf.setFontSize(10);
      pdf.setTextColor(0, 122, 255);
      pdf.text("Built by", 97, 185);
      pdf.addImage(bodex, "PNG", 108, 179, 44, 10);
      pdf.text("using", 155, 185);
      pdf.addImage(kpi, "PNG", 165, 175, 20, 20);
      pdf.text("20250211.v1.113", 187, 185);
      // pdf.line(10, 35, 200, 35);

      const currentURL = window.location.href;
      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Downloaded from: ${currentURL}`, 10, 208);
      pdf.text(`Downloaded on: ${currentDate}`, 150, 208);

      // pdf.text('Employment by Industry (Table)', 95, 45);
      // pdf.autoTable({
      //   startY: 15,
      //   head: [['Industry Sector', 'Employment Count']],
      //   body: secondPageTableData,
      // });

      // Add chart to second page
      // pdf.text('Employment by Industry (Chart)', 135, 100);
      // pdf.addImage(imgData, "PNG", 5, 110, imgWidth, imgHeight); // Adds the chart image to the second page

      // Save the PDF
      pdf.save("industry-employment-data.pdf");

      // Restore the original width of the container
      input.style.width = originalWidth;
    });
  };

  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">
        Employment by Industry Sector in 2021
      </h2>
      <div ref={pdfRef}>
        {/* Responsive Table */}
        <div className="table-responsive mb-4">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Industry Sector</th>
                <th>Employment Count</th>
              </tr>
            </thead>
            <tbody>
              {industryData.map((item, index) => (
                <tr key={index}>
                  <td>{item.sector}</td>
                  <td>{item.employment}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Chart */}
        {/* <div
          style={{
            overflowX: "auto", // Enables horizontal scroll
            display: "flex",
            justifyContent: "center",
            width: "100%", // Takes full width
          }}
        >
          <div
            style={{
              minWidth: "600px", // Minimum width to enable scroll
              width: "1000px", // Chart width, ensuring horizontal scroll on smaller screens
              height: "400px",
            }}
          > */}

        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "auto",
            overflowX: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%", // Takes 100% width of the parent div, but will scroll if needed
              minWidth: "600px", // Ensures a minimum width, enabling scroll when the chart exceeds screen size
              height: "400px",
            }}
          >
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center">
        <button
          onClick={downloadPDF}
          className="download-btn bg-red-800 hover:bg-red-950 p-2 text-white rounded-lg flex items-center justify-between"
        >
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

export default TableNo37;
