import React, { useRef, useState, useEffect } from "react";

import Form from "./Form";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import "../styles/Aerospace.css";
import ModalFunction from "./Modal";
import ChatWindow from "./Betsy";

const kpi = "/img/KPI-DASH 2.png";
const logo = "/img/1.png";

export default function AskBetsyAI() {
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const location = useLocation();
  const [modalShow, setModalShow] = React.useState(false);

  console.log("====================================", location);

  // Close dropdown when clicking outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col">
      {/* Form Section */}
      <ChatWindow data={location?.state} />
      {/* Footer */}
    </div>
  );
}
