import React from 'react'

export default function School22() {

    const schoolData = 
    [
        {"schoolName": "Apple Valley School", "numberOfStudents": 653, "grades": "PK-6"},
        {"schoolName": "Art City School", "numberOfStudents": 460, "grades": "PK-6"},
        {"schoolName": "Barnett School", "numberOfStudents": 413, "grades": "PK-6"},
        {"schoolName": "Bridges Nebo Transition Center", "numberOfStudents": 141, "grades": "PK-12"},
        {"schoolName": "Brockbank School", "numberOfStudents": 505, "grades": "PK-6"},
        {"schoolName": "Brookside School", "numberOfStudents": 393, "grades": "PK-6"},
        {"schoolName": "Canyon School", "numberOfStudents": 495, "grades": "PK-6"},
        {"schoolName": "Cherry Creek School", "numberOfStudents": 505, "grades": "PK-6"},
        {"schoolName": "Diamond Fork Middle", "numberOfStudents": 1039, "grades": "6-8"},
        {"schoolName": "East Meadows School", "numberOfStudents": 584, "grades": "PK-6"},
        {"schoolName": "Foothills School", "numberOfStudents": 723, "grades": "PK-6"},
        {"schoolName": "Goshen School", "numberOfStudents": 288, "grades": "PK-6"},
        {"schoolName": "Hobble Creek School", "numberOfStudents": 459, "grades": "PK-6"},
        {"schoolName": "Landmark High", "numberOfStudents": 342, "grades": "KG-12"},
        {"schoolName": "Larsen School", "numberOfStudents": 371, "grades": "PK-6"},
        {"schoolName": "Maple Grove Middle", "numberOfStudents": 1210, "grades": "6-8"},
        {"schoolName": "Maple Mountain High", "numberOfStudents": 1740, "grades": "7-12"},
        {"schoolName": "Maple Ridge School", "numberOfStudents": 769, "grades": "PK-6"},
        {"schoolName": "Mapleton Junior High", "numberOfStudents": 1213, "grades": "7-9"},
        {"schoolName": "Mapleton School", "numberOfStudents": 589, "grades": "PK-6"},
        {"schoolName": "Meadow Brook School", "numberOfStudents": 691, "grades": "PK-6"},
        {"schoolName": "Mt. Loafer School", "numberOfStudents": 622, "grades": "PK-6"},
        {"schoolName": "Mt. Nebo Middle", "numberOfStudents": 1113, "grades": "6-7"},
        {"schoolName": "Nebo Advanced Learning Center", "numberOfStudents": 2, "grades": "KG-12"},
        {"schoolName": "Nebo Online School", "numberOfStudents": 1736, "grades": "KG-12"},
        {"schoolName": "Orchard Hills School", "numberOfStudents": 572, "grades": "PK-6"},
        {"schoolName": "Park School", "numberOfStudents": 417, "grades": "PK-6"},
        {"schoolName": "Park View School", "numberOfStudents": 358, "grades": "PK-6"},
        {"schoolName": "Payson High", "numberOfStudents": 1554, "grades": "6-12"},
        {"schoolName": "Payson Jr High", "numberOfStudents": 1135, "grades": "7-9"},
        {"schoolName": "Rees School", "numberOfStudents": 641, "grades": "PK-6"},
        {"schoolName": "Riverview School", "numberOfStudents": 708, "grades": "PK-6"},
        {"schoolName": "Sage Creek School", "numberOfStudents": 541, "grades": "PK-6"},
        {"schoolName": "Salem Hills High", "numberOfStudents": 1561, "grades": "9-12"},
        {"schoolName": "Salem Junior High", "numberOfStudents": 993, "grades": "7-9"},
        {"schoolName": "Salem School", "numberOfStudents": 501, "grades": "PK-6"},
        {"schoolName": "Santaquin School", "numberOfStudents": 623, "grades": "PK-6"},
        {"schoolName": "Sierra Bonita Elementary", "numberOfStudents": 623, "grades": "PK-6"},
        {"schoolName": "Spanish Fork High", "numberOfStudents": 1519, "grades": "9-12"},
        {"schoolName": "Spanish Fork Jr High", "numberOfStudents": 1103, "grades": "7-9"},
        {"schoolName": "Spanish Oaks School", "numberOfStudents": 429, "grades": "PK-6"},
        {"schoolName": "Spring Canyon Middle", "numberOfStudents": 1086, "grades": "6-8"},
        {"schoolName": "Spring Lake School", "numberOfStudents": 584, "grades": "PK-6"},
        {"schoolName": "Springville High", "numberOfStudents": 1612, "grades": "8-12"},
        {"schoolName": "Springville Jr High", "numberOfStudents": 1168, "grades": "7-9"},
        {"schoolName": "Taylor School", "numberOfStudents": 455, "grades": "PK-6"},
        {"schoolName": "Valley View Middle", "numberOfStudents": 894, "grades": "6-7"},
        {"schoolName": "Westside School", "numberOfStudents": 580, "grades": "PK-6"},
        {"schoolName": "Wilson School", "numberOfStudents": 331, "grades": "PK-6"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Nebo
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
