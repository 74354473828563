import React from 'react'

export default function TrustCompany() {

    const schoolData = 
    [
        {
            "Name": "Deseret Trust Company",
            "Address": "60 E S TEMPLE STE 800, SALT LAKE CITY, UT 84111",
            "President": "M. Wesley Mashburn, President",
            "Contact No:": "801-363-2991"
        }
    ]
      
  return (
    <div
    className=" mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
        Trust Company in Utah
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Bank Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Address</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">CEO/President</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Contact No.</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school['Name']}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.Address}</td>
                <td className="border border-gray-300 px-4 py-2">{school['President']}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{school['Contact No:']}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
