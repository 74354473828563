import React from 'react'

export default function School26() {

    const schoolData = 
    [
        {"schoolName": "Ecker Hill Middle", "numberOfStudents": 705, "grades": "6-7"},
        {"schoolName": "Jeremy Ranch Preschool", "numberOfStudents": 49, "grades": "PK-PK"},
        {"schoolName": "Jeremy Ranch School", "numberOfStudents": 454, "grades": "KG-5"},
        {"schoolName": "Mc Polin Preschool", "numberOfStudents": 0, "grades": "PK-PK"},
        {"schoolName": "Mcpolin School", "numberOfStudents": 403, "grades": "PK-5"},
        {"schoolName": "Mpes Preschool", "numberOfStudents": 46, "grades": "PK-PK"},
        {"schoolName": "Park City High", "numberOfStudents": 1224, "grades": "10-12"},
        {"schoolName": "Parley'S Park Preschool", "numberOfStudents": 28, "grades": "PK-PK"},
        {"schoolName": "Parleys Park School", "numberOfStudents": 432, "grades": "KG-5"},
        {"schoolName": "Trailside Preschool", "numberOfStudents": 28, "grades": "PK-PK"},
        {"schoolName": "Trailside School", "numberOfStudents": 363, "grades": "KG-5"},
        {"schoolName": "Treasure Mtn. Junior High School", "numberOfStudents": 772, "grades": "8-9"}
      ]
      
      
      
      
      
  return (
    <div
    className="min-h-screen mt-[2rem]  flex flex-col items-center justify-center"
    style={{ backgroundColor: 'rgb(210, 234, 255)' }}
  >
    <div className="overflow-x-auto w-full px-4 sm:px-8 lg:px-16 mt-10 mb-[3rem]">
      <div className="text-center text-3xl sm:text-4xl lg:text-5xl font-bold mt-[5rem] mb-5">
      Schools in Park City
      </div>
     
      <div className="overflow-x-auto">
  <table className="table-auto border-collapse border bg-white border-gray-300 w-full">
          <thead>
            <tr>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">School Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2 text-center">Number of Students</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Grades</th>
             
            </tr>
          </thead>
          <tbody >
            {schoolData.map((school) => (
              <tr key={school.sn} className='hover:bg-gray-100'>
                <td className="border border-gray-300 px-4 py-2 text-center ">{school.schoolName}</td>
            
                <td className="border border-gray-300 px-4 py-2 text-center">{school.numberOfStudents}</td>
                <td className="border border-gray-300 px-4 py-2">{school.grades}</td>
              </tr>
            ))}
          </tbody>
        </table>
</div>

    </div>
    </div>
  )
}
