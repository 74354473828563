import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

// import AppAppBar from './BoxElder-components/AppAppBar3';
// import MainContent from './BoxElder-components/MainContent3';
// import Latest from './BoxElder-components/Latest3';
import AppAppBar from './Carbon-components/AppAppBar5';
import MainContent from './Carbon-components/MainContent5';
import Latest from './Carbon-components/Latest5';
//import MainContent from './Bever-components/MainContent3';
//import Latest from './Bever-components/Latest3';
//import Footer from './Utah-components/Footer';
import AppTheme from '../../shared-theme/AppTheme';

export default function BlogCarbon(props) {
  return (
    <AppTheme {...props} >
      <CssBaseline enableColorScheme />
      {/* <AppAppBar /> */}
      <div className='bg-black'>
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column',  mt: { xs: 10, sm: 16, md: 18, lg:12 }, gap: 4, pt:4 }}
      >
      {/* <Container
  maxWidth="lg"
  component="main"
  sx={{
    display: 'flex',
    flexDirection: 'column',
    my: 16,
    gap: 4,
    bgcolor: 'black', // Set background to black
    color: 'white',   // Ensure text is visible
    minHeight: '100vh' // Make sure it covers the full screen
  }}
> */}

        <MainContent />
        <Latest />
      </Container>
      </div>
      {/* <Footer /> */}
    </AppTheme>
  );
}