// import React from 'react';

// const map = "/img/utah-map.webp";

// export default function Dashboard() {
//   return (
//     <>
//   <h1 className='mt-[30vh]'>Welcome to the UTahData.org!</h1>
//   </>
// );
// }







import React from 'react';
import { useLocation } from 'react-router-dom';

const map = "/img/utah-map1.webp";

export default function Dashboard() {
    const location = useLocation();
    const { name } = location.state || {};
  return (
    <div
      style={{
        backgroundImage: `url(${map})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh', // Full screen height
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        color: 'white', // Text color
        textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)', // Text shadow for better contrast
       
      }}
    >
    <div className='flex flex-col'>
    
    <div className='text-5xl font-bold text-center '> Welcome {name || "User"} to the UTahData.org!</div>
    </div>

    </div>
  );
}
